import FontAwesome from "@expo/vector-icons/build/FontAwesome";
import React from "react";
import { View } from "react-native";
import { CommonReload } from "../filter/CommonReload";
import FreeSelectMonth from "../filter/FreeSelectMonth";

export function WorkListHeader(props: any) {
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <FreeSelectMonth />
      <CommonReload />
    </View>
  );
}
