import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import {
  Organisations,
  OrganisationsModel,
} from "../../models/OrganisationsModel";
import CommonBumonFilter, { OrgMode, OrgOption } from "./CommonBumonFilter";
import {
  LocalStorageKey,
  OrganisationsFilterStorage,
} from "../../models/LocalStorage";
import { loadOrgLocalStorage } from "../../util/OrgUtil";

/**
 * SelectOrgFilterのProps
 */
interface Props {
  /** LocalStorageのキー */
  localStorageKey: LocalStorageKey;
  /** 請求元 */
  defaultFrom?: OrgOption[];
  /** onChangeFromのコールバック */
  onChangeFrom: (v: OrgOption[]) => void;
  /** 請求先 */
  defaultTo?: OrgOption[];
  /** onChangeToのコールバック */
  onChangeTo: (v: OrgOption[]) => void;
  /** 選択肢の部門 */
  onFetchedAllOrgs?: (v: Organisations[]) => void;
  /** 受領画面用の表示 */
  receiptMode?: boolean;
}

const SelectOrgFilter = (props: Props) => {
  /**props */
  const {
    localStorageKey,
    defaultFrom,
    onChangeFrom,
    defaultTo,
    onChangeTo,
    onFetchedAllOrgs,
  } = props;
  /**部門（全て） */
  const [organisationsTo, setOrganisationsTo] = useState<Organisations[]>([]);
  const [organisationsFrom, setorganisationsFrom] = useState<Organisations[]>(
    []
  );
  const [hiddenList, setHiddenList] = useState<Organisations[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingLocalStorage, setIsLoadingLocalStorage] = useState(true);
  const [localStorage, setLocalStorage] = useState<OrganisationsFilterStorage>({
    toOrgOptions: undefined,
    fromOrgOptions: undefined,
  });

  const companyId = window.userInfo?.current_company_id ?? 0;

  /**
   * 部門一覧を取得する
   */
  const fetchOrganisations = async () => {
    let allOlgs: Organisations[] = [];
    if (window.companyOrganisations && window.companyOrganisations.length > 0) {
      //既に取得済みの場合
      setOrganisationsTo(window.companyOrganisations);
      allOlgs = window.companyOrganisations;
      const From = await OrganisationsModel.fetchFromForDropDown();
      const hiddenList = await OrganisationsModel.fetchFromForDropDownHidden();
      setorganisationsFrom(From);
      setHiddenList(hiddenList);
    } else {
      const To = await OrganisationsModel.fetchToForDropDown();
      const From = await OrganisationsModel.fetchFromForDropDown();
      const hiddenList = await OrganisationsModel.fetchFromForDropDownHidden();
      setOrganisationsTo(To);
      setorganisationsFrom(From);
      setHiddenList(hiddenList);
      allOlgs = To;
    }
    if (onFetchedAllOrgs) onFetchedAllOrgs(allOlgs);
    setIsLoading(false);
  };

  /**
   * LocalStorageに保存したフィルタを呼び出す。
   */
  async function loadLocalStorage() {
    let filter: any = await window.storage
      .load({ key: props.localStorageKey })
      .catch((err: any) => null);
    let data: OrganisationsFilterStorage = {
      toOrgOptions: undefined,
      fromOrgOptions: undefined,
    };
    try {
      if (filter !== null) {
        // 初期描写時にローカルストレージに保存していたセグメントを付与
        if (props.localStorageKey === "ReceiptFilter") {
          // 受領画面のみ他の使い方していたため旧ロジック適用（履歴ないため値としては無効）
          data.toOrgOptions = filter?.toOrgOptions;
          data.fromOrgOptions = filter?.fromOrgOptions;
        } else {
          filter?.forEach((item: any) => {
            data.toOrgOptions = item.orgOptions.toOrgOptions;
          });
          filter?.forEach((item: any) => {
            data.fromOrgOptions = item.orgOptions.fromOrgOptions;
          });
        }
      }
    } catch (error) {
      console.log("セグメント履歴がありません。");
    }

    setLocalStorage(data);
    setIsLoadingLocalStorage(false);
  }

  /**
   * LocalStorageにフィルタを保存する。
   */
  async function saveLocalStorage(data: OrganisationsFilterStorage) {
    let companyOrgs = await loadOrgLocalStorage(localStorageKey);
    // 存在しない場合は新しいオブジェクトを追加する
    let found = false;
    companyOrgs = companyOrgs.map((item) => {
      if (item.companyId === companyId) {
        found = true;
        // companyIdが一致する要素のdataを更新する
        return {
          ...item,
          orgOptions: {
            toOrgOptions: data?.toOrgOptions ?? undefined,
            fromOrgOptions: data?.fromOrgOptions ?? undefined,
          },
        };
      }
      return item;
    });

    // 存在しない場合は新しいオブジェクトを追加する
    if (!found) {
      companyOrgs.push({
        companyId: companyId,
        orgOptions: {
          toOrgOptions: data?.toOrgOptions ?? undefined,
          fromOrgOptions: data?.fromOrgOptions ?? undefined,
        },
      });
    }
    await window.storage
      .save({ key: props.localStorageKey, data: companyOrgs })
      .catch((err: any) => null);
    setLocalStorage(data);
  }

  useEffect(() => {
    loadLocalStorage();
    // APIからorganisationsを取得する処理を実行する
    fetchOrganisations();
  }, []);

  const onHandleChange = (orgMode: OrgMode, orgOption: OrgOption[]) => {
    let data = localStorage;
    switch (orgMode) {
      case OrgMode.TO:
        data.toOrgOptions = orgOption;
        onChangeTo(orgOption);
        break;

      case OrgMode.FROM:
        data.fromOrgOptions = orgOption;
        onChangeFrom(orgOption);
        break;

      default:
        break;
    }
    saveLocalStorage(data);
  };

  if (isLoadingLocalStorage) {
    return <></>;
  }
  return (
    <Row className="py-2">
      <Col xs={12} sm={6}>
        <div>
          <CommonBumonFilter
            isLoading={isLoading}
            organisations={organisationsFrom}
            orgMode={OrgMode.FROM}
            onSelected={(e) => {
              onHandleChange(OrgMode.FROM, e);
            }}
            defaultSelectedOrgs={defaultFrom}
            hiddenList={hiddenList}
          />
        </div>
      </Col>
      <Col xs={12} sm={6}>
        <div>
          <CommonBumonFilter
            receiptMode={props.receiptMode}
            isLoading={isLoading}
            organisations={organisationsTo}
            orgMode={OrgMode.TO}
            onSelected={(e) => {
              onHandleChange(OrgMode.TO, e);
            }}
            defaultSelectedOrgs={defaultTo}
          />
        </div>
      </Col>
    </Row>
  );
};

export default SelectOrgFilter;
