import * as React from "react";
import { useState, useEffect } from "react";
import {
  StyleSheet,
  useWindowDimensions,
  View,
  FlatList,
  TouchableOpacity,
} from "react-native";
import { Input, Button, CheckBox, Text } from "react-native-elements";
import Select from "react-select";
import { FontAwesome } from "@expo/vector-icons";

import { useNavigation } from "@react-navigation/native";
import { WorkType, WorkTypeModel } from "../../models/WorkTypeModel";

/**
 * 仕事分類選択画面の仕事分類区分
 */
export const selectWorkType = {
  large: 0,
  mid: 1,
  small: 2,
};

export type Option = {
  label: string;
  value: number;
};

export default function SelectWorkTypeScreen(props: any) {
  const navigation = useNavigation();
  const [worktypes, setWorkTypes] = useState<WorkType[]>([]);
  const [parents, setParents] = useState<WorkType[]>([]);
  const [parent_value, setParentValue] = useState({});

  const workTypeId = props.route.params.id ?? -1;
  const parentId = props.route.params.parentId ?? -1;
  const selectType = props.route.params.type;

  async function initMid() {
    // //中分類を取得する
    // const workType = await WorkTypeModel.fetchOptions({
    //   type: selectWorkType.mid,
    //   id: workTypeId,
    // });

    //中分類の一覧を取得する
    const workTypes = await WorkTypeModel.fetchOptions({
      // parent_id: workType[0]?.parent_id,
      type: selectWorkType.mid,
    });
    if (workTypes) {
      setWorkTypes(workTypes);
    }

    //大分類の一覧を取得する
    const parentWorkTypes = await WorkTypeModel.fetchOptions({
      type: selectWorkType.large,
    });

    // 配列の先頭に【すべて】を付ける

    const dummyAll = {
      company_id: 0, // 必須項目
      created_at: new Date().toISOString(), // 必須項目
      id: -1, // 必須項目
      name: "すべて", // 必須項目
      parent_id: -1, // 必須項目
      rank: 1, // 必須項目
      updated_at: new Date().toISOString(), // 必須項目
    };
    const dummyItem = WorkTypeModel.set(dummyAll);

    const parentWorkTypesWithDummy = [dummyItem, ...parentWorkTypes];

    if (parentWorkTypesWithDummy) {
      setParents(parentWorkTypesWithDummy);
      setParentValue({
        label: dummyAll.name,
        value: dummyAll.id,
      });
    }

    //中分類が存在
    // if (workType?.length > 0) {
    //   setParentValue({
    //     label: workType[0].parent_name,
    //     value: workType[0].parent_id,
    //   });
    // }
  }
  async function initSmall() {
    if (parentId <= 0) {
      alert("中分類仕事が選択されていません。");
      return;
    }

    //セレクトボックスの生成
    //選択中の選択項目を取得(１件のみの想定)
    const parentWorkType = await WorkTypeModel.fetchOptions({
      id: parentId,
      type: selectWorkType.mid,
    });
    if (parentWorkType.length > 0) {
      setParentValue({
        label: parentWorkType[0].name,
        value: parentWorkType[0].id,
      });
    }

    const workTypes = await WorkTypeModel.fetchOptions({
      parent_id: parentId,
      type: selectWorkType.small,
    });
    if (workTypes) {
      setWorkTypes(workTypes);
    }
  }

  async function changeWorktype(id: number) {
    const workTypes = await WorkTypeModel.fetchOptions({
      parent_id: id,
      type: selectWorkType.mid,
    });
    if (workTypes) {
      setWorkTypes(workTypes);
    }
  }

  useEffect(() => {
    if (selectType === selectWorkType.mid) {
      initMid();
    } else if (selectType === selectWorkType.small) {
      initSmall();
    }
    window.functions.unset = () => {
      props.route.params.setWorkTypeId(0);
      props.route.params.setWorkTypeName("");
      navigation.navigate(props.route.params.backScreen);
    };
  }, []);

  const worktypesToOptions = (workTypes: WorkType[]) => {
    const optirons: Option[] = workTypes.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    return optirons;
  };

  function keyExtractor(item: WorkType, index: number) {
    return item.id.toString();
  }

  function renderItem({ item }: any) {
    const parent = item?.parent;

    const parentTeiban = parent?.teiban_work_type_id;

    return (
      <View
        style={{
          height: 50,
          justifyContent: "center",
          paddingLeft: 10,
          paddingRight: 10,
          borderBottomColor: "#ccc",
          borderBottomWidth: 1,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            if (selectType === selectWorkType.mid) {
              props.route.params?.setWorkTypeId(item.id);
              props.route.params?.setWorkTypeName(item.label_name);
            } else if (selectType === selectWorkType.small) {
              props.route.params?.setWorkTypeSmallId(item.id);
              props.route.params?.setWorkTypeSmallName(item.label_name);
            }

            navigation.navigate(props.route.params.backScreen);
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View
              style={{ flex: 1, flexDirection: "row", alignItems: "center" }}
            >
              <Text
                style={{
                  fontSize: 17,
                  color: "#0F74CD",
                  fontWeight: "bold",
                }}
              >
                {`【${item.parent_name}】 `}
              </Text>
              <Text
                style={{
                  fontSize: 17,
                  color: "#000",
                }}
              >
                {item.name}
              </Text>
            </View>
            {item.id == props.route.params.id && (
              <FontAwesome
                name={"check"}
                size={24}
                style={{
                  color: "rgb(32, 137, 220)",
                  position: "absolute",
                  right: 10,
                  top: 0,
                }}
              />
            )}
          </View>
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={{ width: "100%", padding: 5, zIndex: 3000 }}>
        {}
        <Select
          options={worktypesToOptions(parents) ?? []}
          onChange={(val: any) => {
            setParentValue(val);
            changeWorktype(val.value);
          }}
          value={parent_value}
        />
      </View>
      <FlatList
        style={{ width: "100%", backgroundColor: "#fff", marginTop: 5 }}
        keyExtractor={keyExtractor}
        data={worktypes}
        renderItem={renderItem}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
  },
});
