import FontAwesome from "@expo/vector-icons/build/FontAwesome";
import React from "react";
import { View, TouchableOpacity, Text } from "react-native";
//ヘッダーカスタム
export function HeaderLeft(props: any) {
  return (
    <>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <TouchableOpacity
          onPress={() => {
            window.functions.openDrawer();
          }}
          style={{ paddingLeft: 10, paddingRight: 10 }}
        >
          <FontAwesome name="navicon" size={28} color="#aaa" {...props} />
        </TouchableOpacity>
      </View>
    </>
  );
}
