import FontAwesome from "@expo/vector-icons/build/FontAwesome";
import React from "react";
import { Form } from "react-bootstrap";
import { View, TouchableOpacity, Text } from "react-native";
import FreeSelectMonth from "../filter/FreeSelectMonth";
import { CommonReload } from "../filter/CommonReload";

export interface ReceiptHeaderProps {
  confChecked: boolean;
  onChange: (v: boolean) => void;
}

interface props {
  tintColor?: string;
}

export function ReceiptHeader(
  props: props,
  receiptHeaderProps: ReceiptHeaderProps
) {
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <FreeSelectMonth />
      <CommonReload />
    </View>
  );
}
