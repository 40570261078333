import React from "react";
import { View, Text } from "react-native";

type InvoiceSummaryProps = {
  to_org_name: string;
  from_org_name: string;
  work_type_name: string;
};

/**
 * 要約
 * @param props
 * @returns
 */
const InvoiceSummary: React.FC<InvoiceSummaryProps> = (props) => {
  return (
    <View style={{ marginBottom: 20 }}>
      <Text style={{ color: "#0000FF" }}>
        「{props.to_org_name}」へ{"\n"}「{props.from_org_name}」が{"\n"}「
        {props.work_type_name}
        」を請求
      </Text>
    </View>
  );
};

export default InvoiceSummary;
