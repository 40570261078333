import FontAwesome from "@expo/vector-icons/build/FontAwesome";
import React from "react";
import { View, TouchableOpacity, Text } from "react-native";
import FreeSelectMonth from "../filter/FreeSelectMonth";
import { CommonButton } from "../common/CommonButton";

export function HeaderTeibanSelect(props: any) {
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <CommonButton
        title="前月コピー"
        onPress={() => {
          window.functions.onPressButton01();
        }}
      />
      {/* <CommonButton
        title="一括コピー"
        onPress={() => {
          window.functions.onPressButton02();
        }}
      /> */}
      {/* <FreeSelectMonth hideSelecter={true} /> */}
    </View>
  );
}

// export function HeaderTeibanSelect(props: any) {
//   return (
//     <View style={{ flexDirection: "row" }}>
//       <TouchableOpacity
//         onPress={() => {
//           window.functions.teibanAtOnce();
//         }}
//         style={{ paddingLeft: 10, paddingRight: 10, marginTop: 3 }}
//       >
//         <Text style={{ fontSize: 16, color: "rgb(32, 137, 220)" }}>
//           一括登録
//         </Text>
//       </TouchableOpacity>
//       <TouchableOpacity
//         onPress={() => {
//           window.functions.openFilter();
//         }}
//         style={{ paddingLeft: 10, paddingRight: 10 }}
//       >
//         <FontAwesome name="filter" size={28} color="#aaa" {...props} />
//       </TouchableOpacity>
//     </View>
//   );
// }
