import {
  CardStyleInterpolators,
  createStackNavigator,
  HeaderBackButton,
} from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";

import InvoiceScreen from "../../screens/invoice/InvoiceScreen";
import InvoiceDetailScreen from "../../screens/invoice/InvoiceDetailScreen";
import SelectOrganisationScreen from "../../screens/filter/SelectOrganisationScreen";
import SelectWorkTypeScreen from "../../screens/filter/SelectWorkTypeScreen";

import FilterScreen from "../../screens/invoice/FilterScreen";
import TeibanFilterScreen from "../../screens/teiban_old/TeibanFilterScreen";

import TeibanSelectScreen from "../../screens/teiban_old/TeibanSelectScreen";

import { InvoiceTabParamList } from "../../types";
import { HeaderTeibanSelect } from "../../components/header/TeibanHeader";
import { HeaderLeft } from "../../components/header/LeftHeader";
import { InvoiceHeader } from "../../components/header/InvoiceHeader";
import { OrgHeader } from "../../components/header/OrderToInvoiceHeader";
import { SCREENS } from "../../constants/Screens";
import TeibanScreen from "../../screens/teiban/TeibanScreen";
import TeibanDetailScreen from "../../screens/teiban/TeibanDetailScreen";
import EditWorkTypeSmallScreen from "../../screens/filter/EditWorkTypeSmallScreen";
import DailyTeibanScreen from "../../screens/dailyTeiban/DailyTeibanScreen";
import DailyTeibanDetailScreen from "../../screens/dailyTeiban/DailyTeibanDetailScreen";

//請求一覧
const InvoiceTabStack = createStackNavigator<InvoiceTabParamList>();
export function InvoiceTabNavigator() {
  const navigation: any = useNavigation();

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("tabPress", () => {
      window.reloadFlag = true;
    });
    return unsubscribe;
  }, [navigation]);

  window.functions.openDrawer = () => navigation.openDrawer();
  return (
    <InvoiceTabStack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
    >
      <InvoiceTabStack.Screen
        name={SCREENS.INVOICE.INVOICE as keyof InvoiceTabParamList}
        component={InvoiceScreen}
        options={{
          headerTitle: "請求",
          headerLeft: HeaderLeft,
          headerRight: InvoiceHeader,
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name={SCREENS.INVOICE.INVOICE_DETAIL as keyof InvoiceTabParamList}
        component={InvoiceDetailScreen}
        options={{
          headerTitle: "請求書",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                if (props.label == "定番選択") {
                  window.navi.navigate("InvoiceTeibanSelectScreen");
                } else {
                  window.navi.navigate(SCREENS.INVOICE.INVOICE);
                }
              }}
            />
          ),
          //headerRight: null,
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name={SCREENS.INVOICE.SELECT_ORGANISATION as keyof InvoiceTabParamList}
        component={SelectOrganisationScreen}
        options={{
          headerTitle: "組織選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.INVOICE.INVOICE_DETAIL);
              }}
            />
          ),
          headerRight: OrgHeader,
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name={SCREENS.INVOICE.SELECT_WORK_TYPE as keyof InvoiceTabParamList}
        component={SelectWorkTypeScreen}
        options={{
          headerTitle: "仕事分類選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.INVOICE.INVOICE_DETAIL);
              }}
            />
          ),
          headerRight: OrgHeader,
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name={SCREENS.INVOICE.INVOICE_FILTER as keyof InvoiceTabParamList}
        component={FilterScreen}
        options={{
          headerTitle: "フィルター",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.INVOICE.INVOICE);
              }}
            />
          ),
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name={
          SCREENS.INVOICE
            .SELECT_INVOICE_ORGANISATION_FILTER as keyof InvoiceTabParamList
        }
        component={SelectOrganisationScreen}
        options={{
          headerTitle: "組織選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.INVOICE.INVOICE_FILTER);
              }}
            />
          ),
          headerRight: OrgHeader,
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name={
          SCREENS.INVOICE
            .SELECT_INVOICE_WORK_TYPE_FILTER as keyof InvoiceTabParamList
        }
        component={SelectWorkTypeScreen}
        options={{
          headerTitle: "仕事分類選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.INVOICE.INVOICE_DETAIL);
              }}
            />
          ),
          headerRight: OrgHeader,
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name={
          SCREENS.INVOICE.INVOICE_TEIBAN_SELECT as keyof InvoiceTabParamList
        }
        component={TeibanScreen}
        options={{
          headerTitle: "定番一覧",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.INVOICE.INVOICE);
              }}
            />
          ),
          headerRight: HeaderTeibanSelect,
          animationEnabled: true,
        }}
      />

      <InvoiceTabStack.Screen
        name={
          SCREENS.INVOICE.INVOICE_TEIBAN_DETAIL as keyof InvoiceTabParamList
        }
        component={TeibanDetailScreen}
        options={{
          headerTitle: "定番編集",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.INVOICE.INVOICE_TEIBAN_SELECT);
              }}
            />
          ),
          animationEnabled: true,
        }}
      />

      <InvoiceTabStack.Screen
        name={
          SCREENS.INVOICE.INVOICE_TEIBAN_FILTER as keyof InvoiceTabParamList
        }
        component={TeibanFilterScreen}
        options={{
          headerTitle: "フィルター",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.INVOICE.INVOICE_TEIBAN_SELECT);
              }}
            />
          ),
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name={
          SCREENS.INVOICE
            .INVOICE_TEIBAN_ORGANISATION_FILTER as keyof InvoiceTabParamList
        }
        component={SelectOrganisationScreen}
        options={{
          headerTitle: "組織選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.INVOICE.INVOICE_TEIBAN_DETAIL);
              }}
            />
          ),
          headerRight: OrgHeader,
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name={
          SCREENS.INVOICE
            .INVOICE_TEIBAN_WORK_TYPE_FILTER as keyof InvoiceTabParamList
        }
        component={SelectWorkTypeScreen}
        options={{
          headerTitle: "仕事分類選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.INVOICE.INVOICE_TEIBAN_DETAIL);
              }}
            />
          ),
          headerRight: OrgHeader,
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name={SCREENS.INVOICE.EDIT_WORK_TYPE_SMALL as keyof InvoiceTabParamList}
        component={EditWorkTypeSmallScreen}
        options={{
          headerTitle: "仕事(小分類)を登録",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.INVOICE.INVOICE_DETAIL);
              }}
            />
          ),
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name={SCREENS.INVOICE.DAILY_TEIBAN.LIST as keyof InvoiceTabParamList}
        component={DailyTeibanScreen}
        options={{
          headerTitle: "今日の仕事一覧",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.INVOICE.INVOICE);
              }}
            />
          ),
          // headerRight: HeaderTeibanSelect,
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name={SCREENS.INVOICE.DAILY_TEIBAN.EDIT as keyof InvoiceTabParamList}
        component={DailyTeibanDetailScreen}
        options={{
          headerTitle: "今日の仕事編集",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.INVOICE.DAILY_TEIBAN.LIST);
              }}
            />
          ),
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name={
          SCREENS.INVOICE.DAILY_TEIBAN.FILTER.ORG as keyof InvoiceTabParamList
        }
        component={SelectOrganisationScreen}
        options={{
          headerTitle: "組織選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.INVOICE.DAILY_TEIBAN.EDIT);
              }}
            />
          ),
          headerRight: OrgHeader,
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name={
          SCREENS.INVOICE.DAILY_TEIBAN.FILTER.WORK as keyof InvoiceTabParamList
        }
        component={SelectWorkTypeScreen}
        options={{
          headerTitle: "仕事分類選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.INVOICE.DAILY_TEIBAN.EDIT);
              }}
            />
          ),
          headerRight: OrgHeader,
          animationEnabled: true,
        }}
      />
    </InvoiceTabStack.Navigator>
  );
}
