import React, { useEffect, useState } from "react";
import Select, { GroupedOptionsType, ValueType } from "react-select";
import { Organisations } from "../../models/OrganisationsModel";
import { AssignmentType } from "../../models/AssignmentModel";
import { Text } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { SCREENS } from "../../constants/Screens";

/**
 * 請求元・請求先
 */
export const OptionCategory = {
  /** 全て */
  ALL: "全て",
  /** 自分の全受領権限*/
  ALL_MY_RECEIPT_ORG: "自分の全受領権限",
  /** 自分の全所属部門*/
  ALL_MY_ORG: "自分の全所属部門",
  /** 自分の受領権限*/
  MY_RECEIPT_ORG: "自分の受領権限",
  /** 自分の所属部門*/
  MY_ORG: "自分の所属部門",
  /** その他*/
  OTHER: "その他",
};
export type OptionCategory =
  (typeof OptionCategory)[keyof typeof OptionCategory];

/**
 * 部門オプション
 */
export interface OrgOption {
  label: string;
  value: string;
  organisations?: Organisations;
  type: OrgFilterType;
  category: OptionCategory;
}

/**
 * 請求元・請求先
 */
export const OrgMode = {
  /** 請求先 */
  TO: "請求先:",
  /** 請求元 */
  FROM: "請求元:",
};
export type OrgMode = (typeof OrgMode)[keyof typeof OrgMode];

/** 部門の選択対象タイプ */
export const OrgFilterType = {
  /** 自分 */
  SELF: { id: 1, label: "自分" },
  /** 全社 */
  ALL: { id: 2, label: "全社" },
  /** 選択 */
  OTHER: { id: 3, label: "選択" },
  /** 受領権限 */
  RECEIVE: { id: 4, label: "受領" },
} as const;
export type OrgFilterType = (typeof OrgFilterType)[keyof typeof OrgFilterType];

/** 全社のセグメント */
export const allCompanyOption: OrgOption = {
  value: OrgFilterType.ALL.id.toString(),
  label: "全社",
  type: OrgFilterType.ALL,
  category: OptionCategory.ALL,
};
/** 自分の全所属部門 */
export const departmentOption: OrgOption = {
  value: OrgFilterType.SELF.id.toString(),
  label: "自分の全所属部門",
  type: OrgFilterType.SELF,
  category: OptionCategory.ALL_MY_ORG,
};
/** 自分の全受領権限 */
export const receiveOption: OrgOption = {
  value: OrgFilterType.RECEIVE.id.toString(),
  label: "自分の全受領権限",
  type: OrgFilterType.RECEIVE,
  category: OptionCategory.ALL_MY_RECEIPT_ORG,
};

interface Props {
  /** 読み込み中フラグ */
  isLoading: boolean;
  /** 請求先・組織側 */
  orgMode: OrgMode;
  /** 選択用部門一覧 */
  organisations: Organisations[];
  /** 選択イベント */
  onSelected: (v: OrgOption[]) => void;
  /** 初期選択の部門 */
  defaultSelectedOrgs?: OrgOption[];
  /** 受領画面用の表示 */
  receiptMode?: boolean;
  /** 請求元チェックが外れているレコードリスト*/
  hiddenList?: Organisations[];
}

/**
 * 部門フィルター
 */
const CommonBumonFilter = (props: Props) => {
  const {
    isLoading,
    orgMode,
    organisations,
    onSelected,
    defaultSelectedOrgs,
    hiddenList,
  } = props;
  const route = useRoute();
  const [historyList, setHistoryList] = useState<
    { companyId: number; optionList: OrgOption[] }[]
  >([]);
  const [usageHistoryList, setUsageHistoryList] = useState<
    { companyId: number; optionList: OrgOption[] }[]
  >([]);
  const navigation = useNavigation();
  let makeDefaultSelectedOrgs = defaultSelectedOrgs;
  hiddenList?.forEach((item: any) => {
    let processedHistoryOptions = makeDefaultSelectedOrgs?.filter(
      (e) => e.organisations?.id !== item.id
    );
    makeDefaultSelectedOrgs = processedHistoryOptions;
  });
  /**
   * 初期値
   */
  const defaultValue = (): OrgOption[] => {
    // 非表示リストにあるセグメントと被る初期表示セグメントを除外する
    if (
      makeDefaultSelectedOrgs !== undefined &&
      makeDefaultSelectedOrgs !== null
    ) {
      return makeDefaultSelectedOrgs;
    }

    switch (orgMode) {
      case OrgMode.FROM:
        return [departmentOption];
      case OrgMode.TO:
        return [allCompanyOption];
    }
    return [allCompanyOption];
  };

  const [defVal, setDefVal] = useState<{ optionList: OrgOption[] }[]>(
    defaultValue()
  );
  /**
   * 部門をドロップダウンオプション形式にする
   */
  const orgToOption = (
    organisations: Organisations,
    category: OptionCategory
  ): OrgOption => {
    return {
      label: organisations?.name ?? "なし",
      value: organisations?.id.toString() ?? "なし",
      organisations: organisations,
      type: OrgFilterType.OTHER,
      category: category,
    };
  };

  /**
   * ドロップダウンのオプションを作成する
   */
  const makeOption = (): GroupedOptionsType<OrgOption> => {
    // 受領権限がある部門
    const receivingAuthorityOptions = organisations
      .filter((e) => e.assignment?.role === AssignmentType.RECEIVING_AUTHORITY)
      .map((e) => orgToOption(e, OptionCategory.MY_RECEIPT_ORG));
    // 一般所属権限がある部門
    const generalOptions = organisations
      .filter((e) => e.assignment?.role === AssignmentType.GENERAL)
      .map((e) => orgToOption(e, OptionCategory.MY_ORG));
    // その他の部門
    const otherOptions = organisations
      .filter((e) => e.assignment == undefined)
      .map((e) => orgToOption(e, OptionCategory.OTHER));
    let historyOptions =
      usageHistoryList.find(
        (hl) => hl.companyId === window.userInfo?.current_company_id
      )?.optionList ?? [];
    // 非表示リストにあるセグメントと被る履歴セグメントを除外する
    hiddenList?.forEach((item: any) => {
      const processedHistoryOptions = historyOptions.filter(
        (e) => e.organisations?.id !== item.id
      );
      historyOptions = processedHistoryOptions;
    });
    if (props.receiptMode === true) {
      const options: GroupedOptionsType<OrgOption> = [
        {
          label: "全て",
          options: [receiveOption],
        },
        {
          label: "自分の受領権限",
          options: receivingAuthorityOptions,
        },
        // {
        //   label: "自分の所属部門",
        //   options: generalOptions,
        // },
      ];

      return options;
    }

    const options: GroupedOptionsType<OrgOption> = [
      {
        label: "全て",
        options: [allCompanyOption, receiveOption, departmentOption],
      },
      {
        label: "履歴",
        options: historyOptions,
      },
      {
        label: "自分の受領権限",
        options: receivingAuthorityOptions,
      },
      {
        label: "自分の所属部門",
        options: generalOptions,
      },
      {
        label: "その他",
        options: otherOptions,
      },
    ];

    return options;
  };

  // URL末尾から場面を取り出す
  const scene = route.name;

  const onHandleChange = (e: ValueType<OrgOption>) => {
    if (!e) return setDefVal();
    // イベント内の重複を削除
    const ev = e.filter((element, index) => {
      for (let id in e) {
        const item = e[id];
        if (id < index) {
          if (element.value == item.value) {
            return false;
          }
        }
      }
      return true;
    });

    const list = ev as OrgOption[];
    const companyId = window.userInfo?.current_company_id;
    if (companyId) {
      // ～24/01/17使っていたローカルストレージ用⇒未使用だが旧verからの引継ぎでエラーを起こさないため残す。
      let updatedList = [];
      updatedList.push({ companyId: companyId, optionList: list });

      // 24/01/18追加 新規積み立て用ストレージ
      const usageHistoryOptions =
        usageHistoryList.find(
          (hl) => hl.companyId === window.userInfo?.current_company_id
        )?.optionList ?? [];

      let updateHistoryList = usageHistoryList.filter(
        (hl) => hl.companyId !== companyId
      );
      updateHistoryList.push({
        companyId: companyId,
        optionList: Array.from(list),
      }); // listはクローン必須！
      // 履歴分をリストに格納
      for (let x in usageHistoryOptions) {
        updateHistoryList[0].optionList.push(usageHistoryOptions[x]);
      }
      // リスト内の重複を削除
      updateHistoryList[0].optionList = updateHistoryList[0].optionList.filter(
        (element, index) => {
          for (let id in updateHistoryList[0].optionList) {
            const item = updateHistoryList[0].optionList[id];
            if (id < index) {
              if (element.value == item.value) {
                return false;
              }
            }
          }
          return true;
        }
      );

      if (orgMode === OrgMode.FROM) {
        switch (scene) {
          case SCREENS.HOME.HOME:
            localStorage.setItem("FromFilterHome", JSON.stringify(updatedList));
            localStorage.setItem(
              "FromUsageFilterHome",
              JSON.stringify(updateHistoryList)
            );
            break;
          case SCREENS.INVOICE.INVOICE:
            localStorage.setItem(
              "FromFilterInvoice",
              JSON.stringify(updatedList)
            );
            localStorage.setItem(
              "FromUsageFilterInvoice",
              JSON.stringify(updateHistoryList)
            );
            break;
          case SCREENS.RECEIPT.RECEIPT:
            localStorage.setItem(
              "FromFilterReceipt",
              JSON.stringify(updatedList)
            );
            localStorage.setItem(
              "FromUsageFilterReceipt",
              JSON.stringify(updateHistoryList)
            );
            break;
          case SCREENS.WORKLIST.WORKLIST:
            localStorage.setItem(
              "FromFilterWorkListScreen",
              JSON.stringify(updatedList)
            );
            localStorage.setItem(
              "FromUsageFilterWorkListScreen",
              JSON.stringify(updateHistoryList)
            );
            break;
          case SCREENS.COMPARE.COMPARE:
            localStorage.setItem(
              "FromFilterCompareScreen",
              JSON.stringify(updatedList)
            );
            localStorage.setItem(
              "FromUsageFilterCompareScreen",
              JSON.stringify(updateHistoryList)
            );
            break;
          case SCREENS.WORKDB.WORKDB:
            localStorage.setItem(
              "FromFilterWorkDBScreen",
              JSON.stringify(updatedList)
            );
            localStorage.setItem(
              "FromUsageFilterWorkDBScreen",
              JSON.stringify(updateHistoryList)
            );
            break;
        }
      }
      if (orgMode === OrgMode.TO) {
        switch (scene) {
          case SCREENS.HOME.HOME:
            localStorage.setItem("ToFilterHome", JSON.stringify(updatedList));
            localStorage.setItem(
              "ToUsageFilterHome",
              JSON.stringify(updateHistoryList)
            );
            break;
          case SCREENS.INVOICE.INVOICE:
            localStorage.setItem(
              "ToFilterInvoice",
              JSON.stringify(updatedList)
            );
            localStorage.setItem(
              "ToUsageFilterInvoice",
              JSON.stringify(updateHistoryList)
            );
            break;
          case SCREENS.RECEIPT.RECEIPT:
            localStorage.setItem(
              "ToFilterReceipt",
              JSON.stringify(updatedList)
            );
            localStorage.setItem(
              "ToUsageFilterReceipt",
              JSON.stringify(updateHistoryList)
            );
            break;
          case SCREENS.WORKLIST.WORKLIST:
            localStorage.setItem(
              "ToFilterWorkListScreen",
              JSON.stringify(updatedList)
            );
            localStorage.setItem(
              "ToUsageFilterWorkListScreen",
              JSON.stringify(updateHistoryList)
            );
            break;
          case SCREENS.COMPARE.COMPARE:
            localStorage.setItem(
              "ToFilterCompareScreen",
              JSON.stringify(updatedList)
            );
            localStorage.setItem(
              "ToUsageFilterCompareScreen",
              JSON.stringify(updateHistoryList)
            );
            break;
          case SCREENS.WORKDB.WORKDB:
            localStorage.setItem(
              "ToFilterWorkDBScreen",
              JSON.stringify(updatedList)
            );
            localStorage.setItem(
              "ToUsageFilterWorkDBScreen",
              JSON.stringify(updateHistoryList)
            );
            break;
        }
      }
    }
    onSelected(list);
  };

  const optionBgColor = (option: OptionCategory): string => {
    if (option === OptionCategory.ALL) {
      return "#FFDBDB";
    }
    if (option === OptionCategory.ALL_MY_RECEIPT_ORG) {
      return "#FFDBDB";
    }
    if (option === OptionCategory.ALL_MY_ORG) {
      return "#FFDBDB";
    }
    if (option === OptionCategory.MY_RECEIPT_ORG) {
      return "#D4FFCE";
    }
    if (option === OptionCategory.MY_ORG) {
      return "#DDF4FD";
    }
    return "#EEE";
  };

  const customStyles = {
    // タイトル部分の背景色を変更
    groupHeading: (base: any) => ({
      ...base,
      backgroundColor: "gray",
      fontWeight: "bold",
      color: "#FFF",
    }),
    option: (base: any, state: any) => ({
      ...base,
      backgroundColor: optionBgColor(state.data.category),
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: 3,
    }),
    // 各選択肢の背景色を設定
    multiValue: (base: any, state: any) => ({
      ...base,
      backgroundColor: optionBgColor(state.data.category),
    }),
    // 各選択肢の背景色を設定
    multiValueLabel: (base: any, state: any) => ({
      ...base,
      backgroundColor: optionBgColor(state.data.category),
    }),
    valueContainer: (base: any) => ({
      ...base,
      maxHeight: 100,
      overflowY: "auto",
    }),
  };

  const getHistory = () => {
    if (orgMode === OrgMode.FROM) {
      let historyList = null;
      let usageHistoryList = null;
      switch (scene) {
        case SCREENS.HOME.HOME:
          historyList = localStorage.getItem("FromFilterHome");
          usageHistoryList = localStorage.getItem("FromUsageFilterHome");
          break;
        case SCREENS.INVOICE.INVOICE:
          historyList = localStorage.getItem("FromFilterInvoice");
          usageHistoryList = localStorage.getItem("FromUsageFilterInvoice");
          break;
        case SCREENS.RECEIPT.RECEIPT:
          historyList = localStorage.getItem("FromFilterReceipt");
          usageHistoryList = localStorage.getItem("FromUsageFilterReceipt");
          break;
        case SCREENS.WORKLIST.WORKLIST:
          historyList = localStorage.getItem("FromFilterWorkListScreen");
          usageHistoryList = localStorage.getItem(
            "FromUsageFilterWorkListScreen"
          );
          break;
        case SCREENS.COMPARE.COMPARE:
          historyList = localStorage.getItem("FromFilterCompareScreen");
          usageHistoryList = localStorage.getItem(
            "FromUsageFilterCompareScreen"
          );
          break;
        case SCREENS.WORKDB.WORKDB:
          historyList = localStorage.getItem("FromFilterWorkDBScreen");
          usageHistoryList = localStorage.getItem(
            "FromUsageFilterWorkDBScreen"
          );
          break;
      }

      if (historyList !== null) {
        setHistoryList(JSON.parse(historyList));
      }

      if (usageHistoryList !== null) {
        setUsageHistoryList(JSON.parse(usageHistoryList));
      }
    }

    if (orgMode === OrgMode.TO) {
      let historyList = null;
      let usageHistoryList = null;
      switch (scene) {
        case SCREENS.HOME.HOME:
          historyList = localStorage.getItem("ToFilterHome");
          usageHistoryList = localStorage.getItem("ToUsageFilterHome");
          break;
        case SCREENS.INVOICE.INVOICE:
          historyList = localStorage.getItem("ToFilterInvoice");
          usageHistoryList = localStorage.getItem("ToUsageFilterInvoice");
          break;
        case SCREENS.RECEIPT.RECEIPT:
          historyList = localStorage.getItem("ToFilterReceipt");
          usageHistoryList = localStorage.getItem("ToUsageFilterReceipt");
          break;
        case SCREENS.WORKLIST.WORKLIST:
          historyList = localStorage.getItem("ToFilterWorkListScreen");
          usageHistoryList = localStorage.getItem(
            "ToUsageFilterWorkListScreen"
          );
          break;
        case SCREENS.COMPARE.COMPARE:
          historyList = localStorage.getItem("ToFilterCompareScreen");
          usageHistoryList = localStorage.getItem("ToUsageFilterCompareScreen");
          break;
        case SCREENS.WORKDB.WORKDB:
          historyList = localStorage.getItem("ToFilterWorkDBScreen");
          usageHistoryList = localStorage.getItem("ToUsageFilterWorkDBScreen");
          break;
      }

      if (historyList !== null) {
        setHistoryList(JSON.parse(historyList));
      }

      if (usageHistoryList !== null) {
        setUsageHistoryList(JSON.parse(usageHistoryList));
      }
    }
  };

  useEffect(() => {
    getHistory();
    navigation.addListener("focus", () => {
      getHistory();
    });
    setDefVal(defaultValue());
  }, [orgMode, onSelected, hiddenList]);
  return (
    <>
      <Text>{orgMode}</Text>
      <Select
        isMulti={true}
        // defaultValue={defVal}
        value={defVal}
        onChange={onHandleChange}
        options={makeOption()}
        styles={customStyles}
      />
    </>
  );
};

export default CommonBumonFilter;
