import React, { useEffect, useState } from "react";

import {
  ButtonGroup,
  Form,
  Button,
  Dropdown,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { Organisations } from "../../models/OrganisationsModel";
import {
  WorkListFilter,
  WorkListFilterOption,
} from "../../models/WorkListFilterModel";
import { WorkType } from "../../models/WorkTypeModel";
import { View, Text, TouchableOpacity } from "react-native";
import { CommonColor } from "../../constants/Colors";

export const WorkListFilterType = {
  /**請求先 */
  TO_ORG: 1,
  /**仕事(大) */
  WORK_TYPE_LARGE: 2,
  /**仕事(中) */
  WORK_TYPE_MID: 3,
  /**仕事(小) */

  WORK_TYPE_SMALL: 4,
};
export type WorkListFilterType =
  (typeof WorkListFilterType)[keyof typeof WorkListFilterType];

interface WorkListFilterGroupProps {
  /**読み込み中フラグ */
  isLoading: boolean;
  /**フィルタ情報 */
  filter?: WorkListFilter;
  /**選択したフィルタ情報 */
  selectedFilter: WorkListFilterOption;
  /**請求先 有効無効*/
  onChangedToOrg: (v: boolean) => void;
  /**仕事(大) 有効無効*/
  onChangedWorkTypeLarge: (v: boolean) => void;
  /**仕事(中) 有効無効*/
  onChangedWorkTypeMid: (v: boolean) => void;
  /**仕事(小) 有効無効*/
  onChangedWorkTypeSmall: (v: boolean) => void;
  /**選択した請求先id*/
  onSelectedToOrg: (v: Organisations) => void;
  /**選択した仕事(大)id*/
  onSelectedWorkTypeLarge: (v: WorkType|undefined) => void;
  /**選択した仕事(中)id*/
  onSelectedWorkTypeMid: (v: WorkType|undefined) => void;
  /**選択した仕事(小)id*/
  onSelectedWorkTypeSmall: (v: WorkType|undefined) => void;
}

/**
 * 部門フィルター
 * @param props
 * @returns
 */
const WorkListFilterGroup = (props: WorkListFilterGroupProps) => {
  // /**フィルタの有無*/
  // const [isEnabledToOrg, setIsEnabledToOrg] = useState(false);
  // const [isEnabledWorkTypeLarge, setIsEnabledWorkTypeLarge] = useState(false);
  // const [isEnabledWorkTypeMid, setIsEnabledWorkTypeMid] = useState(false);
  // const [isEnabledWorkTypeSmall, setIsEnabledWorkTypeSmall] = useState(false);

  // /**選択 */
  // const [selectedToOrg, setSelectedToOrg] = useState<Organisations>();
  // const [selectedWorkTypeLarge, setSelectedWorkTypeLarge] =
  //   useState<WorkType>();
  // const [selectedWorkTypeMid, setSelectedWorkTypeMid] = useState<WorkType>();
  // const [selectedWorkTypeSmall, setSelectedWorkTypeSmall] =
  //   useState<WorkType>();

  /**フィルタの有無*/
  const isEnabledToOrg = props.selectedFilter.isEnabledToOrg;
  const isEnabledWorkTypeLarge = props.selectedFilter.isEnabledWorkTypeLarge;
  const isEnabledWorkTypeMid = props.selectedFilter.isEnabledWorkTypeMid;
  const isEnabledWorkTypeSmall = props.selectedFilter.isEnabledWorkTypeSmall;

  /**選択 */
  const selectedToOrg = props.selectedFilter.toOrgFilter;
  const selectedWorkTypeLarge = props.selectedFilter.workTypeLargeFilter;
  const selectedWorkTypeMid = props.selectedFilter.workTypeMidFilter;
  const selectedWorkTypeSmall = props.selectedFilter.workTypeSmallFilter;
  /**請求先フィルターの有無を設定する*/
  const onClickOrg = () => {
    // setIsEnabledToOrg(!isEnabledToOrg);
    props.onChangedToOrg(!isEnabledToOrg);
  };
  /**仕事(大)フィルターの有無を設定する*/
  const onClickWorkTypeLarge = () => {
    if( isEnabledWorkTypeLarge ){
      /**仕事(大) 未選択化*/
      props.onSelectedWorkTypeLarge(undefined);
    }
    // setIsEnabledWorkTypeLarge(!isEnabledWorkTypeLarge);
    props.onChangedWorkTypeLarge(!isEnabledWorkTypeLarge);
  };
  /**仕事(中)フィルターの有無を設定する*/
  const onClickWorkTypeMid = () => {
    if( isEnabledWorkTypeMid ){
      /**仕事(中) 未選択化*/
      props.onSelectedWorkTypeMid(undefined);
    }
    // setIsEnabledWorkTypeMid(!isEnabledWorkTypeMid);
    props.onChangedWorkTypeMid(!isEnabledWorkTypeMid);
  };
  /**仕事(小)フィルターの有無を設定する*/
  const onClickWorkTypeSmall = () => {
    if( isEnabledWorkTypeSmall ){
      /**仕事(小) 未選択化*/
      props.onSelectedWorkTypeSmall(undefined);
    }
    // setIsEnabledWorkTypeSmall(!isEnabledWorkTypeSmall);
    props.onChangedWorkTypeSmall(!isEnabledWorkTypeSmall);
  };
  /**請求先idを指定する*/
  const onSelectedToOrg = (v: Organisations) => {
    props.onSelectedToOrg(v);
    if (!isEnabledToOrg) onClickOrg();
    // setSelectedToOrg(v);
  };
  /**仕事(大)idを指定する*/
  const onSelectedWorkTypeLarge = (v: WorkType) => {
    props.onSelectedWorkTypeLarge(v);
    if (!isEnabledWorkTypeLarge) onClickWorkTypeLarge();
    // setSelectedWorkTypeLarge(v);
  };
  /**仕事(中)idを指定する*/
  const onSelectedWorkTypeMid = (v: WorkType) => {
    props.onSelectedWorkTypeMid(v);
    if (!isEnabledWorkTypeMid) onClickWorkTypeMid();
    // setSelectedWorkTypeMid(v);
  };
  /**仕事(小)idを指定する*/
  const onSelectedWorkTypeSmall = (v: WorkType) => {
    props.onSelectedWorkTypeSmall(v);
    if (!isEnabledWorkTypeSmall) onClickWorkTypeSmall();
    // setSelectedWorkTypeSmall(v);
  };

  /**フィルターを除去する */
  const onRemoveFilter = () => {
    // setIsEnabledToOrg(false);
    // setIsEnabledWorkTypeLarge(false);
    // setIsEnabledWorkTypeMid(false);
    // setIsEnabledWorkTypeSmall(false);
    /**請求先 有効無効*/
    props.onChangedToOrg(false);
    /**仕事(大) 有効無効*/
    props.onChangedWorkTypeLarge(false);
    /**仕事(中) 有効無効*/
    props.onChangedWorkTypeMid(false);
    /**仕事(小) 有効無効*/
    props.onChangedWorkTypeSmall(false);
    /**仕事(大) 未選択化*/
    props.onSelectedWorkTypeLarge(undefined);
    /**仕事(中) 未選択化*/
    props.onSelectedWorkTypeMid(undefined);
    /**仕事(小) 未選択化*/
    props.onSelectedWorkTypeSmall(undefined);
  };

  const renderDropdownItems = (args: {
    /**フィルタの内容 */
    items: any[];
    /**アイテム選択 */
    onSelect: (v: any) => void;
  }) => {
    if (args.items && args.items.length > 0) {
      return args.items.map((e) => (
        <Dropdown.Item
          key={e.id}
          onClick={() => {
            args.onSelect(e);
          }}
          eventKey={e.id}
        >
          {e.name}
        </Dropdown.Item>
      ));
    } else {
      return <Dropdown.Item disabled>無し</Dropdown.Item>;
    }
  };

  /**ドロップボタンを設定する */
  const renderBtnDropDown = (args: {
    /**タイトル */
    title: string;
    /**フィルタ有効フラグ */
    isOn: boolean;
    /**フィルタの内容 */
    items: any[];
    /**フィルタ選択 */
    onClick: () => void;
    /**アイテム選択 */
    onSelect: (v: any) => void;
  }) => {
    return (
      <Dropdown
        style={{ width: "100%" }}
        as={ButtonGroup}
        // className="col dropdown-filter"
      >
        <Button
          // style={{ whiteSpace: "nowrap" }}
          variant={args.isOn ? "primary" : "light"}
          onClick={() => args.onClick()}
        >
          {args.title}
        </Button>
        <Dropdown.Toggle
          split
          variant="outline-secondary"
          id="dropdown-split-basic"
        />
        <Dropdown.Menu>
          {props.isLoading ? (
            <Dropdown.Item disabled>
              <Spinner animation="border" size="sm" />
            </Dropdown.Item>
          ) : (
            renderDropdownItems({
              items: args.items,
              onSelect: args.onSelect,
            })
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  useEffect(() => {
    // APIからorganisationsを取得する処理を実行する
    // fetchOrganisations();
  }, []);

  return (
    <>
      <Col xl={1} xxl={10} className="text-center" style={{ marginBottom: 3 }}>
        <div className="d-flex align-items-center h-100">
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Text style={{ marginRight: 5 }}>{"集計:"}</Text>
            <TouchableOpacity
              onPress={onRemoveFilter}
              style={{
                backgroundColor: CommonColor.Normal.Blue,
                paddingHorizontal: 5,
                paddingVertical: 3,
                borderRadius: 10,
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ color: "#FFF", fontSize: 13 }}>解除</Text>
            </TouchableOpacity>
          </View>
        </div>
      </Col>
      {/* {renderBtnDropDown({
          title: "請求先[" + [selectedToOrg?.name ?? "未選択"] + "]",
          isOn: isEnabledToOrg,
          onClick: onClickOrg,
          items: props.filter?.to_orgs ?? [],
          onSelect: onSelectedToOrg,
        })} */}
      <Col xl={1} xxl={3} style={{ marginBottom: 3 }}>
        {renderBtnDropDown({
          title: "仕事(大)[" + [selectedWorkTypeLarge?.name ?? "未選択"] + "]",
          isOn: isEnabledWorkTypeLarge,
          onClick: onClickWorkTypeLarge,
          items: props.filter?.workTypeLarges ?? [],
          onSelect: onSelectedWorkTypeLarge,
        })}
      </Col>
      <Col xl={1} xxl={3} style={{ marginBottom: 3 }}>
        {renderBtnDropDown({
          title: "仕事(中)[" + [selectedWorkTypeMid?.name ?? "未選択"] + "]",
          isOn: isEnabledWorkTypeMid,
          onClick: onClickWorkTypeMid,
          items: props.filter?.workTypeMids ?? [],
          onSelect: onSelectedWorkTypeMid,
        })}
      </Col>
      <Col xl={1} xxl={3} style={{ marginBottom: 3 }}>
        {renderBtnDropDown({
          title: "仕事(小)[" + [selectedWorkTypeSmall?.name ?? "未選択"] + "]",
          isOn: isEnabledWorkTypeSmall,
          onClick: onClickWorkTypeSmall,
          items: props.filter?.workTypeSmalls ?? [],
          onSelect: onSelectedWorkTypeSmall,
        })}
      </Col>
    </>
  );
};

export default WorkListFilterGroup;
