import { AntDesign } from "@expo/vector-icons";
import React, { useContext } from "react";
import { TouchableOpacity, Text, View, StyleSheet } from "react-native";
import {
  currentYM,
  decrementMonth,
  displayToMonth,
  incrementYM,
} from "../../util/DateUtil";
import { selectedContext } from "../../App";

/**先月・今月フィルタータイプ */
export const MonthFilterType = {
  /**先月 */
  LAST: "last",
  /**今月 */
  THIS: "this",
  /**来月 */
  NEXT: "next",
};
/**先月・今月フィルタータイプ */
export type MonthFilterType =
  (typeof MonthFilterType)[keyof typeof MonthFilterType];

type FreeSelectMonthProps = {
  hideSelecter?: boolean; //前後ボタンの非表示フラグ
  selectedYM?: string; //初期の年月
  isNextMonthButton?: boolean; //先月ボタンを翌月ボタンに変更する
  onChange?: (selectedMontth: string) => void;
};

function FreeSelectMonth(props: FreeSelectMonthProps) {
  /**今月YM */
  const current_YM = props.selectedYM ?? currentYM();
  /**先月YM */
  const last_YM = decrementMonth(currentYM());
  /**来月YM */
  const next_YM = incrementYM(currentYM());
  //先月ボタンを翌月ボタンに変更する
  const isNextMonthButton: boolean = props.isNextMonthButton ?? false;

  // const [selected_YM, setSelected_YM] = useState<string>(current_YM);
  const { month, setMonth } = useContext(selectedContext);
  if (props.hideSelecter) {
    return (
      <View style={styles.container}>
        <View style={styles.buttonGroup}>
          <Text style={styles.monthText}>{displayToMonth(month)}</Text>
        </View>
      </View>
    );
  }

  const renderMonthBtn = (monthFilterType: MonthFilterType) => {
    setMonth;
    let btnLabel: string = "今月";
    let targetMonth: string = currentYM();
    switch (monthFilterType) {
      case MonthFilterType.LAST:
        btnLabel = "先月";
        targetMonth = last_YM;
        break;
      case MonthFilterType.NEXT:
        btnLabel = "来月";
        targetMonth = next_YM;
        break;
      default:
        break;
    }

    const onPress = () => {
      setMonth(targetMonth);
      if (props.onChange) {
        props.onChange(targetMonth);
        return;
      }
      // window.functions.setMonthFilter(targetMonth);
    };

    return (
      <TouchableOpacity
        onPress={onPress}
        style={[styles.button, month === targetMonth && styles.selectedButton]}
      >
        <Text
          style={[
            styles.buttonText,
            month === targetMonth && styles.selectedButtonText,
          ]}
        >
          {btnLabel}
        </Text>
      </TouchableOpacity>
    );
  };
  return (
    <View style={styles.container}>
      <View style={styles.buttonGroup}>
        {!isNextMonthButton && renderMonthBtn(MonthFilterType.LAST)}
        {renderMonthBtn(MonthFilterType.THIS)}
        {isNextMonthButton && renderMonthBtn(MonthFilterType.NEXT)}

        <TouchableOpacity
          onPress={() => {
            const last_YM = decrementMonth(month);
            setMonth(last_YM);
            if (props.onChange) {
              props.onChange(last_YM);
              return;
            }
            // window.functions.setMonthFilter(last_YM);
          }}
        >
          <AntDesign name="caretleft" size={24} color="black" />
        </TouchableOpacity>
        <Text style={styles.monthText}>{displayToMonth(month)}</Text>
        <TouchableOpacity
          onPress={() => {
            const increment_YM = incrementYM(month);
            setMonth(increment_YM);
            if (props.onChange) {
              props.onChange(increment_YM);
              return;
            }
            // window.functions.setMonthFilter(increment_YM);
          }}
        >
          <AntDesign name="caretright" size={24} color="black" />
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  buttonGroup: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    // marginBottom: 10,
  },
  button: {
    width: 50,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    marginHorizontal: 5,
  },
  selectedButton: {
    backgroundColor: "#2B92CD",
  },
  buttonText: {
    color: "#2B92CD",
    fontSize: 18,
  },
  selectedButtonText: {
    color: "#FFFFFF",
  },
  monthText: {
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: 24,
    color: "#2B92CD",
    fontWeight: "bold",
  },
});

export default FreeSelectMonth;
