import { OrgOption } from "../components/filter/CommonBumonFilter";
import {
  LocalStorageKey,
  OrganisationsFilterStorage,
  OrganisationsFilterStorageCompany,
} from "../models/LocalStorage";
import { removeDuplicateId } from "./CommonUtil";

/**
 * 部門フィルタを呼び出す
 * @param localStorageKey
 * @returns
 */
export async function loadOrgLocalStorage(localStorageKey: LocalStorageKey) {
  let filter: any[] = await window.storage
    .load({ key: localStorageKey })
    .catch((err: any) => null);

  if (filter == null || !Array.isArray(filter)) return [];

  let options: OrganisationsFilterStorageCompany[] = [];

  filter.map((e) => {
    let data: OrganisationsFilterStorageCompany = {
      companyId: 0,
      orgOptions: { toOrgOptions: undefined, fromOrgOptions: undefined },
    };

    if (e !== null) {
      data.companyId = e?.companyId ?? 0;
      data.orgOptions = {
        toOrgOptions: e?.orgOptions.toOrgOptions,
        fromOrgOptions: e?.orgOptions.fromOrgOptions,
      };
      options.push(data);
    }
  });

  return options;
}

/**
 * 部門フィルタからパラメータを取得する。
 * @param OrgOptions
 * @returns
 */
export function orgOptionsToParam(orgOptions: OrgOption[]) {
  let filterTypes: number[] = [];
  let orgIds: number[] = [];

  orgOptions.map((e) => {
    filterTypes.push(e.type.id);
    if (e.organisations) {
      orgIds.push(e.organisations.id);
    }
  });
  filterTypes = removeDuplicateId(filterTypes);
  orgIds = removeDuplicateId(orgIds);

  return { filter_types: filterTypes, org_ids: orgIds };
}
/**
 * ローカルストレージに保存されている部門フィルタの中から、
 * 現在ログイン中の会社を取得する。
 * @param localStorageKey
 * @returns
 */
export function loadSelectedOptionByCompany(localStorageKey: LocalStorageKey) {
  const companyId = window.userInfo?.current_company_id;

  let selectedMyOrganisations: OrganisationsFilterStorageCompany[];
  switch (localStorageKey) {
    default:
    case LocalStorageKey.HOME:
      selectedMyOrganisations = window.selectedMyOrganisationsHome;
      break;
    case LocalStorageKey.INVOICE:
      selectedMyOrganisations = window.selectedMyOrganisationsInvoice;
      break;
    case LocalStorageKey.RECEIPT:
      selectedMyOrganisations = window.selectedMyOrganisationsRecipt;
      break;
    case LocalStorageKey.WORK_LIST:
      selectedMyOrganisations = window.selectedMyOrganisationsList;
      break;
    case LocalStorageKey.WORK_COMPARE:
      selectedMyOrganisations = window.selectedMyOrganisationsCompare;
      break;
    case LocalStorageKey.WORK_DB:
      selectedMyOrganisations = window.selectedMyOrganisationsDB;
      break;
  }
  let selectedMyOrganisationsCompany = undefined;
  if(selectedMyOrganisations != undefined) {
    selectedMyOrganisationsCompany = selectedMyOrganisations.find(
      (e) => e.companyId == companyId
    )?.orgOptions ?? { toOrgOptions: undefined, fromOrgOptions: undefined };
  } else {
    selectedMyOrganisationsCompany = {toOrgOptions: undefined, fromOrgOptions: undefined };
  }
  return selectedMyOrganisationsCompany;
}
