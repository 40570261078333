/**
 * 所属タイプ
 */
export const AssignmentType = {
  /**0:一般所属 */
  GENERAL: 0,
  /**1:受領権限*/
  RECEIVING_AUTHORITY: 1,
};
/**
 * 請求元フラグ
 */
export const BillingSource = {
  /**0:請求元にはならない */
  FALSE: 0,
  /**1:請求元になり得る */
  TRUE: 1,
};
export type AssignmentType =
  (typeof AssignmentType)[keyof typeof AssignmentType];

export type BillingSource =
  (typeof BillingSource)[keyof typeof BillingSource];

export interface Assignment {
  id: number;
  user_id: number;
  company_id: number;
  organisation_id: number;
  role: AssignmentType;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
  billing_source: BillingSource;
}
