import React, { useState } from "react";
import { Text, ViewStyle, TouchableOpacity, View } from "react-native";
import { CommonColor } from "../../constants/Colors";
import { WorkMemos } from "../../models/WorkMemosModel";
import { WorkMemoModal } from "../modal/WorkMemoModal";
import { Spinner } from "react-bootstrap";

interface WorkMemoButton {
  style?: ViewStyle;
  onPress?: (workMemo: WorkMemos) => void;
  workTypeSmallId?: number;
  workTypeMidId?: number;
  layoutType?: "right" | "left" | "center" | "normal";
}
/**
 * 仕事メモボタン
 * @param props
 * @returns
 */
const WorkMemoButton = (props: WorkMemoButton) => {
  /** */
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [count, setCount] = useState<number | undefined>();

  /**
   * モーダルを開く
   */
  const openModal = () => {
    setIsShowModal(true);
  };

  /**
   * モーダルを閉じる
   */
  const closeModal = () => {
    setIsShowModal(false);
  };

  /**
   * 選択された仕事メモを処理する
   * @param workMemo 選択された仕事メモ
   */
  const onSelected = (workMemo: WorkMemos) => {
    if (!props.onPress) return;
    props.onPress(workMemo);
    closeModal();
  };

  /**
   * 仕事メモ一覧を受け取り、選択中の仕事メモ数を更新する
   * @param workMemos 仕事メモ一覧
   */
  const handleOnLoad = (workMemos: WorkMemos[]) => {
    setCount(workMemos.length);
  };

  const bgColor = () => {
    if (count === 0) {
      return CommonColor.Dark.Grey;
    } else if (count === undefined) {
      return CommonColor.Grey;
    } else {
      return CommonColor.Orange;
    }
  };

  const radius = () => {
    const layoutType = props?.layoutType;

    let radius;
    switch (layoutType) {
      case "left":
        radius = { borderTopLeftRadius: 10, borderBottomLeftRadius: 10 };
        break;

      case "right":
        radius = { borderTopRightRadius: 10, borderBottomRightRadius: 10 };
        break;

      case "center":
        radius = {};
        break;

      default:
        radius = { borderRadius: 10 };
        break;
    }
    console;

    return radius;
  };

  return (
    <View style={props.style}>
      <TouchableOpacity
        style={
          (props.style,
          {
            backgroundColor: bgColor(),
            paddingHorizontal: 10,
            paddingVertical: 2,
            ...radius(),
          })
        }
      >
        <Text style={{ color: CommonColor.White }} onPress={openModal}>
          仕事メモ
          {count === undefined ? (
            <Spinner
              animation="border"
              role="status"
              size="sm"
              style={{ marginLeft: 5 }}
            />
          ) : (
            <Text style={{ color: CommonColor.White, marginLeft: 5 }}>
              {count > 0 ? `(${count})` : ""}
            </Text>
          )}
        </Text>
      </TouchableOpacity>
      <WorkMemoModal
        workTypeSmallId={props.workTypeSmallId}
        workTypeMidId={props.workTypeMidId}
        isShow={isShowModal}
        onPress={onSelected}
        onDismiss={closeModal}
        onLoaded={handleOnLoad}
      />
    </View>
  );
};

export default WorkMemoButton;
