import React from "react";
import { Text } from "react-native";

type Props = {
  title?: string;
};

/**
 * 共通のエラーラベル
 * @param props
 * @returns
 */
const CommonErrorLabel = (props: Props) => {
  return (
    <Text style={{ color: "rgb(255, 25, 12)", fontSize: 12 }}>
      {props.title ?? ""}
    </Text>
  );
};

export default CommonErrorLabel;
