import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

type CommonModalProps = {
  isShow: boolean;
  title?: string;
  body?: string;
  actionTitle?: string;
  element?: JSX.Element;
  buttonVariant?: string;
  onPress?: () => void;
  onDismiss?: () => void;
};

export function CommonModal(props: CommonModalProps) {
  const { isShow, title, body, actionTitle, element, onPress, onDismiss } =
    props;

  const handleClose = () => {
    if (onDismiss) {
      onDismiss();
    }
  };

  return (
    <Modal show={isShow} onHide={handleClose} backdrop={true}>
      {title && (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      {body && (
        <Modal.Body>
          <p>{body}</p>
        </Modal.Body>
      )}
      {element && <>{element}</>}
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          キャンセル
        </Button>
        <Button variant={props.buttonVariant ?? "primary"} onClick={onPress}>
          {actionTitle ?? "実行"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CommonModal;
