/**各画面定義 */
export const SCREENS = {
  /**ホームタブ */
  HOME: {
    /**ホームTOP画面 */
    HOME: "HomeScreen",
    /**旧フィルター画面 */
    HOME_FILTER: "HomeFilterScreen",
    /**旧フィルター部門画面 */
    SELECT_HOME_ORGANISATION: "SelectHomeOrganisationScreen",
    /**旧目標値入力画面 */
    SALES_TARGET: "SalesTargetScreen",
  },
  /**請求タブ */
  INVOICE: {
    /**請求TOP画面 */
    INVOICE: "InvoiceScreen",
    /**請求詳細画面 */
    INVOICE_DETAIL: "InvoiceDetailScreen",
    SELECT_ORGANISATION: "SelectOrganisationScreen",
    SELECT_WORK_TYPE: "SelectWorkTypeScreen",
    EDIT_WORK_TYPE_SMALL: "EditWorkTypeSmall",
    INVOICE_FILTER: "InvoiceFilterScreen",
    SELECT_INVOICE_ORGANISATION_FILTER:
      "SelectInvoiceOrganisationScreen_filter",
    SELECT_INVOICE_WORK_TYPE_FILTER: "SelectInvoiceWorkTypeScreen_filter",
    INVOICE_TEIBAN_SELECT: "InvoiceTeibanSelectScreen",
    INVOICE_TEIBAN_FILTER: "InvoiceTeibanFilterScreen",
    INVOICE_TEIBAN_DETAIL: "InvoiceTeibanDetailScreen",
    INVOICE_TEIBAN_ORGANISATION_FILTER:
      "InvoiceTeibanOrganisationScreen_filter",
    INVOICE_TEIBAN_WORK_TYPE_FILTER: "InvoiceTeibanWorkTypeScreen_filter",
    DAILY_TEIBAN: {
      LIST: "InvoiceDailyTeibanListScreen",
      EDIT: "InvoiceDailyTeibanEditScreen",
      FILTER: {
        ORG: "InvoiceDailyTeibanFilterOrgScreen",
        WORK: "InvoiceDailyTeibanFilterWorkScreen",
      },
    },
  },
  /**受領タブ */
  RECEIPT: {
    /**受領TOP画面 */
    RECEIPT: "ReceiptScreen",
    /**受領詳細画面 */
    RECEIPT_DETAIL: "ReceiptDetailScreen",
    RECEIPT_FILTER: "ReceiptFilterScreen",
    SELECT_RECEIPT_ORGANISATION_FILTER:
      "SelectReceiptOrganisationScreen_filter",
    SELECT_RECEIPT_WORK_TYPE_FILTER: "SelectReceiptWorkTypeScreen_filter",
    SELECT_RECEIPT_ORGANISATION: "SelectReceiptOrganisationScreen",
    SELECT_RECEIPT_WORK_TYPE: "SelectReceiptWorkTypeScreen",
  },

  /**仕事一覧タブ */
  WORKLIST: {
    /**仕事一覧TOP画面 */
    WORKLIST: "WorkListScreen",
  },

  /**月次比較タブ */
  COMPARE: {
    /**月次比較TOP画面 */
    COMPARE: "CompareScreen",
  },

  /**仕事データベースタブ */
  WORKDB: {
    /**仕事一覧TOP画面 */
    WORKDB: "WorkDBScreen",
  },
};
