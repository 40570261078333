import React, { useState, useEffect } from "react";

import { InputGroup, Form, Spinner, Button } from "react-bootstrap";
import CommonInput from "../common/CommonInput";
import { WorkType, WorkTypeModel } from "../../models/WorkTypeModel";
import { CommonColor } from "../../constants/Colors";

interface EditWorkTypeSmallItemProps {
  workTypeMid: WorkType;
  workTypeSmall: WorkType;
  isSelected: boolean;
  onSaved?: () => void;
}
/**
 * 小分類追加ボタン
 * @param props
 * @returns
 */
const EditWorkTypeSmallItem = (props: EditWorkTypeSmallItemProps) => {
  const [name, setName] = useState<string>(props.workTypeSmall?.name ?? "");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [initName, setInitName] = useState<string>(
    props.workTypeSmall?.name ?? ""
  );

  /**
   * 名称を変更していない
   * @returns
   */
  const noEdit = () => {
    if (name == initName) return true;
    return false;
  };

  /**
   * 登録ボタンのイベント
   * @returns
   */
  const onPress = async () => {
    setIsLoading(true);
    try {
      if (props.workTypeSmall.id != 0) {
        await onUpdate(props.workTypeSmall);
      } else {
        await onCreate();
      }
      setInitName(name);
      window.reloadFlagWorkTypeSmallHistory = true;
      window.reloadFlagInvoiceList = true;
      // NotificationToast().success("登録しました。");
    } catch (error) {
      alert("エラーが発生し登録出来ませんでした。");
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdate = async (small: WorkType) => {
    await WorkTypeModel.fetchUpdateSmall({
      work_type_small_name: name,
      work_type_small_id: small.id,
      work_type_id: props.workTypeMid.id,
    });
  };
  const onCreate = async () => {
    await WorkTypeModel.fetchCreateSmall({
      work_type_small_name: name,
      work_type_id: props.workTypeMid.id,
    });
  };

  /**選択済み用の入力フォームのスタイル */
  const selectedColor = props.isSelected
    ? { backgroundColor: CommonColor.Light.Green }
    : {};

  return (
    <InputGroup size="sm" className="mb-3">
      <CommonInput
        value={name}
        onChange={setName}
        style={selectedColor}
      ></CommonInput>
      <Button
        variant={noEdit() ? "outline-secondary" : "primary"}
        id="button-addon2"
        onClick={onPress}
        disabled={isLoading || noEdit()}
      >
        {isLoading ? <Spinner animation="border" size="sm" /> : "確定"}
      </Button>
    </InputGroup>
  );
};

export default EditWorkTypeSmallItem;
