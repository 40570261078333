export function unsecuredCopyToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Unable to copy to clipboard", err);
  }
  document.body.removeChild(textArea);
}

/**
 * 重複を除去する
 * @param v
 * @returns
 */
export function removeDuplicateId(v: number[]) {
  let uniqueIds = v.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });

  return uniqueIds;
}
