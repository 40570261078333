import * as React from "react";
const DatePicker = require("react-mobile-datepicker");
import { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TextInput,
  FlatList,
  TouchableOpacity,
} from "react-native";
import { Input, Button, ButtonGroup, Badge } from "react-native-elements";
const moment = require("moment");
import formatNumber from "format-number";
import { StateColor } from "../../constants/Colors";
import { WorkType, WorkTypeModel } from "../../models/WorkTypeModel";
import {
  DraftFlg,
  Invoice,
  InvoiceModel,
  InvoiceStateType,
  WORK_TYPE_SMALL_DEFAULT_NAME,
} from "../../models/InvoiceModel";
import ButtonWithDropdown from "../../components/common/ButtonWithDoropdown";
import { SCREENS } from "../../constants/Screens";
import InputWithDoropdown from "../../components/common/InputWithDoropdown";
import NextAndBackButton from "../../components/teiban/NextAndBackButton";
import ReadOnlyInvoiceDetail from "../../components/invoice/ReadOnlyInvoiceDetail";
import { InputType } from "../../components/common/CommonInput";
import CoverIndicator from "../../components/common/CoverIndicator";
import { validateInvoice } from "../../util/InvoiceUtil";
import NotificationToast from "../../components/common/NotificationToast";
const numberFormat = formatNumber({
  round: 0,
});

import "react-calendar/dist/Calendar.css";
import { Organisations } from "../../models/OrganisationsModel";
import { Modal } from "react-bootstrap";
import Calendar from "react-calendar";
import BillingDate from "../../components/invoice/BillingDate";
import CommonErrorLabel from "../../components/common/CommonErrorLabel";
import WorkMemoButton from "../../components/common/WorkMemoButton";
import { WorkMemos } from "../../models/WorkMemosModel";
import { selectWorkType } from "../filter/SelectWorkTypeScreen";
/**
 * Receipts detail screen
 * @param props
 * @returns
 */
export default function ReceiptDetailScreen(props: any) {
  const { navigation, functions } = props;

  const [billing_date, setBillingDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  /** 請求関連 ****************************************************************/

  /**請求ID */
  const [props_id, setPropsId] = useState(props.route.params.id);

  /**請求先(請求先) */
  const [to_org, setToOrg] = useState(0);
  /**請求元(請求元) */
  const [from_org, setFromOrg] = useState(0);
  /**請求先(請求先)名称 */
  const [to_org_name, setToOrgName] = useState("未選択");
  /**請求元(請求元)名称 */
  const [from_org_name, setFromOrgName] = useState("未選択");
  /**仕事内容 */
  const [work, setWork] = useState("");
  /**中分類仕事 id*/
  const [work_type_id, setWorkTypeId] = useState(0);
  /**中分類仕事 名称*/
  const [work_type_name, setWorkTypeName] = useState("未選択");
  /**小分類仕事 id*/
  const [work_type_small_id, setWorkTypeSmallId] = useState<number>(0);
  /**小分類類仕事 名称*/
  const [work_type_small_name, setWorkTypeSmallName] = useState("");
  /**単価 */
  const [unit_price, setUnitPrice] = useState("0");
  /**数量 */
  const [quantity, setQuantity] = useState("0");
  /**合計 */
  const [total, setTotal] = useState("0");
  /**請求状態(id) */
  const [state, setState] = useState<InvoiceStateType>(
    InvoiceStateType.Pending
  );
  /**請求状態(名称) */
  const [state_label, setStateLabel] = useState("");
  /**下書き */
  const [draft, setDraft] = useState(0);
  /**請求日 */
  const [dt, setDt] = useState(new Date());
  /**請求情報 */
  const [invoice, setInvoice] = useState<Invoice>();

  /** コメント関連 ****************************************************************/
  /**コメント 選択id*/
  const [selectedIndex, setSelectedIndex] = useState(0);
  /**コメント 一覧*/
  const [comments, setComments] = useState([]);
  /**コメント 入力*/
  const [comment, setComment] = useState("");
  /**コメント一覧の高さ*/
  const [listHeight, setListHeight] = useState(0);
  /**コメント 未読数 */
  const [unread, setUnread] = useState(0);

  /** 画面制御関連 ****************************************************************/
  /**請求読み込みフラグ*/
  const [isLoading, setIsLoading] = useState(true);
  /**請求日ピッカー表示フラグ*/
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  /**受領権限がある請求 */
  const [own, setOwn] = useState<boolean>(false);
  /**削除確認用モーダル */
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  /**一つ前の請求ID */
  const [previousId, setPreviousId] = useState<number | null>();
  /**一つ後の請求ID */
  const [nextId, setNextId] = useState<number | null>();

  /** 入力チェック関連 ****************************************************************/
  /**エラー文言[仕事] */
  const [errWork, setErrWork] = useState("");
  /**エラー文言[単価] */
  const [errUnitPrice, setErrUnitPrice] = useState("");
  /**エラー文言[数量] */
  const [errQuantity, setErrQuantity] = useState("");
  /**エラー文言[請求先・請求先] */
  const [errToOrg, setErrToOrg] = useState("");
  /**エラー文言[請求元・請求元] */
  const [errFromOrg, setErrFromOrg] = useState("");
  /**エラー文言[中分類仕事] */
  const [errWorkType, setErrWorkType] = useState("");
  /**エラー文言[小分類仕事] */
  const [errWorkTypeSmall, setErrWorkTypeSmall] = useState("");
  /** 履歴関連 ****************************************************************/

  /**請求先の履歴 */
  const [toOrgHistory, setToOrgHistory] = useState<Organisations[]>([]);

  /**中分類仕事の履歴 */
  const [workTypeMidHistory, setWorkTypeHistory] = useState<WorkType[]>([]);
  /**中分類仕事の履歴取得インジケーター */
  const [isLoadingWorkTypeMidHistory, setIsLoadingWorkTypeMidHistory] =
    useState<boolean>(true);
  /**小分類仕事の履歴 */
  const [workTypeSmallHistory, setWorkTypeSmallHistory] = useState<WorkType[]>(
    []
  );
  /**小分類仕事の履歴取得インジケーター */
  const [isLoadingWorkTypeSmallHistory, setIsLoadingWorkTypeSmallHistory] =
    useState<boolean>(true);
  /**仕事(内容)の履歴 */
  const [workHistory, setWorkHistory] = useState<{ name: string }[]>([]);
  /**仕事(内容)の履歴取得インジケーター */
  const [isLoadingWorkHistory, setIsLoadingWorkHistory] =
    useState<boolean>(true);
  /**仕事(内容)の履歴 */
  const [unitPriceHistory, setUnitPriceHistory] = useState<{ name: string }[]>(
    []
  );
  /**単価の履歴取得インジケーター */
  const [isLoadingUnitPriceHistory, setIsLoadingUnitPriceHistory] =
    useState<boolean>(true);
  /**履歴取得開始フラグ*/
  const [isEnableLoadHistory, setIsEnableLoadHistory] =
    useState<boolean>(false);
  /** DB関連 ****************************************************************/
  /**中分類仕事の履歴 */
  const [workTypeMidDB, setWorkTypeMidDB] = useState<WorkType[]>([]);
  /**中分類仕事の履歴取得インジケーター */
  const [isLoadingWorkTypeMidDB, setIsLoadingWorkTypeMidDB] =
    useState<boolean>(true);

  /**中分類仕事の履歴 */
  const [workTypeSmallDB, setWorkTypeSmallDB] = useState<WorkType[]>([]);
  /**中分類仕事の履歴取得インジケーター */
  const [isLoadingWorkTypeSmallDB, setIsLoadingWorkTypeSmallDB] =
    useState<boolean>(true);

  /**カレンダー */
  const [showCalendar, setShowCalendar] = useState(false);

  //請求レコードのid
  const invoiceId = props.route.params.id;
  //idが0以上の場合は編集
  const isEdit = props.route.params.id > 0;

  //抽出条件(請求の前後遷移を一覧画面と揃える)
  const filterSelectedYM: string = props.route.params.selected_YM;
  const filterFromOrgFilterTypes: number[] =
    props.route.params.from_filter_types;
  const filterToOrgFilterTypes: number[] = props.route.params.to_filter_types;

  const filterFromOrgIds: number[] = props.route.params.from_org_ids;

  const filterToOrgIds: number[] = props.route.params.to_org_ids;

  const filterStates: number[] = props.route.params.states;

  const type = 1;

  function handleClick() {
    setIsPickerOpen(true);
  }
  function handleCancel() {
    setIsPickerOpen(false);
  }
  function handleSelect(tm: any) {
    setDt(tm);
    setBillingDate(moment(tm).format("YYYY-MM-DD"));
    setIsPickerOpen(false);
  }

  /**入力チェック */
  function validate() {
    const res = validateInvoice({
      unit_price: unit_price,
      quantity: quantity,
      to_org: to_org,
      from_org: from_org,
      work_type_id: work_type_id,
      work_type_small_id: work_type_small_id,
      work_type_small_name: work_type_small_name,
    });

    setErrUnitPrice(res.msg.unit_price);
    setErrQuantity(res.msg.quantity);
    setErrToOrg(res.msg.to_org);
    setErrFromOrg(res.msg.from_org);
    setErrWorkType(res.msg.work_type);
    setErrWorkTypeSmall(res.msg.work_type_small);

    const errorMsg = res.errorList.map((e) => "[" + e + "]");
    if (!res.result)
      NotificationToast().error(errorMsg + "の入力内容が正しくありません。");

    return res.result;
  }

  /**
   * idで請求(受領情報)を取得する
   * @param id
   */
  const fetchById = async (id: number) => {
    const invoice_Id = id ?? invoiceId;

    setPreviousId(0);
    setNextId(0);

    const res = await InvoiceModel.receiptShow(invoice_Id);
    setInvoice(res);
    setBillingDate(res.billing_date);
    setToOrg(res.to_org);
    setFromOrg(res.from_org);
    setToOrgName(res.to_org_name);
    setFromOrgName(res.from_org_obj.name);
    setWorkTypeId(res.work_type_id);
    setWorkTypeName(res.work_type_name);
    setWorkTypeSmallId(res.work_type_small?.id ?? 0);
    setWorkTypeSmallName(
      res.work_type_small?.name ?? WORK_TYPE_SMALL_DEFAULT_NAME
    );
    setWork(res.work ?? "");
    setUnitPrice(res.unit_price.toString());
    setQuantity(res.quantity.toString());
    setTotal(res.total.toString());
    setStateLabel(res.state_label);
    setState(res.state);
    setDt(new Date(res.billing_date));
    setDraft(res.draft);
    setOwn(res.own);

    setIsLoading(false);

    //仕事履歴を取得する
    fetchWorkHistory({
      from_org: res.from_org,
      to_org: res.to_org,
      work_type_id: res.work_type_id,
      work_type_small_id: res.work_type_small_id,
    });

    //単価の履歴を取得する
    fetchUnitPriceHistory({
      from_org: res.from_org,
      to_org: res.to_org,
      work_type_id: res.work_type_id,
      work_type_small_id: res.work_type_small_id,
    });
    setIsEnableLoadHistory(true);
    fetchPreviousAndNextId(invoice_Id);
  };

  /**
   * 定番の並び順前後のIDを取得する
   * @returns
   */
  const fetchPreviousAndNextId = async (invoiceId?: number) => {
    if (invoiceId === 0 || invoiceId === undefined) return;

    const params = {
      id: invoiceId,
      billing_ym: filterSelectedYM,
      to_filter_types: filterToOrgFilterTypes,
      to_org_ids: filterToOrgIds,
      from_filter_types: filterFromOrgFilterTypes,
      from_org_ids: filterFromOrgIds,
      states: filterStates,
    };
    const res = await InvoiceModel.previousAndNextIdReceipt(params);

    setPreviousId(res.previousId);
    setNextId(res.nextId);
  };

  /**
   * カレンダーを閉じる
   */
  const handleCalendarClose = () => setShowCalendar(false);

  /**
   * カレンダーを開く
   */
  const handleCalendarShow = () => setShowCalendar(true);

  function handleSelectCalendar(tm: any) {
    setDt(tm);
    setBillingDate(moment(tm).format("YYYY-MM-DD"));
    setIsPickerOpen(false);
    handleCalendarClose();
  }

  /**
   * 小分類仕事　入力
   * @param v
   */
  const handleOnChangeWorkSmall = (v: string) => {
    setWorkTypeSmallName(v);
    setWorkTypeSmallId(0);
  };

  /**
   * 仕事(内容)　入力
   * @param v
   */
  const handleOnChangeWork = (v: string) => {
    setWork(v);
  };

  /**
   * 中分類仕事フィルター画面に遷移
   */
  const handleOnPressWorkTypeMid = () => {
    window.navi.navigate(SCREENS.RECEIPT.SELECT_RECEIPT_WORK_TYPE, {
      id: work_type_id,
      setWorkTypeId,
      setWorkTypeName,
      backScreen: SCREENS.RECEIPT.RECEIPT_DETAIL,
      type: selectWorkType.mid,
    });
  };

  /**小分類仕事フィルター画面に遷移 */
  const handleOnPressWorkTypeSmall = () => {
    if (work_type_id <= 0) {
      alert("中分類仕事が選択されていません。");
      return;
    }

    window.navi.navigate(SCREENS.RECEIPT.SELECT_RECEIPT_WORK_TYPE, {
      id: work_type_small_id,
      parentId: work_type_id,
      setWorkTypeSmallId,
      setWorkTypeSmallName,
      backScreen: SCREENS.RECEIPT.RECEIPT_DETAIL,

      type: selectWorkType.small,
    });
  };

  /**
   * 中分類仕事を履歴より設定する
   * @param v
   */
  const handleOnSelectedWorkTypeMid = (v: WorkType) => {
    setWorkTypeId(v.id);
    setWorkTypeName(v.name);
  };

  /**
   * 小分類仕事を履歴より設定する
   * @param v
   */
  const handleOnSelectedWorkTypeSmall = (v: WorkType) => {
    setWorkTypeSmallId(v.id);
    setWorkTypeSmallName(v.name);
  };

  /**
   * 仕事を履歴より設定する
   * @param v
   */
  const handleOnSelectedWork = (v: { name: string }) => {
    setWork(v.name);
  };

  /**
   * 単価を履歴より設定する
   * @param v
   */
  const handleOnSelectedUnitPrice = (v: { name: string }) => {
    setUnitPrice(v.name);
  };

  /**
   * 中分類仕事の履歴を取得する
   * @returns
   */
  const fetchWorkTypeMidHistory = async () => {
    // initWorkType();
    if (from_org === 0 || to_org === 0) return;
    setIsLoadingWorkTypeMidHistory(true);
    const data = await WorkTypeModel.fetchMidHistory(from_org, to_org);

    setWorkTypeHistory(data);
    setIsLoadingWorkTypeMidHistory(false);
  };

  /**
   * 小分類仕事の履歴を取得する
   * @returns
   */
  const fetchWorkTypeSmallHistory = async () => {
    //中分類仕事を変更したら小分類仕事を初期化する
    // initWorkTypeSmall();
    if (from_org === 0 || to_org === 0 || work_type_id === 0) return;
    setIsLoadingWorkTypeSmallHistory(true);
    const data = await WorkTypeModel.fetchSmallHistory(
      from_org,
      to_org,
      work_type_id
    );
    setWorkTypeSmallHistory(data);
    //新規作成時
    if (!isEdit) {
      if (data.length > 0) {
        const first = data[0];
        //小分類に値を入れる
        setWorkTypeSmallId(first.id);
        setWorkTypeSmallName(first.name);
      } else {
        setWorkTypeSmallId(0);
        setWorkTypeSmallName("");
      }
    }
    setIsLoadingWorkTypeSmallHistory(false);
  };

  /**
   * 仕事(内容)の履歴を取得する
   * @param params
   * @returns
   */
  const fetchWorkHistory = async (params?: {
    from_org: number;
    to_org: number;
    work_type_id: number;
    work_type_small_id?: number;
  }) => {
    setWorkHistory([]);

    const fromOrg = params?.from_org ?? from_org;
    const toOrg = params?.to_org ?? to_org;
    const workTypeId = params?.work_type_id ?? work_type_id;
    const workTypeSmallId = params?.work_type_small_id ?? work_type_small_id;

    if (fromOrg === 0 || toOrg === 0 || workTypeId === 0) return;
    setIsLoadingWorkHistory(true);
    const data = await InvoiceModel.fetchWorkHistory({
      to_org_id: toOrg,
      from_org_id: fromOrg,
      work_type_id: workTypeId,
      work_type_small_id: workTypeSmallId,
    });
    setWorkHistory(data);
    //新規作成時
    if (!isEdit) {
      if (data.length > 0) {
        //一番最新の仕事内容を入力欄に入れる。
        const first = data[0];
        setWork(first.name);
      } else {
        setWork("");
      }
    }

    setIsLoadingWorkHistory(false);
  };

  /**
   * 単価の履歴を取得する
   * @param params
   * @returns
   */
  const fetchUnitPriceHistory = async (params?: {
    from_org: number;
    to_org: number;
    work_type_id: number;
    work_type_small_id?: number;
  }) => {
    setUnitPriceHistory([]);

    const fromOrg = params?.from_org ?? from_org;
    const toOrg = params?.to_org ?? to_org;
    const workTypeId = params?.work_type_id ?? work_type_id;
    const workTypeSmallId = params?.work_type_small_id ?? work_type_small_id;

    if (fromOrg === 0 || toOrg === 0 || workTypeId === 0) return;
    setIsLoadingUnitPriceHistory(true);
    const data = await InvoiceModel.fetchUnitPriceHistory({
      to_org_id: toOrg,
      from_org_id: fromOrg,
      work_type_id: workTypeId,
      work_type_small_id: workTypeSmallId,
    });
    setUnitPriceHistory(data);
    //新規作成時
    if (!isEdit) {
      if (data.length > 0) {
        //一番最新の単価を入力欄に入れる。
        const first = data[0];
        setUnitPrice(first.name);
      } else {
        setUnitPrice("");
      }
    }
    setIsLoadingUnitPriceHistory(false);
  };

  /**
   * 作業メモのタップイベントを処理します。
   *
   * @param {WorkMemos} workMemo - 作業メモオブジェクト。
   * @return {void} なし
   */
  function handleOnPressWorkMemo(workMemo: WorkMemos) {
    if (
      workMemo.work_type_obj !== undefined &&
      (work_type_small_id != workMemo.id ||
        work_type_small_name != workMemo.work_type_obj?.name)
    ) {
      setWorkTypeSmallId(workMemo.work_type_obj!.id);
      setWorkTypeSmallName(workMemo.work_type_obj?.name);
    }

    setWork(workMemo.name);
    setUnitPrice(workMemo.unit_price.toString());
  }

  /**
   * 次の請求に移動する
   * @returns
   */
  const handleOnNextInvoice = async () => {
    if (nextId === null || nextId === undefined) return;

    setIsLoading(true);
    window.navi.navigate(SCREENS.RECEIPT.RECEIPT_DETAIL, {
      id: nextId,
    });
    setIsEnableLoadHistory(true);
    fetchById(nextId);
  };

  /**
   * 前の請求に移動する
   * @returns
   */
  const handleOnPreviousInvoice = () => {
    if (previousId === null || previousId === undefined) return;

    setIsLoading(true);
    window.navi.navigate(SCREENS.RECEIPT.RECEIPT_DETAIL, {
      id: previousId,
    });
    setIsEnableLoadHistory(true);
    fetchById(previousId);
  };

  /**
   * 請求状態を変更する
   * @param state
   */
  const updateInvoice = async (state: InvoiceStateType) => {
    setIsLoading(true);
    await InvoiceModel.updateReceipt({
      id: props.route.params.id,
      billing_date: billing_date,
      to_org: to_org,
      from_org: from_org,
      work_type_id: work_type_id,
      work_type_small_id: work_type_small_id,
      work_type_small_name: work_type_small_name,
      work: work,
      unit_price: unit_price,
      quantity: quantity,
      total: total,
      state: state,
    });

    return;
  };

  /**
   * 受領地スト画面に戻る
   */
  const backListScreen = () => {
    navigation.navigate(SCREENS.RECEIPT.RECEIPT, {
      update_id: props.route.params.id,
      delete_id: 0,
    });
  };

  /**------------------------------------------------
   * useEffect
   * ------------------------------------------------
   * */
  /**■ 単価と数量の変更 */
  useEffect(() => {
    const total = Number(unit_price) * Number(quantity);
    setTotal(total.toString());
  }, [unit_price, quantity]);

  /**■請求先(id)と請求元(id)の変更 */
  useEffect(() => {
    fetchWorkTypeMidHistory();
  }, [to_org, from_org]);

  /**■中分類仕事(id)の変更 */
  useEffect(() => {
    fetchWorkTypeSmallHistory();
  }, [work_type_id]);

  /**■ 中分類仕事(id)と小分類仕事(id)の変更 */
  useEffect(() => {
    if (!isEnableLoadHistory) return;
    fetchWorkHistory();
    fetchUnitPriceHistory();
  }, [work_type_small_id]);

  /**■ 初期 */
  useEffect(() => {
    //setIsLoading(true);
    if (isEdit) {
      fetchById(invoiceId);
      window
        .axios({
          method: "get",
          url:
            window.baseUrl +
            "/comment?type=0&target_id=" +
            props.route.params.id,
          headers: { Authorization: "Bearer " + window.accessToken },
        })
        .then(({ data }: any) => {
          if (data) {
            setComments(data);
            //
            let ur = 0;
            data.forEach((item: any) => {
              if (!item.read_flag) {
                ur++;
              }
            });
            setUnread(ur);
            //
            setSelectedIndex(0);
            setIsLoading(false);
          }
        })
        .catch((err: any) => {
          window.functions.logout();
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const component1 = () => <Text>請求内容</Text>;
  const component2 = () => (
    <Text>
      コメント{comments.length > 0 ? "(" + comments.length + ")" : ""}{" "}
      {unread > 0 && <Badge value={unread} status="error" />}
    </Text>
  );
  const buttons = [{ element: component1 }, { element: component2 }];

  return (
    <View style={styles.container}>
      <View style={{ width: "100%" }}>
        {isEdit && draft != 1 && (
          <View
            style={{
              width: "100%",
              height: 25,
              backgroundColor: StateColor[state],
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ textAlign: "center", fontSize: 14 }}>
              {state_label}
            </Text>
          </View>
        )}
        {draft == 1 && (
          <View
            style={{
              width: "100%",
              height: 25,
              backgroundColor: "#ccc",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ textAlign: "center", fontSize: 14 }}>下書き</Text>
          </View>
        )}
        {isEdit && draft != 1 && (
          <ButtonGroup
            onPress={(index) => {
              if (index == 1 && unread > 0) window.reloadFlag = true;
              window
                .axios({
                  method: "get",
                  url:
                    window.baseUrl +
                    "/comment?type=0&target_id=" +
                    props.route.params.id +
                    (index == 1 ? "&read=true" : ""),
                  headers: { Authorization: "Bearer " + window.accessToken },
                })
                .then(({ data }: any) => {
                  if (data) {
                    setComments(data);
                    //
                    let ur = 0;
                    data.forEach((item: any) => {
                      if (!item.read_flag) {
                        ur++;
                      }
                    });
                    setUnread(ur);
                    //
                    setSelectedIndex(index);
                  }
                })
                .catch((err: any) => {});
            }}
            selectedIndex={selectedIndex}
            buttons={buttons}
            containerStyle={{ borderWidth: 0, height: 40 }}
            innerBorderStyle={{ width: 0 }}
            selectedButtonStyle={{
              borderBottomWidth: 2,
              borderBottomColor: "rgb(47, 149, 220)",
              backgroundColor: "white",
            }}
          />
        )}
      </View>
      <ScrollView
        style={{
          width: "100%",
          padding: 10,
          display: selectedIndex == 0 || selectedIndex == -1 ? "flex" : "none",
        }}
      >
        <NextAndBackButton
          onNext={() => handleOnNextInvoice()}
          onPrevious={() => handleOnPreviousInvoice()}
          previousDisabled={
            previousId === undefined || previousId === null || previousId === 0
          }
          nextDisabled={nextId === undefined || nextId === null || nextId === 0}
        />

        {isEdit &&
          (state == InvoiceStateType.Pending ||
            state == InvoiceStateType.Received ||
            state == InvoiceStateType.Rejected ||
            state == InvoiceStateType.Onhold ||
            state == InvoiceStateType.Resubmitted) &&
          draft != DraftFlg.ON && (
            <View
              style={{
                width: "100%",
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              {!own && invoice && <ReadOnlyInvoiceDetail item={invoice} />}
              {own && (
                <>
                  {/* #############################
                    請求日
                  ################################
                  */}
                  <BillingDate
                    billingDate={billing_date}
                    dt={dt}
                    onSelectCalendar={handleSelectCalendar}
                  />
                  {/* #############################
                    請求先
                  ################################
                  */}
                  <View style={styles.itemViewStyle}>
                    <Text style={{}}>請求先</Text>
                    <TouchableOpacity
                      onPress={() => {
                        window.navi.navigate(
                          SCREENS.RECEIPT.SELECT_RECEIPT_ORGANISATION,
                          {
                            id: to_org,
                            setOrg: setToOrg,
                            setOrgName: setToOrgName,
                            backScreen: SCREENS.RECEIPT.RECEIPT_DETAIL,
                            type: 0,
                            mode: "to_org",
                          }
                        );
                      }}
                    >
                      <Text style={styles.itemStyleEdit}>
                        {to_org_name}
                        <View style={{ position: "absolute", right: 0 }}>
                          <Text style={{ fontWeight: "normal" }}>＞</Text>
                        </View>
                      </Text>
                    </TouchableOpacity>
                    <Text style={{ color: "rgb(255, 25, 12)", fontSize: 12 }}>
                      {errToOrg}
                    </Text>
                  </View>
                  {/* #############################
                  請求元
                  #################################
                  */}
                  <View style={styles.itemViewStyle}>
                    <Text style={{}}>請求元</Text>
                    <TouchableOpacity
                      onPress={() => {
                        window.navi.navigate(
                          SCREENS.RECEIPT.SELECT_RECEIPT_ORGANISATION,
                          {
                            id: from_org,
                            setOrg: setFromOrg,
                            setOrgName: setFromOrgName,
                            backScreen: SCREENS.RECEIPT.RECEIPT_DETAIL,
                            range: "belong",
                            type: 0,
                            mode: "from_org",
                          }
                        );
                      }}
                    >
                      <Text
                        style={{
                          ...styles.itemStyleEdit,
                          fontSize: 22,
                          fontWeight: "bold",
                          color: "#f00",
                        }}
                      >
                        {from_org_name}
                        <View style={{ position: "absolute", right: 0 }}>
                          <Text style={{ fontWeight: "normal", color: "#000" }}>
                            ＞
                          </Text>
                        </View>
                      </Text>
                    </TouchableOpacity>
                    <CommonErrorLabel title={errFromOrg} />
                  </View>
                  {/* #############################
                  中分類（仕事）
                    #################################
                    */}
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 3,
                    }}
                  >
                    <Text style={{}}>中分類（仕事）</Text>
                    <WorkMemoButton
                      workTypeMidId={work_type_id}
                      onPress={handleOnPressWorkMemo}
                    />
                  </View>
                  <ButtonWithDropdown
                    dropdownTitle="履歴"
                    value={work_type_name}
                    onClick={handleOnPressWorkTypeMid}
                    dropdownItems={workTypeMidHistory}
                    isLoading={isLoadingWorkTypeMidHistory}
                    onSelect={handleOnSelectedWorkTypeMid}
                    errorMsg={errWorkType}
                  />
                  {/* #############################
                  小分類（仕事
                    #################################
                    */}
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 3,
                    }}
                  >
                    <Text style={{}}>小分類（仕事）</Text>
                    <WorkMemoButton
                      style={{ marginRight: 10 }}
                      workTypeSmallId={work_type_small_id}
                      onPress={handleOnPressWorkMemo}
                    />
                  </View>
                  <ButtonWithDropdown
                    dropdownTitle="DB"
                    value={work_type_small_name}
                    onClick={handleOnPressWorkTypeSmall}
                    // onClickDB={handleOnPressWorkTypeMid}
                    // onSelectDB={handleOnPressWorkTypeMid}
                    dropdownItems={workTypeSmallHistory}
                    // dropdownItemsDB={workTypeMidDB}
                    isLoading={isLoadingWorkTypeSmallHistory}
                    onSelect={handleOnSelectedWorkTypeSmall}
                    errorMsg={errWorkTypeSmall}
                  />

                  {/* #############################
                  仕事
                  #################################
                  */}
                  <Text style={{}}>仕事</Text>
                  <InputWithDoropdown
                    dropdownTitle="履歴"
                    value={work}
                    onChange={handleOnChangeWork}
                    dropdownItems={workHistory}
                    isLoading={isLoadingWorkHistory}
                    onSelect={handleOnSelectedWork}
                    errorMsg={errWork}
                    onPressCopy={() => {
                      if (confirm("小分類（仕事）の内容をコピーしますか？")) {
                        setWork(work_type_small_name);
                      }
                    }}
                  />
                  {/* #############################
                  単価／時給
                    #################################
                    */}
                  <Text style={{}}>単価／時給</Text>
                  <InputWithDoropdown
                    inputType={InputType.NUMBER}
                    dropdownTitle="履歴"
                    value={unit_price}
                    onChange={setUnitPrice}
                    dropdownItems={unitPriceHistory}
                    isLoading={isLoadingUnitPriceHistory}
                    onSelect={handleOnSelectedUnitPrice}
                    errorMsg={errUnitPrice}
                  />
                  {/* #############################
                  数量/ 時間
                    #################################
                    */}
                  <View style={styles.itemViewStyle}>
                    <Text style={{}}>数量／時間</Text>
                    <Input
                      keyboardType={"number-pad"}
                      style={styles.itemStyleEdit}
                      value={quantity}
                      errorMessage={errQuantity}
                      onChangeText={(value: any) => {
                        setQuantity(value);
                        let val = parseInt(unit_price) * parseInt(value);
                        setTotal(val.toString());
                      }}
                      onFocus={() => {
                        if (quantity == "0") {
                          setQuantity("");
                        }
                      }}
                      onBlur={() => {
                        if (quantity == "") {
                          setQuantity("0");
                        }
                      }}
                    />
                  </View>
                  {/* #############################
                  合計
                  #################################
                  */}
                  <View style={styles.itemViewStyle}>
                    <Text style={{}}>合計</Text>
                    <Text style={styles.itemStyle}>
                      <Text style={{ ...styles.itemStyle }}>
                        {numberFormat(Number(total))}
                      </Text>
                    </Text>
                  </View>
                  {/* #############################
                  その他
                  #################################
                  */}

                  {(state == InvoiceStateType.Pending ||
                    state == InvoiceStateType.Onhold ||
                    state == InvoiceStateType.Resubmitted) && (
                    <Button
                      style={{ marginTop: 10 }}
                      title="受領する"
                      titleStyle={{ fontSize: 17 }}
                      disabled={false}
                      onPress={async () => {
                        if (!validate()) return;
                        if (confirm("受領してもよろしいですか？")) {
                          await updateInvoice(InvoiceStateType.Received);
                          backListScreen();
                        }
                      }}
                    />
                  )}
                  {(state == InvoiceStateType.Pending ||
                    state == InvoiceStateType.Resubmitted) && (
                    <Button
                      title="保留する"
                      type="outline"
                      titleStyle={{ fontSize: 15 }}
                      buttonStyle={{ height: 30 }}
                      style={{ marginTop: 10 }}
                      onPress={async () => {
                        if (!validate()) return;
                        if (confirm("保留してもよろしいですか？")) {
                          await updateInvoice(InvoiceStateType.Onhold);
                          backListScreen();
                        }
                      }}
                    />
                  )}
                  {(state == InvoiceStateType.Pending ||
                    state == InvoiceStateType.Onhold ||
                    state == InvoiceStateType.Resubmitted) && (
                    <Button
                      title="却下する"
                      type="outline"
                      titleStyle={{ fontSize: 15, color: "#f00" }}
                      buttonStyle={{ height: 30, borderColor: "#f00" }}
                      style={{ marginTop: 10 }}
                      onPress={async () => {
                        if (!validate()) return;
                        if (confirm("却下してもよろしいですか？")) {
                          await updateInvoice(InvoiceStateType.Rejected);
                          backListScreen();
                        }
                      }}
                    />
                  )}
                  <Button
                    title={"修正する"}
                    titleStyle={{ fontSize: 15 }}
                    disabled={false}
                    buttonStyle={{
                      marginTop: 20,
                      height: 30,
                      backgroundColor: "#3cb371",
                    }}
                    onPress={async () => {
                      if (!validate()) return;
                      if (confirm("修正してもよろしいですか？")) {
                        await updateInvoice(state);
                        backListScreen();
                      }
                    }}
                  />
                  <Button
                    title="削除する"
                    titleStyle={{ fontSize: 15 }}
                    disabled={false}
                    buttonStyle={{
                      height: 30,
                      backgroundColor: "#f00",
                      marginTop: 20,
                    }}
                    onPress={() => {
                      if (!confirm("削除してもよいですか？")) return;
                      window
                        .axios({
                          method: "delete",
                          url:
                            window.baseUrl +
                            "/invoice/" +
                            props.route.params.id,
                          headers: {
                            Authorization: "Bearer " + window.accessToken,
                          },
                        })
                        .then(() => {
                          // window.reloadFlag = true;
                          navigation.navigate(SCREENS.RECEIPT.RECEIPT, {
                            update_id: 0,
                            delete_id: props.route.params.id,
                          });
                        });
                    }}
                  />
                  {state == InvoiceStateType.Received && (
                    <Button
                      title="受領を確認中に戻す"
                      titleStyle={{ fontSize: 15, color: "#333" }}
                      disabled={false}
                      buttonStyle={{
                        height: 30,
                        backgroundColor: "#87cefa",
                        marginTop: 20,
                      }}
                      onPress={async () => {
                        if (!validate()) return;
                        if (!confirm("確認中に戻してもよいですか？")) return;
                        await updateInvoice(InvoiceStateType.Pending);
                        backListScreen();
                      }}
                    />
                  )}
                </>
              )}
            </View>
          )}
      </ScrollView>
      {/* コメント画面 */}
      <View
        style={{
          flex: 1,
          width: "100%",
          display: selectedIndex == 1 || selectedIndex == -1 ? "flex" : "none",
        }}
      >
        <View
          style={{ flex: 1, marginBottom: 70 }}
          onLayout={(info) => {
            setListHeight(info.nativeEvent.layout.height);
          }}
        >
          <FlatList
            inverted
            style={{
              width: "100%",
              position: "absolute",
              maxHeight: listHeight,
            }}
            keyExtractor={keyExtractor}
            data={comments}
            renderItem={renderItem}
            ref={(ref) => {
              window.commentList = ref;
            }}
          />
        </View>
        <View
          style={{
            position: "absolute",
            width: "100%",
            height: 70,
            bottom: 0,
            left: 0,
            backgroundColor: "#fff",
            flexDirection: "row",
            borderTopColor: "#eee",
            borderTopWidth: 1,
          }}
        >
          <View
            style={{
              flex: 1,
              height: "100%",
              backgroundColor: "#fff",
              padding: 5,
              paddingLeft: 20,
            }}
          >
            <TextInput
              multiline
              numberOfLines={2}
              style={{
                flex: 1,
                borderWidth: 1,
                borderColor: "#ddd",
                borderRadius: 3,
              }}
              onChangeText={(value) => {
                setComment(value);
              }}
              value={comment}
            />
          </View>
          <View
            style={{
              width: 70,
              height: "100%",
              backgroundColor: "#fff",
              padding: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              title="送信"
              titleStyle={{ fontSize: 14 }}
              onPress={() => {
                if (comment != "" && comment != null) {
                  window
                    .axios({
                      method: "post",
                      url: window.baseUrl + "/comment",
                      data: {
                        type: 0,
                        target_id: props.route.params.id,
                        comment: comment,
                      },
                      headers: {
                        Authorization: "Bearer " + window.accessToken,
                      },
                    })
                    .then(({ data }: any) => {
                      setComments(data);
                      //
                      let ur = 0;
                      data.forEach((item: any) => {
                        if (!item.read_flag) {
                          ur++;
                        }
                      });
                      setUnread(ur);
                      //
                      setComment("");
                    })
                    .catch((err: any) => {
                      window.functions.logout();
                    });
                }
              }}
            />
          </View>
        </View>
      </View>
      {isLoading && <CoverIndicator />}
    </View>
  );
}

function keyExtractor(item: any, index: any) {
  return item.id.toString();
}

function renderItem({ item }: any) {
  return (
    <View
      style={{
        width: "100%",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        borderBottomColor: "#ddd",
        borderBottomWidth: 1,
        backgroundColor: item.read_flag == false ? "#ffe4e1" : "#fff",
      }}
    >
      <Text style={{ fontWeight: "bold", marginBottom: 3 }}>
        {moment(item.created_at).format("YYYY-MM-DD HH:mm")}　{item.user_name}
      </Text>
      <Text>{item.comment}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
  },
  itemViewStyle: {
    marginBottom: 12,
  },
  itemStyle: {
    fontWeight: "bold",
    fontSize: 17,
  },
  itemStyleEdit: {
    fontWeight: "bold",
    color: "#333",
    fontSize: 17,
  },
});
