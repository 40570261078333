import React from "react";

import { numberFormat } from "../../util/Math";
import { StyleSheet, View, Text } from "react-native";
import { Invoice } from "../../models/InvoiceModel";

type ReadOnlyInvocieDetailProps = {
  item: Invoice;
};

/**
 * 読み取り専用の請求情報
 */
const ReadOnlyInvocieDetail = React.forwardRef(
  (props: ReadOnlyInvocieDetailProps, ref) => {
    return (
      <>
        <View style={styles.itemViewStyle}>
          <Text style={{}}>請求日</Text>
          <Text style={styles.itemStyle}>{props.item.billing_date}</Text>
        </View>
        <View style={styles.itemViewStyle}>
          <Text style={{}}>請求先</Text>
          <Text style={styles.itemStyle}>{props.item.to_org_name}</Text>
        </View>
        <View style={styles.itemViewStyle}>
          <Text style={{}}>請求元</Text>
          <Text style={styles.itemStyle}>{props.item.from_org_obj.name}</Text>
        </View>
        <View style={styles.itemViewStyle}>
          <Text style={{}}>分類（仕事）</Text>
          <Text style={styles.itemStyle}>{props.item.work_type_name}</Text>
        </View>
        <View style={styles.itemViewStyle}>
          <Text style={{}}>仕事</Text>
          <Text style={styles.itemStyle}>{props.item.work}</Text>
        </View>
        <View style={styles.itemViewStyle}>
          <Text style={{}}>単価／時給</Text>
          <Text style={styles.itemStyle}>
            {numberFormat(Number(props.item.unit_price))}
          </Text>
        </View>
        <View style={styles.itemViewStyle}>
          <Text style={{}}>数量／時間</Text>
          <Text style={styles.itemStyle}>
            {numberFormat(Number(props.item.quantity))}
          </Text>
        </View>
        <View style={styles.itemViewStyle}>
          <Text style={{}}>合計</Text>
          <Text style={styles.itemStyle}>
            {numberFormat(Number(props.item.total))}
          </Text>
        </View>
      </>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
  },
  itemViewStyle: {
    marginBottom: 12,
  },
  itemStyle: {
    fontWeight: "bold",
    fontSize: 17,
  },
  itemStyleEdit: {
    fontWeight: "bold",
    color: "#333",
    fontSize: 17,
  },
});

export default ReadOnlyInvocieDetail;
