import {
  CardStyleInterpolators,
  createStackNavigator,
  HeaderBackButton,
} from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { useState } from "react";

import SelectOrganisationScreen from "../../screens/filter/SelectOrganisationScreen";
import SelectWorkTypeScreen from "../../screens/filter/SelectWorkTypeScreen";

import ReceiptScreen from "../../screens/receipt/ReceiptScreen";
import ReceiptDetailScreen from "../../screens/receipt/ReceiptDetailScreen";

import FilterScreen from "../../screens/invoice/FilterScreen";

import { ReceiptTabParamList } from "../../types";
import { HeaderLeft } from "../../components/header/LeftHeader";
import { OrgHeader } from "../../components/header/OrderToInvoiceHeader";
import { SCREENS } from "../../constants/Screens";
import { ReceiptHeader } from "../../components/header/ReceiptHeader";

//受領一覧
const ReceiptTabStack = createStackNavigator<ReceiptTabParamList>();
export function ReceiptTabNavigator() {
  const navigation: any = useNavigation();
  window.functions.openDrawer = () => navigation.openDrawer();

  const [confChecked, setConfChecked] = useState(false);
  window.functions.setConfChecked = setConfChecked;

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("tabPress", (e: any) => {
      window.reloadFlag = true;
    });
    return unsubscribe;
  }, [navigation]);

  return (
    <ReceiptTabStack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
    >
      <ReceiptTabStack.Screen
        name={SCREENS.RECEIPT.RECEIPT as keyof ReceiptTabParamList}
        component={ReceiptScreen}
        options={{
          headerTitle: "受領",
          headerLeft: HeaderLeft,
          headerRight: (props) =>
            ReceiptHeader(props, {
              confChecked: confChecked,
              onChange(v) {
                setConfChecked(v);
                window.functions.setConfFilter(v);
              },
            }),
        }}
      />

      <ReceiptTabStack.Screen
        name={SCREENS.RECEIPT.RECEIPT_DETAIL as keyof ReceiptTabParamList}
        component={ReceiptDetailScreen}
        options={{
          headerTitle: "受領処理：請求",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.RECEIPT.RECEIPT);
              }}
            />
          ),
          //headerRight: null,
          animationEnabled: true,
        }}
      />
      <ReceiptTabStack.Screen
        name={SCREENS.RECEIPT.RECEIPT_FILTER as keyof ReceiptTabParamList}
        component={FilterScreen}
        options={{
          headerTitle: "フィルター",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.RECEIPT.RECEIPT);
              }}
            />
          ),
          animationEnabled: true,
        }}
      />
      <ReceiptTabStack.Screen
        name={
          SCREENS.RECEIPT
            .SELECT_RECEIPT_ORGANISATION_FILTER as keyof ReceiptTabParamList
        }
        component={SelectOrganisationScreen}
        options={{
          headerTitle: "組織選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.RECEIPT.RECEIPT_FILTER);
              }}
            />
          ),
          headerRight: OrgHeader,
          animationEnabled: true,
        }}
      />
      <ReceiptTabStack.Screen
        name={
          SCREENS.RECEIPT
            .SELECT_RECEIPT_WORK_TYPE_FILTER as keyof ReceiptTabParamList
        }
        component={SelectWorkTypeScreen}
        options={{
          headerTitle: "仕事分類選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.RECEIPT.RECEIPT_FILTER);
              }}
            />
          ),
          headerRight: OrgHeader,
          animationEnabled: true,
        }}
      />

      <ReceiptTabStack.Screen
        name={
          SCREENS.RECEIPT
            .SELECT_RECEIPT_ORGANISATION as keyof ReceiptTabParamList
        }
        component={SelectOrganisationScreen}
        options={{
          headerTitle: "組織選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.RECEIPT.RECEIPT_DETAIL);
              }}
            />
          ),
          headerRight: OrgHeader,
          animationEnabled: true,
        }}
      />
      <ReceiptTabStack.Screen
        name={
          SCREENS.RECEIPT.SELECT_RECEIPT_WORK_TYPE as keyof ReceiptTabParamList
        }
        component={SelectWorkTypeScreen}
        options={{
          headerTitle: "仕事分類選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.RECEIPT.RECEIPT_DETAIL);
              }}
            />
          ),
          headerRight: OrgHeader,
          animationEnabled: true,
        }}
      />
    </ReceiptTabStack.Navigator>
  );
}
