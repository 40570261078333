import {
  CardStyleInterpolators,
  createStackNavigator,
} from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { useState } from "react";

import ReceiptScreen from "../../screens/receipt/ReceiptScreen";

import { WorkDBTabParamList } from "../../types";
import { HeaderLeft } from "../../components/header/LeftHeader";
import { SCREENS } from "../../constants/Screens";
import { ReceiptHeader } from "../../components/header/ReceiptHeader";
import { WorkDBHeader } from "../../components/header/WorkDBHeader";
import WorkDBScreen from "../../screens/workDB/WorkDBScreen2";

//受領一覧
const WorkDBTabStack = createStackNavigator<WorkDBTabParamList>();
export function WorkDBTabNavigator() {
  const navigation: any = useNavigation();
  window.functions.openDrawer = () => navigation.openDrawer();

  const [confChecked, setConfChecked] = useState(false);
  window.functions.setConfChecked = setConfChecked;

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("tabPress", (e: any) => {
      window.reloadFlag = true;
    });
    return unsubscribe;
  }, [navigation]);

  return (
    <WorkDBTabStack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
    >
      <WorkDBTabStack.Screen
        name={SCREENS.WORKDB.WORKDB as keyof WorkDBTabParamList}
        component={WorkDBScreen}
        options={{
          headerTitle: "仕事データベース",
          headerLeft: HeaderLeft,
          headerRight: (props) => WorkDBHeader(props),
        }}
      />
    </WorkDBTabStack.Navigator>
  );
}
