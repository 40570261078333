import { FontAwesome } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { useNavigation, useNavigationState } from "@react-navigation/native";
import * as React from "react";
import {
  DrawerContentScrollView,
  DrawerItem,
  DrawerItemList,
  createDrawerNavigator,
} from "@react-navigation/drawer";

import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";

import { BottomTabParamList } from "../types";
import { HomeTabNavigator } from "./tabStack/HomeTabStack";
import { InvoiceTabNavigator } from "./tabStack/InvoiceTabStack";
import { ReceiptTabNavigator } from "./tabStack/ReceiptTabStack";
import { SettingNavigator } from "./tabStack/SettingTabStack";
import { AccountNavigator } from "./tabStack/AccountTabStack";
import { WorkListTabNavigator } from "./tabStack/WorkListTabStack";
import { CompareTabNavigator } from "./tabStack/CompareTabStack";
import { WorkDBTabNavigator } from "./tabStack/WorkDBTabStack";
import { LinkURL } from "../constants/Setting";
import { tabContext } from "../App";
import { useEffect } from "react";

const Drawer = createDrawerNavigator();
const BottomTab = createBottomTabNavigator<BottomTabParamList>();

function CustomDrawerContent(props: any) {
  const userInfo = window.userInfo;

  const isAdmin = userInfo?.master ?? 0 > 0 ? true : false;
  const isHeadquarters = userInfo?.admin ?? 0 > 0 ? true : false;

  return (
    <DrawerContentScrollView {...props}>
      <DrawerItemList {...props} />
      {isHeadquarters && (
        <DrawerItem
          label="本部システム"
          onPress={() => window.open(LinkURL().honbu, "_blank")}
        />
      )}
      {isAdmin && (
        <DrawerItem
          label="アドミン"
          onPress={() => window.open(LinkURL().admin, "_blank")}
        />
      )}
    </DrawerContentScrollView>
  );
}

export default function BottomTabNavigator() {
  const navigation: any = useNavigation();
  window.navi = navigation;

  return (
    <Drawer.Navigator
      initialRouteName="Drawer"
      drawerContent={(props: any) => <CustomDrawerContent {...props} />}
    >
      <Drawer.Screen
        name="Drawer"
        component={BottomTabComponent}
        options={{ title: "ホーム" }}
      />
      {/* <Drawer.Screen
        name="Teiban"
        component={SettingNavigator}
        options={{ title: "定番編集" }}
      /> */}
      <Drawer.Screen
        name="Logout"
        component={AccountNavigator}
        options={{ title: "ログアウト" }}
      />
    </Drawer.Navigator>
  );
}

export const TabList = {
  Home: { name: "ホーム", index: 0 },
  Invoice: { name: "請求", index: 1 },
  Receipt: { name: "受領", index: 2 },
  WorkList: { name: "一覧", index: 3 },
  Compare: { name: "比較", index: 4 },
  WorkDB: { name: "仕事DB", index: 5 },
};

function BottomTabComponent() {
  const colorScheme = useColorScheme();
  const { setTabIndex } = React.useContext(tabContext);
  const navigationState = useNavigationState((state) => state);

  // Drawerのインデックスを取得
  const drawerIndex = navigationState.routes.find(
    (route) => route.name === "Drawer"
  )?.state?.index;

  useEffect(() => {
    setTabIndex(drawerIndex ?? 0);
  }, [drawerIndex]);

  return (
    <BottomTab.Navigator
      initialRouteName="Home" //Home
      tabBarOptions={{
        activeTintColor: Colors[colorScheme].tint,
        style: { height: 60 },
      }}
    >
      <BottomTab.Screen
        name="Home"
        component={HomeTabNavigator}
        options={{
          title: "ホーム",
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              name="home"
              color={
                focused
                  ? Colors[colorScheme].tabIconSelected
                  : Colors[colorScheme].tabIconDefault
              }
            />
          ),
        }}
      />
      <BottomTab.Screen
        name="Invoice"
        component={InvoiceTabNavigator}
        options={{
          title: "請求",
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              name="money"
              color={
                focused
                  ? Colors[colorScheme].tabIconSelected
                  : Colors[colorScheme].tabIconDefault
              }
            />
          ),
        }}
      />
      <BottomTab.Screen
        name="Receipt"
        component={ReceiptTabNavigator}
        options={{
          title: "受領",
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              name="check-square-o"
              color={
                focused
                  ? Colors[colorScheme].tabIconSelected
                  : Colors[colorScheme].tabIconDefault
              }
            />
          ),
        }}
      />
      <BottomTab.Screen
        name="WorkList"
        component={WorkListTabNavigator}
        options={{
          title: "一覧",
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              name="wpforms"
              color={
                focused
                  ? Colors[colorScheme].tabIconSelected
                  : Colors[colorScheme].tabIconDefault
              }
            />
          ),
        }}
      />

      <BottomTab.Screen
        name="Compare"
        component={CompareTabNavigator}
        options={{
          title: "比較",
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              name="wpforms"
              color={
                focused
                  ? Colors[colorScheme].tabIconSelected
                  : Colors[colorScheme].tabIconDefault
              }
            />
          ),
        }}
      />

      <BottomTab.Screen
        name="WorkDB"
        component={WorkDBTabNavigator}
        options={{
          title: "仕事DB",
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              name="list"
              color={
                focused
                  ? Colors[colorScheme].tabIconSelected
                  : Colors[colorScheme].tabIconDefault
              }
            />
          ),
        }}
      />
    </BottomTab.Navigator>
  );
}

// https://icons.expo.fyi/
function TabBarIcon(props: { name: string; color: string }) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}
