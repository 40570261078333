import * as React from "react";

import { CheckBox, Text } from "react-native-elements";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { Teiban, TeibanModel } from "../../models/TeibanModel";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { CommonColor } from "../../constants/Colors";
import { useState } from "react";
import NotificationToast from "../common/NotificationToast";
import { Regex } from "../../constants/Regex";

type TeibanListItemProps = {
  item: Teiban;
  isChecked: boolean;
  onCheck?: (id: string) => void;
  onPress?: () => void;
  onUpdated?: (v: Teiban) => void;
};

/**
 * 定番一覧のアイテム
 * @param param0
 * @returns
 */
const TeibanListItem = React.forwardRef((props: TeibanListItemProps, ref) => {
  /**請求日付 */
  const [targetDay, setTargetDay] = useState<string>(props.item.targetday);
  /**設定数量 */
  const [quantity, setQuantity] = useState<string>(
    props.item.quantity.toString()
  );
  /**請求日付 保存中フラグ*/
  const [isSaveingTargetDay, setIsSaveingTargetDay] = useState<boolean>(false);
  /**設定数量 保存中フラグ*/
  const [isSaveingQuantity, setIsSaveingQuantity] = useState<boolean>(false);
  /**設定数量を変更した。 */
  const isEditedTargetDay = props.item.targetday !== targetDay;
  /**設定数量を変更した。 */
  const isEditedQuantity = props.item.quantity !== Number(quantity);
  /**下書きの定番 */
  const isDraft = props.item.draft !== 0;

  /**
   * 定番タップイベント
   */
  const handleOnPress = () => {
    if (props.onPress) {
      props.onPress();
    }
  };

  //設定日付を変更する
  const handleOnChangeTargetDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e === undefined) return;
    const value = e.target.value;
    if (value.length > 2) return;
    if (Regex.NUMBER.test(value) || value === "") {
      setTargetDay(value);
    }
  };

  //設定数量を変更する
  const handleOnChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e === undefined) return;
    const value = e.target.value;
    if (value.length > 2) return;
    if (Regex.NUMBER.test(value) || value === "") {
      setQuantity(value);
    }
  };

  const handleOnTextInputTap = () => {
    // テキスト入力のタップ時に発火するイベントハンドラー
    // 何も処理を行わず、タップイベントの伝播を停止する
  };

  // Enterキーが押されたときのイベントハンドラ
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault(); // デフォルトのフォームの移動を防ぐ
      event.target.blur(); // フォーカスを外す
    }
  };

  // 設定日付のフォーカスが外れたときのイベントハンドラ
  const handleTargetDayUpdate = async () => {
    //変更が無い場合は何もしない
    if (!isEditedTargetDay) return;

    try {
      setIsSaveingTargetDay(true);
      const res = await TeibanModel.updateTargetDay({
        id: props.item.id,
        targetDay: targetDay,
      });

      setIsSaveingTargetDay(false);
      NotificationToast().updated();
      if (props.onUpdated !== undefined) props.onUpdated(res);
    } catch (error) {
      setIsSaveingTargetDay(false);
      NotificationToast().error("登録に失敗しました。");
    }
  };

  // 設定数量のフォーカスが外れたときのイベントハンドラ
  const handleQuantityUpdate = async () => {
    //変更が無い場合は何もしない
    if (!isEditedQuantity) return;
    try {
      setIsSaveingQuantity(true);
      const res = await TeibanModel.updateQuantity({
        id: props.item.id,
        quantity: quantity,
      });

      setIsSaveingQuantity(false);
      NotificationToast().updated();
      if (props.onUpdated !== undefined) props.onUpdated(res);
    } catch (error) {
      setIsSaveingQuantity(false);
      NotificationToast().error("登録に失敗しました。");
    }
  };

  //設定数量の更新

  const draftTag = () => {
    if (props.item.draft === 0) return <></>;
    return (
      <Col>
        <View
          style={{
            backgroundColor: "#ccc",
            padding: 2,
            width: 50,
          }}
        >
          <Text
            style={{
              fontSize: 12,
              textAlign: "center",
            }}
          >
            下書き
          </Text>
        </View>
      </Col>
    );
  };

  return (
    <TouchableOpacity onPress={handleOnPress}>
      <Container
        fluid
        className="p-0 border"
        style={{ backgroundColor: isDraft ? CommonColor.Light.Grey : "" }}
      >
        {draftTag()}

        <View style={{ flexDirection: "row" }}>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: 40,
            }}
          >
            <CheckBox
              containerStyle={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
              wrapperStyle={{
                margin: 0,
              }}
              checked={props.isChecked}
              checkedColor={CommonColor.Normal.Red}
              onPress={() => {
                if (props.onCheck === undefined) {
                  return;
                }
                props.onCheck(props.item.id.toString());
              }}
            />
          </View>
          <Col xs={10} sm={11}>
            <Row>
              <Col xs={4} sm={3}>
                <Text style={[styles.subtitle]}>{props.item.to_org_name}</Text>
              </Col>
              <Col>
                <TouchableOpacity onPress={handleOnTextInputTap}>
                  <InputGroup size="sm">
                    <InputGroup.Text id="inputGroup-sizing-sm">
                      請求日付:
                    </InputGroup.Text>
                    <Form.Control
                      disabled={isSaveingTargetDay}
                      value={targetDay ?? ""}
                      type="number"
                      placeholder="未設定"
                      onChange={handleOnChangeTargetDay}
                      maxLength={2}
                      onKeyDown={handleKeyDown}
                      // onBlur={handleTargetDayOnBlur}
                    />
                    <Button
                      disabled={isSaveingTargetDay || !isEditedTargetDay}
                      variant={
                        isEditedTargetDay ? "primary" : "outline-secondary"
                      }
                      id="button-addon2"
                      onClick={handleTargetDayUpdate}
                    >
                      {isSaveingTargetDay ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "登録"
                      )}
                    </Button>
                  </InputGroup>
                </TouchableOpacity>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text style={[styles.work_name]}>{props.item.work}</Text>
              </Col>
            </Row>
            <Row>
              <Col xs={4} sm={3}>
                <Text style={[styles.total]}>{props.item.unit_price}</Text>
              </Col>
              <Col>
                <TouchableOpacity onPress={handleOnTextInputTap}>
                  <InputGroup size="sm">
                    <InputGroup.Text id="inputGroup-sizing-sm">
                      設定数量:
                    </InputGroup.Text>
                    <Form.Control
                      disabled={
                        (targetDay as unknown as boolean | undefined) ||
                        isSaveingQuantity
                      }
                      value={quantity ?? ""}
                      type="number"
                      placeholder="未設定"
                      onChange={handleOnChangeQuantity}
                      maxLength={2}
                      onKeyDown={handleKeyDown}
                    />
                    <Button
                      disabled={isSaveingQuantity || !isEditedQuantity}
                      variant={
                        isEditedQuantity ? "primary" : "outline-secondary"
                      }
                      id="button-addon2"
                      onClick={handleQuantityUpdate}
                    >
                      {isSaveingQuantity ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "登録"
                      )}
                    </Button>
                  </InputGroup>
                </TouchableOpacity>
              </Col>
            </Row>
          </Col>
          <View
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Text style={[styles.allow]}>{">"}</Text>
          </View>
        </View>
      </Container>
    </TouchableOpacity>
  );
});

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  content: {
    flexDirection: "column",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 4,
  },
  subtitle: {
    fontSize: 12,
    color: "#222",
  },

  /**仕事名 */
  work_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#222",
  },
  /**金額 */
  total: {
    fontSize: 14,
    color: "#F00",
  },
  /**矢印 */
  allow: {
    paddingLeft: 3,
  },
  checkboxContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "auto",
  },
});

export default TeibanListItem;
