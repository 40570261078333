import {
  CardStyleInterpolators,
  createStackNavigator,
  HeaderBackButton,
} from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";

import HomeScreen from "../../screens/home/HomeScreen";

import SelectOrganisationScreen from "../../screens/filter/SelectOrganisationScreen";

import HomeFilterScreen from "../../screens/home/HomeFilterScreen";

import { HomeTabParamList } from "../../types";
import SalesTargetScreen from "../../screens/home/SalesTargetScreen";
import { HeaderHome } from "../../components/header/HomeHeader";
import { HeaderLeft } from "../../components/header/LeftHeader";
import { OrgHeader } from "../../components/header/OrderToInvoiceHeader";
import { SCREENS } from "../../constants/Screens";

const HomeTabStack = createStackNavigator<HomeTabParamList>();
export function HomeTabNavigator() {
  const navigation: any = useNavigation();

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("tabPress", () => {
      window.reloadFlag = true;
    });
    return unsubscribe;
  }, [navigation]);

  window.functions.openDrawer = () => navigation.openDrawer();

  return (
    <HomeTabStack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
    >
      <HomeTabStack.Screen
        name={SCREENS.HOME.HOME as keyof HomeTabParamList}
        component={HomeScreen}
        options={{
          headerTitle: "ホーム",
          headerLeft: HeaderLeft,
          headerRight: HeaderHome,
        }}
      />
      <HomeTabStack.Screen
        name={SCREENS.HOME.HOME_FILTER as keyof HomeTabParamList}
        component={HomeFilterScreen}
        options={{
          headerTitle: "対象選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.HOME.HOME);
              }}
            />
          ),
          animationEnabled: true,
        }}
      />
      <HomeTabStack.Screen
        name={SCREENS.HOME.SELECT_HOME_ORGANISATION as keyof HomeTabParamList}
        component={SelectOrganisationScreen}
        options={{
          headerTitle: "組織選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.HOME.HOME);
              }}
            />
          ),
          headerRight: OrgHeader,
          animationEnabled: true,
        }}
      />
      <HomeTabStack.Screen
        name={SCREENS.HOME.SALES_TARGET as keyof HomeTabParamList}
        component={SalesTargetScreen}
        options={{
          headerTitle: "目標設定",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate(SCREENS.HOME.HOME);
              }}
            />
          ),
          animationEnabled: true,
        }}
      />
    </HomeTabStack.Navigator>
  );
}
