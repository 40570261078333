import React, { useState, useEffect } from "react";
import { View, Text, ViewStyle } from "react-native";
import { APP_VERSION, APP_VER_COLOR } from "../../constants/Setting";

interface Props {
  style?: ViewStyle;
}
/**
 * 時計
 * @param props
 * @returns
 */
const CommonClock = (props: Props) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  //秒針
  // const hourDeg = (currentTime.getHours() / 12) * 360;
  // const minuteDeg = (currentTime.getMinutes() / 60) * 360;
  // const secondDeg = (currentTime.getSeconds() / 60) * 360;

  const renderLoginUserLabel = () => {
    if (!window?.userInfo) return;

    return (
      <View style={{ width: "100%" }}>
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 13,
            fontFamily: "Arial",
            fontWeight: "bold",
            color: APP_VER_COLOR(),
            textAlign: "right",
          }}
        >
          【{window?.userInfo?.current_company?.name ?? "Loading..."}】{" "}
          {window?.userInfo?.name ?? " "}
        </Text>
      </View>
    );
  };

  return (
    <View style={[props.style]}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View>
          <Text
            style={{ fontSize: 30, fontFamily: "Arial", fontWeight: "bold" }}
          >
            {currentTime.toLocaleTimeString()}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            flexShrink: 1,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <View style={{ width: "100%" }}>
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={{
                fontSize: 11,
                fontFamily: "Arial",
                fontWeight: "bold",
                color: APP_VER_COLOR(),
                textAlign: "right",
              }}
            >
              {APP_VERSION()}
            </Text>
          </View>
          {renderLoginUserLabel()}
        </View>
      </View>
    </View>
  );
};

export default CommonClock;
