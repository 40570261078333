import * as React from "react";
import { useState, useEffect, Fragment, useContext } from "react";
import { CheckBox, Divider, Text } from "react-native-elements";
import {
  StyleSheet,
  View,
  FlatList,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { useFocusEffect, useNavigation } from "@react-navigation/native";

import formatNumber from "format-number";
import { LOCAL_STORAGE_KEY, LocalStorageKey } from "../../models/LocalStorage";
import {
  Invoice,
  InvoiceModel,
  InvoiceStateType,
} from "../../models/InvoiceModel";
import { currentYM, displayToYearMonth } from "../../util/DateUtil";
import {
  OrgOption,
  allCompanyOption,
  departmentOption,
  receiveOption,
} from "../../components/filter/CommonBumonFilter";
import ReceptListItem from "../../components/receipt/ReceptListItem";
import CommonModal from "../../components/modal/CommonModal";
import { SCREENS } from "../../constants/Screens";
import {
  Col,
  Collapse,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import SelectOrgFilter from "../../components/filter/SelectOrgFilter";
import { FloatingButton } from "../../components/common/FloatingButton";
import { CommonColor } from "../../constants/Colors";
import "../../assets/styles/TableStyles.css";
import YearMonthLabel from "../../components/common/YearMonth";
import CoverIndicator from "../../components/common/CoverIndicator";
import CommonListEmpty from "../../components/common/CommonListEmpty";
import CommonSelectModal, {
  SelectItem,
} from "../../components/modal/CommonSelectModal";
import NotificationToast from "../../components/common/NotificationToast";
import {
  loadSelectedOptionByCompany,
  orgOptionsToParam,
} from "../../util/OrgUtil";
import FreeSelectMonth from "../../components/filter/FreeSelectMonth";
import { APP_VERSION_DATA } from "../../constants/Setting";
import { get } from "../../util/Api";
import { isInPlannedStatus } from "../../util/InvoiceUtil";
import SortChangeButton from "../../components/invoice/SortChangeButton";
import {
  LocalStorageKeys,
  LocalStorageService,
} from "../../services/LocalStorageService.";
import { selectedContext, tabContext } from "../../App";
import { TabList } from "../../navigation/BottomTabNavigator";

const numberFormat = formatNumber({
  round: 0,
});

/**
 * Receipts screen
 * @param props
 * @returns
 */
export default function ReceiptScreen(props: any) {
  const navigation = useNavigation();

  const selectedMyOrganisations = loadSelectedOptionByCompany(
    LocalStorageKey.RECEIPT
  );

  //タブがアクティブになっている。
  const { tabIndex } = useContext(tabContext);
  const isActive = tabIndex === TabList.Receipt.index;

  // 並び替えタイプ（LocalStorage）を取得する
  // 並び替えタイプは請求一覧のテーブル、および、請求総計の並び替えボタンに設定する
  // 並び替えタイプは、LocalStorageに保存される
  // 並び替えタイプは、true（昇順）もしくはfalse（降順）で保存される
  const loadSortType =
    LocalStorageService.get(LocalStorageKeys.SORT.RECEIPT) === null
      ? true
      : LocalStorageService.get(LocalStorageKeys.SORT.RECEIPT) === true;

  //絞り込み条件：年月
  const { month } = React.useContext(selectedContext);

  //受領[請求]レコード
  const [receipts, setReceipts] = useState<{
    [key: string]: Invoice[];
  }>({});
  //データ取得中
  const [isFetching, setIsFetching] = useState(false);

  const [monthPage, setMonthPage] = useState<{ [key: string]: number }>({
    [currentYM()]: 0,
  });

  const [monthlast_page, setMonthLastPage] = useState<{
    [key: string]: number;
  }>({
    [currentYM()]: -1,
  });

  /**フィルタ関連*************************************************************/

  //絞り込み条件：年月(一括変更用)
  const [selectedYMforChangeState, setSelectedYMforChangeState] =
    useState<string>(month);

  const [fromOrgOptions, setFromOrgOptions] = useState<OrgOption[]>(
    selectedMyOrganisations.fromOrgOptions ?? [allCompanyOption]
  );
  const [toOrgOptions, setToOrgOptions] = useState<OrgOption[]>([
    departmentOption,
  ]);

  //絞り込み条件：確認中のみ
  const [isOnlyPending, setIsOnlyPending] = useState<boolean>(true);
  //絞り込み条件：保留
  const [isOnlyHold, setIsOnlyHold] = useState<boolean>(true);

  // ソート(昇順)フラグ
  const [isAsc, setIsAsc] = useState(loadSortType);

  /**画面制御関連 ***************************************************************/
  //ローディングインジケーター(リスト下部)
  const [isLoading, setIsLoading] = useState(true);
  //一括受領 確認モーダルの表示フラグ
  const [isShowReceivedModal, setIsShowReceivedModal] =
    useState<boolean>(false);
  //一括確認 確認モーダルの表示フラグ
  const [isShowPendingModal, setIsShowPendingModal] = useState<boolean>(false);
  //一括操作 モーダルの表示フラグ
  const [isShowAllChangeModal, setIsShowAllChangeModal] =
    useState<boolean>(false);

  //受領でチェックしたアイテム
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});
  //フィルタの開閉フラグ
  const [isCollapsed, setIsCollapsed] = useState(false);
  /**全画面インジケーターの表示フラグ */
  const [isShowCoverIndicator, setIsShowCoverIndicator] =
    useState<boolean>(true);
  /**初回読み込みフラグ */
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  /**合計値 ***************************************************************/
  //確認中合計
  const [total, setTotal] = useState<number>(0);
  //受領合計(確認済み)
  const [total_k, setTotal_k] = useState<number>(0);

  //一件以上 削除チェックが付いている
  const hasChecked = Object.values(checkedItems).includes(true);

  /**一括操作の選択肢 */
  const allChangeSelectItems: SelectItem[] = [
    { title: "【確認中】に変更", id: InvoiceStateType.Pending },
    { title: "【受領】に変更", id: InvoiceStateType.Received },
  ];
  /**確認中のみの請求状態のパラメータ */
  const onlyPending = [InvoiceStateType.Pending, InvoiceStateType.Resubmitted];
  /**保留の請求状態のパラメータ */
  const onlyHold = [InvoiceStateType.Onhold, InvoiceStateType.Rejected];

  // const [isLoadLocalStorage, setIsLoadLocalStorage] = useState<boolean>(false);

  /**フィルタの開閉 */
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  /**
   * 請求状態のパラメータ作成
   * @returns
   */
  const statesFilter = () => {
    let params: number[] = [];
    if (isOnlyPending) {
      params = params.concat(onlyPending);
    }
    if (isOnlyHold) {
      params = params.concat(onlyHold);
    }
    return params;
  };

  /**
   * 受領一覧を取得する
   * @returns
   */
  const loadNext = async (isInit: boolean = false) => {
    try {
      let current_page = isInit ? 0 : monthPage[month];
      console.log("current_page", current_page);
      if (current_page == monthlast_page[month]) return;

      const fromOrgParams = orgOptionsToParam(fromOrgOptions);
      const toOrgParams = orgOptionsToParam(toOrgOptions);

      const res = await InvoiceModel.fetchReceiptList({
        page: current_page + 1,
        billing_ym: month,
        from_filter_types: fromOrgParams.filter_types,
        from_org_ids: fromOrgParams.org_ids,
        to_filter_types: toOrgParams.filter_types,
        to_org_ids: toOrgParams.org_ids,
        states: statesFilter(),
        isAsc: isAsc,
      });

      if (isInit) {
        setReceipts((prevMonthReceipts) => ({
          ...prevMonthReceipts,
          [month]: res.data,
        }));
      } else {
        let currentReceipts = receipts[month] ?? [];
        let tmp = currentReceipts.slice();

        tmp = tmp.concat(res.data);

        setReceipts((prevMonthReceipts) => ({
          ...prevMonthReceipts,
          [month]: tmp,
        }));
      }

      setMonthPage((pre) => ({
        ...pre,
        [month]: res.current_page,
      }));
      setMonthLastPage((pre) => ({
        ...pre,
        [month]: res.last_page,
      }));
      setIsLoading(false);
      return;
    } catch (error) {
      console.error(error);
      // その他のエラーハンドリング
    }
  };

  /**
   * 集計を取得する
   * @param ym
   */
  const getTotal = async (ym: string = month) => {
    setTotal(0);
    setTotal_k(0);
    const fromOrgParams = orgOptionsToParam(fromOrgOptions);
    const toOrgParams = orgOptionsToParam(toOrgOptions);

    const res = await InvoiceModel.getTotalByMonth({
      billing_ym: ym,
      from_filter_types: fromOrgParams.filter_types,
      from_org_ids: fromOrgParams.org_ids,
      to_filter_types: toOrgParams.filter_types,
      to_org_ids: toOrgParams.org_ids,
      states: statesFilter(),
    });

    setTotal(res.total);
    setTotal_k(res.total_k);

    setIsFirstLoad(false);
  };

  //再読み込み処理
  const onReload = async (name?: string) => {
    setIsShowCoverIndicator(true);
    setIsLoading(true);
    setReceipts((prevMonthReceipts) => ({
      ...prevMonthReceipts,
      [month]: [],
    }));
    setMonthPage((pre) => ({
      ...pre,
      [month]: 0,
    }));
    setMonthLastPage((pre) => ({
      ...pre,
      [month]: -1,
    }));
    getTotal();
    await loadNext(true);
    setIsShowCoverIndicator(false);
  };

  /**
   * 全ての請求に☑をつける(受領権限があるもの)
   * @returns
   */
  const handleCheckAllItems = () => {
    const updatedCheckedItems: Record<string, boolean> = {};
    for (const item of receipts[month]) {
      //受領権限があるもの
      if (item.own && !isInPlannedStatus(item)) {
        updatedCheckedItems[item.id] = true;
      }
    }
    setCheckedItems(updatedCheckedItems);
  };
  /**
   *　受領の☑を外す
   * @returns
   */
  const handleUncheckAll = () => {
    setCheckedItems({});
  };
  /**
   * 受領の☑変更
   * @returns
   */
  const handleCheckboxToggle = (itemId: string) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  /**
   * idを指定し一括受領する
   */
  const changeStateInoices = async (state: InvoiceStateType) => {
    closeModal();
    setIsShowCoverIndicator(true);
    setIsLoading(true);

    try {
      const ids = Object.keys(checkedItems)
        .filter((key) => checkedItems[key])
        .map(Number);

      await InvoiceModel.changeStateByIds({
        ids: ids,
        state: state,
      });
      handleUncheckAll();
      onReload("cnageStateInoices");
    } catch (error) {}
  };

  /**
   * 一括で請求状態を変更する(受領権限がある項目のみ)
   */
  const changeStateAllInoices = async (selectedItem: SelectItem) => {
    closeModal();
    setIsShowCoverIndicator(true);
    setIsLoading(true);

    const fromOrgParams = orgOptionsToParam(fromOrgOptions);
    const toOrgParams = orgOptionsToParam(toOrgOptions);

    try {
      await InvoiceModel.changeStateByFilter({
        billing_ym: selectedYMforChangeState,
        from_filter_types: fromOrgParams.filter_types,
        from_org_ids: fromOrgParams.org_ids,
        to_filter_types: toOrgParams.filter_types,
        to_org_ids: toOrgParams.org_ids,
        states: statesFilter(),
        changeState: selectedItem.id,
      });
      handleUncheckAll();
      onReload();
    } catch (error) {
      NotificationToast().error("変更できませんでした。");
      onReload();
    }
  };

  /**
   * リスト選択イベント
   * @param invoice
   */
  const onhandleListSelect = (invoice: Invoice) => {
    //フィルタの情報を含め詳細画面に遷移する。

    const fromOrgParams = orgOptionsToParam(fromOrgOptions);
    const toOrgParams = orgOptionsToParam(toOrgOptions);
    window.navi.navigate(SCREENS.RECEIPT.RECEIPT_DETAIL, {
      id: invoice.id,
      selected_YM: month,
      from_filter_types: fromOrgParams.filter_types,
      from_org_ids: fromOrgParams.org_ids,
      to_filter_types: toOrgParams.filter_types,
      to_org_ids: toOrgParams.org_ids,
      states: statesFilter(),
    });
  };

  /**
   * 詳細画面で更新した請求レコードを更新する
   * @param id
   */
  async function _updateItem(id: number) {
    if (receipts[month]) {
      let items: Invoice[] = receipts[month];
      const res = await InvoiceModel.receiptItem(id);
      const list = items.map((e) => {
        if (e.id == res.id) {
          return res;
        }
        return e;
      });
      setReceipts((pre) => ({ ...pre, [month]: list }));
    }
  }

  /**
   * 詳細画面で削除した請求レコードを除去する
   * @param id
   */
  function _deleteItem(id: number) {
    let items: Invoice[] = receipts[month].slice();
    for (let i = 0; i < items.length; i++) {
      if (items[i].id == id) {
        items.splice(i, 1);
        break;
      }
    }
    window.invoices = items;
    setReceipts((pre) => ({ ...pre, [month]: items }));
  }

  /**
   * 一覧アイテム
   */
  const renderItem = ({ item }: any) => {
    return (
      <Fragment key={item.id}>
        <ReceptListItem
          item={item}
          isChecked={checkedItems[item.id] || false}
          onCheck={handleCheckboxToggle}
          onPress={onhandleListSelect}
        />
      </Fragment>
    );
  };

  /**
   * 確認中モーダルを表示する
   */
  const openPendingModal = () => {
    setIsShowPendingModal(true);
  };
  //受領モーダルを展開する
  const openReceivedModal = () => {
    setIsShowReceivedModal(true);
  };

  /**
   * モーダルを閉じる
   * @param data
   */
  const closeModal = () => {
    setIsShowReceivedModal(false);
    setIsShowPendingModal(false);
    setIsShowAllChangeModal(false);
  };

  /**グローバル関数を設定する */
  const setWindowFunctions = () => {
    window.functions.reloadList = () => {
      if (isShowCoverIndicator) return;
      onReload();
    };

    if (window.reloadFlag) {
      window.reloadFlag = false;
      onReload();
    }
  };

  /**
   * 確認中のみボタン
   */
  const onhandleIsPendingChange = () => {
    //確認中のみにチェックを入れたら、保留にもチェックを入れる
    if (!isOnlyPending) {
      setIsOnlyHold(true);
    }

    setIsOnlyPending(!isOnlyPending);
  };

  /**
   * 保留ボタン
   */
  const onhandleIsHoldChange = () => {
    setIsOnlyHold(!isOnlyHold);
  };

  // アプリのバージョンチェック
  async function app_version_check() {
    const url = "/app_version_check";
    let response = await get(url, { app_version: APP_VERSION_DATA() });
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    if (response.data) {
      alert(
        "バージョンが古い可能性がございます。ブラウザのキャッシュをクリアしてください。"
      );
    }
  }

  /*********************************************
   * useEffect
   * *******************************************
   */
  //リストの描画が出来ない場合があるので明示的に再描画させる。
  useEffect(() => {}, [receipts]);

  //詳細画面から戻ってきた用
  useEffect(() => {
    if (
      props.route.params &&
      props.route.params.hasOwnProperty("update_id") &&
      props.route.params.update_id > 0
    ) {
      _updateItem(props.route.params.update_id);
      navigation.navigate(SCREENS.RECEIPT.RECEIPT, {
        update_id: 0,
      });
      getTotal();
    }
    if (
      props.route.params &&
      props.route.params.hasOwnProperty("delete_id") &&
      props.route.params.delete_id > 0
    ) {
      _deleteItem(props.route.params.delete_id);
      navigation.navigate(SCREENS.RECEIPT.RECEIPT, {
        delete_id: 0,
      });
      getTotal();
    }
  }, [props.route.params]);

  //請求元・請求先変更時
  useEffect(() => {
    onReload();
  }, [fromOrgOptions, toOrgOptions]);

  useEffect(() => {
    onReload();
  }, [isOnlyPending, isOnlyHold, isAsc]);

  useEffect(() => {
    if (!isActive) return;
    if (isFirstLoad) return;
    //年月変更ボタン
    window.functions.setMonthFilter = async (ym: string) => {
      handleUncheckAll();
    };
    onReload("month");
  }, [month, isActive]);

  // 抽出条件が変更されたらグローバル関数を更新する。
  useFocusEffect(() => {
    setWindowFunctions();
  });

  // 画面切り替え時にバージョンが違っていたらアラート表示する。
  useEffect(() => {
    app_version_check();
  }, [props.route.name]);

  return (
    <View style={styles.container}>
      <YearMonthLabel ym={month} />
      <Collapse in={!isCollapsed} className="w-100 px-3">
        <div>
          <div className="w-100">
            <SelectOrgFilter
              localStorageKey={LocalStorageKey.RECEIPT}
              defaultFrom={fromOrgOptions}
              onChangeFrom={setFromOrgOptions}
              defaultTo={toOrgOptions}
              onChangeTo={setToOrgOptions}
              receiptMode={true}
            />
          </div>

          <Container>
            <Row>
              <Col xs={12} sm={6}>
                <div className="d-flex justify-content-center">
                  <InputGroup size="sm">
                    <InputGroup.Text id="basic-addon2">受領</InputGroup.Text>
                    <Form.Control
                      style={{ textAlign: "right" }}
                      value={numberFormat(total)}
                      disabled={true}
                    />
                    <InputGroup.Text>円</InputGroup.Text>
                  </InputGroup>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <div className="d-flex justify-content-center">
                  <InputGroup size="sm">
                    <InputGroup.Text id="basic-addon2">確認中</InputGroup.Text>
                    <Form.Control
                      style={{ textAlign: "right" }}
                      value={numberFormat(total_k)}
                      disabled={true}
                    />
                    <InputGroup.Text>円</InputGroup.Text>
                  </InputGroup>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Collapse>

      <Row style={{ width: "100%" }}>
        <Col xs={9} sm={10}></Col> {/* 空のColコンポーネントを追加 */}
        <Col
          xs={3}
          sm={2}
          className="d-flex align-items-center justify-content-end p-2"
        >
          <SortChangeButton
            isAsc={isAsc}
            onPress={setIsAsc}
            localStorageKey={LocalStorageKeys.SORT.RECEIPT}
          />
          <TouchableOpacity
            style={{
              marginRight: 10,
              paddingHorizontal: 8,
              paddingVertical: 2,
              backgroundColor: CommonColor.Normal.Grey,
              borderRadius: 20,
            }}
            onPress={handleToggleCollapse}
          >
            <Text>{isCollapsed ? "展開" : "折りたたむ"}</Text>
          </TouchableOpacity>
          {/* <TouchableOpacity
            style={{
              marginRight: 10,
              paddingHorizontal: 8,
              paddingVertical: 2,
              backgroundColor: CommonColor.Normal.Blue,
              borderRadius: 20,
            }}
            onPress={() => {
              setSelectedYMforChangeState(month);
              setIsShowAllChangeModal(true);
            }}
          >
            <Text style={{ color: "#FFF" }}>一括操作</Text>
          </TouchableOpacity> */}
          <TouchableOpacity
            style={{
              marginRight: 10,
              paddingHorizontal: 8,
              paddingVertical: 2,
              backgroundColor: CommonColor.Normal.Grey,
              borderRadius: 20,
            }}
            onPress={handleCheckAllItems}
          >
            <Text>全て選択</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              marginRight: 0,
              paddingHorizontal: 8,
              paddingVertical: 2,
              backgroundColor: CommonColor.Normal.Grey,
              borderRadius: 20,
            }}
            onPress={handleUncheckAll}
          >
            <Text>選択解除</Text>
          </TouchableOpacity>
        </Col>
      </Row>

      {/* Collapseトグルボタン */}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <CheckBox
          containerStyle={{ paddingHorizontal: 10, paddingVertical: 1 }}
          checked={isOnlyPending}
          onPress={onhandleIsPendingChange}
          title="確認中のみ"
        />
        <CheckBox
          containerStyle={{ paddingHorizontal: 10, paddingVertical: 1 }}
          checked={isOnlyHold}
          onPress={onhandleIsHoldChange}
          title="保留"
        />
      </View>
      <Divider />
      <FlatList
        style={{ width: "100%" }}
        keyExtractor={keyExtractor}
        data={receipts[month] ?? []}
        renderItem={renderItem}
        onRefresh={() => {
          setIsFetching(true);
        }}
        refreshing={isFetching}
        onEndReached={() => {
          loadNext();
        }}
        onEndReachedThreshold={0.2}
        ListEmptyComponent={
          <CommonListEmpty isLoading={isShowCoverIndicator} />
        }
        ListFooterComponent={
          !isShowCoverIndicator &&
          monthPage[month] !== monthlast_page[month] ? (
            <ActivityIndicator style={{ marginVertical: 10 }} />
          ) : (
            <View style={{ height: 100 }} />
          )
        }
      />

      {hasChecked && (
        <>
          <FloatingButton
            text="受領"
            style={{
              right: 30,
              bottom: 80,
              backgroundColor: CommonColor.Normal.Green,
            }}
            onPress={openReceivedModal}
          />

          <FloatingButton
            text="確認中"
            style={{
              right: 30,

              backgroundColor: CommonColor.Normal.Blue,
            }}
            onPress={openPendingModal}
          />
        </>
      )}

      <CommonSelectModal
        isShow={isShowAllChangeModal}
        body={
          `【${displayToYearMonth(selectedYMforChangeState)}】${
            isOnlyPending ? "(及び、それ以前のすべての【確認中】 ) " : ""
          }の請求を、現在の絞り込み条件で請求状態を一括変更します。 ` +
          "\n" +
          "※受領権限が無いものは変更できません。"
        }
        element={
          <FreeSelectMonth
            onChange={(v) => {
              setSelectedYMforChangeState(v);
            }}
          ></FreeSelectMonth>
        }
        actionTitle="実行"
        onPress={(e) => {
          changeStateAllInoices(e);
        }}
        onDismiss={closeModal}
        select={allChangeSelectItems}
      />
      <CommonModal
        isShow={isShowReceivedModal}
        body="選択済みの項目を全て[受領]にしますか?"
        actionTitle="受領する"
        onPress={() => {
          changeStateInoices(InvoiceStateType.Received);
        }}
        onDismiss={closeModal}
      />
      <CommonModal
        isShow={isShowPendingModal}
        body="選択済みの項目を全て[確認中]にしますか?"
        actionTitle="確認中に戻す"
        onPress={() => {
          changeStateInoices(InvoiceStateType.Pending);
        }}
        onDismiss={closeModal}
      />
      {isShowCoverIndicator && <CoverIndicator />}
    </View>
  );
}

function keyExtractor(item: any, index: any) {
  return item.id.toString();
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
});
