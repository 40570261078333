import { OrgFilterType } from "../components/filter/CommonBumonFilter";
import { post } from "../util/Api";
import { Assignment } from "./AssignmentModel";
import { UsersGoal } from "./UsersGoalsModel";

/**
 * 部門
 */
export interface Organisations {
  id: number;
  company_id: number;
  parent_id: number;
  name: string;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
  goal: number;
  kojin: number;
  rank: number;
  parent_org: Organisations | null;
  tmp_rank: number | null;
  assignment?: Assignment;
  usersGoals?: UsersGoal;
  billing_source: number;
}

export class OrganisationsModel {
  static set(data: any): Organisations {
    const d: Organisations = {
      id: data.id,
      company_id: data.company_id,
      parent_id: data.parent_id,
      name: data.name,
      created_at: data.created_at,
      updated_at: data.updated_at,
      deleted_at: data.deleted_at,
      goal: data.goal,
      kojin: data.kojin,
      rank: data.rank,
      parent_org: data?.parent_org ?? null,
      tmp_rank: data?.tmp_rank ?? null,
      assignment: data?.assignment,
      usersGoals: data?.usersGoals,
      billing_source: data.billing_source,
    };

    return d;
  }

  /**
   * 会社の部門を全て取得する。
   * @returns
   */
  static async fetchAllByCompany(params?: {
    orgFilterType: number;
  }): Promise<Organisations[]> {
    const uri = "/organisation/allOrgByCompany";
    let response = await post(uri, params);

    if (response.status !== 200) {
      throw new Error();
    }

    if (response.data === null || response.data === undefined) {
      return [];
    }

    return Array.isArray(response.data)
      ? response.data.map((data) => this.set(data))
      : [this.set(response)];
  }

  /**
   * 会社の部門を全て取得する。
   * @returns
   */
  static async fetchToForDropDown(): Promise<Organisations[]> {
    const uri = "/organisation/toOrgForDropDown";
    let response = await post(uri, {});

    if (response.status !== 200) {
      throw new Error();
    }

    if (response.data === null || response.data === undefined) {
      return [];
    }

    return Array.isArray(response.data)
      ? response.data.map((data) => this.set(data))
      : [this.set(response)];
  }

  /**
   * 非表示リストを全て取得する。
   * @returns
   */
  static async fetchFromForDropDown(): Promise<Organisations[]> {
    const uri = "/organisation/fromOrgForDropDown";
    let response = await post(uri, {});

    if (response.status !== 200) {
      throw new Error();
    }

    if (response.data === null || response.data === undefined) {
      return [];
    }

    return Array.isArray(response.data)
      ? response.data.map((data) => this.set(data))
      : [this.set(response)];
  }


  /**
   * 非表示リストを全て取得する。
   * @returns
   */
  static async fetchFromForDropDownHidden(): Promise<Organisations[]> {
    const uri = "/organisation/fromOrgForDropDownHidden";
    let response = await post(uri, {});

    if (response.status !== 200) {
      throw new Error();
    }

    if (response.data === null || response.data === undefined) {
      return [];
    }

    return Array.isArray(response.data)
      ? response.data.map((data) => this.set(data))
      : [this.set(response)];
  }

  /**
   * 月間の請求合計を取得する
   * @param params
   * @returns
   */
  static async getTotalByMonth(params: {
    billing_ym: string;
  }): Promise<{ total: number; total_k: number } | null> {
    const uri = "/invoice/totalByYearMonth";
    let response = await post(uri, { billing_ym: params.billing_ym });

    if (response.status !== 200) {
      throw new Error();
    }

    const data = response.data;

    if (data === null || data === undefined) {
      return null;
    }

    return { total: data.total, total_k: data.total_k };
  }


  /**
   * Fetches the organization history based on the given parameters.
   *
   * @param {Object} params - The parameters for fetching the organization history.
   * @param {number} params.from_org_id - The ID of the starting organization.
   * @return {Promise<Organisations[]>} A promise that resolves to an array of Organisations objects representing the organization history.
   * @throws {Error} If the response status is not 200.
   */
  static async fetchToOrgHistory(params:{fromOrgId:number
  }): Promise<Organisations[]>{


    const uri = "/organisation/toOrgHistory";
    let response = await post(uri, { fromOrgId: params.fromOrgId });


    if (response.status !== 200) {
      throw new Error();
    }

    if (response.data === null || response.data === undefined) {
      return [];
    }

    return Array.isArray(response.data)
      ? response.data.map((data) => this.set(data))
      : [this.set(response)];
  }
}
