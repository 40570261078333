import React, { Ref, SyntheticEvent, useEffect } from "react";
import { Spinner, Table } from "react-bootstrap";
import { Invoice, isReceived } from "../../models/InvoiceModel";
import "../../assets/styles/TableStyles.css";
import { ActivityIndicator, View, Text, TouchableOpacity } from "react-native";
import { formatLocalNumber } from "../../util/Math";
import { priceStyle } from "../../util/DisplayUtil";
import { dateToMD } from "../../util/DateUtil";
import { CommonColor } from "../../constants/Colors";

import { MaterialCommunityIcons } from "@expo/vector-icons";

const Columns = ["日付", "小分類", "仕事", "請求額", "請求先", "中分類"];

type WorkListTableProps = {
  items: Invoice[];
  isLoading: boolean;
  tabelRef: Ref<HTMLTableElement>;
  isExpand: boolean;
  onChangeExpand: () => void;
};

const WorkListTable = (props: WorkListTableProps) => {
  const vh = document.body.clientHeight;

  /**展開ボタン */
  const renderExpandButton = () => {
    return (
      <TouchableOpacity
        style={{
          backgroundColor: "#FFF",
          borderRadius: 100,
          width: 20,
          height: 20,
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
        onPress={props.onChangeExpand}
      >
        <MaterialCommunityIcons
          name={props.isExpand ? "minus" : "plus"}
          size={20}
          color="black"
        />
      </TouchableOpacity>
    );
  };

  const renderHeader = (columnTitle: string, index: number) => {
    if (index >= 4 && !props.isExpand) {
      //中分類と請求先は非表示にする。
      return (
        <th
          style={{ display: "none" }}
          className="_sticky bg-secondary work-list-table-item"
          key={columnTitle}
        ></th>
      );
    }

    return (
      <th
        className="_sticky bg-secondary work-list-table-item"
        key={columnTitle}
      >
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text style={{ color: "#FFF" }}>{columnTitle}</Text>
          {!props.isExpand && index == 3 && renderExpandButton()}
          {props.isExpand && index == 5 && renderExpandButton()}
        </View>
      </th>
    );
  };

  const renderItem = (item: Invoice, total: number) => {
    const closeStyle: React.CSSProperties = props.isExpand
      ? {}
      : { display: "none" };

    const bgColor = isReceived(item.state) ? "inherit" : "#5AE";
    return (
      <tr
        className={"work-list-table"}
        style={{ backgroundColor: bgColor }}
        key={item.id.toString()}
      >
        <td className="work-list-table-item" style={{ textAlign: "right" }}>
          {dateToMD(item.billing_date)}
        </td>
        <td className="work-list-table-item">
          {item?.work_type_small?.name ?? "未登録"}
        </td>
        <td className="work-list-table-item">{item.work}</td>
        <td className="work-list-table-item" style={priceStyle(item.total)}>
          {formatLocalNumber(item.total, {
            isThousands: true,
            isShowUnit: true,
          })}
        </td>
        <td style={closeStyle} className="work-list-table-item">
          {item?.to_org_obj?.name ?? "未登録"}
        </td>
        <td style={closeStyle} className="work-list-table-item">
          {item?.work_type?.name ?? "未登録"}
        </td>
        {/* <td style={priceStyle(item.unit_price)}>
          {formatLocalNumber(item.unit_price)}
        </td> */}
        {/* <td style={{ textAlign: "right" }}>
          {formatLocalNumber(item.quantity)}
        </td> */}
        {/* <td style={priceStyle(total)}>
          {formatLocalNumber(total, { isThousands: true, isShowUnit: true })}
        </td> */}
      </tr>
    );
  };

  let cumulativeTotal = 0;
  return (
    <>
      <Table striped bordered hover ref={props.tabelRef}>
        <thead>
          <tr>{Columns.map((e, i) => renderHeader(e, i))}</tr>
        </thead>
        <tbody>
          {props.items.map((item) => {
            //金額の累積を求める。
            const total = item.total;
            cumulativeTotal += total;

            return renderItem(item, cumulativeTotal);
          })}
        </tbody>
      </Table>
      {props.isLoading ? (
        <ActivityIndicator style={{ marginVertical: 10 }} />
      ) : (
        <div style={{ height: 70 }}></div>
      )}
    </>
  );
};

export default WorkListTable;
