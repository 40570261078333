import React from "react";
import { User } from "../models/UserModel";
import ReceptListItem from "../components/receipt/ReceptListItem";
// import { User } from "../models/UserModel";

/**LocalStorage構造のバージョン */
const version = "@1.0.0";

/** ユーザー情報を格納するキー */
export const LocalStorageKeys = {
  USER: "user",
  /** APIキーを格納するキー */
  API_KEY: "api_key",
  /**
   * 並び替えを格納するキー
   */
  SORT: {
    /** 請求書の並び替えを格納するキー */
    INVOICE: "sort_invoice",
    /** 受領の並び替えを格納するキー */
    RECEIPT: "sort_receipt",
  },
  FILTER: {
    /** 請求書の並び保留のみ */
    INVOICE_ONLY_PENDING: "isPending_invoice",
  },
};

export class LocalStorageService {
  /**
   * キーにバージョンと現在の会社IDを追加して返す
   *
   * @param {string} key - キー
   * @return {string} - キーにバージョンと現在の会社IDを追加した文字列
   */
  private static getKeyWithVersion(key: string): string {
    try {
      // ユーザー情報を取得する
      const userObj = LocalStorageService.getUser();
      // 現在の会社IDを取得する
      const current_company_id = userObj ? userObj.current_company_id : null;
      // キーにバージョンと現在の会社IDを追加して返す
      return key + version + "_" + current_company_id;
    } catch (error) {
      // エラーが発生した場合はキーにバージョンのみを追加して返す
      return key + version;
    }
  }

  /**
   * ローカルストレージからキーを指定して値を取得する
   *
   * @template T
   * @param {string} key - 取得するキー
   * @return {T | null} 取得した値
   */
  static get<T>(key: string): T | null {
    // Format the key with version
    const keyformat = LocalStorageService.getKeyWithVersion(key);

    // Get the value from the local storage
    const value = localStorage.getItem(keyformat);
    if (value === null) {
      return null;
    }

    // Try to parse the value as JSON and return
    try {
      return JSON.parse(value) as T;
    } catch (error) {
      // If parsing fails, return the value as is
      return value as unknown as T;
    }
  }

  /**
   * ローカルストレージにキーと値を指定して保存する
   *
   * @template T
   * @param {string} key - 保存するキー
   * @param {T} value - 保存する値
   * @return {void} なし
   */
  static set<T>(key: string, value: T): void {
    const keyformat = LocalStorageService.getKeyWithVersion(key);

    localStorage.setItem(keyformat, JSON.stringify(value));
  }

  /**ユーザー情報を読み込む */
  static getUser(): User | null {
    const userData = localStorage.getItem(LocalStorageKeys.USER + version);
    if (userData) {
      return JSON.parse(userData);
    } else {
      return null;
    }
  }

  /**ユーザー情報を保持する */
  static setUser(user: User): void {
    localStorage.setItem(LocalStorageKeys.USER + version, JSON.stringify(user));
  }
}
