import * as React from "react";
import { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";

import { Button } from "react-native-elements";
import Select from "react-select";

const moment = require("moment");
import formatNumber from "format-number";

const sprintf = require("sprintf-js").sprintf;

const numberFormat = formatNumber({
  round: 0,
});

export default function HomeFilterScreen(props: any) {
  const { navigation, functions } = props;

  let y = moment().format("YYYY");
  let m = moment().format("MM");

  const [billing_y_obj, setBillingYobj] = useState({ label: y, value: y });
  const [billing_m_obj, setBillingMobj] = useState({ label: m, value: m });
  const [to_org, setToOrg] = useState(0);
  const [from_org, setFromOrg] = useState(0);
  const [to_org_name, setToOrgName] = useState("未選択");
  const [from_org_name, setFromOrgName] = useState("未選択");

  const [isLoading, setIsLoading] = useState(false);

  let years = [];
  y = parseInt(y);
  y += 10;
  for (let i = 0; i <= 20; i++) {
    years.push({ label: y, value: y });
    y--;
  }
  let months = [];
  for (let i = 1; i <= 12; i++) {
    months.push({ label: sprintf("%02d", i), value: sprintf("%02d", i) });
  }

  async function loadFilter() {
    let filter: any = await window.storage
      .load({ key: props.route.params.target + "Filter" })
      .catch((err: any) => null);
    if (filter) {
      // setBillingYobj(filter.billing_y_obj ?? {});
      // setBillingMobj(filter.billing_m_obj);
      // setToOrg(filter.to_org);
      // setFromOrg(filter.from_org);
      // setToOrgName(filter.to_org_name);
      // setFromOrgName(filter.from_org_name);
    }
  }

  useEffect(() => {
    loadFilter();
  }, []);

  return (
    <View style={styles.container}>
      <ScrollView
        style={{
          width: "100%",
          padding: 10,
        }}
      >
        <View
          style={{
            width: "100%",
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <View style={styles.itemViewStyle}>
            <Text style={{}}>対象年月</Text>
            <View style={{ flexDirection: "row" }}>
              <View style={{ width: "50%" }}>
                <Select
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  options={years}
                  onChange={(val: any) => {
                    setBillingYobj(val);
                  }}
                  value={billing_y_obj}
                />
              </View>
              <View style={{ width: "50%" }}>
                <Select
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  options={months}
                  onChange={(val: any) => {
                    setBillingMobj(val);
                  }}
                  value={billing_m_obj}
                />
              </View>
            </View>
          </View>
          <View style={styles.itemViewStyle}>
            <Text style={{}}>請求元（請求元-受取側）</Text>
            <TouchableOpacity
              onPress={() => {
                window.navi.navigate(
                  "Select" + props.route.params.target + "OrganisationScreen",
                  {
                    id: from_org,
                    setOrg: setFromOrg,
                    setOrgName: setFromOrgName,
                    backScreen: props.route.params.target + "FilterScreen",
                    type: -1,
                    mode: "from_org",
                  }
                );
              }}
            >
              <Text style={styles.itemStyleEdit}>
                {from_org_name}
                <View style={{ position: "absolute", right: 0 }}>
                  <Text style={{ fontWeight: "normal" }}>＞</Text>
                </View>
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.itemViewStyle}>
            <Text style={{}}>依頼主（請求先-支払側）</Text>
            <TouchableOpacity
              onPress={() => {
                window.navi.navigate(
                  "Select" + props.route.params.target + "OrganisationScreen",
                  {
                    id: to_org,
                    setOrg: setToOrg,
                    setOrgName: setToOrgName,
                    backScreen: props.route.params.target + "FilterScreen",
                    type: -1,
                    mode: "to_org",
                  }
                );
              }}
            >
              <Text style={styles.itemStyleEdit}>
                {to_org_name}
                <View style={{ position: "absolute", right: 0 }}>
                  <Text style={{ fontWeight: "normal" }}>＞</Text>
                </View>
              </Text>
            </TouchableOpacity>
          </View>

          <Button
            title="選択"
            titleStyle={{ fontSize: 17 }}
            style={{ marginTop: 20 }}
            onPress={() => {
              // let data: HomeFilterStorage = {
              //   billing_y_obj,
              //   billing_m_obj,
              //   billing_ym: "00",
              //   to_org,
              //   from_org,
              //   to_org_name,
              //   from_org_name,
              // };
              // window.storage.save({
              //   key: props.route.params.target + "Filter",
              //   data,
              // });
              // navigation.navigate(props.route.params.backScreen);
            }}
          />
          <Button
            title="リセット"
            type="outline"
            titleStyle={{ fontSize: 15 }}
            buttonStyle={{ height: 30 }}
            style={{ marginTop: 10 }}
            onPress={() => {
              window.storage.remove({
                key: props.route.params.target + "Filter",
              });
              navigation.navigate(props.route.params.backScreen);
            }}
          />
        </View>
      </ScrollView>

      {isLoading && (
        <View
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            flex: 1,
            backgroundColor: "#fff",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size="large" />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
  },
  itemViewStyle: {
    marginBottom: 12,
  },
  itemStyle: {
    fontWeight: "bold",
    fontSize: 17,
  },
  itemStyleEdit: {
    fontWeight: "bold",
    color: "#333",
    fontSize: 17,
  },
});
