import { SortType } from "../components/filter/SortTypeFilter";
import { post } from "../util/Api";
import { Organisations } from "./OrganisationsModel";

/**
 * 仕事モデル
 */
export interface WorkDB {
  workTypeLargeName: string;
  workTypeMiddleName: string;
  workTypeSmallName: string;
  work: string;
  unit_price: number;
  quantity: number;
  total: number;
}

export interface WorkMid {
  name: string;
  id: number;
  total: number;
  small_list: WorkSmall[];
}

export interface WorkSmall {
  name: string;
  id: number;
  total: number;
  detail_list: WorkDB[];
}

export interface WorkDetail {
  billing_date: string;
  user_name: string;
  fromOrg?: Organisations;
  toOrg?: Organisations;
}

export class WorkDBModel {
  static set(data: any): WorkDB {
    const workDB: WorkDB = {
      workTypeLargeName: data.workTypeLargeName,
      workTypeMiddleName: data.workTypeMiddleName,
      workTypeSmallName: data.workTypeSmallName,
      work: data.work,
      unit_price: data.unit_price,
      quantity: data.quantity,
      total: data.total,
    };
    return workDB;
  }

  static async large(params: {
    to_filter_types: number[];
    to_org_ids: number[];
    from_filter_types: number[];
    from_org_ids: number[];
    isOnlyMyInvoice: number;
  }): Promise<{ name: string; total: number }[]> {
    const uri = "/workdb/large";
    const response = await post(uri, params);
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    return response.data;
  }
  /**
   * middleメソッドは、指定されたパラメータを使用して、中分類仕事・小分類仕事・仕事データを取得します。
   *
   * @param {Object} params - 次のパラメータを含むオブジェクト:
   *   - {number[]} to_filter_types: フィルターの種類の配列
   *   - {number[]} to_org_ids: 組織IDの配列
   *   - {number[]} from_filter_types: フィルターの種類の配列
   *   - {number[]} from_org_ids: 組織IDの配列
   *   - {string} large_name: 大きな名前
   *   - {number} isOnlyMyInvoice: 自分の請求書のみのフラグ
   */
  static async middle(params: {
    to_filter_types: number[];
    to_org_ids: number[];
    from_filter_types: number[];
    from_org_ids: number[];
    large_name: string;
    isOnlyMyInvoice: number;
  }): Promise<WorkMid[]> {
    const uri = "/workdb/middle";
    const response = await post(uri, params);
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    return response.data;
  }

  static async detail(
    params: {
      to_filter_types: number[];
      to_org_ids: number[];
      from_filter_types: number[];
      from_org_ids: number[];
      isOnlyMyInvoice: number;
    },
    workDB: WorkDB
  ): Promise<WorkDetail[]> {
    const uri = "/workdb/detail";
    params = {
      ...params,
      ...{
        large_name: workDB.workTypeLargeName,
        mid_name: workDB.workTypeMiddleName,
        small_name: workDB.workTypeSmallName,
        work: workDB.work,
        unit_price: workDB.unit_price,
        quantity: workDB.quantity,
        total: workDB.total,
      },
    };
    const response = await post(uri, params);
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    return response.data;
  }
}
