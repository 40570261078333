import { StyleSheet, TextStyle, ViewStyle } from "react-native";

interface Styles {
  container: ViewStyle;
  backgroundColor: ViewStyle;
  stateContainer: ViewStyle;
  stateBadge: ViewStyle;
  checkBoxContainer: ViewStyle;
  stateText: TextStyle;
  commentText: TextStyle;
  contentContainer: ViewStyle;
  dateRow: ViewStyle;
  dateText: TextStyle;
  orgNameContainer: ViewStyle;
  orgNameText: TextStyle;
  workTypeContainer: ViewStyle;
  workTypeText: TextStyle;
  workRow: ViewStyle;
  teibanBadge: ViewStyle;
  teibanText: TextStyle;
  workText: TextStyle;
  amountRow: ViewStyle;
  amountText: TextStyle;
  fromOrgContainer: ViewStyle;
  fromOrgText: TextStyle;
  rightContainer: ViewStyle;
  arrowText: TextStyle;
}

export class InvoiceItemStyles {
  public static styles: Styles = StyleSheet.create({
    container: {
      width: "100%",
      height: 65,
      borderBottomColor: "#ccc",
      borderBottomWidth: 1,
      flexDirection: "row",
    },

    backgroundColor: {
      backgroundColor: "#e1fae1",
    },
    stateContainer: {
      justifyContent: "center",
      alignItems: "center",
      width: 60,
    },
    stateBadge: {
      backgroundColor: "#ccc",
      padding: 2,
      width: 50,
    },
    checkBoxContainer: {
      justifyContent: "center",
      alignItems: "center",
      width: 40,
    },
    stateText: {
      fontSize: 12,
      textAlign: "center",
    },
    commentText: {
      color: "#c00",
      fontSize: 9,
      paddingTop: 3,
    },
    contentContainer: {
      flexDirection: "column",
      justifyContent: "center",
      flexShrink: 1,
    },
    dateRow: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 0,
      flexWrap: "nowrap",
    },
    dateText: {
      fontSize: 12,
      marginRight: 10,
    },
    orgNameContainer: {
      maxWidth: "50%",
      minWidth: 70,
      marginRight: 10,
    },
    orgNameText: {
      fontSize: 12,
    },
    workTypeContainer: {
      flexShrink: 1,
    },
    workTypeText: {
      fontSize: 14,
      color: "#f00",
      fontWeight: "bold",
    },
    workRow: {
      flexDirection: "row",
      alignItems: "center",
    },
    teibanBadge: {
      backgroundColor: "#60B077",
      padding: 1,
      width: 40,
      marginRight: 5,
    },
    teibanText: {
      fontSize: 11,
      textAlign: "center",
      color: "#fff",
    },
    workText: {
      fontSize: 14,
      fontWeight: "bold",
    },
    amountRow: {
      flexDirection: "row",
    },
    amountText: {
      fontSize: 14, // Font size
      fontWeight: "bold", // Font weight
      paddingBottom: 2, // Padding bottom
    },
    fromOrgContainer: {
      width: 120,
    },
    fromOrgText: {
      fontSize: 12,
      paddingBottom: 2,
      color: "#333",
    },
    rightContainer: {
      justifyContent: "flex-end",
      alignItems: "center",
      paddingRight: 10,
      flexDirection: "row",
      marginLeft: "auto",
    },
    arrowText: {
      paddingLeft: 3,
    },
  });
}
