import React from "react";
import {
  DropdownButton,
  Dropdown,
  InputGroup,
  Form,
  Spinner,
  Button,
} from "react-bootstrap";
import { WorkType } from "../../models/WorkTypeModel";
import CommonErrorLabel from "./CommonErrorLabel";
import { Organisations } from "../../models/OrganisationsModel";

type ButtonWithDropdownProps = {
  dropdownTitle?: string;
  dropdownItems?: WorkType[];
  dropdownTitleDB?: string;
  dropdownItemsDB?: WorkType[] | Organisations[];
  value?: string;
  style?: React.CSSProperties;
  onClickDB?: () => void;
  onSelectDB?: (value: WorkType) => void;
  isLoadingDB?: boolean;
  onClick?: () => void;
  onSelect?: (value: WorkType | Organisations) => void;
  isLoading?: boolean;
  errorMsg?: string;
};

const ButtonWithDropdown: React.FC<ButtonWithDropdownProps> = (props) => {
  const handleDropdownItemClick = (item: WorkType) => {
    if (props.onSelect) {
      props.onSelect(item);
    }
  };
  //エラー
  const isError = () => {
    if (props.errorMsg && props.errorMsg.length > 0) return true;
    return false;
  };

  //履歴用ドロップダウン
  const renderDropdownItems = () => {
    if (props.dropdownItems && props.dropdownItems.length > 0) {
      return props.dropdownItems.map((item, index) => (
        <Dropdown.Item
          key={index}
          onClick={() => handleDropdownItemClick(item)}
        >
          {item.name}
        </Dropdown.Item>
      ));
    } else {
      return <Dropdown.Item disabled>無し</Dropdown.Item>;
    }
  };

  //DB用ドロップダウン
  const renderDropdownItemsDB = () => {
    if (props.dropdownItemsDB && props.dropdownItemsDB.length > 0) {
      return props.dropdownItemsDB.map((item, index) => (
        <Dropdown.Item
          key={index}
          onClick={() => handleDropdownItemClick(item)}
        >
          {item.name}
        </Dropdown.Item>
      ));
    } else {
      return <Dropdown.Item disabled>無し</Dropdown.Item>;
    }
  };

  /**
   * プレースホルダーの表示
   * @returns
   */
  const placeholderLabel = () => {
    if (props.value) {
      return props.value;
    }

    return "未選択";
  };

  return (
    <div className="mb-1">
      <InputGroup style={{ width: "100%" }}>
        <Form.Control
          placeholder="未選択"
          type="button"
          style={{ textAlign: "left", ...props.style }}
          value={placeholderLabel()}
          aria-label="Text input with dropdown button"
          onClick={props.onClick}
        />
        <DropdownButton
          className="dropdown-filter"
          variant="outline-secondary"
          title={"履歴"}
          id="input-group-dropdown-2"
          align="end"
        >
          {props.isLoading ? (
            <Dropdown.Item disabled>
              <Spinner animation="border" size="sm" />
            </Dropdown.Item>
          ) : (
            renderDropdownItems()
          )}
        </DropdownButton>

        {props.onClickDB && (
          <DropdownButton
            className="dropdown-filter"
            variant="outline-secondary"
            title={"DB"}
            id="input-group-dropdown-2"
            align="end"
          >
            {props.isLoadingDB ? (
              <Dropdown.Item disabled>
                <Spinner animation="border" size="sm" />
              </Dropdown.Item>
            ) : (
              renderDropdownItemsDB()
            )}
          </DropdownButton>
        )}
      </InputGroup>

      {isError() && <CommonErrorLabel title={props.errorMsg} />}
    </div>
  );
};

export default ButtonWithDropdown;
