import { relativeTimeRounding } from "moment";
import React, { Fragment, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  StyleSheet,
  View,
  ActivityIndicator,
  TouchableOpacity,
  FlatList,
  Alert,
} from "react-native";
import { CheckBox, Divider, Text } from "react-native-elements";

export interface SelectItem {
  title: string;
  id: number;
}

type CommonSelectModalProps = {
  isShow: boolean;
  title?: string;
  body?: string;
  element?: JSX.Element;
  actionTitle?: string;
  onPress: (e: SelectItem) => void;
  onDismiss?: () => void;
  select: SelectItem[];
};

export function CommonSelectModal(props: CommonSelectModalProps) {
  const { isShow, title, body, actionTitle, onPress, onDismiss } = props;

  const [selectedItem, setSelectedItem] = useState<SelectItem>();

  const disableBtn = selectedItem === undefined;

  const handleClose = () => {
    if (onDismiss) {
      onDismiss();
    }
  };

  const renderCheckBox = (item: SelectItem) => {
    const checked = selectedItem?.id == item.id;
    return (
      <Fragment key={item.id}>
        <CheckBox
          checked={checked}
          onPress={() => setSelectedItem(item)}
          title={item.title}
        />
      </Fragment>
    );
  };

  return (
    <Modal show={isShow} onHide={handleClose} backdrop={true}>
      {title && (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body>
        <p>{body}</p>
        {props.element && <>{props.element}</>}
        <View style={{ flexDirection: "column" }}>
          {props.select.map((e) => renderCheckBox(e))}
        </View>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          キャンセル
        </Button>

        <Button
          disabled={disableBtn}
          variant="primary"
          onClick={() => {
            if (selectedItem === undefined) return;
            onPress(selectedItem);
          }}
        >
          {actionTitle ?? "実行"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CommonSelectModal;
