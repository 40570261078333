import * as React from "react";
import { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  FlatList,
  TouchableOpacity,
  Text,
  ColorValue,
} from "react-native";
// import { Button, Text } from "react-native-elements";
import Select from "react-select";
import { FontAwesome } from "@expo/vector-icons";

import { useNavigation } from "@react-navigation/native";
import { Button, Container, Modal } from "react-bootstrap";
import { WorkType, WorkTypeModel } from "../../models/WorkTypeModel";
import CoverIndicator from "../../components/common/CoverIndicator";
import EditWorkTypeSmallItem from "../../components/invoice/EditWorkTypeSmallItem";
import { CommonColor } from "../../constants/Colors";

export default function EditWorkTypeSmallScreen(props: any) {
  const navigation = useNavigation();
  const [worktypes, setWorkTypes] = useState<WorkType[]>([]);
  const [parents, setParents] = useState<{ label: string; value: number }[]>(
    []
  );
  const [parent_value, setParentValue] = useState({});

  /**選択した仕事(中分類) */
  const [selectedWorkTypeMid, setSelectedWorkTypeMid] = useState<WorkType>();
  /**選択した仕事(小分類) */
  const [workTypeSmalls, setWorkTypeSmalls] = useState<WorkType[]>([]);
  /**画面制御関連 **********************************************************/
  /**仕事(小分類)読み込み中フラグ */
  const [isLoadingWorkTypeSmalls, setIsLoadingWorkTypeSmalls] =
    useState<boolean>(false);
  /**仕事(小分類)編集モーダル表示フラグ */
  const [modalOpen, setModalOpen] = useState(false);

  /**選択済みの仕事(小分類)のID */
  const selectedWorkTypeSmallId = props.route.params.workTypeSmallId ?? 0;

  async function init() {
    const wkt = await WorkTypeModel.fetchFind({
      id: props.route.params.id,
    });
    if (wkt && wkt.parent) {
      setParentValue({
        label: wkt.parent.name,
        value: wkt.parent.id,
      });
    }

    const data = await WorkTypeModel.fetchAll({
      id: 0,
    });
    let newList: { label: string; value: number }[] = [];
    if (data) {
      newList = data.map(function (item: WorkType) {
        return { label: item.name, value: item.id };
      });
      setParents(newList);
    }

    setParentValue({
      label: newList[0].label,
      value: newList[0].value,
    });
    const wkt_list = await WorkTypeModel.fetchAll({ id: newList[0].value });
    if (wkt_list) {
      setWorkTypes(wkt_list);
    }
  }

  async function changeWorktype(id: number) {
    const wkt_list = await WorkTypeModel.fetchAll({ id: id });
    if (wkt_list) {
      setWorkTypes(wkt_list);
    }
  }

  /**
   * 中分類IDを元に小分類を全て取得する
   * @param workTypeMid
   */
  async function fetchWorkTypeSmalls(workTypeMid: WorkType) {
    setIsLoadingWorkTypeSmalls(true);
    const res = await WorkTypeModel.fetchAllById({ id: workTypeMid.id });
    setWorkTypeSmalls(res);
    setIsLoadingWorkTypeSmalls(false);
  }

  useEffect(() => {
    init();

    if (
      props.route.params.workTypeMidId &&
      props.route.params.workTypeMidName
    ) {
      //前の画面で中分類を指定していた場合は
      //該当の小分類編集モーダルを開く
      openModal({
        company_id: 0,
        id: props.route.params.workTypeMidId,
        name: props.route.params.workTypeMidName,
        parent_id: 0,
        rank: 0,
      });
    }

    window.functions.unset = () => {
      props.route.params.setWorkTypeId(0);
      props.route.params.setWorkTypeName("");
      navigation.navigate(props.route.params.backScreen);
    };
  }, []);

  function keyExtractor(item: any, index: any) {
    return item.id.toString();
  }

  function renderItem({ item }: { item: WorkType }) {
    const isSelected =
      props.route.params.workTypeMidId == item.id ? true : false;

    const bgColor: ColorValue = isSelected
      ? CommonColor.Normal.Green
      : CommonColor.White;

    const subTextColor: ColorValue = isSelected
      ? CommonColor.Black
      : CommonColor.Dark.Grey;

    return (
      <View
        style={{
          height: 50,
          justifyContent: "center",
          paddingLeft: 10,
          paddingRight: 10,
          borderBottomColor: "#ccc",
          borderBottomWidth: 1,
          backgroundColor: bgColor,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View>
            <Text style={{ fontSize: 12, color: subTextColor }}>
              {item.parent_name}
            </Text>
            <Text style={{ fontSize: 17 }}>{item.name}</Text>
            {item.id == props.route.params.id && (
              <FontAwesome
                name={"check"}
                size={24}
                style={{
                  color: "rgb(32, 137, 220)",
                  position: "absolute",
                  right: 10,
                  top: 10,
                }}
              />
            )}
          </View>
          <View>
            <TouchableOpacity
              style={{
                backgroundColor: CommonColor.Normal.Blue,
                paddingHorizontal: 10,
                borderRadius: 5,
                paddingVertical: 5,
              }}
              onPress={() => openModal(item)}
            >
              <Text style={{ color: "white" }}>編集</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }

  /**
   * 小分類編集モーダルを開く
   * @param workType
   */
  const openModal = async (workType: WorkType) => {
    setSelectedWorkTypeMid(workType);
    await fetchWorkTypeSmalls(workType);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const renderItemWorkTypeSmalll = ({ item }: { item: WorkType }) => {
    let isSelected = false;

    if (selectedWorkTypeSmallId != 0 && item.id == selectedWorkTypeSmallId) {
      isSelected = true;
    }
    return (
      <EditWorkTypeSmallItem
        isSelected={isSelected}
        workTypeMid={selectedWorkTypeMid!}
        workTypeSmall={item}
      />
    );
  };

  return (
    <>
      <View style={styles.container}>
        <View style={{ width: "100%", padding: 5, zIndex: 3000 }}>
          <Select
            options={parents}
            onChange={(val: any) => {
              setParentValue(val);
              changeWorktype(val.value);
            }}
            value={parent_value}
          />
        </View>

        <FlatList
          style={{
            width: "100%",
            backgroundColor: "#fff",
            marginTop: 5,
          }}
          keyExtractor={keyExtractor}
          data={worktypes}
          renderItem={renderItem}
        />
      </View>
      <Modal
        show={modalOpen}
        onHide={closeModal}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedWorkTypeMid?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "50vh", overflowY: "auto" }}>
          <Container>
            <FlatList
              keyExtractor={keyExtractor}
              data={workTypeSmalls}
              renderItem={renderItemWorkTypeSmalll}
            />
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>閉じる</Button>
          <Button
            onClick={() => {
              const list = workTypeSmalls.concat({
                company_id: 0,
                id: 0,
                name: "",
                parent_id: 0,
                rank: 0,
              });
              setWorkTypeSmalls(list);
            }}
          >
            仕事(小分類)の追加
          </Button>
        </Modal.Footer>
      </Modal>
      {isLoadingWorkTypeSmalls && <CoverIndicator />}{" "}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
  },
});

const modalStyle = StyleSheet.create({
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    width: "80%",
    height: "80%",
    margin: "10px",
    // overflow: "auto",
  },
});
