import * as React from "react";
import { useState } from "react";
import { StyleSheet, View } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import { Input, Button, CheckBox, Text } from "react-native-elements";
import { useAuth0 } from "@auth0/auth0-react";
import { LOCAL_STORAGE_KEY } from "../../models/LocalStorage";
import { UserModel } from "../../models/UserModel";
import { LocalStorageService } from "../../services/LocalStorageService.";

const moment = require("moment");

export default function LoginScreen(props: any) {
  const { navigation, functions } = props;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errstr, setErrstr] = useState("");
  // console.log("呼ばれた");
  // const { user, isLoading, loginWithRedirect, getAccessTokenSilently } =
  //   useAuth0();

  // const handleLogin = async () => {
  //   // await loginWithRedirect();
  //   // const accessToken = await getAuth0Token();
  //   // window.accessToken = accessToken;
  //   // window.functions.setIsAuth(true);
  // };

  // 認証が完了している場合、JWTトークンを取得する
  // const getAuth0Token = async () => {
  //   // const domain = "dev-luy4w0choa3fshfj.us.auth0.com";
  //   // const accessToken = await getAccessTokenSilently({
  //   //   authorizationParams: {
  //   //     audience: `https://${domain}/api/v2/`,
  //   //     scope: "read:current_user",
  //   //   },
  //   // });
  //   // console.log("JWT:", accessToken);
  //   // return accessToken;
  // };

  /**
   * 自分のユーザー情報を取得する
   */
  const fetchMyUserInfo = async () => {
    try {
      const res = await UserModel.fetchMyUserInfo();
      LocalStorageService.setUser(res);
      window.userInfo = res;
    } catch (error) {}
  };

  return (
    <View style={styles.container}>
      <View style={{ width: "100%", flex: 1 }}>
        <View
          style={{
            width: "100%",
            height: 250,
            backgroundColor: "#eee",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 20,
          }}
        >
          <Text>ロゴなど</Text>
        </View>
        <Input
          placeholder="メールアドレス"
          leftIcon={
            <Icon name="envelope" size={22} color="rgb(32, 137, 220)" />
          }
          style={{ padding: 5 }}
          value={email}
          onChangeText={(value) => setEmail(value)}
        />
        <Input
          placeholder="パスワード"
          leftIcon={<Icon name="lock" size={30} color="rgb(32, 137, 220)" />}
          style={{ padding: 5 }}
          secureTextEntry={true}
          value={password}
          onChangeText={(value) => setPassword(value)}
        />
        {/*<View style={{ marginTop: 20 }}>
          <CheckBox
            center
            title="ログインを維持する"
            checked={remember}
            onPress={() => {
              setRemember(!remember);
            }}
          />
          </View>*/}
        {errstr != "" && (
          <View>
            <Text style={{ color: "#c00", textAlign: "center" }}>{errstr}</Text>
          </View>
        )}
        <Button
          style={{ marginTop: 30 }}
          title="ログインする"
          onPress={() => {
            setErrstr("");
            window
              .axios({
                method: "post",
                url: window.baseUrl + "/login",
                data: {
                  email,
                  password,
                },
                headers: {},
              })
              .then(async (res: any) => {
                const { data } = res;
                if (!data.result) {
                  setErrstr("認証に失敗しました");
                } else {
                  window.storage.save({
                    key: LOCAL_STORAGE_KEY.ACCESS_TOKEN,
                    data: data.token,
                  });

                  window.accessToken = data.token;
                  await fetchMyUserInfo();
                  window.functions.setIsAuth(true);
                  // 受領中の画面のフィルタを確認中にする
                  _setReceiptFilter(true);
                }
              })
              .catch((err: any) => {});
          }}
        />
        {/* <View style={{ marginTop: 30 }}>
          <Button title="Auth0 ログイン" onPress={handleLogin} />
        </View> */}
        <View style={{ flexGrow: 1, minHeight: 100 }}></View>
        <View style={{ height: 20 }}>
          <Text style={{ fontSize: 12, textAlign: "center" }}>
            ©️ 2021 Foster Co. Ltd. All rights reserved.
          </Text>
        </View>
      </View>
    </View>
  );
}

async function _setReceiptFilter(flag: boolean) {
  let df = 0;
  let y = moment().add("month", df).startOf("month").format("YYYY");
  let billing_y_obj = { label: y, value: y };
  let m = moment().add("month", df).startOf("month").format("MM");
  let billing_m_obj = { label: m, value: m };

  let filter = await window.storage
    .load({ key: "ReceiptFilter" })
    .catch((err: any) => null);
  if (!filter) {
    let data = {
      billing_y_obj: billing_y_obj,
      billing_m_obj: billing_m_obj,
      to_org: 0,
      from_org: 0,
      to_org_name: "未選択",
      from_org_name: "未選択",
      work_type_id: 0,
      work_type_name: "未選択",
      state_obj: flag
        ? { label: "確認中", value: 0 }
        : { label: "未選択", value: -1 },
    };
    window.storage.save({
      key: "ReceiptFilter",
      data,
    });
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 10,
    backgroundColor: "#fff",
  },
});
