import * as React from "react";
import { useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity, Text } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { HeaderTitleBar } from "../../components/common/HeaderBar";
import { FloatingButton } from "../../components/common/FloatingButton";
import { SCREENS } from "../../constants/Screens";
import { CommonColor } from "../../constants/Colors";
import CommonModal from "../../components/modal/CommonModal";
import { Col, Row } from "react-bootstrap";
import NotificationToast from "../../components/common/NotificationToast";
import CoverIndicator from "../../components/common/CoverIndicator";
import CommonLoadingIndicator from "../../components/common/LoadingIndicator";

import {
  DailyTeiban,
  DailyTeibanModel,
  quantity,
  work,
} from "../../models/DailyTeibanModel";
import DailyTeibanListItem from "../../components/dailyTeiban/DailyTeibanListItem";
import InputWithDoropdown from "../../components/common/InputWithDoropdown";
import { InvoiceModel } from "../../models/InvoiceModel";

export default function DailyTeibanScreen(props: any) {
  // const selectedYM: string = props.route.params.selected_YM ?? "";

  /**navigation */
  const navigation = useNavigation();

  /** 定番一覧関連 ****************************************************************/
  /**定番一覧 */
  const [dailyTeibans, setDailyTeibans] = useState<DailyTeiban[]>([]);
  /**定番チェック */
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});
  /**一覧の数量管理 */
  const [quantities, setQuantities] = useState<quantity[]>([]);
  /**一覧の仕事管理 */
  const [works, setWorks] = useState<work[]>([]);
  /**編集中の仕事 */
  const [editWork, setEditWork] = useState<string>();
  /**選択中の仕事 */
  const [selectedDailyTeiban, setSelectedDailyTeiban] = useState<DailyTeiban>();
  /**仕事(内容)の履歴 */
  const [workHistory, setWorkHistory] = useState<{ name: string }[]>([]);
  /**仕事(内容)の履歴取得インジケーター */
  const [isLoadingWorkHistory, setIsLoadingWorkHistory] =
    useState<boolean>(true);
  /** 画面制御関連 ****************************************************************/
  /**定番下部の読み込みフラグ */
  const [isBottomLoading, setIsBottomLoading] = useState(false);
  /**操作不可の読み込みフラグ */
  const [isLoading, setIsLoading] = useState(true);
  /**定番読み込みページ */
  const [page, setPage] = useState(0);
  /**定番読み込み最終ページ */
  const [last_page, setLastPage] = useState(-1);
  /**削除モーダルの表示フラグ */
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  /**一括定番コピーモーダルの表示フラグ */
  const [isShowCopyAllModal, setIsShowCopyAllModal] = useState<boolean>(false);
  /**仕事設定モーダルの表示フラグ */
  const [isShowWorkModal, setIsShowWorkModal] = useState<boolean>(false);
  /**チェック済みの項目の有無 */
  const hasChecked = Object.values(checkedItems).includes(true);
  /**チェック済みの項目のid */
  const checkedIds = Object.keys(checkedItems)
    .filter((key) => checkedItems[key])
    .map(Number);

  /**
   * 定番一覧を読み込む
   * @param pageNo
   * @param lastPageNo
   * @returns
   */
  async function loadNext(pageNo?: number, lastPageNo?: number) {
    pageNo = pageNo ?? page;
    lastPageNo = lastPageNo ?? last_page;
    if (pageNo === lastPageNo) return;
    if (isBottomLoading) return;
    setIsBottomLoading(true);
    let params: { page: number; filter?: any } = {
      page: pageNo + 1,
      // type: props.route.params.type,
    };
    try {
      const res = await DailyTeibanModel.index(params);
      setDailyTeibans((prevDataArray) => [...prevDataArray, ...res.data]);
      setPage(res.current_page);
      setLastPage(res.last_page);
      setIsBottomLoading(false);
      setIsLoading(false);
    } catch (error) {
      window.functions.logout();
    }
  }

  const handleWork = (e: DailyTeiban) => {
    setSelectedDailyTeiban(e);
    fetchWorkHistory(e);
    setEditWork(undefined);
    setIsShowWorkModal(true);
  };

  /**仕事を設定して閉じる */
  const onhandleSetWork = () => {
    if (!selectedDailyTeiban) return closeAllModal();

    const exist = works.find((e) => e.id === selectedDailyTeiban.id);
    if (exist) {
      const updateWorks = works.map((e) => {
        if (e.id == selectedDailyTeiban.id) {
          return { id: selectedDailyTeiban.id, work: editWork };
        }
        return e;
      });
      setWorks(updateWorks);
      closeAllModal();
      return;
    }
    const updateWorks = works.concat({
      id: selectedDailyTeiban.id,
      work: editWork,
    });
    setWorks(updateWorks);
    closeAllModal();
  };

  /**
   * 数量を更新する
   * @param value
   * @returns
   */
  const handleQuantity = (value: quantity) => {
    if (value.quantity < 1) {
      value.quantity = 1;
    }

    const exist = quantities.find((e) => e.id === value.id);
    if (exist) {
      const updateQuantities = quantities.map((e) => {
        if (e.id == value.id) {
          return value;
        }
        return e;
      });
      setQuantities(updateQuantities);
      return;
    }
    const updateQuantities = quantities.concat(value);
    setQuantities(updateQuantities);
  };

  /**仕事(内容)の履歴を取得する */
  const fetchWorkHistory = async (dailyTeiban: DailyTeiban) => {
    setWorkHistory([]);

    const fromOrg = dailyTeiban.from_org ?? 0;
    const toOrg = dailyTeiban.to_org ?? 0;
    const workTypeId = dailyTeiban.work_type_id ?? 0;
    const workTypeSmallId = dailyTeiban.work_type_small_id ?? 0;

    if (fromOrg === 0 || toOrg === 0 || workTypeId === 0) return;
    setIsLoadingWorkHistory(true);
    const data = await InvoiceModel.fetchWorkHistory({
      to_org_id: toOrg,
      from_org_id: fromOrg,
      work_type_id: workTypeId,
      work_type_small_id: workTypeSmallId,
    });
    setWorkHistory(data);
    setIsLoadingWorkHistory(false);
  };

  /**
   * 定番の☑変更
   * @returns
   */
  const handleCheckboxToggle = (itemId: string) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  /**
   * 定番の☑を外す
   * @returns
   */
  const handleUncheckAll = () => {
    setCheckedItems({});
  };

  /**
   * 全ての定番に☑をつける
   * @returns
   */
  const handleCheckAllItems = () => {
    const updatedCheckedItems: Record<string, boolean> = {};
    for (const item of dailyTeibans) {
      updatedCheckedItems[item.id] = true;
    }
    setCheckedItems(updatedCheckedItems);
  };

  /**
   * 定番を削除する
   */
  const deleteTeiban = async () => {
    setIsShowDeleteModal(false);
    setIsLoading(true);
    try {
      const ids = Object.keys(checkedItems)
        .filter((key) => checkedItems[key])
        .map(Number);

      await DailyTeibanModel.deleteByIds(ids);
      handleUncheckAll();
      reload();
    } catch (error) {}
  };

  /**
   * ドラッグ並び替え処理
   * @returns
   */
  const onDragEnd = (result: any) => {
    if (!result || !result.destination) {
      return;
    }

    const items = Array.from(dailyTeibans);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    // リストアイテムの並び順を変更する処理

    // ドラッグ終了時に新しい並び順のリストを作成し、適用する
    const updatedDailyTeibans: DailyTeiban[] = items.map((item, index) => ({
      ...item,
      // indexを更新する
      index: index + 1,
    }));

    setDailyTeibans(updatedDailyTeibans);

    const requestPayload = {
      items: updatedDailyTeibans.map((item, index) => ({
        id: item.id,
        order: index + 1, //0は使用しない
      })),
    };
    DailyTeibanModel.updateOrder(requestPayload);
  };

  /**
   * 削除モーダルを開く
   */
  const openDeleteModal = () => {
    setIsShowDeleteModal(true);
  };

  /**
   * モーダルを閉じる
   */
  const closeAllModal = () => {
    setIsShowDeleteModal(false);
    setIsShowWorkModal(false);
    setIsShowCopyAllModal(false);
  };

  /**
   * 選択した今日の仕事を請求にコピー
   */
  const onhandleCopySelected = async () => {
    setIsShowCopyAllModal(false);
    setIsLoading(true);
    try {
      await DailyTeibanModel.toInvoice({
        dailyTeibanIds: checkedIds,
        quantities: quantities,
        works: works,
      });
      window.reloadFlag = true;
      window.navi.navigate(SCREENS.INVOICE.INVOICE, {});
    } catch (error) {
      setIsLoading(false);
      closeAllModal();
      NotificationToast().error("登録に失敗しました。");
    }
  };

  /**
   * 再読み込み処理
   */
  const reload = () => {
    setIsLoading(true);
    setDailyTeibans([]);
    setPage(0);
    setLastPage(-1);
    loadNext(0, -1);
  };

  //仕事を履歴より設定する
  const handleOnSelectedWork = (v: { name: string }) => {
    setEditWork(v.name);
  };
  /**
   * 仕事設定モーダル
   * @returns
   */
  const renderSetWorkModal = () => {
    let work: string;
    if (editWork === undefined) {
      const worksById = works.find((e) => e.id === selectedDailyTeiban?.id) ?? {
        id: selectedDailyTeiban?.id,
        work: selectedDailyTeiban?.work,
      };

      work = worksById.work ?? "";
      setEditWork(work);
    } else {
      work = editWork;
    }

    return (
      <CommonModal
        isShow={isShowWorkModal}
        body="請求する仕事を変更できます。"
        element={
          <View style={{ paddingHorizontal: 10 }}>
            <Text style={{}}>仕事</Text>
            <InputWithDoropdown
              dropdownTitle="履歴"
              value={work}
              onChange={setEditWork}
              dropdownItems={workHistory}
              isLoading={isLoadingWorkHistory}
              onSelect={handleOnSelectedWork}
            />
          </View>
        }
        actionTitle="設定"
        onPress={onhandleSetWork}
        onDismiss={closeAllModal}
      />
    );
  };

  /**
   * スクロールイベント
   */
  const onScrollList = (event: React.SyntheticEvent) => {
    const currentTarget = event.currentTarget;

    if (
      currentTarget.scrollHeight >=
      currentTarget.scrollTop + currentTarget.clientHeight + 10
    ) {
      loadNext();
    }
  };

  /**
   * 詳細画面で更新した請求レコードを更新する
   * @param id
   */
  async function _updateItem(id: number) {
    if (dailyTeibans) {
      let items: DailyTeiban[] = dailyTeibans.slice();
      items.forEach(async (item: DailyTeiban, index: number) => {
        if (item.id == id) {
          const res = await DailyTeibanModel.show(id);
          items[index] = res;
        }
      });
      setDailyTeibans(items);
    }
  }

  /**
   * 詳細画面で削除した請求レコードを除去する
   * @param id
   */
  function _deleteItem(id: number) {
    let items: DailyTeiban[] = dailyTeibans.slice();
    for (let i = 0; i < items.length; i++) {
      if (items[i].id == id) {
        items.splice(i, 1);
        break;
      }
    }

    setDailyTeibans(items);
  }
  useEffect(() => {
    if (
      props.route.params &&
      props.route.params.hasOwnProperty("update_id") &&
      props.route.params.update_id > 0
    ) {
      _updateItem(props.route.params.update_id);
    }
    if (
      props.route.params &&
      props.route.params.hasOwnProperty("delete_id") &&
      props.route.params.delete_id > 0
    ) {
      _deleteItem(props.route.params.delete_id);
    }
  }, [props.route.params]);

  useEffect(() => {
    navigation.addListener("focus", async () => {
      window.functions.reloadList = () => {
        reload();
      };
      if (window.reloadFlag) {
        window.reloadFlag = false;
        reload();
      }
    });

    loadNext();
  }, []);

  /**
   * 定番のリストアイテム
   * @returns
   */
  const DraggableListItem = ({ item, index }: any) => {
    const quantity: quantity = quantities.find((e) => e.id === item.id) ?? {
      id: item.id,
      quantity: 1,
    };

    const work = works.find((e) => e.id === item.id);

    return (
      <Draggable
        draggableId={item.id.toString()}
        index={index}
        key={item.id.toString()}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <DailyTeibanListItem
              quantity={quantity.quantity.toString()}
              work={work ? work.work : item.work}
              item={item}
              isChecked={checkedItems[item.id] || false}
              onCheck={handleCheckboxToggle}
              onTapWork={handleWork}
              onChangeQuantity={handleQuantity}
              onPress={() => {
                window.navi.navigate(SCREENS.INVOICE.DAILY_TEIBAN.EDIT, {
                  id: item.id,
                });
              }}
              onUpdated={onUpdated}
            />
          </div>
        )}
      </Draggable>
    );
  };

  const onUpdated = (item: DailyTeiban) => {
    const updatedTeibans = dailyTeibans.map((dailyTeiban) => {
      if (dailyTeiban.id === item.id) {
        // dailyTeiban.targetday = item.targetday;
        // dailyTeiban.quantity = item.quantity;
      }
      return dailyTeiban;
    });
    setDailyTeibans(updatedTeibans);
  };

  return (
    <View style={styles.container}>
      <HeaderTitleBar title="今日の仕事" />
      <Row style={{ width: "100%" }}>
        <Col xs={9} sm={10}></Col> {/* 空のColコンポーネントを追加 */}
        <Col
          xs={3}
          sm={2}
          className="d-flex align-items-center justify-content-end p-2"
        >
          <TouchableOpacity
            style={{
              marginRight: 10,
              paddingHorizontal: 8,
              paddingVertical: 2,
              backgroundColor: CommonColor.Normal.Grey,
              borderRadius: 20,
            }}
            onPress={handleCheckAllItems}
          >
            <Text>全て選択</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              marginRight: 0,
              paddingHorizontal: 8,
              paddingVertical: 2,
              backgroundColor: CommonColor.Normal.Grey,
              borderRadius: 20,
            }}
            onPress={handleUncheckAll}
          >
            <Text>選択解除</Text>
          </TouchableOpacity>
        </Col>
      </Row>
      {dailyTeibans && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  width: "100%",
                  overflow: "auto",
                  paddingBottom: 100,
                }} // スクロール可能にするためのスタイルを追加
                onScroll={onScrollList}
              >
                {dailyTeibans.map((item: DailyTeiban, index: number) => (
                  <DraggableListItem key={item.id} item={item} index={index} />
                ))}
                {provided.placeholder}
                {isBottomLoading && !isLoading && <CommonLoadingIndicator />}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {hasChecked ? (
        <FloatingButton
          text={"請求"}
          style={{
            right: 100,
            backgroundColor: CommonColor.Normal.Blue,
          }}
          onPress={() => setIsShowCopyAllModal(true)}
        />
      ) : (
        <></>
      )}
      {hasChecked ? (
        <FloatingButton
          icon="trash"
          style={{
            backgroundColor: CommonColor.Normal.Red,
          }}
          onPress={openDeleteModal}
        />
      ) : (
        <FloatingButton
          icon="plus"
          onPress={() => {
            window.navi.navigate(SCREENS.INVOICE.DAILY_TEIBAN.EDIT, {});
          }}
        />
      )}
      <CommonModal
        isShow={isShowCopyAllModal}
        body="選択済みの仕事を今日の請求に追加しますか？"
        actionTitle="請求する"
        onPress={onhandleCopySelected}
        onDismiss={closeAllModal}
      />

      <CommonModal
        isShow={isShowDeleteModal}
        body="選択済みの項目を削除しますか？"
        actionTitle="削除する"
        onPress={deleteTeiban}
        onDismiss={closeAllModal}
        buttonVariant="danger"
      />
      {renderSetWorkModal()}

      {isLoading ? <CoverIndicator /> : <></>}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    // flex: 1,
    // justifyContent: "center",
    backgroundColor: "#fff",
  },
  mr10: {
    marginRight: 10,
  },
});
