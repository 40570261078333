import React from "react";
import { Table } from "react-bootstrap";
import { HomeTotal } from "../../models/HomeModel";
import { convertToMoneyAndFormat } from "../../util/Math";
import { dateToDayName, dateToMD } from "../../util/DateUtil";
import { CommonColor } from "../../constants/Colors";
import { Text } from "react-native";
const ColumnsType = {
  DATE: 1,
  RECEPT: 2,
  CHECK: 3,
  TOTAL: 4,
};

interface Item {
  id: number;
  label: string;
  type: number;
}

/**部門の選択対象タイプ */
const Columns = {
  /**日付 */
  DATE: { id: 1, label: "日付", type: ColumnsType.DATE },
  /**受領 */
  RECIPT: { id: 2, label: "受領", type: ColumnsType.RECEPT },
  /**受領(累計) */
  RECIPT_COUNT_UP: { id: 3, label: "累計", type: ColumnsType.RECEPT },
  /**確認中 */
  CHECK: { id: 4, label: "確認", type: ColumnsType.CHECK },
  /**確認中(累計) */
  CHECK_COUNT_UP: { id: 5, label: "累計", type: ColumnsType.CHECK },
  /**合計 */
  TOTAL: { id: 6, label: "合計", type: ColumnsType.TOTAL },
  /**合計(累計)  */
  TOTAL_COUNT_UP: { id: 7, label: "累計", type: ColumnsType.TOTAL },
} as const;

const Headers: Item[] = [
  Columns.DATE,
  Columns.RECIPT,
  Columns.RECIPT_COUNT_UP,
  Columns.CHECK,
  Columns.CHECK_COUNT_UP,
  Columns.TOTAL,
  Columns.TOTAL_COUNT_UP,
];

export interface Props {
  homeTotals: HomeTotal[];
}

const HomeCountTable = (props: Props) => {
  const renderHeader = (item: Item) => {
    const type = item.type;
    const headerStyle = {
      backgroundColor:
        type === ColumnsType.RECEPT
          ? CommonColor.Dark.Green
          : type === ColumnsType.CHECK
          ? CommonColor.Dark.Blue
          : type === ColumnsType.TOTAL
          ? CommonColor.Dark.Grey
          : CommonColor.White,
      color: CommonColor.Black,
    };

    return (
      <th key={item.id} style={headerStyle}>
        {item.label}
      </th>
    );
  };

  const renderItem = (item: HomeTotal, index: number) => {
    const today = new Date();
    const currentMonthDay = `${today.getMonth() + 1}/${today.getDate()}`;
    const itemMonthDay = dateToMD(item.day_md);
    const isToday = itemMonthDay === currentMonthDay;
    //曜日の表示
    const dayName = dateToDayName(item.day_md);

    // スタイル定義
    const rowStyle: any = {
      fontWeight: isToday ? "bold" : "normal", // isTodayの場合は太字、そうでなければ通常
    };

    return (
      <tr key={item.day_md} style={rowStyle}>
        <td style={{ textAlign: "right", color: dayName.color }}>
          {/* <Text style={{ fontSize: 5 }}> {isToday && ""}</Text> */}
          <Text
            style={{
              fontSize: 13,
              color: dayName.color,
              fontWeight: isToday ? "bold" : "normal",
            }}
          >
            {dateToMD(item.day_md)}
          </Text>
          <Text style={{ fontSize: 10, color: dayName.color }}>
            {dayName.name}
          </Text>
        </td>
        <td style={{ textAlign: "right", ...getTypeColor(ColumnsType.RECEPT) }}>
          {convertToMoneyAndFormat(item.total)}
        </td>
        <td style={{ textAlign: "right", ...getTypeColor(ColumnsType.RECEPT) }}>
          {convertToMoneyAndFormat(item.cumulative_total)}
        </td>
        <td style={{ textAlign: "right", ...getTypeColor(ColumnsType.CHECK) }}>
          {convertToMoneyAndFormat(item.total_k)}
        </td>
        <td style={{ textAlign: "right", ...getTypeColor(ColumnsType.CHECK) }}>
          {convertToMoneyAndFormat(item.cumulative_total_k)}
        </td>
        <td style={{ textAlign: "right", ...getTypeColor(ColumnsType.TOTAL) }}>
          {convertToMoneyAndFormat(Number(item.total) + Number(item.total_k))}
        </td>
        <td style={{ textAlign: "right", ...getTypeColor(ColumnsType.TOTAL) }}>
          {convertToMoneyAndFormat(
            Number(item.cumulative_total) + Number(item.cumulative_total_k)
          )}
        </td>
      </tr>
    );
  };

  return (
    <Table striped bordered hover>
      <thead
        style={{
          position: "sticky",
          top: "-12px",
        }}
      >
        <tr className="bg-secondary text-white">
          {Headers.map((e) => renderHeader(e))}
        </tr>
      </thead>
      <tbody>{props.homeTotals.map((e, index) => renderItem(e, index))}</tbody>
    </Table>
  );
};

const getTypeColor = (type: number) => {
  let bgColor = CommonColor.Light.Grey;
  switch (type) {
    case ColumnsType.RECEPT:
      bgColor = CommonColor.Light.Green;
      break;
    case ColumnsType.CHECK:
      bgColor = CommonColor.Light.Blue;
      break;
    case ColumnsType.TOTAL:
      bgColor = CommonColor.Light.Grey;
      break;
    default:
      return {};
  }

  return {
    backgroundColor: bgColor, // 列の色を決定する関数 getTypeColor を作成する必要があります
    color: "#000",
  };
};

export default HomeCountTable;
