import * as React from "react";
const DatePicker = require("react-mobile-datepicker");
import { useState, useEffect } from "react";
import {
  Text,
  ScrollView,
  TouchableOpacity,
  View,
  StyleSheet,
} from "react-native";

import { Input, Button } from "react-native-elements";
const moment = require("moment");
import formatNumber from "format-number";
// import { DailyTeibanModel } from "../../models/DailyTeibanModel";
import { SCREENS } from "../../constants/Screens";
import InputWithDoropdown from "../../components/common/InputWithDoropdown";
import { CommonColor } from "../../constants/Colors";
import ButtonWithDropdown from "../../components/common/ButtonWithDoropdown";
import InvoiceSummary from "../../components/invoice/invoiceSummary";
import { WorkType, WorkTypeModel } from "../../models/WorkTypeModel";
import {
  InvoiceModel,
  WORK_TYPE_SMALL_DEFAULT_NAME,
} from "../../models/InvoiceModel";
import NextAndBackButton from "../../components/teiban/NextAndBackButton";
import CoverIndicator from "../../components/common/CoverIndicator";
import CommonModal from "../../components/modal/CommonModal";
import { InputType } from "../../components/common/CommonInput";
import { validateInvoice } from "../../util/InvoiceUtil";
import NotificationToast from "../../components/common/NotificationToast";
import { DailyTeibanModel } from "../../models/DailyTeibanModel";
import WorkMemoButton from "../../components/common/WorkMemoButton";
import { Form, InputGroup } from "react-bootstrap";
import CommonErrorLabel from "../../components/common/CommonErrorLabel";
import {
  Organisations,
  OrganisationsModel,
} from "../../models/OrganisationsModel";
import { WorkMemos } from "../../models/WorkMemosModel";
import { selectWorkType } from "../filter/SelectWorkTypeScreen";

const numberFormat = formatNumber({
  round: 0,
});

export default function DailyTeibanDetailScreen(props: any) {
  const { navigation, functions } = props;

  const [billing_date, setBillingDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  /*自動入力　制御関連*********************************************************/
  /** 自動入力(中分類仕事) */
  const [autoInputWorkTypeSmall, setAutoInputWorkTypeSmall] = useState(true);
  /** 自動入力(仕事内容) */
  const [autoInputWork, setAutoInputWork] = useState(true);
  /** 自動入力(単価) */
  const [autoInputUnitPrice, setAutoInputUnitPrice] = useState(true);
  /** 定番関連 ****************************************************************/

  /**定番種別(リニューアルに伴い0しか使用しない) */
  const [type, setType] = useState(0);
  /**請求先(請求先) */
  const [to_org, setToOrg] = useState(0);
  /**請求先(請求先)名称 */
  const [to_org_name, setToOrgName] = useState("未選択");
  /**請求元(請求元) */
  const [from_org, setFromOrg] = useState<number>(0);
  /**請求元(請求元)名称 */
  const [from_org_name, setFromOrgName] = useState<string>("未選択");
  /**仕事内容 */
  const [work, setWork] = useState<string>("");
  /**中分類仕事 id*/
  const [work_type_id, setWorkTypeId] = useState<number>(0);
  /**中分類仕事 名称*/
  const [work_type_name, setWorkTypeName] = useState<string>("");
  /**小分類仕事 id*/
  const [work_type_small_id, setWorkTypeSmallId] = useState<number>(0);
  /**小分類類仕事 名称*/
  const [work_type_small_name, setWorkTypeSmallName] = useState("");
  /**単価 */
  const [unit_price, setUnitPrice] = useState("0");

  /** 画面制御関連 ****************************************************************/
  const [isLoading, setIsLoading] = useState(true);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [dt, setDt] = useState(new Date());
  /**削除確認用モーダル */
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);

  /**一つ前の請求ID */
  const [previousId, setPreviousId] = useState<number | null>();
  /**一つ後の請求ID */
  const [nextId, setNextId] = useState<number | null>();
  /**請求最終利用情報の取得フラグ */
  const [isEnableLoadLast, setIsEnableLoadLast] = useState<boolean>(false);

  /** 履歴関連 ****************************************************************/
  /**請求先の履歴 */
  const [toOrgHistory, setToOrgHistory] = useState<Organisations[]>([]);
  /**中分類仕事の履歴 */
  const [workTypeMidHistory, setWorkTypeHistory] = useState<WorkType[]>([]);
  /**中分類仕事の履歴取得インジケーター */
  const [isLoadingWorkTypeMidHistory, setIsLoadingWorkTypeMidHistory] =
    useState<boolean>(true);
  /**小分類仕事の履歴 */
  const [workTypeSmallHistory, setWorkTypeSmallHistory] = useState<WorkType[]>(
    []
  );
  /**小分類仕事の履歴取得インジケーター */
  const [isLoadingWorkTypeSmallHistory, setIsLoadingWorkTypeSmallHistory] =
    useState<boolean>(true);
  /**仕事(内容)の履歴 */
  const [workHistory, setWorkHistory] = useState<{ name: string }[]>([]);
  /**仕事(内容)の履歴取得インジケーター */
  const [isLoadingWorkHistory, setIsLoadingWorkHistory] =
    useState<boolean>(true);
  /**単価の履歴 */
  const [unitPriceHistory, setUnitPriceHistory] = useState<{ name: string }[]>(
    []
  );
  /**単価の履歴取得インジケーター */
  const [isLoadingUnitPriceHistory, setIsLoadingUnitPriceHistory] =
    useState<boolean>(true);

  /**履歴取得開始フラグ*/
  const [isEnableLoadHistory, setIsEnableLoadHistory] =
    useState<boolean>(false);

  /** 入力チェック関連 ****************************************************************/
  /**エラー文言[仕事] */
  const [errWork, setErrWork] = useState("");
  /**エラー文言[単価] */
  const [errUnitPrice, setErrUnitPrice] = useState("");
  /**エラー文言[数量] */
  const [errQuantity, setErrQuantity] = useState("");
  /**エラー文言[請求先・請求先] */
  const [errToOrg, setErrToOrg] = useState("");
  /**エラー文言[請求元・請求元] */
  const [errFromOrg, setErrFromOrg] = useState("");
  /**エラー文言[中分類仕事] */
  const [errWorkType, setErrWorkType] = useState("");
  /**エラー文言[小分類仕事] */
  const [errWorkTypeSmall, setErrWorkTypeSmall] = useState("");

  /** DB関連 ****************************************************************/
  /**小分類仕事のDB */
  const [workTypeMidDB, setWorkTypeMidDB] = useState<WorkType[]>([]);
  /**小分類仕事のDB取得インジケーター */
  const [isLoadingWorkTypeMidDB, setIsLoadingWorkTypeMidDB] =
    useState<boolean>(true);

  /**中分類仕事のDB */
  const [workTypeSmallDB, setWorkTypeSmallDB] = useState<WorkType[]>([]);
  /**中分類仕事のDB取得インジケーター */
  const [isLoadingWorkTypeSmallDB, setIsLoadingWorkTypeSmallDB] =
    useState<boolean>(true);

  const teibanId = props.route.params.id;
  /**編集フラグ*/
  const isEdit = () => {
    if (teibanId > 0 && teibanId !== undefined) {
      return true;
    }
    return false;
  };

  /**入力チェック */
  function validate() {
    const res = validateInvoice({
      unit_price: unit_price,
      to_org: to_org,
      from_org: from_org,
      work_type_id: work_type_id,
      work_type_small_id: work_type_small_id,
      work_type_small_name: work_type_small_name,
    });

    setErrUnitPrice(res.msg.unit_price);
    setErrQuantity(res.msg.quantity);
    setErrToOrg(res.msg.to_org);
    setErrFromOrg(res.msg.from_org);
    setErrWorkType(res.msg.work_type);
    setErrWorkTypeSmall(res.msg.work_type_small);

    const errorMsg = res.errorList.map((e) => "[" + e + "]");
    if (!res.result)
      NotificationToast().error(errorMsg + "の入力内容が正しくありません。");

    return res.result;
  }

  /**
   * 登録処理
   * @returns
   */
  const onRegister = async () => {
    if (!validate()) return;
    setIsLoading(true);
    const id = props.route.params.id == 0 ? undefined : props.route.params.id;

    let params = {
      id,
      to_org,
      from_org,
      work_type_id,
      work,
      unit_price,
      source_id: props.route.params.id,
      work_type_small_id,
      work_type_small_name,
    };

    try {
      await DailyTeibanModel.store(params);
      window.reloadFlag = true;
      navigation.navigate(SCREENS.INVOICE.DAILY_TEIBAN.LIST);
    } catch (error) {
      window.functions.logout();
    }
  };

  const onDelete = async () => {
    setIsShowDeleteModal(false);
    setIsLoading(true);
    const id = props.route.params.id == 0 ? undefined : props.route.params.id;
    try {
      if (id == undefined) return;
      await DailyTeibanModel.deleteByIds([id]);
      window.reloadFlag = true;
      navigation.navigate(SCREENS.INVOICE.DAILY_TEIBAN.LIST);
    } catch (error) {
      window.functions.logout();
    }
  };

  /**
   * 定番をidで取得する
   */
  const fetchById = async (id?: number) => {
    try {
      const teiban_Id = id ?? teibanId;

      const res = await DailyTeibanModel.show(teiban_Id);
      setType(res.type);
      setToOrg(res.to_org);
      setFromOrg(res.from_org);
      setToOrgName(res.to_org_name);
      setFromOrgName(res.from_org_obj?.name ?? "");
      setWorkTypeId(res.work_type_id);
      setWorkTypeName(res.work_type_obj?.name ?? "");
      setWorkTypeSmallId(res.work_type_small_id ?? 0);
      setWorkTypeSmallName(
        res.work_type_small_obj?.name ?? WORK_TYPE_SMALL_DEFAULT_NAME
      );

      setWork(res.work ? res.work : "");
      setUnitPrice(res.unit_price.toString());
      // setQuantity(res.quantity.toString());
      // setTotal(res.total.toString());
      // setDraft(res.draft);

      setIsLoading(false);

      fetchWorkHistory({
        from_org: res.from_org,
        to_org: res.to_org,
        work_type_id: res.work_type_id,
        work_type_small_id: res.work_type_small_id,
      });

      fetchUnitPriceHistory({
        from_org: res.from_org,
        to_org: res.to_org,
        work_type_id: res.work_type_id,
        work_type_small_id: res.work_type_small_id,
        work: res.work,
      });
      setIsEnableLoadHistory(true);
      fetchPreviousAndNextId(teiban_Id);
    } catch (error) {
      // window.functions.logout();
    }
  };

  /**
   * 定番新規の初期値データを取得する
   */
  const fetchDefault = async () => {
    const res = await InvoiceModel.last();
    setType(props.route.params.type);
    if (res != null) {
      setToOrg(res.to_org);
      setToOrgName(res.to_org_name);
      setFromOrg(res.from_org);
      setFromOrgName(res.from_org_obj.name);
      setWorkTypeId(res.work_type_id);
      setWorkTypeName(res.work_type_name);
      setWorkTypeSmallId(res.work_type_small?.id ?? 0);
      setWorkTypeSmallName(res.work_type_small?.name ?? "");
      setWork(res.work ? res.work : "");
      setWork(res.work);
      setUnitPrice(res.unit_price.toString());

      //仕事履歴を取得する
      fetchWorkHistory({
        from_org: res.from_org,
        to_org: res.to_org,
        work_type_id: res.work_type_id,
        work_type_small_id: res.work_type_small_id,
      });
      //単価の履歴を取得する
      fetchUnitPriceHistory({
        from_org: res.from_org,
        to_org: res.to_org,
        work_type_id: res.work_type_id,
        work_type_small_id: res.work_type_small_id,
        work: res.work,
      });
      setIsEnableLoadHistory(true);
    }
    setIsLoading(false);
  };

  /**
   * 定番の並び順前後のIDを取得する
   * @returns
   */
  const fetchPreviousAndNextId = async (teibanId?: number) => {
    if (teibanId === 0 || teibanId === undefined) return;
    const res = await DailyTeibanModel.previousAndNextId(teibanId);
    setPreviousId(res.previousId);
    setNextId(res.nextId);
  };

  /**請求先・請求先フィルター画面に遷移 */
  const handleOnPressToOrg = () => {
    window.navi.navigate(SCREENS.INVOICE.DAILY_TEIBAN.FILTER.ORG, {
      id: to_org,
      setOrg: setToOrg,
      setOrgName: setToOrgName,
      backScreen: SCREENS.INVOICE.DAILY_TEIBAN.EDIT,
      type: type,
      mode: "to_org",
    });
  };

  /**請求元・請求元フィルター画面に遷移 */
  const handleOnPressFromOrg = () => {
    window.navi.navigate(SCREENS.INVOICE.DAILY_TEIBAN.FILTER.ORG, {
      id: from_org,
      setOrg: setFromOrg,
      setOrgName: setFromOrgName,
      backScreen: SCREENS.INVOICE.DAILY_TEIBAN.EDIT,
      range: "belong",
      type: type,
      mode: "from_org",
    });
  };

  /**入力エラーラベル */
  const renderErrorLabel = (errMsg: string) => {
    return (
      <Text style={{ color: "rgb(255, 25, 12)", fontSize: 12 }}>{errMsg}</Text>
    );
  };

  /**小分類仕事　入力 */
  const handleOnChangeWorkSmall = (v: string) => {
    setWorkTypeSmallName(v);
    setWorkTypeSmallId(0);
  };

  /**仕事　入力 */
  const handleOnChangeWork = (v: string) => {
    //単価の自動入力を停止する
    setAutoInputUnitPrice(false);
    setWork(v);
  };

  /**中分類仕事フィルター画面に遷移 */
  const handleOnPressWorkTypeMid = () => {
    window.navi.navigate(SCREENS.INVOICE.DAILY_TEIBAN.FILTER.WORK, {
      id: work_type_id,
      setWorkTypeId,
      setWorkTypeName,
      backScreen: SCREENS.INVOICE.DAILY_TEIBAN.EDIT,
      type: selectWorkType.mid,
    });
  };

  /**小分類仕事フィルター画面に遷移 */
  const handleOnPressWorkTypeSmall = () => {
    if (work_type_id <= 0) {
      alert("中分類仕事が選択されていません。");
      return;
    }

    window.navi.navigate(SCREENS.INVOICE.DAILY_TEIBAN.FILTER.WORK, {
      id: work_type_small_id,
      parentId: work_type_id,
      setWorkTypeSmallId,
      setWorkTypeSmallName,
      backScreen: SCREENS.INVOICE.DAILY_TEIBAN.EDIT,
      type: selectWorkType.small,
    });
  };

  /**
   * 単価、作業、細工種別の自動入力を切り替えます。
   *
   * @param {boolean} isAuto - 自動入力を行うか否かを指定します。
   * @return {void} 戻り値はありません。
   */
  const setAutoInput = (isAuto: boolean) => {
    setAutoInputUnitPrice(isAuto);
    setAutoInputWork(isAuto);
    setAutoInputWorkTypeSmall(isAuto);
  };

  /**中分類仕事を履歴より設定する */
  const handleOnSelectedWorkTypeMid = (v: WorkType) => {
    setAutoInput(true);
    setWorkTypeId(v.id);
    setWorkTypeName(v.name);
  };

  //小分類仕事を履歴より設定する
  const handleOnSelectedWorkTypeSmall = (v: WorkType) => {
    setAutoInput(true);
    setWorkTypeSmallId(v.id);
    setWorkTypeSmallName(v.name);
  };

  //仕事を履歴より設定する
  const handleOnSelectedWork = (v: { name: string }) => {
    setAutoInput(true);
    setWork(v.name);
  };

  //単価を履歴より設定する
  const handleOnSelectedUnitPrice = (v: { name: string }) => {
    setAutoInput(true);
    setUnitPrice(v.name);
  };

  //中分類仕事の履歴を取得する
  const fetchWorkTypeMidHistory = async () => {
    // initWorkType();
    if (from_org === 0 || to_org === 0) return;
    setIsLoadingWorkTypeMidHistory(true);
    const data = await WorkTypeModel.fetchMidHistory(from_org, to_org);

    setWorkTypeHistory(data);

    //新規作成時
    if (!isEdit()) {
      if (data.length > 0) {
        const first = data[0];
        //小分類に値を入れる
        setWorkTypeId(first.id);
        setWorkTypeName(first.name);
      } else {
        setWorkTypeSmallId(0);
        setWorkTypeSmallName("");
      }
    }

    setIsLoadingWorkTypeMidHistory(false);
  };

  //小分類仕事の履歴を取得する
  const fetchWorkTypeSmallHistory = async () => {
    // initWorkTypeSmall();
    if (from_org === 0 || to_org === 0 || work_type_id === 0) return;
    setIsLoadingWorkTypeSmallHistory(true);
    const data = await WorkTypeModel.fetchSmallHistory(
      from_org,
      to_org,
      work_type_id
    );
    setWorkTypeSmallHistory(data);
    setIsLoadingWorkTypeSmallHistory(false);

    //新規作成時 自動入力可能時
    if (!isEdit() && autoInputWorkTypeSmall) {
      if (data.length > 0) {
        const first = data[0];
        //小分類に値を入れる
        setWorkTypeSmallId(first.id);
        setWorkTypeSmallName(first.name);
      } else {
        setWorkTypeSmallId(0);
        setWorkTypeSmallName("");
      }
    }
  };

  /**仕事(内容)の履歴を取得する */
  const fetchWorkHistory = async (params?: {
    from_org: number;
    to_org: number;
    work_type_id: number;
    work_type_small_id?: number;
  }) => {
    const fromOrg = params?.from_org ?? from_org;
    const toOrg = params?.to_org ?? to_org;
    const workTypeId = params?.work_type_id ?? work_type_id;
    const workTypeSmallId = params?.work_type_small_id ?? work_type_small_id;

    if (
      fromOrg === 0 ||
      toOrg === 0 ||
      workTypeId === 0 ||
      workTypeSmallId == 0
    ) {
      setWorkHistory([]);
      setWork("");
      return;
    }
    setIsLoadingWorkHistory(true);
    const data = await InvoiceModel.fetchWorkHistory({
      to_org_id: toOrg,
      from_org_id: fromOrg,
      work_type_id: workTypeId,
      work_type_small_id: workTypeSmallId,
    });
    setWorkHistory(data);

    //新規作成時 (自動入力可能時)
    if (!isEdit() && autoInputWork) {
      if (data.length > 0) {
        //一番最新の仕事内容を入力欄に入れる。

        const first = data[0];
        setWork(first.name);
      } else {
        setWork("");
      }
    }

    setIsLoadingWorkHistory(false);
  };

  /**中分類仕事を履歴より設定する */
  const handleOnSelectedToOrg = (v: Organisations | WorkType) => {
    setAutoInput(true);
    setToOrgName(v.name);
    setToOrg(v.id);
  };

  /**小分類仕事のDBを取得する */
  const fetchWorkTypeSmallDB = async () => {
    if (from_org === 0 || to_org === 0 || work_type_id === 0) return;
    setIsLoadingWorkTypeSmallDB(true);
    const data = await WorkTypeModel.fetchSmallDB(
      from_org,
      to_org,
      work_type_id
    );
    setWorkTypeSmallDB(data);
    setIsLoadingWorkTypeSmallDB(false);
  };

  /**
   * 単価の履歴を取得する
   * @param params
   * @returns
   */
  const fetchUnitPriceHistory = async (params?: {
    from_org: number;
    to_org: number;
    work_type_id: number;
    work_type_small_id?: number;
    work?: string;
  }) => {
    setUnitPriceHistory([]);
    const fromOrg = params?.from_org ?? from_org;
    const toOrg = params?.to_org ?? to_org;
    const workTypeId = params?.work_type_id ?? work_type_id;
    const workTypeSmallId = params?.work_type_small_id ?? work_type_small_id;
    const workMemo = params?.work ?? work;

    if (
      fromOrg === 0 ||
      toOrg === 0 ||
      workTypeId === 0 ||
      workTypeSmallId === 0 ||
      workMemo === ""
    ) {
      setUnitPrice("");
      setUnitPriceHistory([]);
      return;
    }
    setIsLoadingUnitPriceHistory(true);
    const data = await InvoiceModel.fetchUnitPriceHistory({
      to_org_id: toOrg,
      from_org_id: fromOrg,
      work_type_id: workTypeId,
      work_type_small_id: workTypeSmallId,
      work: workMemo,
    });
    setUnitPriceHistory(data);
    //新規作成時 自動入力可能時
    if (!isEdit() && autoInputUnitPrice) {
      if (data.length > 0) {
        //一番最新の単価を入力欄に入れる。
        const first = data[0];
        setUnitPrice(first.name);
      } else {
        setUnitPrice("");
      }
    }

    setIsLoadingUnitPriceHistory(false);
  };

  function closeDeleteModal(): void {
    setIsShowDeleteModal(false);
  }

  /**中分類仕事のDBを取得する */
  const fetchWorkTypeMidDB = async () => {
    if (from_org === 0 || to_org === 0) return;

    setIsLoadingWorkTypeMidDB(true);
    const data = await WorkTypeModel.fetchMidDB(from_org, to_org);

    setWorkTypeMidDB(data);

    setIsLoadingWorkTypeMidDB(false);
  };

  /**請求先部門の履歴を取得する */
  const fetchToOrgHistory = async () => {
    setToOrgHistory([]);

    if (from_org === 0) {
      return;
    }

    const data = await OrganisationsModel.fetchToOrgHistory({
      fromOrgId: from_org,
    });

    setToOrgHistory(data);

    //新規作成時
    if (!isEdit()) {
      if (data.length > 0) {
        const first = data[0];
        //小分類に値を入れる
        setToOrg(first.id);
        setToOrgName(first.name);
      } else {
        setToOrg(0);
        setToOrgName("");
      }
    }
  };

  /**
   * 作業メモのタップイベントを処理します。
   *
   * @param {WorkMemos} workMemo - 作業メモオブジェクト。
   * @return {void} なし
   */
  function handleOnPressWorkMemo(workMemo: WorkMemos) {
    if (
      workMemo.work_type_obj !== undefined &&
      (work_type_small_id != workMemo.id ||
        work_type_small_name != workMemo.work_type_obj?.name)
    ) {
      setAutoInputWorkTypeSmall(false);
      setAutoInputWork(false);
      setAutoInputUnitPrice(false);
      setWorkTypeSmallId(workMemo.work_type_obj!.id);
      setWorkTypeSmallName(workMemo.work_type_obj?.name);
    }

    // 自動入力の設定
    setAutoInputWork(false);
    setAutoInputUnitPrice(false);

    setWork(workMemo.name);
    setUnitPrice(workMemo.unit_price.toString());
  }

  /**------------------------------------------------
   * useEffect
   * ------------------------------------------------
   * */

  /**■ 請求元(id)の変更 */
  useEffect(() => {
    if (from_org != 0) {
      fetchToOrgHistory();
    }
  }, [from_org]);

  /**■ 請求先(id)と請求元(id)の変更 */
  useEffect(() => {
    if (to_org != 0 && from_org != 0) {
      //最初のto_orgとfrom_orgの設定時には最終利用を取得しないようにする。
      if (isEnableLoadLast) {
        // fetchLastInvocie();
      } else {
        setIsEnableLoadLast(true);
      }
    }
    fetchWorkTypeMidDB();
    fetchWorkTypeMidHistory();
  }, [to_org, from_org]);

  /**■ 中分類仕事(id)の変更 */
  useEffect(() => {
    fetchWorkTypeSmallHistory();
    fetchWorkTypeSmallDB();
  }, [work_type_id]);

  /**■ 中分類仕事(id)と小分類仕事(id)の変更 */
  useEffect(() => {
    if (!isEnableLoadHistory) return;
    fetchWorkHistory();
  }, [work_type_small_id]);

  /**■ 仕事の変更 */
  useEffect(() => {
    if (!isEnableLoadHistory) return;
    fetchUnitPriceHistory();
  }, [work]);

  /**■ 初期 */
  useEffect(() => {
    if (isEdit()) {
      //編集の場合
      fetchById();
      return;
    }
    //新規の場合
    fetchDefault();
  }, []);

  return (
    <View style={styles.container}>
      <ScrollView
        style={{
          width: "100%",
          padding: 10,
        }}
      >
        <View
          style={{
            width: "100%",
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          {/* <NextAndBackButton
            onNext={handleOnNextTeiban}
            onPrevious={handleOnPreviousTeiban}
            previousDisabled={previousId === undefined || previousId === null}
            nextDisabled={nextId === undefined || nextId === null}
          /> */}
          {/* #############################
              請求先
            #################################
            */}
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 3,
            }}
          >
            <Text style={{}}>請求先</Text>
          </View>
          <ButtonWithDropdown
            dropdownTitle="履歴"
            value={to_org_name}
            style={{
              color: "black",
              fontWeight: "bold",
              fontSize: "1.3rem",
            }}
            onClick={handleOnPressToOrg}
            dropdownItems={toOrgHistory}
            isLoading={isLoadingWorkTypeMidHistory}
            onSelect={handleOnSelectedToOrg}
            errorMsg={errToOrg}
          />
          {/* #############################
            請求元
            #################################
            */}
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 3,
            }}
          >
            <Text style={{}}>請求元</Text>
          </View>
          <div className="mb-3">
            <InputGroup style={{ width: "100%" }}>
              <Form.Control
                placeholder="未選択"
                type="button"
                style={{
                  textAlign: "left",
                  color: "red",
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                }}
                value={from_org_name}
                aria-label="Text input with dropdown button"
                onClick={handleOnPressFromOrg}
              />
            </InputGroup>
          </div>
          <CommonErrorLabel title={errFromOrg} />
          {/* #############################
           中分類（仕事）
            #################################
            */}
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 3,
            }}
          >
            <Text style={{}}>中分類（仕事）</Text>
            <WorkMemoButton
              workTypeMidId={work_type_id}
              onPress={handleOnPressWorkMemo}
            />
          </View>
          <ButtonWithDropdown
            dropdownTitle="DB"
            value={work_type_name}
            onClick={handleOnPressWorkTypeMid}
            // onClickDB={handleOnPressWorkTypeMid}
            onSelectDB={handleOnPressWorkTypeMid}
            dropdownItems={workTypeMidHistory}
            // dropdownItemsDB={workTypeMidDB}
            isLoading={isLoadingWorkTypeMidHistory}
            onSelect={handleOnSelectedWorkTypeMid}
            errorMsg={errWorkType}
          />
          {/* #############################
           小分類（仕事
            #################################
            */}

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 3,
            }}
          >
            <Text style={{}}>小分類（仕事）</Text>
            <WorkMemoButton
              style={{ marginRight: 10 }}
              workTypeSmallId={work_type_small_id}
              onPress={handleOnPressWorkMemo}
            />
          </View>

          <ButtonWithDropdown
            dropdownTitle="DB"
            value={work_type_small_name}
            onClick={handleOnPressWorkTypeSmall}
            // onClickDB={handleOnPressWorkTypeMid}
            // onSelectDB={handleOnPressWorkTypeMid}
            dropdownItems={workTypeSmallHistory}
            // dropdownItemsDB={workTypeMidDB}
            isLoading={isLoadingWorkTypeSmallHistory}
            onSelect={handleOnSelectedWorkTypeSmall}
            errorMsg={errWorkTypeSmall}
          />
          {/* #############################
           仕事
            #################################
            */}
          <Text style={{}}>仕事</Text>
          <InputWithDoropdown
            dropdownTitle="履歴"
            value={work}
            onChange={handleOnChangeWork}
            dropdownItems={workHistory}
            isLoading={isLoadingWorkHistory}
            onSelect={handleOnSelectedWork}
            errorMsg={errWork}
            onPressCopy={() => {
              if (confirm("小分類（仕事）の内容をコピーしますか？")) {
                setWork(work_type_small_name);
              }
            }}
          />
          {/* #############################
           単価／時給
            #################################
            */}
          <Text>単価／時給</Text>
          <InputWithDoropdown
            inputType={InputType.NUMBER}
            dropdownTitle="履歴"
            value={unit_price}
            onChange={setUnitPrice}
            dropdownItems={unitPriceHistory}
            isLoading={isLoadingUnitPriceHistory}
            onSelect={handleOnSelectedUnitPrice}
          />
          {renderErrorLabel(errUnitPrice)}
          {/* #############################
            請求要約
            #################################
            */}

          <InvoiceSummary
            to_org_name={to_org_name}
            from_org_name={from_org_name}
            work_type_name={work_type_name}
          />
          <Button
            title={"確定"}
            type="outline"
            titleStyle={{ color: "#fff" }}
            buttonStyle={{
              backgroundColor: CommonColor.Normal.Blue,
            }}
            style={{ marginTop: 30 }}
            onPress={onRegister}
          />
          {props.route.params.id > 0 && (
            <Button
              title="削除する"
              titleStyle={{ fontSize: 15 }}
              disabled={false}
              buttonStyle={{
                height: 30,
                backgroundColor: "#f00",
                marginTop: 30,
              }}
              onPress={() => {
                setIsShowDeleteModal(true);
              }}
            />
          )}
        </View>
      </ScrollView>
      {isLoading && <CoverIndicator />}
      <CommonModal
        isShow={isShowDeleteModal}
        body="選択済みの項目を削除しますか？"
        actionTitle="削除する"
        onPress={onDelete}
        onDismiss={closeDeleteModal}
        buttonVariant="danger"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
  },
  itemViewStyle: {
    marginBottom: 12,
  },
  itemStyle: {
    fontWeight: "bold",
    fontSize: 17,
  },
  itemStyleEdit: {
    fontWeight: "bold",
    color: "#333",
    fontSize: 17,
  },
});
