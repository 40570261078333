import { FontAwesome } from "@expo/vector-icons";
import React from "react";
import { TouchableOpacity, Text, StyleSheet } from "react-native";

type CommonButtonProps = {
  onPress?: () => void;
  title?: string;
  styleButton?: object;
  styleText?: object;
  icon?: string;
  iconStyle?: { size?: number; color?: string };
};

/**
 * 基本のボタン
 * @param props
 * @returns
 */
export function CommonButton(props: CommonButtonProps) {
  const buttonStyle = StyleSheet.compose(styles.button, props.styleButton);
  const textStyle = StyleSheet.compose(styles.buttonText, props.styleText);

  return (
    <TouchableOpacity onPress={props.onPress} style={buttonStyle}>
      {props.icon && (
        <FontAwesome
          name={props.icon}
          size={props.iconStyle?.size ?? 24}
          color={props.iconStyle?.color ?? "#FFF"}
        />
      )}
      {props.title && <Text style={textStyle}>{props.title}</Text>}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  button: {
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    marginHorizontal: 5,
    paddingHorizontal: 3,
  },
  buttonText: {
    color: "#2B92CD",
    fontSize: 18,
  },
});
