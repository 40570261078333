import FontAwesome from "@expo/vector-icons/build/FontAwesome";
import React from "react";
import { View, TouchableOpacity, Text } from "react-native";
import SelectMonth from "../filter/SelectMonth";

export function CommonReload() {
  return (
    <TouchableOpacity
      onPress={() => {
        window.functions.reloadList();
      }}
      style={{ paddingLeft: 10, paddingRight: 10 }}
    >
      <FontAwesome name="repeat" size={28} color="#aaa" />
    </TouchableOpacity>
  );
}
