import moment from "moment";
import { Invoice } from "../models/InvoiceModel";

export interface ValidateInvoice {
  unit_price: string;
  quantity?: string;
  to_org: number;
  from_org: number;
  work_type_id: number;
  work_type_small_id: number;
  work_type_small_name: string;
}

export interface ValidateInvoiceErrorMsg {
  unit_price: string;
  quantity: string;
  to_org: string;
  from_org: string;
  work_type: string;
  work_type_small: string;
}

/**
 * 請求の入力チェック
 * @param arg
 * @returns invoice
 */
export function validateInvoice(arg: ValidateInvoice): {
  result: boolean;
  msg: ValidateInvoiceErrorMsg;
  errorList: string[];
} {
  let flag = true;
  let errorMsg: ValidateInvoiceErrorMsg = {
    unit_price: "",
    quantity: "",
    to_org: "",
    from_org: "",
    work_type: "",
    work_type_small: "",
  };
  const errorList: string[] = [];

  //請求先
  if (arg.to_org == 0) {
    errorList.push("請求先");
    errorMsg.to_org = "選択してください";
    flag = false;
  }

  //請求元
  if (arg.from_org == 0) {
    errorList.push("請求元");
    errorMsg.from_org = "選択してください";
    flag = false;
  }

  //仕事種別(中分類)
  if (arg.work_type_id == 0) {
    errorList.push("中分類(仕事)");
    errorMsg.work_type = "選択してください";
    flag = false;
  }

  //仕事種別(小分類)
  if (arg.work_type_small_id == 0 && arg.work_type_small_name == "") {
    errorList.push("小分類(仕事)");
    errorMsg.work_type_small = "設定してください";
    flag = false;
  }

  //単価

  if (!isFinite(parseInt(arg.unit_price))) {
    errorMsg.unit_price = "数値で入力してください";
    errorList.push("単価／時給");
    flag = false;
  } else if (arg.unit_price === "") {
    errorMsg.unit_price = "入力してください";
    errorList.push("単価／時給");
    flag = false;
  }

  if (arg.quantity) {
    //数量
    if (!isFinite(parseInt(arg.quantity))) {
      errorList.push("数量/時間");
      errorMsg.quantity = "数値で入力してください";
      flag = false;
    } else if (arg.quantity === "") {
      errorList.push("数量/時間");
      errorMsg.quantity = "入力してください";
      flag = false;
    } else if (parseInt(arg.quantity) <= 0) {
      errorList.push("数量/時間");
      errorMsg.quantity = "1以上で入力してください";
      flag = false;
    }
  }

  return { result: flag, msg: errorMsg, errorList: errorList };
}

/**
 * 現在の請求がが予定状態にあるかどうかを判定します。
 *
 * @return {boolean} 請求状態が確認中で、請求日が将来の日付である場合はtrue、それ以外の場合はfalseを返します。
 */
export const isInPlannedStatus = (invoice: Invoice) => {
  return invoice.state == 0 && moment(invoice.billing_date).diff(moment()) > 0;
};
