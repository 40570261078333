import React from "react";

import {
  Button,
  Dropdown,
  DropdownButton,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import CommonInput, { InputType } from "./CommonInput";
import CommonErrorLabel from "./CommonErrorLabel";

type InputWithDoropdownProps = {
  inputType?: InputType;
  dropdownTitle?: string;
  dropdownItems?: any[];
  dropdownTitleDB?: string;
  dropdownItemsDB?: any[];
  value?: string;
  onChange?: (v: string) => void;
  onSelect?: (v: any) => void;
  onPressCopy?: () => void;
  isLoading?: boolean;
  isLoadingDB?: boolean;
  errorMsg?: string;
};

const InputWithDoropdown: React.FC<InputWithDoropdownProps> = (props) => {
  /**
   * 入力フォームでの文字入力
   * @param v
   */
  const handleChange = (v: string) => {
    if (props.onChange) {
      props.onChange(v);
    }
  };

  /**
   * 入力エラーの確認
   * @returns
   */
  const isError = () => {
    if (props.errorMsg && props.errorMsg.length > 0) return true;
    return false;
  };

  /**
   * 選択イベント
   * @param item
   */
  const handleDropdownItemClick = (item: any) => {
    if (props.onSelect) {
      props.onSelect(item);
    }
  };

  /**
   * 選択リストアイテム
   * @returns
   */
  const renderDropdownItems = () => {
    if (props.dropdownItems && props.dropdownItems.length > 0) {
      return props.dropdownItems.map((item, index) => (
        <Dropdown.Item
          key={index}
          onClick={() => handleDropdownItemClick(item)}
        >
          {item.name}
        </Dropdown.Item>
      ));
    } else {
      return <Dropdown.Item disabled>無し</Dropdown.Item>;
    }
  };

  //DB用ドロップダウン
  const renderDropdownItemsDB = () => {
    if (props.dropdownItemsDB && props.dropdownItemsDB.length > 0) {
      return props.dropdownItemsDB.map((item, index) => (
        <Dropdown.Item
          key={index}
          onClick={() => handleDropdownItemClick(item)}
        >
          {item.name}
        </Dropdown.Item>
      ));
    } else {
      return <Dropdown.Item disabled>無し</Dropdown.Item>;
    }
  };

  return (
    <div className="mb-1">
      <InputGroup style={{ width: "100%" }}>
        <CommonInput
          type={props.inputType ?? InputType.TEXT}
          value={props.value ?? ""}
          placeholder="未選択"
          onChange={handleChange}
        />
        {props.onPressCopy && (
          <Button
            variant={"primary"}
            id="button-addon2"
            onClick={props.onPressCopy}
          >
            {"複製"}
          </Button>
        )}

        <DropdownButton
          className="dropdown-filter"
          variant="outline-secondary"
          title={props.dropdownTitle}
          id="input-group-dropdown-2"
          align="end"
        >
          {props.isLoading ? (
            <Dropdown.Item disabled>
              <Spinner animation="border" size="sm" />
            </Dropdown.Item>
          ) : (
            renderDropdownItems()
          )}
        </DropdownButton>
        {props.dropdownItemsDB && (
          <DropdownButton
            className="dropdown-filter"
            variant="outline-secondary"
            title={props.dropdownTitleDB}
            id="input-group-dropdown-2"
            align="end"
          >
            {props.isLoading ? (
              <Dropdown.Item disabled>
                <Spinner animation="border" size="sm" />
              </Dropdown.Item>
            ) : (
              renderDropdownItemsDB()
            )}
          </DropdownButton>
        )}
      </InputGroup>
      {isError() && <CommonErrorLabel title={props.errorMsg} />}
    </div>
  );
};

export default InputWithDoropdown;
