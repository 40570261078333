const baseUrl_RELEASE = "https://xs591901.xsrv.jp/api";
// const baseUrl_DEVELOP = "http://localhost/api";
const baseUrl_DEVELOP = "http://192.168.128.54/api";
const baseUrl_TRN = "https://tran-it.biz/foster/laravel/public/api";
const baseUrl_TRN_HTTPS = "https://tran-it.biz/foster/laravel/public/api";

const baseUrl_DEVELOP_HONBU = "http://tran-it.biz/foster/honbu-invoice";
const baseUrl_DEVELOP_ADMIN = "http://tran-it.biz/foster/admin-invoice";

const baseUrl_RELEASE_HONBU = "https://honbu.seikyu.work";
const baseUrl_RELEASE_ADMIN = "https://admin.seikyu.work";

export const AppModeType = {
  RELEASE: 1,
  DEVELOP: 2,
  TRN: 3,
  TRN_HTTPS: 4,
  TRN_AUTH0: 5,
};

export type AppModeType = (typeof AppModeType)[keyof typeof AppModeType];

/**
 * 動作モード
 */
// const appModeType: AppModeType = AppModeType.TRN;
// const appModeType: AppModeType = AppModeType.DEVELOP;
// const appModeType: AppModeType = AppModeType.TRN_HTTPS;
const appModeType: AppModeType = AppModeType.RELEASE;
const appVersion = "2024.09.26.01";

export function APP_VER_COLOR() {
  switch (appModeType) {
    case AppModeType.DEVELOP:
    case AppModeType.TRN:
    case AppModeType.TRN_HTTPS:
    case AppModeType.TRN_AUTH0:
      return "#F00";

    case AppModeType.RELEASE:
      return "#666";

    default:
      return "#666";
  }
}

//アプリバージョン
export function APP_VERSION() {
  let ver = "ver." + appVersion;

  switch (appModeType) {
    case AppModeType.DEVELOP:
      ver = ver + " [DEVELOP]";
      break;
    case AppModeType.TRN:
      ver = ver + " [TRN]";
      break;
    case AppModeType.TRN_HTTPS:
      ver = ver + " [TRN_HTTPS]";
      break;
    case AppModeType.TRN_AUTH0:
      ver = ver + " [TRN_AUTH0]";
      break;
    case AppModeType.RELEASE:
      break;
    default:
      break;
  }

  return ver;
}

//アプリバージョン
export function APP_VERSION_DATA() {
  return appVersion;
}

//
/**
 * プロジェクトの相対パスを設定する。
 * （トランソニックサーバーはpublic rootにプロジェクトを配置できない為）
 * 末尾の/は不要
 * @returns
 */
export function linkingUrl() {
  switch (appModeType) {
    case AppModeType.DEVELOP:
      return "/";
    case AppModeType.TRN:
      return "/foster/invoice/";
    case AppModeType.TRN_HTTPS:
      return "/foster/invoiceHttps";
    case AppModeType.TRN_AUTH0:
      return "/foster/invoiceAuth0";
    case AppModeType.RELEASE:
      return "/";
    default:
      return "/";
  }
}

//apiのドメインを設定する。
export function baseURL() {
  let baseUrl: string;

  switch (appModeType) {
    case AppModeType.DEVELOP:
      baseUrl = baseUrl_DEVELOP;
      break;
    case AppModeType.TRN:
      baseUrl = baseUrl_TRN;
      break;
    case AppModeType.TRN_AUTH0:
    case AppModeType.TRN_HTTPS:
      baseUrl = baseUrl_TRN_HTTPS;
      break;
    case AppModeType.RELEASE:
      baseUrl = baseUrl_RELEASE;
      break;
    default:
      throw new Error("Invalid app mode type");
  }

  return baseUrl;
}

/**
 * auth0の設定値
 * @returns setting
 */
export function Auth0Setting(): { domain: string; clientId: string } {
  let domain: string;
  let clientId: string;

  switch (appModeType) {
    case AppModeType.DEVELOP:
      domain = "dev-luy4w0choa3fshfj.us.auth0.com";
      clientId = "HyevGF9sopt3ekD0npIMtIbvI5pWm4uD";
      break;
    case AppModeType.TRN_AUTH0:
    case AppModeType.TRN_HTTPS:
    case AppModeType.TRN:
      domain = "dev-luy4w0choa3fshfj.us.auth0.com";
      clientId = "HyevGF9sopt3ekD0npIMtIbvI5pWm4uD";
      break;
    case AppModeType.RELEASE:
      domain = "";
      clientId = "";
      break;
    default:
      throw new Error("Invalid app mode type");
  }

  return { domain: domain, clientId: clientId };
}

/**
 * 本部・管理のURL
 * @returns
 */
export function LinkURL() {
  let honbu: string;
  let admin: string;

  switch (appModeType) {
    case AppModeType.RELEASE:
      honbu = baseUrl_RELEASE_HONBU;
      admin = baseUrl_RELEASE_ADMIN;
      break;

    default:
      honbu = baseUrl_DEVELOP_HONBU;
      admin = baseUrl_DEVELOP_ADMIN;
      break;
  }

  return { honbu: honbu, admin: admin };
}
