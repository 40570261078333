import * as React from "react";
import { useState, useEffect, Fragment, useContext } from "react";
import { CheckBox, Divider, Text } from "react-native-elements";
import {
  StyleSheet,
  View,
  ActivityIndicator,
  TouchableOpacity,
  FlatList,
} from "react-native";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import formatNumber from "format-number";
import { LocalStorageKey } from "../../models/LocalStorage";
import { currentYM } from "../../util/DateUtil";
import { FloatingButton } from "../../components/common/FloatingButton";
import { CommonColor } from "../../constants/Colors";
import { Invoice, InvoiceModel } from "../../models/InvoiceModel";
import {
  OrgOption,
  allCompanyOption,
  departmentOption,
} from "../../components/filter/CommonBumonFilter";
import InvoiceListItem from "../../components/invoice/InvoiceListItem";
import SelectOrgFilter from "../../components/filter/SelectOrgFilter";
import {
  Col,
  Collapse,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import CommonModal from "../../components/modal/CommonModal";
import { SCREENS } from "../../constants/Screens";
import YearMonthLabel from "../../components/common/YearMonth";
import CoverIndicator from "../../components/common/CoverIndicator";
import CommonListEmpty from "../../components/common/CommonListEmpty";
import {
  loadSelectedOptionByCompany,
  orgOptionsToParam,
} from "../../util/OrgUtil";
import { APP_VERSION_DATA } from "../../constants/Setting";
import { get } from "../../util/Api";
import SortChangeButton from "../../components/invoice/SortChangeButton";
import {
  LocalStorageKeys,
  LocalStorageService,
} from "../../services/LocalStorageService.";
import { selectedContext, tabContext } from "../../App";
import { TabList } from "../../navigation/BottomTabNavigator";

const numberFormat = formatNumber({
  round: 0,
});

/**
 * Invoices screen
 * @param props
 * @returns
 */
export default function InvoiceScreen(props: any) {
  /**navigation */
  const navigation = useNavigation();

  const selectedMyOrganisations = loadSelectedOptionByCompany(
    LocalStorageKey.INVOICE
  );

  // 並び替えタイプ（LocalStorage）を取得する
  // 並び替えタイプは請求一覧のテーブル、および、請求総計の並び替えボタンに設定する
  // 並び替えタイプは、LocalStorageに保存される
  // 並び替えタイプは、true（昇順）もしくはfalse（降順）で保存される
  const loadSortType =
    LocalStorageService.get(LocalStorageKeys.SORT.INVOICE) === true;

  const loadIsOnlyPending =
    LocalStorageService.get(LocalStorageKeys.FILTER.INVOICE_ONLY_PENDING) ===
    true;

  //タブがアクティブになっている。
  const { tabIndex } = useContext(tabContext);
  const isActive = tabIndex === TabList.Invoice.index;

  /**一覧関連******************************************* */
  /**請求一覧 */
  const [invoices, setInvoices] = useState<{ [key: string]: Invoice[] }>({
    [currentYM()]: [],
  });
  /**請求一覧 読み込み中*/
  const [isFetching, setIsFetching] = useState(false);
  /**請求一覧 読み込みページ*/
  const [page, setPage] = useState<{ [key: string]: number }>({
    [currentYM()]: 0,
  });
  /**請求一覧 最終ページ(初期値は-1)*/
  const [last_page, setLastPage] = useState<{ [key: string]: number }>({
    [currentYM()]: -1,
  });
  /**請求一覧 Api読み込み中*/
  const [isLoading, setIsLoading] = useState<{ [key: string]: boolean }>({
    [currentYM()]: true,
  });
  /**フィルタ制御関連****************************************/
  const [fromOrgOptions, setFromOrgOptions] = useState<OrgOption[]>(
    selectedMyOrganisations.fromOrgOptions ?? [departmentOption]
  );
  const [toOrgOptions, setToOrgOptions] = useState<OrgOption[]>(
    selectedMyOrganisations.toOrgOptions ?? [allCompanyOption]
  );

  /**年月*/
  const { month } = useContext(selectedContext);
  //確認中合計
  const [total, setTotal] = useState<{ [key: string]: number }>({
    [currentYM()]: 0,
  });
  //受領合計(確認済み)
  const [total_k, setTotal_k] = useState<{ [key: string]: number }>({
    [currentYM()]: 0,
  });
  //絞り込み条件：確認中のみ
  const [isOnlyPending, setIsOnlyPending] =
    useState<boolean>(loadIsOnlyPending);
  // ソート(昇順)フラグ
  const [isAsc, setIsAsc] = useState(loadSortType);
  /**画面制御関連****************************************/

  /**請求削除確認用モーダル表示フラグ*/
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  /**削除チェックを付けた請求*/
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});
  /**全画面インジケーターの表示フラグ */
  const [isShowCoverIndicator, setIsShowCoverIndicator] =
    useState<boolean>(true);
  /**フィルタの開閉フラグ*/
  const [isCollapsed, setIsCollapsed] = useState(false);
  /**フィルタの開閉 */
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  /**初回読み込み */
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  /**一件以上 削除チェックが付いている */
  const hasChecked = Object.values(checkedItems).includes(true);

  /**
   * 請求レコードを取得する。
   * @param billing_ym
   * @param isInit
   * @returns
   */
  const loadNext = async (
    billing_ym: string = month,
    isInit: boolean = false
  ) => {
    setIsLoading((pre) => ({ ...pre, [month]: true }));

    //選択中の年月のページが未定義の場合は0とする。
    let current_page = isInit ? 0 : page[billing_ym] ?? 0;

    if (current_page == last_page[billing_ym]) return;

    const fromOrgParams = orgOptionsToParam(fromOrgOptions);
    const toOrgParams = orgOptionsToParam(toOrgOptions);

    try {
      const res = await InvoiceModel.fetchInvoiceList({
        page: current_page + 1,
        billing_ym: billing_ym,
        from_filter_types: fromOrgParams.filter_types,
        from_org_ids: fromOrgParams.org_ids,
        to_filter_types: toOrgParams.filter_types,
        to_org_ids: toOrgParams.org_ids,
        isAsc: isAsc,
        isOnlyPending: isOnlyPending,
      });
      if (isInit) {
        setInvoices((pre) => ({ ...pre, [billing_ym]: res.data }));
      } else {
        const data = invoices[billing_ym].concat(res.data);
        setInvoices((pre) => ({ ...pre, [billing_ym]: data }));
      }

      setPage((pre) => ({ ...pre, [billing_ym]: res.current_page }));
      setLastPage((pre) => ({ ...pre, [billing_ym]: res.last_page }));

      setIsLoading((pre) => ({ ...pre, [month]: false }));
    } catch (error) {
      window.functions.logout;
    }
  };

  /**
   * 集計を取得する
   * @param ym
   */
  const getTotal = async (ym: string = month) => {
    const fromOrgParams = orgOptionsToParam(fromOrgOptions);
    const toOrgParams = orgOptionsToParam(toOrgOptions);
    const res = await InvoiceModel.getTotalByMonth({
      billing_ym: ym,
      from_filter_types: fromOrgParams.filter_types,
      from_org_ids: fromOrgParams.org_ids,
      to_filter_types: toOrgParams.filter_types,
      to_org_ids: toOrgParams.org_ids,
    });
    setTotal((pre) => ({ ...pre, [month]: res.total }));
    setTotal_k((pre) => ({ ...pre, [month]: res.total_k }));
  };

  /**
   * 再読み込み処理
   */
  const onReload = async (ym?: string) => {
    setIsShowCoverIndicator(true);
    setInvoices((pre) => ({ ...pre, [month]: [] }));

    setPage((pre) => ({ ...pre, [month]: 0 }));
    setLastPage((pre) => ({ ...pre, [month]: -1 }));

    setTotal((pre) => ({ ...pre, [month]: 0 }));
    setTotal_k((pre) => ({ ...pre, [month]: 0 }));

    getTotal(month);
    await loadNext(month, true);
    setIsShowCoverIndicator(false);
    setIsFirstLoad(false);
  };

  /**
   * 全ての請求に☑をつける
   * @returns
   */
  const handleCheckAllItems = () => {
    const updatedCheckedItems: Record<string, boolean> = {};
    for (const item of invoices[month]) {
      //所属部門のみ
      if (item.own) {
        updatedCheckedItems[item.id] = true;
      }
    }
    setCheckedItems(updatedCheckedItems);
  };
  /**
   * 請求の☑を外す
   * @returns
   */
  const handleUncheckAll = () => {
    setCheckedItems({});
  };
  /**
   * 請求の☑変更
   * @returns
   */
  const handleCheckboxToggle = (itemId: string) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  /**
   * リスト選択イベント
   * @param invoice
   */
  const onhandleListSelect = (invoice: Invoice) => {
    const fromOrgParams = orgOptionsToParam(fromOrgOptions);
    const toOrgParams = orgOptionsToParam(toOrgOptions);

    const eee: number = 0;
    const aaa = eee.toString();

    //フィルタの情報を含め詳細画面に遷移する。
    window.navi.navigate(SCREENS.INVOICE.INVOICE_DETAIL, {
      id: invoice.id,
      month: month,
      from_filter_types: fromOrgParams.filter_types,
      from_org_ids: fromOrgParams.org_ids,
      to_filter_types: toOrgParams.filter_types,
      to_org_ids: toOrgParams.org_ids,
      nagae: "nagae000",
    });
  };

  /**
   * 請求レコードの表示
   * @param param0
   * @returns
   */
  const renderItem = ({ item }: any) => {
    return (
      <Fragment key={item.id}>
        <InvoiceListItem
          item={item}
          isChecked={checkedItems[item.id] || false}
          onCheck={handleCheckboxToggle}
          onPress={onhandleListSelect}
        />
      </Fragment>
    );
  };

  /**
   * 削除モーダルを展開する
   */
  const openDeleteModal = () => {
    setIsShowDeleteModal(true);
  };

  /**
   * idを指定し請求を一括削除する
   */
  const deleteInoices = async () => {
    setIsShowDeleteModal(false);
    setIsLoading((pre) => ({ ...pre, [month]: true }));

    month;
    try {
      const ids = Object.keys(checkedItems)
        .filter((key) => checkedItems[key])
        .map(Number);

      await InvoiceModel.deleteByIds(ids);
      handleUncheckAll();
      onReload();
    } catch (error) {}
  };

  /**
   * 全てのモーダルを閉じる
   */
  const closeModal = () => {
    setIsShowDeleteModal(false);
  };

  /**
   * 詳細画面で更新した請求レコードを更新する
   * @param id
   */
  async function _updateItem(id: number) {
    if (invoices[month]) {
      let items: Invoice[] = invoices[month];
      const res = await InvoiceModel.item(id);
      const list = items.map((e) => {
        if (e.id == res.id) {
          return res;
        }
        return e;
      });
      setInvoices((pre) => ({ ...pre, [month]: list }));
    }
  }

  /**
   * 詳細画面で削除した請求レコードを除去する
   * @param id
   */
  function _deleteItem(id: number) {
    let items: Invoice[] = invoices[month].slice();
    for (let i = 0; i < items.length; i++) {
      if (items[i].id == id) {
        items.splice(i, 1);
        break;
      }
    }
    window.invoices = items;
    setInvoices((pre) => ({ ...pre, [month]: items }));
  }

  /**グローバル関数を設定する */
  const setWindowFunctions = () => {
    window.functions.reloadList = () => {
      if (isShowCoverIndicator) return;
      //リロード時に請求日を削除する。
      localStorage.removeItem(LocalStorageKey.BILLING_DATE);
      onReload();
    };

    if (window.reloadFlag) {
      window.reloadFlag = false;
      onReload();
    }

    if (window.reloadFlagInvoiceList) {
      //小分類を変更した場合
      window.reloadFlagInvoiceList = false;
      if (
        confirm("仕事(小分類)が変更されています。請求一覧を再取得しますか？")
      ) {
        onReload();
      }
    }
  };

  // アプリのバージョンチェック
  async function app_version_check() {
    const url = "/app_version_check";
    let response = await get(url, { app_version: APP_VERSION_DATA() });
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    if (response.data) {
      alert(
        "バージョンが古い可能性がございます。ブラウザのキャッシュをクリアしてください。"
      );
    }
  }

  /**
   * 確認中のみボタン
   */
  const onhandleIsPendingChange = () => {
    //確認中のみにチェックを入れたら、保留にもチェックを入れる
    setIsOnlyPending(!isOnlyPending);

    LocalStorageService.set(
      LocalStorageKeys.FILTER.INVOICE_ONLY_PENDING,
      !isOnlyPending
    );
  };

  /**********************************************
   * useEffect
   * **********************************************
   */
  //リストの描画が出来ない場合があるので明示的に再描画させる。
  useEffect(() => {}, [invoices]);

  // useEffect(() => {
  //   onReload();
  // }, [isAsc]);

  //請求元・請求先変更時
  useEffect(() => {
    onReload();
  }, [toOrgOptions, fromOrgOptions, isAsc, isOnlyPending]);

  useEffect(() => {
    if (!isActive) return;
    if (isFirstLoad) return;
    handleUncheckAll();
    onReload();
  }, [month]);

  useEffect(() => {
    if (
      props.route.params &&
      props.route.params.hasOwnProperty("update_id") &&
      props.route.params.update_id > 0
    ) {
      _updateItem(props.route.params.update_id);
      navigation.navigate(SCREENS.INVOICE.INVOICE, {
        update_id: 0,
      });
      getTotal();
    }
    if (
      props.route.params &&
      props.route.params.hasOwnProperty("delete_id") &&
      props.route.params.delete_id > 0
    ) {
      _deleteItem(props.route.params.delete_id);
      navigation.navigate(SCREENS.INVOICE.INVOICE, {
        delete_id: 0,
      });
      getTotal();
    }
  }, [props.route.params]);

  // 抽出条件が変更されたらグローバル関数を更新する。
  useFocusEffect(() => {
    setWindowFunctions();
  });

  // 画面切り替え時にバージョンが違っていたらアラート表示する。
  useEffect(() => {
    app_version_check();
  }, [props.route.name]);

  return (
    <View style={styles.container}>
      <YearMonthLabel ym={month} />
      <Collapse in={!isCollapsed} className="w-100 px-3">
        <div>
          <div className="w-100">
            <SelectOrgFilter
              localStorageKey={LocalStorageKey.INVOICE}
              defaultFrom={fromOrgOptions}
              onChangeFrom={setFromOrgOptions}
              defaultTo={toOrgOptions}
              onChangeTo={setToOrgOptions}
            />
          </div>
          <Container>
            <Row>
              <Col xs={12} sm={6}>
                <div className="d-flex justify-content-center">
                  <InputGroup size="sm">
                    <InputGroup.Text id="basic-addon2">受領</InputGroup.Text>
                    <Form.Control
                      style={{ textAlign: "right" }}
                      value={numberFormat(total[month])}
                      disabled={true}
                    />
                    <InputGroup.Text>円</InputGroup.Text>
                  </InputGroup>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <div className="d-flex justify-content-center">
                  <InputGroup size="sm">
                    <InputGroup.Text id="basic-addon2">確認中</InputGroup.Text>
                    <Form.Control
                      style={{ textAlign: "right" }}
                      value={numberFormat(total_k[month])}
                      disabled={true}
                    />
                    <InputGroup.Text>円</InputGroup.Text>
                  </InputGroup>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Collapse>
      <Row style={{ width: "100%" }}>
        <Col xs={9} sm={10}></Col> {/* 空のColコンポーネントを追加 */}
        <Col
          xs={3}
          sm={2}
          className="d-flex align-items-center justify-content-end p-2"
        >
          <SortChangeButton
            isAsc={isAsc}
            onPress={setIsAsc}
            localStorageKey={LocalStorageKeys.SORT.INVOICE}
          />
          <TouchableOpacity
            style={{
              marginRight: 10,
              paddingHorizontal: 8,
              paddingVertical: 2,
              backgroundColor: CommonColor.Normal.Grey,
              borderRadius: 20,
            }}
            onPress={handleToggleCollapse}
          >
            <Text>{isCollapsed ? "展開" : "折りたたむ"}</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={{
              marginRight: 10,
              paddingHorizontal: 8,
              paddingVertical: 2,
              backgroundColor: CommonColor.Normal.Grey,
              borderRadius: 20,
            }}
            onPress={handleCheckAllItems}
          >
            <Text>全て選択</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              marginRight: 0,
              paddingHorizontal: 8,
              paddingVertical: 2,
              backgroundColor: CommonColor.Normal.Grey,
              borderRadius: 20,
            }}
            onPress={handleUncheckAll}
          >
            <Text>選択解除</Text>
          </TouchableOpacity>
        </Col>
      </Row>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <CheckBox
          containerStyle={{ paddingHorizontal: 10, paddingVertical: 1 }}
          checked={isOnlyPending}
          onPress={onhandleIsPendingChange}
          title="確認中のみ"
        />
      </View>
      <Divider />
      <FlatList
        style={{ width: "100%" }}
        keyExtractor={keyExtractor}
        data={invoices[month] ?? []}
        renderItem={renderItem}
        onRefresh={() => {
          setIsFetching(true);
        }}
        refreshing={isFetching}
        onEndReached={() => {
          if (!isLoading[month]) loadNext();
        }}
        onEndReachedThreshold={0.2}
        ListEmptyComponent={
          <CommonListEmpty isLoading={isShowCoverIndicator} />
        }
        ListFooterComponent={
          !isShowCoverIndicator &&
          page[month] !== last_page[month] &&
          invoices[month] !== undefined &&
          invoices[month].length > 0 &&
          isLoading[month] ? (
            <>
              <ActivityIndicator style={{ marginVertical: 10 }} />
            </>
          ) : (
            <View style={{ height: 100 }} />
          )
        }
      />
      <FloatingButton
        text="定番"
        style={{
          right: 100,
          backgroundColor: CommonColor.Normal.Green,
        }}
        onPress={() => {
          window.teibanSelectMonth = month;
          window.navi.navigate(SCREENS.INVOICE.INVOICE_TEIBAN_SELECT, {
            type: 0,
            month: month,
          });
        }}
      />
      <FloatingButton
        text={"今日" + "\n" + "仕事"}
        style={{
          right: 30,
          bottom: 70,
          backgroundColor: CommonColor.Normal.Green,
        }}
        onPress={() => {
          window.navi.navigate(SCREENS.INVOICE.DAILY_TEIBAN.LIST);
        }}
      />
      <FloatingButton
        style={{
          right: 30,
          backgroundColor: CommonColor.Normal.Blue,
        }}
        onPress={() => {
          window.navi.navigate(SCREENS.INVOICE.INVOICE_DETAIL, { id: 0 });
        }}
        icon="plus"
      />
      {hasChecked && (
        <FloatingButton
          icon="trash"
          style={{
            backgroundColor: CommonColor.Normal.Red,
          }}
          onPress={openDeleteModal}
        />
      )}
      <CommonModal
        isShow={isShowDeleteModal}
        body="全ての削除可能な請求を削除しますか？"
        actionTitle="削除する"
        onPress={deleteInoices}
        onDismiss={closeModal}
      />
      <CommonModal
        isShow={isShowDeleteModal}
        body="選択済みの項目を削除しますか？"
        actionTitle="削除する"
        onPress={deleteInoices}
        onDismiss={closeModal}
      />
      {isShowCoverIndicator && <CoverIndicator />}
    </View>
  );
}

function keyExtractor(item: any, index: any) {
  return item.id.toString();
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
});
