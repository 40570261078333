import { toast } from "react-toastify";

/**
 * 共通トースト
 * AuthLayoutを呼び出していれば使用できる
 *
 * @returns
 */
const NotificationToast = () => {
  const saved = () => {
    toast.success("登録しました。", {
      position: "top-right", //ポジションを指定。top-right, top-left, top-center, bottom-left, bottom-right, bottom-centerから選択
      autoClose: 3000, //設定したミリ秒経過時にclose defaultは5000
      closeOnClick: true, //クリックで消せるか否かを制御
      pauseOnHover: true, //ホバーするとミリ秒の進行が止まる
      draggable: true, //ドラッグしながら消すことができる
      theme: "light", //背景をlight,dark,coloredから選択
    });
  };
  const updated = () => {
    toast.info("更新しました。", {
      position: "top-right",
      autoClose: 3000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
  };
  const deleted = () => {
    toast.error("削除しました。", {
      position: "top-right",
      autoClose: 3000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
  };
  //任意のエラーメッセージを表示できるように引数を持たせる
  const error = (msg: string) => {
    toast.warning(msg, {
      position: "top-right",
      autoClose: 3000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
  };
  const success = (msg: string) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
  };
  return { saved, updated, deleted, error, success };
};
export default NotificationToast;
