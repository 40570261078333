import * as React from "react";
import { useState, useEffect } from "react";

import "react-calendar/dist/Calendar.css";

import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";

import { Input, Button, ButtonGroup, Badge } from "react-native-elements";
const moment = require("moment");
import formatNumber from "format-number";
import { StateColor } from "../../constants/Colors";

import InputWithDoropdown from "../../components/common/InputWithDoropdown";
import { SCREENS } from "../../constants/Screens";
import {
  DraftFlg,
  Invoice,
  InvoiceModel,
  InvoiceStateType,
  WORK_TYPE_SMALL_DEFAULT_NAME,
} from "../../models/InvoiceModel";
import ButtonWithDropdown from "../../components/common/ButtonWithDoropdown";
import NextAndBackButton from "../../components/teiban/NextAndBackButton";
import InvoiceSummary from "../../components/invoice/invoiceSummary";
import { TeibanModel } from "../../models/TeibanModel";
import NotificationToast from "../../components/common/NotificationToast";
import { FloatingButton } from "../../components/common/FloatingButton";
import CommonErrorLabel from "../../components/common/CommonErrorLabel";
import { WorkType, WorkTypeModel } from "../../models/WorkTypeModel";
import CommonModal from "../../components/modal/CommonModal";
import ReadOnlyInvocieDetail from "../../components/invoice/ReadOnlyInvoiceDetail";
import { InputType } from "../../components/common/CommonInput";
import CoverIndicator from "../../components/common/CoverIndicator";
import { validateInvoice } from "../../util/InvoiceUtil";
import { Form, InputGroup, Modal, Row } from "react-bootstrap";
import AddWorkTypeSmallButton from "../../components/common/AddWorkTypeSmallButton";
import { useFocusEffect } from "@react-navigation/native";
import {
  Organisations,
  OrganisationsModel,
} from "../../models/OrganisationsModel";
import { Calendar } from "react-calendar";
import { LocalStorageKey } from "../../models/LocalStorage";
import { WorkMemos } from "../../models/WorkMemosModel";
import WorkMemoButton from "../../components/common/WorkMemoButton";
import BillingDate from "../../components/invoice/BillingDate";
import { selectWorkType } from "../filter/SelectWorkTypeScreen";

const numberFormat = formatNumber({
  round: 0,
});
/**
 * Invoices detail screen
 * @param props
 * @returns
 */
export default function InvoiceDetailScreen(props: any) {
  const { navigation } = props;

  /*自動入力　制御関連*********************************************************/
  /** 自動入力(中分類仕事) */
  const [autoInputWorkTypeSmall, setAutoInputWorkTypeSmall] = useState(true);
  /** 自動入力(仕事内容) */
  const [autoInputWork, setAutoInputWork] = useState(true);
  /** 自動入力(単価) */
  const [autoInputUnitPrice, setAutoInputUnitPrice] = useState(true);

  /** 請求関連 ****************************************************************/
  /**請求日 */
  const [billing_date, setBillingDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  /**請求先(請求先) */
  const [to_org, setToOrg] = useState(0);
  /**請求元(請求元) */
  const [from_org, setFromOrg] = useState(0);
  /**請求先(請求先)名称 */
  const [to_org_name, setToOrgName] = useState("未選択");
  /**請求元(請求元)名称 */
  const [from_org_name, setFromOrgName] = useState("未選択");
  /**仕事内容 */
  const [work, setWork] = useState("");
  /**中分類仕事 id*/
  const [work_type_id, setWorkTypeId] = useState(0);
  /**中分類仕事 名称*/
  const [work_type_name, setWorkTypeName] = useState("未選択");
  /**小分類仕事 id*/
  const [work_type_small_id, setWorkTypeSmallId] = useState<number>(0);
  /**小分類類仕事 名称*/
  const [work_type_small_name, setWorkTypeSmallName] = useState("");
  /**単価 */
  const [unit_price, setUnitPrice] = useState("0");
  /**数量 */
  const [quantity, setQuantity] = useState("1");
  /**合計 */
  const [total, setTotal] = useState("0");
  /**請求状態 */
  const [state, setState] = useState<InvoiceStateType>(
    InvoiceStateType.Pending
  );
  /**請求状態 名称*/
  const [state_label, setStateLabel] = useState("");
  /**下書きフラグ*/
  const [draft, setDraft] = useState(0);

  /** 入力チェック関連 ****************************************************************/
  /**エラー文言[仕事] */
  const [errWork, setErrWork] = useState("");
  /**エラー文言[単価] */
  const [errUnitPrice, setErrUnitPrice] = useState("");
  /**エラー文言[数量] */
  const [errQuantity, setErrQuantity] = useState("");
  /**エラー文言[請求先・請求先] */
  const [errToOrg, setErrToOrg] = useState("");
  /**エラー文言[請求元・請求元] */
  const [errFromOrg, setErrFromOrg] = useState("");
  /**エラー文言[中分類仕事] */
  const [errWorkType, setErrWorkType] = useState("");
  /**エラー文言[小分類仕事] */
  const [errWorkTypeSmall, setErrWorkTypeSmall] = useState("");

  /** コメント関連 ****************************************************************/
  /**コメント 選択id*/
  const [selectedIndex, setSelectedIndex] = useState(0);
  /**コメント 一覧*/
  const [comments, setComments] = useState<Comment[]>([]);
  /**コメント 入力*/
  const [comment, setComment] = useState<string>("");
  /**コメント一覧の高さ*/
  const [listHeight, setListHeight] = useState(0);
  /**コメント 未読数 */
  const [unread, setUnread] = useState(0);

  /** 画面制御関連 ****************************************************************/
  /**請求読み込みフラグ*/
  const [isLoading, setIsLoading] = useState(true);
  /**請求日ピッカー表示フラグ*/
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  /**請求日 */
  const [dt, setDt] = useState(new Date());
  /**自分の請求 */
  const [own, setOwn] = useState(false);
  /**生成元の定番ID */
  const [teiban_id, setTeibanId] = useState(0);
  /**請求情報 */
  const [invoice, setInvoice] = useState<Invoice>();
  /**請求ID */
  const [props_id, setPropsId] = useState(props.route.params.id);
  /**定番ID */
  const [props_teiban, setPropsTeiban] = useState(props.route.params.teiban);
  //削除確認用モーダル
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  /**一つ前の請求ID */
  const [previousId, setPreviousId] = useState<number | null>();
  /**一つ後の請求ID */
  const [nextId, setNextId] = useState<number | null>();
  /**請求最終利用情報の取得フラグ */
  const [isEnableLoadLast, setIsEnableLoadLast] = useState<boolean>(false);

  /** 履歴関連 ****************************************************************/
  /**請求先の履歴 */
  const [toOrgHistory, setToOrgHistory] = useState<Organisations[]>([]);

  /**中分類仕事の履歴 */
  const [workTypeMidHistory, setWorkTypeHistory] = useState<WorkType[]>([]);
  /**中分類仕事の履歴取得インジケーター */
  const [isLoadingWorkTypeMidHistory, setIsLoadingWorkTypeMidHistory] =
    useState<boolean>(true);
  /**小分類仕事の履歴 */
  const [workTypeSmallHistory, setWorkTypeSmallHistory] = useState<WorkType[]>(
    []
  );
  /**小分類仕事の履歴取得インジケーター */
  const [isLoadingWorkTypeSmallHistory, setIsLoadingWorkTypeSmallHistory] =
    useState<boolean>(true);
  /**仕事(内容)の履歴 */
  const [workHistory, setWorkHistory] = useState<{ name: string }[]>([]);
  /**仕事(内容)の履歴取得インジケーター */
  const [isLoadingWorkHistory, setIsLoadingWorkHistory] =
    useState<boolean>(true);
  /**単価の履歴 */
  const [unitPriceHistory, setUnitPriceHistory] = useState<{ name: string }[]>(
    []
  );
  /**単価の履歴取得インジケーター */
  const [isLoadingUnitPriceHistory, setIsLoadingUnitPriceHistory] =
    useState<boolean>(true);
  /**履歴取得開始フラグ*/
  const [isEnableLoadHistory, setIsEnableLoadHistory] =
    useState<boolean>(false);
  /** DB関連 ****************************************************************/
  /**中分類仕事のDB */
  const [workTypeMidDB, setWorkTypeMidDB] = useState<WorkType[]>([]);
  /**中分類仕事のDB取得インジケーター */
  const [isLoadingWorkTypeMidDB, setIsLoadingWorkTypeMidDB] =
    useState<boolean>(true);

  /**中分類仕事のDB */
  const [workTypeSmallDB, setWorkTypeSmallDB] = useState<WorkType[]>([]);
  /**中分類仕事のDB取得インジケーター */
  const [isLoadingWorkTypeSmallDB, setIsLoadingWorkTypeSmallDB] =
    useState<boolean>(true);

  /**カレンダー */
  const [showCalendar, setShowCalendar] = useState(false);

  /**編集フラグ*/
  const isEdit = () => {
    if (invoiceId > 0 && invoiceId !== undefined) {
      return true;
    }
    return false;
  };
  const invoiceId = props.route.params.id;

  const filterSelectedYM: string = props.route.params.selected_YM;
  const filterFromOrgFilterTypes: number[] =
    props.route.params.from_filter_types ?? [];
  const filterToOrgFilterTypes: number[] =
    props.route.params.to_filter_types ?? [];
  const filterFromOrgIds: number[] = props.route.params.from_org_ids ?? [];
  const filterToOrgIds: number[] = props.route.params.to_org_ids ?? [];

  function handleClick() {
    setIsPickerOpen(true);
  }
  function handleCancel() {
    setIsPickerOpen(false);
  }
  function handleSelect(tm: any) {
    setDt(tm);
    setBillingDate(moment(tm).format("YYYY-MM-DD"));
    setIsPickerOpen(false);
  }

  function handleSelectCalendar(tm: any) {
    setDt(tm);
    setBillingDate(moment(tm).format("YYYY-MM-DD"));
    setIsPickerOpen(false);
    handleCalendarClose();
  }

  /**入力チェック */
  function validate() {
    const res = validateInvoice({
      unit_price: unit_price,
      quantity: quantity,
      to_org: to_org,
      from_org: from_org,
      work_type_id: work_type_id,
      work_type_small_id: work_type_small_id,
      work_type_small_name: work_type_small_name,
    });

    setErrUnitPrice(res.msg.unit_price);
    setErrQuantity(res.msg.quantity);
    setErrToOrg(res.msg.to_org);
    setErrFromOrg(res.msg.from_org);
    setErrWorkType(res.msg.work_type);
    setErrWorkTypeSmall(res.msg.work_type_small);

    const errorMsg = res.errorList.map((e) => "[" + e + "]");
    if (!res.result) {
      NotificationToast().error(errorMsg + "の入力内容が正しくありません。");
    }

    return res.result;
  }

  /**
   * 定番の並び順前後のIDを取得する
   * @returns
   */
  const fetchPreviousAndNextId = async (invoiceId?: number) => {
    if (invoiceId === 0 || invoiceId === undefined) return;
    const res = await InvoiceModel.previousAndNextId({
      id: invoiceId,
      billing_ym: filterSelectedYM,
      to_filter_types: filterToOrgFilterTypes,
      to_org_ids: filterToOrgIds,
      from_filter_types: filterFromOrgFilterTypes,
      from_org_ids: filterFromOrgIds,
    });

    setPreviousId(res.previousId);
    setNextId(res.nextId);
  };

  /**小分類仕事　入力 */
  const handleOnChangeWorkSmall = (v: string) => {
    setWorkTypeSmallName(v);
    setWorkTypeSmallId(0);
  };

  /**仕事　入力 */
  const handleOnChangeWork = (v: string) => {
    //単価の自動入力を停止する
    setAutoInputUnitPrice(false);
    setWork(v);

    // setUnitPrice("");
    setUnitPriceHistory([]);
  };

  /**中分類仕事を履歴より設定する */
  const handleOnSelectedToOrg = (v: Organisations | WorkType) => {
    setAutoInput(true);
    setToOrgName(v.name);
    setToOrg(v.id);
  };

  /**
   * 単価、作業、細工種別の自動入力を切り替えます。
   *
   * @param {boolean} isAuto - 自動入力を行うか否かを指定します。
   * @return {void} 戻り値はありません。
   */
  const setAutoInput = (isAuto: boolean) => {
    setAutoInputUnitPrice(isAuto);
    setAutoInputWork(isAuto);
    setAutoInputWorkTypeSmall(isAuto);
  };

  /**請求元フィルター画面に遷移 */
  const handleOnPressToOrg = () => {
    window.navi.navigate(SCREENS.INVOICE.SELECT_ORGANISATION, {
      id: to_org,
      setOrg: setToOrg,
      setOrgName: setToOrgName,
      backScreen: SCREENS.INVOICE.INVOICE_DETAIL,
      type: 0,
      mode: "to_org",
    });
  };

  /**中分類仕事フィルター画面に遷移 */
  const handleOnPressWorkTypeMid = () => {
    window.navi.navigate(SCREENS.INVOICE.SELECT_INVOICE_WORK_TYPE_FILTER, {
      id: work_type_id,
      setWorkTypeId,
      setWorkTypeName,
      backScreen: SCREENS.INVOICE.INVOICE_DETAIL,
      type: selectWorkType.mid,
    });
  };

  /**小分類仕事フィルター画面に遷移 */
  const handleOnPressWorkTypeSmall = () => {
    if (work_type_id <= 0) {
      alert("中分類仕事が選択されていません。");
      return;
    }

    window.navi.navigate(SCREENS.INVOICE.SELECT_INVOICE_WORK_TYPE_FILTER, {
      id: work_type_small_id,
      parentId: work_type_id,
      setWorkTypeSmallId,
      setWorkTypeSmallName,
      backScreen: SCREENS.INVOICE.INVOICE_DETAIL,
      type: selectWorkType.small,
    });
  };

  /**中分類仕事を履歴より設定する */
  const handleOnSelectedWorkTypeMid = (v: WorkType) => {
    setAutoInput(true);
    setWorkTypeId(v.id);
    setWorkTypeName(v.name);
  };

  /**小分類仕事を履歴より設定する */
  const handleOnSelectedWorkTypeSmall = (v: WorkType) => {
    setAutoInput(true);
    setWorkTypeSmallId(v.id);
    setWorkTypeSmallName(v.name);
  };

  /**仕事を履歴より設定する */
  const handleOnSelectedWork = (v: { name: string }) => {
    setAutoInput(true);
    setWork(v.name);
  };

  /**単価を履歴より設定する */
  const handleOnSelectedUnitPrice = (v: { name: string }) => {
    setAutoInput(true);
    setUnitPrice(v.name);
  };

  /**中分類仕事のDBを取得する */
  const fetchWorkTypeMidDB = async () => {
    if (from_org === 0 || to_org === 0) return;

    setIsLoadingWorkTypeMidDB(true);
    const data = await WorkTypeModel.fetchMidDB(from_org, to_org);

    setWorkTypeMidDB(data);

    setIsLoadingWorkTypeMidDB(false);
  };

  /**中分類仕事の履歴を取得する */
  const fetchWorkTypeMidHistory = async () => {
    // initWorkType();
    if (from_org === 0 || to_org === 0) return;
    setIsLoadingWorkTypeMidHistory(true);
    const data = await WorkTypeModel.fetchMidHistory(from_org, to_org);

    setWorkTypeHistory(data);

    //新規作成時
    if (!isEdit()) {
      if (data.length > 0) {
        const first = data[0];
        //小分類に値を入れる
        setWorkTypeId(first.id);
        setWorkTypeName(first.name);
      } else {
        setWorkTypeSmallId(0);
        setWorkTypeSmallName("");
      }
    }

    setIsLoadingWorkTypeMidHistory(false);
  };

  /**小分類仕事の履歴を取得する */
  const fetchWorkTypeSmallHistory = async () => {
    // initWorkTypeSmall();
    if (from_org === 0 || to_org === 0 || work_type_id === 0) return;
    setIsLoadingWorkTypeSmallHistory(true);
    const data = await WorkTypeModel.fetchSmallHistory(
      from_org,
      to_org,
      work_type_id
    );
    setWorkTypeSmallHistory(data);
    setIsLoadingWorkTypeSmallHistory(false);

    //新規作成時 自動入力可能時
    if (!isEdit() && autoInputWorkTypeSmall) {
      if (data.length > 0) {
        const first = data[0];
        //小分類に値を入れる
        setWorkTypeSmallId(first.id);
        setWorkTypeSmallName(first.name);
      } else {
        setWorkTypeSmallId(0);
        setWorkTypeSmallName("");
      }
    }

    window.reloadFlagWorkTypeSmallHistory = false;
  };

  /**小分類仕事のDBを取得する */
  const fetchWorkTypeSmallDB = async () => {
    // initWorkTypeSmall();
    if (from_org === 0 || to_org === 0 || work_type_id === 0) return;
    setIsLoadingWorkTypeSmallDB(true);
    const data = await WorkTypeModel.fetchSmallDB(
      from_org,
      to_org,
      work_type_id
    );
    setWorkTypeSmallDB(data);
    setIsLoadingWorkTypeSmallDB(false);

    // window.reloadFlagWorkTypeSmallHistory = false;
  };

  /**請求先部門の履歴を取得する */
  const fetchToOrgHistory = async () => {
    setToOrgHistory([]);

    if (from_org === 0) {
      return;
    }

    const data = await OrganisationsModel.fetchToOrgHistory({
      fromOrgId: from_org,
    });

    setToOrgHistory(data);

    //新規作成時
    if (!isEdit()) {
      if (data.length > 0) {
        const first = data[0];
        //小分類に値を入れる
        setToOrg(first.id);
        setToOrgName(first.name);
      } else {
        setToOrg(0);
        setToOrgName("");
      }
    }

    window.reloadFlagWorkTypeSmallHistory = false;
  };

  /**仕事(内容)の履歴を取得する */
  const fetchWorkHistory = async (params?: {
    from_org: number;
    to_org: number;
    work_type_id: number;
    work_type_small_id?: number;
  }) => {
    const fromOrg = params?.from_org ?? from_org;
    const toOrg = params?.to_org ?? to_org;
    const workTypeId = params?.work_type_id ?? work_type_id;
    const workTypeSmallId = params?.work_type_small_id ?? work_type_small_id;

    if (
      fromOrg === 0 ||
      toOrg === 0 ||
      workTypeId === 0 ||
      workTypeSmallId == 0
    ) {
      setWorkHistory([]);
      setWork("");
      return;
    }
    setIsLoadingWorkHistory(true);
    const data = await InvoiceModel.fetchWorkHistory({
      to_org_id: toOrg,
      from_org_id: fromOrg,
      work_type_id: workTypeId,
      work_type_small_id: workTypeSmallId,
    });
    setWorkHistory(data);

    console.log("autoInputWork:", autoInputWork);

    //新規作成時 (自動入力可能時)
    if (!isEdit() && autoInputWork) {
      if (data.length > 0) {
        //一番最新の仕事内容を入力欄に入れる。

        const first = data[0];
        setWork(first.name);
      } else {
        setWork("");
      }
    }

    setIsLoadingWorkHistory(false);
  };

  /**
   * 単価の履歴を取得する
   * @param params
   * @returns
   */
  const fetchUnitPriceHistory = async (params?: {
    from_org: number;
    to_org: number;
    work_type_id: number;
    work_type_small_id?: number;
    work?: string;
  }) => {
    setUnitPriceHistory([]);

    const fromOrg = params?.from_org ?? from_org;
    const toOrg = params?.to_org ?? to_org;
    const workTypeId = params?.work_type_id ?? work_type_id;
    const workTypeSmallId = params?.work_type_small_id ?? work_type_small_id;
    const workMemo = params?.work ?? work;

    if (
      fromOrg === 0 ||
      toOrg === 0 ||
      workTypeId === 0 ||
      workTypeSmallId === 0 ||
      workMemo === ""
    ) {
      setUnitPrice("");
      setUnitPriceHistory([]);
      return;
    }
    setIsLoadingUnitPriceHistory(true);
    const data = await InvoiceModel.fetchUnitPriceHistory({
      to_org_id: toOrg,
      from_org_id: fromOrg,
      work_type_id: workTypeId,
      work_type_small_id: workTypeSmallId,
      work: workMemo,
    });
    setUnitPriceHistory(data);

    //新規作成時 自動入力可能時
    if (!isEdit() && autoInputUnitPrice) {
      if (data.length > 0) {
        //一番最新の単価を入力欄に入れる。
        const first = data[0];
        setUnitPrice(first.name);
      } else {
        setUnitPrice("");
      }
    }

    setIsLoadingUnitPriceHistory(false);
  };

  /**
   * 次の請求に移動する
   * @param params
   * @returns
   */
  const handleOnNextInvoice = async () => {
    if (nextId === null) return;

    setIsLoading(true);
    window.navi.navigate(SCREENS.INVOICE.INVOICE_DETAIL, {
      id: nextId,
    });
    setIsEnableLoadHistory(false);
    fetchById(nextId);
  };

  /**
   * 前の請求に移動する
   * @param params
   * @returns
   */
  const handleOnPreviousInvoice = () => {
    if (previousId === null) return;

    setIsLoading(true);
    window.navi.navigate(SCREENS.INVOICE.INVOICE_DETAIL, {
      id: previousId,
    });
    setIsEnableLoadHistory(false);
    fetchById(previousId);
  };

  /**
   * 請求をidで取得する
   */
  const fetchById = async (id?: number) => {
    const invoice_Id = id ?? invoiceId;

    setPreviousId(0);
    setNextId(0);
    try {
      const res = await InvoiceModel.show(invoice_Id);
      setInvoice(res);
      setBillingDate(res.billing_date);
      setToOrg(res.to_org);
      setFromOrg(res.from_org);
      setToOrgName(res.to_org_name);
      setFromOrgName(res.from_org_obj.name);
      setWorkTypeId(res.work_type_id);
      setWorkTypeName(res.work_type_name);
      setWorkTypeSmallId(res.work_type_small?.id ?? 0);
      setWorkTypeSmallName(
        res.work_type_small?.name ?? WORK_TYPE_SMALL_DEFAULT_NAME
      );
      setWork(res.work ?? "");
      setUnitPrice(res.unit_price.toString());
      setQuantity(res.quantity.toString());
      setTotal(res.total.toString());
      setStateLabel(res.state_label);
      setState(res.state);
      setDt(new Date(res.billing_date));
      setDraft(res.draft);
      setOwn(res.own);
      setTeibanId(res.teiban_id);
      setIsLoading(false);

      //仕事履歴を取得する
      fetchWorkHistory({
        from_org: res.from_org,
        to_org: res.to_org,
        work_type_id: res.work_type_id,
        work_type_small_id: res.work_type_small_id,
      });
      //単価の履歴を取得する
      fetchUnitPriceHistory({
        from_org: res.from_org,
        to_org: res.to_org,
        work_type_id: res.work_type_id,
        work_type_small_id: res.work_type_small_id,
        work: res.work,
      });
      setIsEnableLoadHistory(true);

      fetchPreviousAndNextId(invoice_Id);
    } catch (error) {
      // window.functions.logout();
    }
  };

  //新規作成時の初期値表示
  const fetchLast = async () => {
    try {
      const res = await InvoiceModel.last();
      if (res != null) {
        setToOrg(res.to_org);
        setToOrgName(res.to_org_name);
        setFromOrg(res.from_org);
        setFromOrgName(res.from_org_obj.name);
        setWorkTypeId(res.work_type_id);
        setWorkTypeName(res.work_type_name);
        setWorkTypeSmallId(res.work_type_small?.id ?? 0);
        setWorkTypeSmallName(res.work_type_small?.name ?? "");
        setWork(res.work);

        setUnitPrice(res.unit_price.toString());

        //仕事履歴を取得する
        fetchWorkHistory({
          from_org: res.from_org,
          to_org: res.to_org,
          work_type_id: res.work_type_id,
          work_type_small_id: res.work_type_small_id,
        });
        //単価の履歴を取得する
        fetchUnitPriceHistory({
          from_org: res.from_org,
          to_org: res.to_org,
          work_type_id: res.work_type_id,
          work_type_small_id: res.work_type_small_id,
          work: res.work,
        });
        setIsEnableLoadHistory(true);
      }
    } catch (error) {}

    const lastUseBillingDate = localStorage.getItem(
      LocalStorageKey.BILLING_DATE
    );
    //最後の請求日を設定する
    if (lastUseBillingDate != null) {
      const date = new Date(lastUseBillingDate);
      setDt(date);
      const dt = moment(lastUseBillingDate);
      setBillingDate(dt.format("YYYY-MM-DD"));
    }

    setOwn(true);
    setIsLoading(false);
  };

  /**
   * 請求新規作成
   * @param params
   */
  const onCreate = async (params: { draftFlg: DraftFlg }) => {
    setIsLoading(true);
    const res = await InvoiceModel.create({
      billing_date: billing_date,
      to_org: to_org,
      from_org: from_org,
      work_type_id: work_type_id,
      work_type_small_id: work_type_small_id,
      work_type_small_name: work_type_small_name,
      work: work,
      unit_price: unit_price,
      quantity: quantity,
      total: total,
      state: 0,
      comment: comment,
      draft: params.draftFlg,
      teiban_id: teiban_id,
    });
    window.reloadFlag = true;

    //請求日をローカルストレージに保存する。

    localStorage.setItem(LocalStorageKey.BILLING_DATE, dt.toString());

    if (teiban_id == 0) {
      navigation.navigate(SCREENS.INVOICE.INVOICE, {
        update_id: 0,
        delete_id: props_id,
      });
    } else {
      navigation.navigate(SCREENS.INVOICE.INVOICE_TEIBAN_SELECT);
    }
  };

  /**
   * 更新する
   * @param params
   */
  const onUpdate = async (params: {
    state: InvoiceStateType;
    draftFlg: DraftFlg;
    comment?: any;
  }) => {
    setIsLoading(true);
    const res = await InvoiceModel.update({
      id: props_id,
      billing_date: billing_date,
      to_org: to_org,
      from_org: from_org,
      work_type_id: work_type_id,
      work_type_small_id: work_type_small_id,
      work_type_small_name: work_type_small_name,
      work: work,
      unit_price: unit_price,
      quantity: quantity,
      total: total,
      comment: params.comment,
      state: params.state,
      draft: params.draftFlg,
    });
  };

  /**
   * モーダルを閉じる
   */
  const closeModal = () => {
    setIsShowDeleteModal(false);
  };

  /**
   * 請求を削除する
   */
  const onDelete = async () => {
    closeModal();
    setIsLoading(true);

    const res = await InvoiceModel.deleteByIds([props_id]);
    window.reloadFlag = true;
    navigation.navigate(SCREENS.INVOICE.INVOICE, {
      update_id: 0,
      delete_id: props_id,
    });
  };

  /**
   * 定番作成
   */
  const onCreateTeiban = async () => {
    await TeibanModel.store({
      type: 0,
      billing_date: billing_date,
      to_org: to_org,
      from_org: from_org,
      work_type_id: work_type_id,
      work: work,
      unit_price: unit_price,
      quantity: quantity,
      total: total,
      source_id: props_id,
      work_type_small_id: work_type_small_id,
      work_type_small_name: work_type_small_name,
    });
    NotificationToast().success("定番に登録しました。");
  };

  /**
   * 請求元・請求先が入れば、その他の項目は、最後の履歴が入る。
   */
  const fetchLastInvocie = async () => {
    if (!isEdit()) return;
    setIsLoading(true);
    const res = await InvoiceModel.last({
      to_org_id: to_org,
      from_org_id: from_org,
    });
    if (res != null) {
      setWorkTypeId(res.work_type_id);
      setWorkTypeName(res.work_type_name);
      setWorkTypeSmallId(res.work_type_small?.id ?? 0);
      setWorkTypeSmallName(res.work_type_small?.name ?? "");
      setWork(res.work);
      setUnitPrice(res.unit_price.toString());
    } else {
      setWorkTypeId(0);
      setWorkTypeName("未選択");
      setWorkTypeSmallId(0);
      setWorkTypeSmallName("");
      setWork("");
      setUnitPrice("0");
    }

    setIsLoading(false);
  };

  /**
   * コメントを取得する
   */
  const fetchComments = async () => {
    window
      .axios({
        method: "get",
        url: window.baseUrl + "/comment?type=0&target_id=" + props_id,
        headers: { Authorization: "Bearer " + window.accessToken },
      })
      .then(({ data }: any) => {
        if (data) {
          setComments(data);
          //
          let ur = 0;
          data.forEach((item: any) => {
            if (!item.read_flag) {
              ur++;
            }
          });
          setUnread(ur);
          //
          setSelectedIndex(0);
          setIsLoading(false);
        }
      })
      .catch((err: any) => {
        window.functions.logout();
      });
  };

  /**
   * カレンダーを閉じる
   */
  const handleCalendarClose = () => setShowCalendar(false);

  /**
   * カレンダーを開く
   */
  const handleCalendarShow = () => setShowCalendar(true);

  /**
   * 小分類を取得する。
   */
  const fetchWorkTypeSmall = async () => {
    if (work_type_small_id != 0 && work_type_small_id != undefined) {
      const res = await WorkTypeModel.fetchFind({ id: work_type_small_id });
      setWorkTypeSmallName(res.name);
    }
  };

  /**
   * 作業メモのタップイベントを処理します。
   *
   * @param {WorkMemos} workMemo - 作業メモオブジェクト。
   * @return {void} なし
   */
  function handleOnPressWorkMemo(workMemo: WorkMemos) {
    if (
      workMemo.work_type_obj !== undefined &&
      (work_type_small_id != workMemo.id ||
        work_type_small_name != workMemo.work_type_obj?.name)
    ) {
      setAutoInputWorkTypeSmall(false);
      setAutoInputWork(false);
      setAutoInputUnitPrice(false);
      setWorkTypeSmallId(workMemo.work_type_obj!.id);
      setWorkTypeSmallName(workMemo.work_type_obj?.name);
    }

    // 自動入力の設定
    setAutoInputWork(false);
    setAutoInputUnitPrice(false);

    setWork(workMemo.name);
    setUnitPrice(workMemo.unit_price.toString());
  }

  /**
   * グローバル関数
   */
  const setWindowFunctions = () => {
    if (window.reloadFlagWorkTypeSmallHistory) {
      fetchWorkTypeSmallHistory();
      fetchWorkTypeSmall();
    }
  };

  /**------------------------------------------------
   * useEffect
   * ------------------------------------------------
   * */

  // 抽出条件が変更されたらグローバル関数を更新する。
  useFocusEffect(() => {
    setWindowFunctions();
  });

  /**■ 単価と数量の変更 */
  useEffect(() => {
    const total = Number(unit_price) * Number(quantity);
    setTotal(total.toString());
  }, [unit_price, quantity]);

  useEffect(() => {
    if (from_org != 0) {
      fetchToOrgHistory();
    }
  }, [from_org]);

  /**■ 請求先(id)と請求元(id)の変更 */
  useEffect(() => {
    if (to_org != 0 && from_org != 0) {
      //最初のto_orgとfrom_orgの設定時には最終利用を取得しないようにする。
      if (isEnableLoadLast) {
        // fetchLastInvocie();
      } else {
        setIsEnableLoadLast(true);
      }
    }
    fetchWorkTypeMidDB();
    fetchWorkTypeMidHistory();
  }, [to_org, from_org]);

  /**■ 中分類仕事(id)の変更 */
  useEffect(() => {
    fetchWorkTypeSmallHistory();
    // fetchWorkTypeSmallDB();

    if (work_type_small_id != 0) {
      setWorkTypeSmallId(0);
      setWorkTypeSmallName("");
    }
  }, [work_type_id]);

  /**■ 小分類仕事(id)の変更 */
  useEffect(() => {
    if (!isEnableLoadHistory) return;
    fetchWorkHistory();
  }, [work_type_small_id]);

  /**■ 仕事の変更 */
  useEffect(() => {
    if (!isEnableLoadHistory) return;
    fetchUnitPriceHistory();
  }, [work]);

  /**■ 画面読み込み時 */
  useEffect(() => {
    if (isEdit()) {
      //編集時
      fetchById(props_id);
      fetchComments();
      return;
    }
    if (props_id > 0 || (props_id == 0 && props_teiban > 0)) {
      let url =
        props_teiban > 0
          ? window.baseUrl + "/teiban/" + props_teiban
          : window.baseUrl + "/invoice/" + props_id;
      window
        .axios({
          method: "get",
          url,
          data: {},
          headers: { Authorization: "Bearer " + window.accessToken },
        })
        .then(({ data }: any) => {
          if (props_teiban > 0) {
            setToOrg(data.to_org);
            setFromOrg(data.from_org);
            setToOrgName(data.to_org_name);
            setFromOrgName(data.from_org_name);
            setWorkTypeId(data.work_type_id);
            setWorkTypeName(data.work_type_name);
            setWork(data.work ? data.work : "");
            setUnitPrice(data.unit_price);
            setQuantity(data.quantity);
            setTotal(data.total);
            if (props_teiban > 0) setTeibanId(props_teiban);
            setOwn(true);
          } else {
            setBillingDate(data.billing_date);
            setToOrg(data.to_org);
            setFromOrg(data.from_org);
            setToOrgName(data.to_org_name);
            setFromOrgName(data.from_org_name);
            setWorkTypeId(data.work_type_id);
            setWorkTypeName(data.work_type_name);
            setWork(data.work ? data.work : "");
            setUnitPrice(data.unit_price);
            setQuantity(data.quantity);
            setTotal(data.total);
            setStateLabel(data.state_label);
            setState(data.state);
            setDt(new Date(data.billing_date));
            setDraft(data.draft);
            setOwn(data.own);
            setTeibanId(data.teiban_id);
          }
        })
        .catch((err: any) => {
          window.functions.logout();
        });
    } else {
      //新規作成時
      fetchLast();
    }
  }, []);

  const component1 = () => <Text>請求内容</Text>;
  const component2 = () => (
    <Text>
      コメント{comments.length > 0 ? "(" + comments.length + ")" : ""}{" "}
      {unread > 0 && <Badge value={unread} status="error" />}
    </Text>
  );
  const buttons = [{ element: component1 }, { element: component2 }];

  return (
    <>
      <View style={styles.container}>
        <View style={{ width: "100%" }}>
          {props_id > 0 && draft != 1 && (
            <View
              style={{
                width: "100%",
                height: 25,
                backgroundColor: StateColor[state],
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ textAlign: "center", fontSize: 14 }}>
                {state_label}
              </Text>
            </View>
          )}
          {draft == 1 && (
            <View
              style={{
                width: "100%",
                height: 25,
                backgroundColor: "#ccc",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ textAlign: "center", fontSize: 14 }}>下書き</Text>
            </View>
          )}
          {props_id > 0 && draft != 1 && (
            <ButtonGroup
              onPress={(index) => {
                if (index == 1 && unread > 0) window.reloadFlag = true;
                window
                  .axios({
                    method: "get",
                    url:
                      window.baseUrl +
                      "/comment?type=0&target_id=" +
                      props_id +
                      (index == 1 ? "&read=true" : ""),
                    headers: { Authorization: "Bearer " + window.accessToken },
                  })
                  .then(({ data }: any) => {
                    if (data) {
                      setComments(data);
                      //
                      let ur = 0;
                      data.forEach((item: any) => {
                        if (!item.read_flag) {
                          ur++;
                        }
                      });
                      setUnread(ur);
                      //
                      setSelectedIndex(index);
                    }
                  })
                  .catch((err: any) => {});
              }}
              selectedIndex={selectedIndex}
              buttons={buttons}
              containerStyle={{ borderWidth: 0, height: 40 }}
              innerBorderStyle={{ width: 0 }}
              selectedButtonStyle={{
                borderBottomWidth: 2,
                borderBottomColor: "rgb(47, 149, 220)",
                backgroundColor: "white",
              }}
            />
          )}
        </View>
        <ScrollView
          style={{
            width: "100%",
            padding: 10,
            display:
              selectedIndex == 0 || selectedIndex == -1 ? "flex" : "none",
          }}
        >
          <NextAndBackButton
            onNext={() => handleOnNextInvoice()}
            onPrevious={() => handleOnPreviousInvoice()}
            previousDisabled={
              previousId === undefined ||
              previousId === null ||
              previousId === 0
            }
            nextDisabled={
              nextId === undefined || nextId === null || nextId === 0
            }
          />

          {own != true && (
            <View
              style={{
                width: "100%",
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              {invoice && <ReadOnlyInvocieDetail item={invoice} />}
            </View>
          )}
          {own == true && (
            <View
              style={{
                width: "100%",
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              {/* #############################
              請求日
            ################################
            */}
              <BillingDate
                billingDate={billing_date}
                dt={dt}
                onSelectCalendar={handleSelectCalendar}
              />
              {/* #############################
              請求先
            #################################
            */}

              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 3,
                }}
              >
                <Text style={{}}>請求先</Text>
              </View>
              <ButtonWithDropdown
                dropdownTitle="履歴"
                value={to_org_name}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                }}
                onClick={handleOnPressToOrg}
                dropdownItems={toOrgHistory}
                isLoading={isLoadingWorkTypeMidHistory}
                onSelect={handleOnSelectedToOrg}
                errorMsg={errToOrg}
              />

              {/* #############################
            請求元
            #################################
            */}

              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 3,
                }}
              >
                <Text style={{}}>請求元</Text>
              </View>
              <div className="mb-3">
                <InputGroup style={{ width: "100%" }}>
                  <Form.Control
                    placeholder="未選択"
                    type="button"
                    style={{
                      textAlign: "left",
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                    }}
                    value={from_org_name}
                    aria-label="Text input with dropdown button"
                    onClick={() => {
                      window.navi.navigate(
                        SCREENS.INVOICE.SELECT_ORGANISATION,
                        {
                          id: from_org,
                          setOrg: (v: any) => {
                            setAutoInput(true);
                            setFromOrg(v);
                          },
                          setOrgName: setFromOrgName,
                          backScreen: SCREENS.INVOICE.INVOICE_DETAIL,
                          range: "belong",
                          type: 0,
                          mode: "from_org",
                        }
                      );
                    }}
                  />
                </InputGroup>
              </div>
              <CommonErrorLabel title={errFromOrg} />

              {/* #############################
           中分類（仕事）
            #################################
            */}
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 3,
                }}
              >
                <Text style={{}}>中分類（仕事）</Text>
                <WorkMemoButton
                  workTypeMidId={work_type_id}
                  onPress={handleOnPressWorkMemo}
                />
              </View>
              <ButtonWithDropdown
                dropdownTitle="DB"
                value={work_type_name}
                onClick={handleOnPressWorkTypeMid}
                // onClickDB={handleOnPressWorkTypeMid}
                onSelectDB={handleOnPressWorkTypeMid}
                dropdownItems={workTypeMidHistory}
                // dropdownItemsDB={workTypeMidDB}
                isLoading={isLoadingWorkTypeMidHistory}
                onSelect={handleOnSelectedWorkTypeMid}
                errorMsg={errWorkType}
              />

              {/* #############################
           小分類（仕事
            #################################
            */}
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 3,
                }}
              >
                <Text style={{}}>小分類（仕事）</Text>
                <WorkMemoButton
                  style={{ marginRight: 10 }}
                  workTypeSmallId={work_type_small_id}
                  onPress={handleOnPressWorkMemo}
                />
                <AddWorkTypeSmallButton
                  gotoScreen={SCREENS.INVOICE.EDIT_WORK_TYPE_SMALL}
                  backScreen={SCREENS.INVOICE.INVOICE_DETAIL}
                  workTypeMidId={work_type_id}
                  workTypeSmallId={work_type_small_id}
                  workTypeMidName={work_type_name}
                />
              </View>
              <ButtonWithDropdown
                dropdownTitle="DB"
                value={work_type_small_name}
                onClick={handleOnPressWorkTypeSmall}
                // onClickDB={handleOnPressWorkTypeMid}
                // onSelectDB={handleOnPressWorkTypeMid}
                dropdownItems={workTypeSmallHistory}
                // dropdownItemsDB={workTypeMidDB}
                isLoading={isLoadingWorkTypeSmallHistory}
                onSelect={handleOnSelectedWorkTypeSmall}
                errorMsg={errWorkTypeSmall}
              />
              {/* #############################
           仕事
            #################################
            */}
              <Text style={{}}>仕事</Text>
              <InputWithDoropdown
                dropdownTitle="履歴"
                value={work}
                onChange={handleOnChangeWork}
                dropdownItems={workHistory}
                isLoading={isLoadingWorkHistory}
                onSelect={handleOnSelectedWork}
                errorMsg={errWork}
                onPressCopy={() => {
                  if (confirm("小分類（仕事）の内容をコピーしますか？")) {
                    setAutoInput(true);
                    setWork(work_type_small_name);
                  }
                }}
              />
              {/* #############################
           単価／時給
            #################################
            */}
              <Text style={{}}>単価／時給</Text>
              <InputWithDoropdown
                inputType={InputType.NUMBER}
                dropdownTitle="履歴"
                value={unit_price}
                onChange={setUnitPrice}
                dropdownItems={unitPriceHistory}
                isLoading={isLoadingUnitPriceHistory}
                onSelect={handleOnSelectedUnitPrice}
                errorMsg={errUnitPrice}
              />
              {/* #############################
           数量/ 時間
            #################################
            */}
              <View style={styles.itemViewStyle}>
                <Text style={{}}>数量／時間</Text>
                <Input
                  keyboardType={"number-pad"}
                  style={styles.itemStyleEdit}
                  value={quantity}
                  errorMessage={errQuantity}
                  onChangeText={(value: any) => {
                    setQuantity(value);
                    let val = parseInt(unit_price) * parseInt(value);
                    setTotal(val.toString());
                  }}
                  onFocus={() => {
                    if (quantity == "0") {
                      setQuantity("");
                    }
                  }}
                  onBlur={() => {
                    if (quantity == "") {
                      setQuantity("0");
                    }
                  }}
                />
              </View>
              {/* #############################
            合計
            #################################
            */}
              <View style={styles.itemViewStyle}>
                <Text style={{}}>合計</Text>
                <Text style={styles.itemStyle}>
                  <Text style={{ ...styles.itemStyle }}>
                    {numberFormat(Number(total))}
                  </Text>
                </Text>
              </View>
              {/* #############################
            請求要約
            #################################
            */}
              <InvoiceSummary
                to_org_name={to_org_name}
                from_org_name={from_org_name}
                work_type_name={work_type_name}
              />

              {/* #############################
              その他
              #################################
              */}
              {props_id > 0 && draft == 0 && (
                <Button
                  title={
                    [
                      InvoiceStateType.Rejected,
                      InvoiceStateType.Onhold,
                    ].includes(state)
                      ? "再提出する"
                      : "修正する"
                  }
                  titleStyle={{ fontSize: 17 }}
                  disabled={false}
                  onPress={async () => {
                    if (!validate()) return;
                    if (
                      confirm(
                        [
                          InvoiceStateType.Rejected,
                          InvoiceStateType.Onhold,
                        ].includes(state)
                          ? "再提出してもよろしいですか？"
                          : "修正してもよろしいですか？"
                      )
                    ) {
                      await onUpdate({
                        state: [
                          InvoiceStateType.Rejected,
                          InvoiceStateType.Onhold,
                        ].includes(state)
                          ? InvoiceStateType.Resubmitted
                          : state,
                        draftFlg: DraftFlg.OFF,
                      });

                      // window.reloadFlag = true;
                      navigation.navigate(SCREENS.INVOICE.INVOICE, {
                        update_id: props_id,
                        delete_id: 0,
                      });
                    }
                  }}
                />
              )}

              {props_id > 0 && draft == 1 && (
                <>
                  <Button
                    title={"請求する"}
                    titleStyle={{ fontSize: 17 }}
                    disabled={false}
                    onPress={async () => {
                      if (!validate()) return;
                      if (confirm("請求してもよろしいですか？")) {
                        await onUpdate({
                          state: InvoiceStateType.Pending,
                          draftFlg: DraftFlg.OFF,
                        });
                        window.reloadFlag = true;
                        navigation.navigate(SCREENS.INVOICE.INVOICE);
                      }
                    }}
                  />
                  <Button
                    title="下書き保存する"
                    type="outline"
                    titleStyle={{ fontSize: 15 }}
                    buttonStyle={{ height: 30 }}
                    style={{ marginTop: 10 }}
                    onPress={async () => {
                      const res = await onUpdate({
                        state: InvoiceStateType.Pending,
                        draftFlg: DraftFlg.ON,
                        comment: comment,
                      });

                      window.reloadFlag = true;
                      navigation.navigate(SCREENS.INVOICE.INVOICE);
                    }}
                  />
                </>
              )}
              {props_id == 0 && draft == 0 && (
                <>
                  <Button
                    title={"請求する"}
                    titleStyle={{ fontSize: 17 }}
                    disabled={false}
                    onPress={async () => {
                      if (!validate()) return;
                      if (confirm("請求してもよろしいですか？")) {
                        onCreate({ draftFlg: DraftFlg.OFF });
                      }
                    }}
                  />
                  <Button
                    title="下書き保存する"
                    type="outline"
                    titleStyle={{ fontSize: 17 }}
                    buttonStyle={{ height: 30 }}
                    style={{ marginTop: 10 }}
                    onPress={() => {
                      onCreate({ draftFlg: DraftFlg.ON });
                    }}
                  />
                </>
              )}
              {props_id > 0 && state == 1 && own == true && teiban_id == 0 && (
                <View style={{ marginTop: 10 }}>
                  <Button
                    title="同じ内容で請求する"
                    titleStyle={{ fontSize: 17 }}
                    disabled={false}
                    buttonStyle={{ backgroundColor: "#3cb371" }}
                    onPress={() => {
                      setPropsId(0);
                      setDt(new Date());
                      setBillingDate(moment().format("YYYY-MM-DD"));
                    }}
                  />
                  <Button
                    title="定番に登録する"
                    type="outline"
                    titleStyle={{ fontSize: 15, color: "#3cb371" }}
                    buttonStyle={{ height: 30, borderColor: "#3cb371" }}
                    style={{ marginTop: 10 }}
                    onPress={() => {
                      onCreateTeiban();
                    }}
                  />
                </View>
              )}
              {props_id > 0 && (
                <Button
                  title="削除する"
                  titleStyle={{ fontSize: 15 }}
                  disabled={false}
                  buttonStyle={{
                    height: 30,
                    backgroundColor: "#f00",
                    marginTop: 30,
                  }}
                  onPress={() => setIsShowDeleteModal(true)}
                />
              )}
            </View>
          )}
        </ScrollView>
        {props_id == 0 && draft == 0 && (
          <FloatingButton
            text="請求"
            onPress={() => {
              if (!validate()) return;
              if (confirm("請求してもよろしいですか？")) {
                onCreate({ draftFlg: DraftFlg.OFF });
              }
            }}
          />
        )}
        {props_id > 0 && draft == 1 && (
          <FloatingButton
            text="請求"
            onPress={async () => {
              if (!validate()) return;
              if (confirm("請求してもよろしいですか？")) {
                await onUpdate({
                  state: InvoiceStateType.Pending,
                  draftFlg: DraftFlg.OFF,
                });

                window.reloadFlag = true;
                navigation.navigate(SCREENS.INVOICE.INVOICE);
              }
            }}
          />
        )}

        {/* コメント画面 */}
        <View
          style={{
            flex: 1,
            width: "100%",
            display:
              selectedIndex == 1 || selectedIndex == -1 ? "flex" : "none",
          }}
        >
          <View
            style={{ flex: 1, marginBottom: 70 }}
            onLayout={(info) => {
              setListHeight(info.nativeEvent.layout.height);
            }}
          >
            <FlatList
              inverted
              style={{
                width: "100%",
                position: "absolute",
                maxHeight: listHeight,
              }}
              keyExtractor={keyExtractor}
              data={comments}
              renderItem={renderItem}
              ref={(ref) => {
                window.commentList = ref;
              }}
            />
          </View>
          <View
            style={{
              position: "absolute",
              width: "100%",
              height: 70,
              bottom: 0,
              left: 0,
              backgroundColor: "#fff",
              flexDirection: "row",
              borderTopColor: "#eee",
              borderTopWidth: 1,
            }}
          >
            <View
              style={{
                flex: 1,
                height: "100%",
                backgroundColor: "#fff",
                padding: 5,
                paddingLeft: 20,
              }}
            >
              <TextInput
                multiline
                numberOfLines={2}
                style={{
                  flex: 1,
                  borderWidth: 1,
                  borderColor: "#ddd",
                  borderRadius: 3,
                }}
                onChangeText={(value) => {
                  setComment(value);
                }}
                value={comment}
              />
            </View>
            <View
              style={{
                width: 70,
                height: "100%",
                backgroundColor: "#fff",
                padding: 5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                title="送信"
                titleStyle={{ fontSize: 14 }}
                onPress={() => {
                  if (comment != "" && comment != null) {
                    window
                      .axios({
                        method: "post",
                        url: window.baseUrl + "/comment",
                        data: {
                          type: 0,
                          target_id: props_id,
                          comment: comment,
                        },
                        headers: {
                          Authorization: "Bearer " + window.accessToken,
                        },
                      })
                      .then(({ data }: any) => {
                        setComments(data);
                        //
                        let ur = 0;
                        data.forEach((item: any) => {
                          if (!item.read_flag) {
                            ur++;
                          }
                        });
                        setUnread(ur);
                        //
                        setComment("");
                        window.reloadFlag = true;
                      })
                      .catch((err: any) => {
                        window.functions.logout();
                      });
                  }
                }}
              />
            </View>
          </View>
        </View>
        {isLoading && <CoverIndicator />}
      </View>
      <CommonModal
        isShow={isShowDeleteModal}
        body="この請求を削除しますか？"
        actionTitle="削除する"
        onPress={onDelete}
        onDismiss={closeModal}
      />
    </>
  );
}

function keyExtractor(item: any, index: any) {
  return item.id.toString();
}

function renderItem({ item }: any) {
  return (
    <View
      style={{
        width: "100%",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        borderBottomColor: "#ddd",
        borderBottomWidth: 1,
        backgroundColor: item.read_flag == false ? "#ffe4e1" : "#fff",
      }}
    >
      <Text style={{ fontWeight: "bold", marginBottom: 3 }}>
        {moment(item.created_at).format("YYYY-MM-DD HH:mm")}　{item.user_name}
      </Text>
      <Text>{item.comment}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
  },
  itemViewStyle: {
    marginBottom: 12,
  },
  itemStyle: {
    fontWeight: "bold",
    fontSize: 17,
  },
  itemStyleEdit: {
    fontWeight: "bold",
    color: "#333",
    fontSize: 17,
  },
});
