import React, { Ref, useState } from "react";
import { Table } from "react-bootstrap";
import "../../assets/styles/TableStyles.css";
import { WorkListCompare } from "../../models/WorkListCompareModel";
import { displayToYearMonth, manipulateYearMonth } from "../../util/DateUtil";
import { ActivityIndicator, TouchableOpacity, View, Text } from "react-native";
import { priceStyle } from "../../util/DisplayUtil";
import { formatLocalNumber } from "../../util/Math";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import { CommonColor } from "../../constants/Colors";

type WorkCompareListTableProps = {
  items: WorkListCompare[];
  isLoading: boolean;
  selected_YM: string;
  tableRef: Ref<HTMLTableElement>;
  onExpand: (e: WorkListCompare, type: "large" | "middle") => void;
};

const WorkCompareListTable = (props: WorkCompareListTableProps) => {
  const [isExpand, setIsExpand] = useState(window.width >= window.height);

  const Columns = [
    "項目",
    displayToYearMonth(manipulateYearMonth(props.selected_YM, -3)),
    displayToYearMonth(manipulateYearMonth(props.selected_YM, -2)),
    displayToYearMonth(manipulateYearMonth(props.selected_YM, -1)),
    displayToYearMonth(props.selected_YM),
    "昨年差",
    displayToYearMonth(manipulateYearMonth(props.selected_YM, -12)),
  ];

  /**
   * 展開ボタン
   */
  const renderExpandButton = () => {
    return (
      <TouchableOpacity
        style={{
          backgroundColor: "#FFF",
          borderRadius: 100,
          width: 20,
          height: 20,
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
        onPress={() => setIsExpand(!isExpand)}
      >
        <MaterialCommunityIcons
          name={isExpand ? "minus" : "plus"}
          size={20}
          color="black"
        />
      </TouchableOpacity>
    );
  };

  /**
   * ヘッダー
   */
  const renderHeader = (columnTitle: string, index: number) => {
    let titleStyle: React.CSSProperties = {};
    if (index === 4) {
      // 今月の列は背景色を変更する。
      titleStyle = {
        ...titleStyle,
        ...{ backgroundColor: CommonColor.Normal.Blue },
      };
    }
    if (isExpand === false && [1, 5, 6].includes(index)) {
      // 非表示になる列 3か月前・今月・昨年差・去年
      titleStyle = {
        ...titleStyle,
        ...{ display: "none" },
      };
    }

    return (
      <th
        className={index === 4 ? "_sticky" : "_sticky bg-secondary"}
        style={{ ...titleStyle }}
        key={columnTitle}
      >
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text style={{ color: "#FFF" }}>{columnTitle}</Text>
          {!isExpand && index == 4 && renderExpandButton()}
          {isExpand && index == 6 && renderExpandButton()}
        </View>
      </th>
    );
  };

  return (
    <Table
      striped
      bordered
      hover
      ref={props.tableRef}
      style={{ overflow: "scroll" }}
    >
      <thead>
        <tr>{Columns.map((e, index) => renderHeader(e, index))}</tr>
      </thead>
      <tbody>
        {props.items.map((item, index) => {
          return (
            <WorkListItem
              item={item}
              isExpand={isExpand}
              key={index}
              index={index}
              onExpand={props.onExpand}
            />
          );
        })}
        <tr>
          <td colSpan={9} className="text-center">
            {props.isLoading ? (
              <ActivityIndicator style={{ marginVertical: 10 }} />
            ) : (
              <div style={{ height: 70 }}></div>
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

const WorkListItem = ({
  item,
  isExpand,
  type = "large",
  index,
  onExpand,
}: {
  item: WorkListCompare;
  isExpand: boolean;
  type?: "large" | "middle" | "small" | "work";
  index: number;
  onExpand: (e: WorkListCompare, type: "large" | "middle") => void;
}) => {
  const [showDetail, setShowDetail] = useState(false);
  const name = item.name ?? "未登録";
  // 昨年差
  const compare = item.tsc - item.t1ya;
  const closeStyle: React.CSSProperties = isExpand ? {} : { display: "none" };
  //集計行である(1行目)
  const isTotalRow = index == 0 && type === "large";
  const totalStyle: React.CSSProperties = isTotalRow
    ? { fontWeight: "bold" }
    : {};

  let nextType: typeof type = "middle";
  if (type === "small") {
    nextType = "work";
  }
  if (type === "large") {
    nextType = "middle";
  }
  if (type === "middle") {
    nextType = "small";
  }
  let itemStyle: React.CSSProperties = {
    cursor: "pointer",
    whiteSpace: "nowrap",
    flexDirection: "row",
    justifyContent: "center",
  };
  if (type === "large") {
    itemStyle = { ...itemStyle, ...{ fontWeight: "bold" } };
  }
  if (type === "middle") {
    itemStyle = { ...itemStyle, ...{ marginLeft: 15 } };
  }
  if (type === "small") {
    itemStyle = { ...itemStyle, ...{ marginLeft: 30 } };
  }
  if (type === "work") {
    itemStyle = { ...itemStyle, ...{ marginLeft: 45 } };
  }
  if (window.width < window.height && name.length > 8) {
    itemStyle = { ...itemStyle, ...{ fontSize: 14 } };
  }
  if (window.width < window.height && name.length > 12) {
    itemStyle = { ...itemStyle, ...{ fontSize: 12 } };
  }

  let iconColor: string = "#000";
  switch (nextType) {
    case "small":
      iconColor = "#666";
      break;
    case "work":
      iconColor = "#AAA";
      break;

    default:
      break;
  }

  const onhandleExpand = () => {
    if (
      (type === "large" || type === "middle") &&
      item.work_list.length === 0 &&
      item.isLoading == false
    ) {
      onExpand(item, type);
    }
    setShowDetail(!showDetail);
  };

  const renderIcon = () => {
    if (item.isLoading) {
      return (
        <>
          <AntDesign
            name={"download"}
            size={16}
            color={CommonColor.Normal.Green}
          />
          <span className="p-2">{name}</span>
        </>
      );
    }

    return (
      <>
        {type !== "work" && (
          <AntDesign
            name={showDetail ? "caretdown" : "caretright"}
            size={16}
            color={iconColor}
          />
        )}
        <span className="p-2">{name}</span>
      </>
    );
  };

  return (
    <>
      <tr
        style={isTotalRow ? { backgroundColor: CommonColor.Grey } : undefined}
      >
        <td>
          {isTotalRow && (
            <span className="p-2" style={{ fontWeight: "bold" }}>
              {name}
            </span>
          )}
          {!isTotalRow && (
            <TouchableOpacity onPress={onhandleExpand}>
              {/* // <TouchableOpacity onPress={() => onExpand(item)}> */}
              {/* <TouchableOpacity onPress={() => setShowDetail(!showDetail)}> */}
              <a
                // onClick={() => setShowDetail(!showDetail)}
                style={{ ...itemStyle }}
              >
                {renderIcon()}
              </a>
            </TouchableOpacity>
          )}
        </td>
        {/* 3か月前 */}
        <td style={{ ...priceStyle(item.t3ma), ...closeStyle, ...totalStyle }}>
          {formatLocalNumber(item.t3ma, {
            minusMark: false,
            isThousands: true,
          })}
        </td>
        {/* 2か月前 */}
        <td style={{ ...priceStyle(item.t2ma), ...totalStyle }}>
          {formatLocalNumber(item.t2ma, {
            minusMark: false,
            isThousands: true,
          })}
        </td>
        {/* 1か月前 */}
        <td style={{ ...priceStyle(item.t1ma), ...totalStyle }}>
          {formatLocalNumber(item.t1ma, {
            minusMark: false,
            isThousands: true,
          })}
        </td>
        {/* 当月 */}
        <td
          style={{
            ...priceStyle(item.tsc),
            ...{ backgroundColor: CommonColor.Dark.Blue, fontWeight: "bold" },
            ...totalStyle,
          }}
        >
          {formatLocalNumber(item.tsc, {
            minusMark: false,
            isThousands: true,
          })}
        </td>
        {/* 昨年差額 */}
        <td style={{ ...priceStyle(compare), ...closeStyle, ...totalStyle }}>
          {formatLocalNumber(compare, {
            minusMark: false,
            isThousands: true,
          })}
        </td>
        {/* 昨年*/}
        <td style={{ ...priceStyle(item.t1ya), ...closeStyle, ...totalStyle }}>
          {formatLocalNumber(item.t1ya, {
            minusMark: false,
            isThousands: true,
          })}
        </td>
      </tr>
      {showDetail &&
        item.work_list.map((work, index) => {
          return (
            <WorkListItem
              item={work}
              isExpand={isExpand}
              type={nextType}
              key={index}
              index={index}
              onExpand={onExpand}
            />
          );
        })}
    </>
  );
};

export default WorkCompareListTable;
