import React from "react";
import { displayToYearMonth } from "../../util/DateUtil";

type YearMonthLabelProps = {
  ym: string;
};

const YearMonthLabel = (props: YearMonthLabelProps) => {
  return (
    <div
      className="info"
      style={{
        marginTop: 5,
        fontWeight: "bold",
        textAlign: "center",

        fontSize: 14,
      }}
    >
      {displayToYearMonth(props.ym)}
    </div>
  );
};

export default YearMonthLabel;
