import React, { forwardRef } from "react";
import { Invoice } from "../../models/InvoiceModel";
import moment from "moment";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { Badge, CheckBox } from "react-native-elements";
import {} from "react-native-gesture-handler";
import { numberFormat } from "../../util/Math";
import { CommonColor, StateColor } from "../../constants/Colors";
import InvoiceCommentList from "../invoice/InvoiceCommentList";
import { InvoiceItemStyles } from "../styles/invoiceStyle";
import { isInPlannedStatus } from "../../util/InvoiceUtil";

type ReceptListItemProps = {
  item: Invoice;
  isChecked: boolean;
  onCheck?: (id: string) => void;
  onPress?: (item: Invoice) => void;
};

/**
 * 受領リストアイテム
 */
const ReceptListItem = React.forwardRef((props: ReceptListItemProps, ref) => {
  /**props */
  const { item, isChecked, onCheck, onPress } = props;
  //受領権限の有無
  const hasPermission = props.item.own;
  //リストクリック時
  const onHandlePress = () => {
    if (props.onPress) {
      props.onPress(props.item);
    }
  };

  //チェックボックスクリック時
  const onHandlePressCheckBox = () => {
    if (props.onCheck === undefined) {
      return;
    }
    props.onCheck(props.item.id.toString());
  };

  return (
    <TouchableOpacity
      activeOpacity={1}
      style={[
        InvoiceItemStyles.styles.container,
        {
          backgroundColor: props.item.teiban_id > 0 ? "#e1fae1" : "#fff",
        },
      ]}
      onPress={onHandlePress}
    >
      <View style={InvoiceItemStyles.styles.checkBoxContainer}>
        {hasPermission && (
          <CheckBox
            containerStyle={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
            wrapperStyle={{
              margin: 0,
            }}
            checked={props.isChecked}
            checkedColor={CommonColor.Normal.Red}
            onPress={onHandlePressCheckBox}
          />
        )}
      </View>
      <View style={InvoiceItemStyles.styles.stateContainer}>
        {props.item.draft != 1 && (
          <View
            style={{
              backgroundColor:
                props.item.state == 0 &&
                moment(props.item.billing_date).diff(moment()) > 0
                  ? "#dcdcdc"
                  : StateColor[props.item.state],
              padding: 2,
              width: 50,
            }}
          >
            <Text
              style={{
                fontSize: 12,
                textAlign: "center",
              }}
            >
              {isInPlannedStatus(item) ? "予定" : props.item.state_label}
            </Text>
          </View>
        )}
        {props.item.draft == 1 && (
          <View style={InvoiceItemStyles.styles.stateBadge}>
            <Text style={InvoiceItemStyles.styles.stateText}>下書き</Text>
          </View>
        )}
        {props.item.commented > 0 && (
          <Text style={InvoiceItemStyles.styles.commentText}>コメント済</Text>
        )}
      </View>
      <View style={InvoiceItemStyles.styles.contentContainer}>
        <View style={InvoiceItemStyles.styles.dateRow}>
          <Text style={InvoiceItemStyles.styles.dateText}>
            {moment(props.item.billing_date).format("MM/DD")}
          </Text>
          <View style={[InvoiceItemStyles.styles.orgNameContainer]}>
            <Text
              style={InvoiceItemStyles.styles.orgNameText}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {props.item.to_org_name_s}
            </Text>
          </View>
          <View style={InvoiceItemStyles.styles.workTypeContainer}>
            <Text
              style={InvoiceItemStyles.styles.workTypeText}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {props.item?.work_type_small?.name ?? "その他"}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {props.item.teiban_id > 0 && (
            <View style={InvoiceItemStyles.styles.teibanBadge}>
              <Text style={InvoiceItemStyles.styles.teibanText}>定番</Text>
            </View>
          )}
          <Text
            style={InvoiceItemStyles.styles.workText}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {props.item.work ?? "未設定"}
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          {/* 金額表示 */}
          <Text
            style={{
              fontSize: 14,
              paddingBottom: 2,
              fontWeight: "bold",
              color: props.item.state == 1 ? "#ff0000" : "#ff8c00",
            }}
          >
            {numberFormat(Number(props.item.total))}
          </Text>
          <Text
            style={InvoiceItemStyles.styles.fromOrgText}
            ellipsizeMode="tail"
            numberOfLines={1}
          >
            {"　請求元："}
            {props.item.from_org_name_s}
          </Text>
          <Text
            style={{ fontSize: 12, color: "#F00" }}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {" 【"}
            {props.item.work_type_name ?? "未設定"}
            {"】"}
          </Text>
          <Text
            style={{
              fontSize: 12,
              paddingBottom: 2,
              color: "#333",
            }}
          ></Text>
        </View>
      </View>
      <View style={InvoiceItemStyles.styles.rightContainer}>
        {props.item.unread > 0 && (
          <Badge value={props.item.unread} status="error" />
        )}
        <Text style={InvoiceItemStyles.styles.arrowText}>{">"}</Text>
      </View>
    </TouchableOpacity>
  );
});

export default ReceptListItem;
