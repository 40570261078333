import { selectWorkType } from "../screens/filter/SelectWorkTypeScreen";
import { get, post } from "../util/Api";

/**
 * 仕事モデル
 */
export interface WorkType {
  parent?: WorkType;
  company_id: number;
  created_at?: Date;
  deleted_at?: Date;
  updated_at?: Date;
  id: number;
  name: string;
  parent_id: number;
  rank: number;
  label_name?: string;
  parent_name?: string;
}

export class WorkTypeModel {
  static set(data: any): WorkType {
    const d: WorkType = {
      parent: data?.parent,
      company_id: data.company_id ?? -1,
      created_at: data.created_at,
      deleted_at: data?.deleted_at,
      id: data.id ?? -1,
      name: data.name ?? "不明",
      parent_id: data.parent_id ?? -1,
      rank: data.rank ?? 0,
      updated_at: data.updated_at,
      label_name: data?.label_name ?? "",
      parent_name: data?.parent_name ?? "",
    };

    return d;
  }

  /**
   * 仕事マスタを取得する。
   * @param params
   * @returns mid history
   */
  static async fetchFind(params?: { id?: number }): Promise<WorkType> {
    const uri = "/worktype/" + params?.id;
    let response = await get(uri);
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return this.set(response.data);
  }

  /**
   * 仕事マスタを取得する。
   * @param params
   * @returns mid history
   */
  static async fetchAll(params?: { id?: number }): Promise<WorkType[]> {
    const uri = "/worktype";
    let response = await get(uri, params);
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return Array.isArray(response.data)
      ? response.data.map((data) => this.set(data))
      : [this.set(response)];
  }

  /**
   * 中分類(仕事)の履歴を取得する
   * @param params
   * @returns mid history
   */
  static async fetchMidHistory(
    from_org_id: number,
    to_org_id: number
  ): Promise<WorkType[]> {
    const uri = "/worktype/midHistory";
    let response = await post(uri, { from_org_id, to_org_id });
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return Array.isArray(response.data)
      ? response.data.map((data) => this.set(data))
      : [this.set(response)];
  }

  /**
   * 中分類(仕事)のDBを取得する
   * @param params
   * @returns mid history
   */
  static async fetchMidDB(
    from_org_id: number,
    to_org_id: number
  ): Promise<WorkType[]> {
    const uri = "/worktype/midDB";
    let response = await post(uri, { from_org_id, to_org_id });
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return Array.isArray(response.data)
      ? response.data.map((data) => this.set(data))
      : [this.set(response)];
  }

  /**
   * 小分類(仕事)の履歴を取得する
   * @param params
   * @returns mid history
   */
  static async fetchSmallHistory(
    from_org_id: number,
    to_org_id: number,
    work_type_id: number
  ): Promise<WorkType[]> {
    const uri = "/worktype/smallHistory";
    let response = await post(uri, { from_org_id, to_org_id, work_type_id });
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return Array.isArray(response.data)
      ? response.data.map((data) => this.set(data))
      : [this.set(response)];
  }
  /**
   * 小分類(仕事)のDBを取得する
   * @param params
   * @returns mid history
   */
  static async fetchSmallDB(
    from_org_id: number,
    to_org_id: number,
    work_type_id: number
  ): Promise<WorkType[]> {
    const uri = "/worktype/smallDB";
    let response = await post(uri, { from_org_id, to_org_id, work_type_id });
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return Array.isArray(response.data)
      ? response.data.map((data) => this.set(data))
      : [this.set(response)];
  }

  /**
   * 親IDを元に仕事マスタを取得する。
   * @param params
   * @returns mid history
   */
  static async fetchAllById(params: { id: number }): Promise<WorkType[]> {
    const uri = "/worktype/getWorkTypeByParent";
    let response = await post(uri, params);
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return Array.isArray(response.data)
      ? response.data.map((data) => this.set(data))
      : [this.set(response)];
  }

  /**
   * 小分類仕事を作成する。
   * @param params
   * @returns mid history
   */
  static async fetchCreateSmall(params: {
    work_type_small_name: string;
    work_type_id: number;
  }): Promise<void> {
    const uri = "/worktype/createWorkTypeSmall";
    let response = await post(uri, params);
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return;
  }

  /**
   * 小分類仕事を更新する。
   * @param params
   * @returns mid history
   */
  static async fetchUpdateSmall(params: {
    work_type_small_name: string;
    work_type_small_id: number;
    work_type_id: number;
  }): Promise<void> {
    const uri = "/worktype/updateWorkTypeSmall";
    let response = await post(uri, params);
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    return;
  }

  /**
   * 選択画面用の仕事分類を取得する
   * @param params
   * @returns mid history
   */
  static async fetchOptions(params?: {
    id?: number;
    type?: number;
    parent_id?: number;
  }): Promise<WorkType[]> {
    const uri = "/worktype/selectOptions";
    let response = await post(uri, params);
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return Array.isArray(response.data)
      ? response.data.map((data) => this.set(data))
      : [this.set(response)];
  }
}
