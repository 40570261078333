import * as Linking from "expo-linking";
import { linkingUrl } from "../constants/Setting";

export default {
  prefixes: [Linking.makeUrl(linkingUrl())],
  config: {
    screens: {
      Loading: {
        LoadingScreen: "loading",
      },
      Login: {
        screens: {
          LoginScreen: linkingUrl() + "login",
        },
      },
      Teiban: {
        screens: {
          TeibanScreen: "teiban",
        },
      },
      Root: {
        path: linkingUrl(), // <- TRNテストサーバーの場合は追加する
        screens: {
          Drawer: {
            screens: {
              Home: {
                screens: {
                  HomeScreen: "home",
                },
              },
              Invoice: {
                screens: {
                  InvoiceScreen: "invoice",
                  InvoiceDetailScreen: "invoice/detail",
                  SelectOrganisationScreen: "invoice/select_organisation",
                },
              },
              Order: {
                screens: {
                  OrderScreen: "order",
                  OrderDetailScreen: "order/detail",
                  SelectOrderOrganisationScreen: "order/select_organisation",
                },
              },
              Receipt: {
                screens: {
                  ReceiptScreen: "receipt",
                  ReceiptDetailScreen: "receipt/detail",
                },
              },
              WorkList: {
                screens: {
                  WorkScreen: "workList",
                },
              },
              Compare: {
                screens: {
                  WorkScreen: "compare",
                },
              },
              Work: {
                screens: {
                  WorkScreen: "work",
                  WorkDetailScreen: "work/detail",
                },
              },
            },
          },
        },
      },
      NotFound: linkingUrl() + "*",
    },
  },
};
