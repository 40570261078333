import axios, { AxiosResponse, CancelToken } from "axios";
import { AuthType } from "../App";

/**APIメソッドタイプ */
export const ApiMethodType = {
  POST: "post",
  PUT: "put",
  GET: "get",
  HEAD: "head",
  DELETE: "delete",
};
/**APIタイプ */
export type ApiMethodType = (typeof ApiMethodType)[keyof typeof ApiMethodType];

/**
 * キャンセルトークン生成
 * @returns
 */
export const makeCancelTokenSource = () => {
  return axios.CancelToken.source();
};

/**
 * Post
 */
export async function post(
  url: string,
  params: any = {},
  type?: ApiMethodType
): Promise<AxiosResponse<any>> {
  let apiMethodType = ApiMethodType.POST;

  if (type !== undefined) {
    apiMethodType = type;
  }

  try {
    const response = await window.axios({
      method: apiMethodType as any,
      url: window.baseUrl + url,
      params,
      headers: { Authorization: "Bearer " + window.accessToken },
    });
    return response;
  } catch (e: any) {
    if (e.response && e.response.status === 401) {
      logOut();
    }
    throw new Error();
  }
}

/**
 * get
 */
export async function get(
  url: string,
  params: any = {},
  cancelToken?: CancelToken // ここでキャンセルトークンを引数として受け取る
): Promise<AxiosResponse<any>> {
  try {
    const response = await window.axios({
      method: "get",
      url: window.baseUrl + url,
      params,
      headers: { Authorization: "Bearer " + window.accessToken },
    });
    return response;
  } catch (e: any) {
    if (e.response && e.response.status === 401) {
      logOut();
    }

    throw new Error();
  }
}

/**
 * api
 */

/**
 * Post
 */
export async function api(args: {
  url: string;
  params?: any;
  type?: ApiMethodType;
  cancelToken?: CancelToken; // ここでキャンセルトークンを引数として受け取る
}): Promise<AxiosResponse<any>> {
  let apiMethodType = ApiMethodType.POST;

  if (args.type !== undefined) {
    apiMethodType = args.type;
  }

  try {
    const response = await window.axios({
      method: apiMethodType as any,
      url: window.baseUrl + args.url,
      params: args?.params ?? {},
      headers: { Authorization: "Bearer " + window.accessToken },
      cancelToken: args?.cancelToken, // ここで渡されたキャンセルトークンを設定
    });

    return response;
  } catch (e: any) {
    if (axios.isCancel(e)) {
      console.error("Request canceled:", e.message);
    } else if (e.response && e.response.status === 401) {
      logOut();
    }
    throw new Error();
  }
}

function logOut() {
  if (window.authType == AuthType.laravel) {
    window.functions.logout();
  }
}

export interface RequestParam {
  filter: any;
}
