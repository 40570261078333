import * as React from "react";
import { useState, useEffect, useRef, useContext } from "react";
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
} from "react-native";

import {
  OrgFilterType,
  OrgOption,
  allCompanyOption,
  departmentOption,
} from "../../components/filter/CommonBumonFilter";
import { Row, Col } from "react-bootstrap";
import WorkListTable from "../../components/workList/WorkListTable";

import {
  Invoice,
  InvoiceModel,
  InvoiceStateType,
} from "../../models/InvoiceModel";
import { currentYM } from "../../util/DateUtil";
import {
  WorkListFilter,
  WorkListFilterModel,
} from "../../models/WorkListFilterModel";
import WorkListFilterGroup from "../../components/filter/WorkListFilterGroup";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import YearMonthLabel from "../../components/common/YearMonth";
import { FloatingButton } from "../../components/common/FloatingButton";
import CoverIndicator from "../../components/common/CoverIndicator";
import SelectOrgFilter from "../../components/filter/SelectOrgFilter";
import { LocalStorageKey } from "../../models/LocalStorage";
import { formatLocalNumber } from "../../util/Math";
import { CommonColor } from "../../constants/Colors";
import { Organisations } from "../../models/OrganisationsModel";
import { WorkType } from "../../models/WorkTypeModel";
import { CheckBox } from "react-native-elements";
import {
  loadSelectedOptionByCompany,
  orgOptionsToParam,
} from "../../util/OrgUtil";
import { APP_VERSION_DATA } from "../../constants/Setting";
import { get, makeCancelTokenSource } from "../../util/Api";
import axios, { CancelTokenSource } from "axios";
import { selectedContext, tabContext } from "../../App";
import { TabList } from "../../navigation/BottomTabNavigator";
/**
 * Works list screen
 * @param props
 * @returns
 */
export default function WorkListScreen(props: any) {
  const navigation = useNavigation();

  const { month } = React.useContext(selectedContext);

  //タブがアクティブになっている。
  const { tabIndex } = useContext(tabContext);
  const isActive = tabIndex === TabList.WorkList.index;
  console.log("WorkList", isActive);

  /**一覧関連********************************************/

  const selectedMyOrganisations = loadSelectedOptionByCompany(
    LocalStorageKey.WORK_LIST
  );

  /**読み込みページ */
  const [monthPage, setMonthPage] = useState<{ [key: string]: number }>({
    [currentYM()]: 0,
  });
  /**最終ページ(初期値は-1)*/
  const [monthlast_page, setMonthLastPage] = useState<{
    [key: string]: number;
  }>({
    [currentYM()]: -1,
  });
  /**一覧情報*/
  const [monthInvoices, setMonthInvoices] = useState<{
    [key: string]: Invoice[];
  }>({});
  /**合計金額*/
  const [monthTotal, setMonthTotal] = useState<{
    [key: string]: { total: number; total_kakunin: number };
  }>({});

  /**画面制御関連********************************************/
  /**仕事一覧読み込み中フラグ */
  const [isLoading, setIsLoading] = useState(true);
  /**フィルタ読み込み中フラグ */
  const [isFilterLoading, setIsFilterLoading] = useState(true);
  /**TOPスクロールするボタンの表示フラグ */
  const [isShowUpButton, setIsShowUpButton] = useState(false);
  /**画面全体を覆うインジケーターの表示フラグ */
  const [isShowCoverIndicator, setIsShowCoverIndicator] = useState(true);
  /** スクロールビューを参照するためのref */
  const scrollViewRef = useRef<ScrollView>(null);
  /** スクロールビューの高さを設定するための状態変数 */
  const [scrollViewHeight, setScrollViewHeight] = useState<number>(0);
  /** テーブル要素を参照するためのref */
  const tableRef = useRef<HTMLTableElement>(null);
  /** テーブルの高さを設定するための状態変数*/
  const [tableHeight, setTableHeight] = useState<number>(0);
  /**初回読み込みフラグ */
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  /**テーブルの展開フラグ */
  const [isTableExpand, setIsTableExpand] = useState(
    window.width >= window.height
  );

  /**フィルタ制御関連****************************************/
  const [fromOrgOptions, setFromOrgOptions] = useState<OrgOption[]>(
    selectedMyOrganisations.fromOrgOptions ?? [departmentOption]
  );
  const [toOrgOptions, setToOrgOptions] = useState<OrgOption[]>(
    selectedMyOrganisations.toOrgOptions ?? [allCompanyOption]
  );

  /**集計フィルタ制御関連****************************************/
  /**請求先の有効化フラグ */
  const [isEnabledToOrg, setIsEnabledToOrg] = useState(false);
  /**仕事(大)の有効化フラグ */
  const [isEnabledWorkTypeLarge, setIsEnabledWorkTypeLarge] = useState(false);
  /**仕事(中)の有効化フラグ */
  const [isEnabledWorkTypeMid, setIsEnabledWorkTypeMid] = useState(false);
  /**仕事(小)の有効化フラグ */
  const [isEnabledWorkTypeSmall, setIsEnabledWorkTypeSmall] = useState(false);
  /**請求先フィルタ */
  const [toOrgFilter, setToOrgFilter] = useState<Organisations | undefined>();
  /**仕事(大)フィルタ */
  const [workTypeLargeFilter, setWorkTypeLargeFilter] = useState<
    WorkType | undefined
  >();
  /**仕事(中)フィルタ */
  const [workTypeMidFilter, setWorkTypeMidFilter] = useState<
    WorkType | undefined
  >();
  /**仕事(小)フィルタ */
  const [workTypeSmallFilter, setWorkTypeSmallFilter] = useState<
    WorkType | undefined
  >();
  /**フィルタ情報 */
  const [monthFilters, setMonthFilters] = useState<{
    [key: string]: WorkListFilter;
  }>({});
  /**受領済みのみフィルタ */
  const [isOnlyReceived, setIsOnlyReceived] = useState(false);

  /**ソート順 */
  const [sort, setSort] = useState(CONSTANTS.SORT.DAY);

  /**指定されている月の読み込みページ */
  const currentMonthPage = monthPage[month];
  /**指定されている月の最終ページ */
  const currentMonthLastPage = monthlast_page[month];
  //キャンセルトークン管理
  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource>(
    makeCancelTokenSource()
  );

  /**フィルタのパラメータ */
  const filter = {
    isEnabledToOrg: isEnabledToOrg,
    isEnabledWorkTypeLarge: isEnabledWorkTypeLarge,
    isEnabledWorkTypeMid: isEnabledWorkTypeMid,
    isEnabledWorkTypeSmall: isEnabledWorkTypeSmall,
    toOrgFilterId: toOrgFilter?.id,
    workTypeLargeFilterId: workTypeLargeFilter?.id,
    workTypeMidFilterId: workTypeMidFilter?.id,
    workTypeSmallFilterId: workTypeSmallFilter?.id,
    toOrgFilter: toOrgFilter,
    workTypeLargeFilter: workTypeLargeFilter,
    workTypeMidFilter: workTypeMidFilter,
    workTypeSmallFilter: workTypeSmallFilter,
  };

  /**データ取得 */
  let loadNext = async (params?: {
    /**初期化する　*/
    isInit?: boolean;
    /**年月　*/
    month?: string;
  }) => {
    const isInit = params?.isInit ?? false;
    if (isInit === false) {
      if (
        currentMonthPage === currentMonthLastPage &&
        currentMonthPage &&
        currentMonthLastPage
      ) {
        return;
      }
    }
    // if (isInit) {
    //   cancelTokenSource?.cancel();
    //   const cancelToken = makeCancelTokenSource();
    //   setCancelTokenSource(cancelToken);
    // }

    setIsLoading(true);

    const fromOrgParams = orgOptionsToParam(fromOrgOptions);
    const toOrgParams = orgOptionsToParam(toOrgOptions);
    const res = await InvoiceModel.fetchWorkList(
      {
        page: isInit ? 1 : currentMonthPage + 1,
        billing_ym: params?.month ?? month,
        from_filter_types: fromOrgParams.filter_types,
        from_org_ids: fromOrgParams.org_ids,
        to_filter_types: toOrgParams.filter_types,
        to_org_ids: toOrgParams.org_ids,
        states: isOnlyReceived ? [InvoiceStateType.Received] : [],
        // states: [InvoiceStateType.Received],
        filter: filter,
        sort: sort,
      },
      cancelTokenSource?.token
    );

    // if (cancelTokenSource) {
    //   setCancelTokenSource(cancelTokenSource);
    // }

    if (params?.isInit ?? false) {
      setMonthInvoices((prevMonthInvoices) => ({
        ...prevMonthInvoices,
        [month]: res.data,
      }));
    } else {
      let currentInvoices = monthInvoices[month] ?? [];
      let tmp = currentInvoices.slice();

      tmp = tmp.concat(res.data);

      setMonthInvoices((prevMonthReceipts) => ({
        ...prevMonthReceipts,
        [month]: tmp,
      }));
    }
    setMonthPage((pre) => ({
      ...pre,
      [month]: res.current_page,
    }));
    setMonthLastPage((pre) => ({
      ...pre,
      [month]: res.last_page,
    }));
    setIsLoading(false);
    setIsShowCoverIndicator(false);
  };

  /**
   * 合計金額を取得する
   */
  const fetcthTotal = async () => {
    const fromOrgParams = orgOptionsToParam(fromOrgOptions);
    const toOrgParams = orgOptionsToParam(toOrgOptions);

    const res = await InvoiceModel.fetchWorkListTotal({
      billing_ym: month,
      from_filter_types: fromOrgParams.filter_types,
      from_org_ids: fromOrgParams.org_ids,
      to_filter_types: toOrgParams.filter_types,
      to_org_ids: toOrgParams.org_ids,
      states: [],
      // states: [InvoiceStateType.Received],
      filter: filter,
    });

    setMonthTotal((pre) => ({
      ...pre,
      [month]: res,
    }));
  };

  /**リセット処理 */
  const onReload = async () => {
    setIsShowCoverIndicator(true);
    setMonthInvoices((prevMonthInvoices) => ({
      ...prevMonthInvoices,
      [month]: [],
    }));
    setMonthPage((pre) => ({
      ...pre,
      [month]: 0,
    }));
    setMonthLastPage((pre) => ({
      ...pre,
      [month]: -1,
    }));
    loadFilter();
    await loadNext({ isInit: true });
    await fetcthTotal();
    setIsShowCoverIndicator(false);
    setIsFirstLoad(false);
  };

  /**フィルタを取得する */
  const loadFilter = async () => {
    setIsFilterLoading(true);
    const fromOrgParams = orgOptionsToParam(fromOrgOptions);
    const toOrgParams = orgOptionsToParam(toOrgOptions);
    const res = await WorkListFilterModel.fetchFilter({
      page: currentMonthPage + 1,
      billing_ym: month,
      from_filter_types: fromOrgParams.filter_types,
      from_org_ids: fromOrgParams.org_ids,
      to_filter_types: toOrgParams.filter_types,
      to_org_ids: toOrgParams.org_ids,
      states: [],
      // states: [InvoiceStateType.Received],
      filter: filter,
    });

    setMonthFilters((pre) => ({
      ...pre,
      [month]: res,
    }));
    setIsFilterLoading(false);
  };

  /**
   * スクロールイベント
   */
  const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    /**操作対象の要素を取得 */
    const nativeEvent = event.nativeEvent;

    if (
      nativeEvent.contentOffset.y + nativeEvent.layoutMeasurement.height >=
      nativeEvent.contentSize.height - 1
    ) {
      if (isLoading) return;
      loadNext();
    }
    /**テーブル下まで500pxを切ったら、一番上へスクロールするボタンを表示 */
    nativeEvent.contentOffset.y > 500
      ? setIsShowUpButton(true)
      : setIsShowUpButton(false);
  };

  /**
   * フィルタを初期化する
   */
  const initFilter = () => {
    setIsEnabledToOrg(false);
    setIsEnabledWorkTypeLarge(false);
    setIsEnabledWorkTypeMid(false);
    setIsEnabledWorkTypeSmall(false);
    setToOrgFilter(undefined);
    setWorkTypeLargeFilter(undefined);
    setWorkTypeMidFilter(undefined);
    setWorkTypeSmallFilter(undefined);
  };

  /**テーブルの展開 */
  const handleonChangeExpand = () => {
    setIsTableExpand(!isTableExpand);
  };

  /**グローバル関数を設定する */
  const setWindowFunctions = () => {
    window.functions.reloadList = () => {
      if (isShowCoverIndicator) return;
      onReload();
    };

    //フォーカス時にリロードさせる。
    // if (window.reloadFlag) {
    //   window.reloadFlag = false;
    //   onReload();
    // }
  };

  // アプリのバージョンチェック
  async function app_version_check() {
    const url = "/app_version_check";
    let response = await get(url, { app_version: APP_VERSION_DATA() });
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    if (response.data) {
      alert(
        "バージョンが古い可能性がございます。ブラウザのキャッシュをクリアしてください。"
      );
    }
  }

  /**useEffect------------------------------------------------------ */

  /**■APIに影響するパラメータ  */

  // 抽出条件が変更されたらグローバル関数を更新する。
  useFocusEffect(() => {
    setWindowFunctions();
  });

  /**■ソート条件*/
  useEffect(() => {
    // if (isFirstLoad) return;
    onReload();
  }, [sort]);

  /** ■受領済みのみ フィルタ */
  useEffect(() => {
    // if (isFirstLoad) return;
    onReload();
  }, [isOnlyReceived]);

  /**■フィルタ有無 */
  useEffect(() => {
    // if (isFirstLoad) return;
    onReload();
  }, [
    toOrgOptions,
    fromOrgOptions,
    isEnabledToOrg,
    isEnabledWorkTypeLarge,
    isEnabledWorkTypeMid,
    isEnabledWorkTypeSmall,
  ]);
  /**■仕事(小)フィルタ */
  useEffect(() => {
    // if (isFirstLoad) return;
    if (isEnabledWorkTypeSmall) {
      onReload();
    }
  }, [workTypeSmallFilter]);
  /**■仕事(中)フィルタ */
  useEffect(() => {
    // if (isFirstLoad) return;
    if (isEnabledWorkTypeMid) {
      onReload();
    }
  }, [workTypeMidFilter]);
  /**■仕事(大)フィルタ */
  useEffect(() => {
    // if (isFirstLoad) return;
    if (isEnabledWorkTypeLarge) {
      onReload();
    }
  }, [workTypeLargeFilter]);
  /**■請求先フィルタ */
  useEffect(() => {
    // if (isFirstLoad) return;
    if (isEnabledToOrg) {
      onReload();
    }
  }, [toOrgFilter]);

  /**■年月 */
  useEffect(() => {
    if (!isActive) return;
    initFilter();
    onReload();
  }, [month]);
  /**■ページ */
  useEffect(() => {
    // if (isFirstLoad) return;
    if (tableRef === null) return;
    if (tableRef.current === null || tableRef.current === undefined) return;
    if (scrollViewRef.current === null || scrollViewRef.current === undefined)
      return;
    setTableHeight(tableRef.current.getBoundingClientRect().height);

    //ページが読み込めていない場合は処理をしない。
    if (
      currentMonthLastPage === undefined ||
      currentMonthLastPage === -1 ||
      currentMonthPage === undefined
    )
      return;
    //最終ページまで読み込んでいる場合は処理をしない。
    if (currentMonthLastPage === currentMonthPage) return;

    if (tableHeight < scrollViewHeight) {
      // テーブル下に達したらhandleClick
      loadNext();
    }
  }, [currentMonthLastPage, currentMonthLastPage]);

  useEffect(() => {
    if (!isActive) return;
    initFilter();
    onReload();
  }, [isActive]);
  // 画面切り替え時にバージョンが違っていたらアラート表示する。
  useEffect(() => {
    // if (!isActive) return;
    initFilter();
    onReload();
    app_version_check();
  }, [props.route.name]);

  return (
    <View style={styles.container}>
      <ScrollView
        style={{
          width: "100%",
          paddingHorizontal: 10,
          overflow: "scroll",
        }}
        onScroll={onScroll}
        scrollEventThrottle={0}
        ref={scrollViewRef}
        onLayout={(event) => {
          let { height } = event.nativeEvent.layout;
          setScrollViewHeight(height);
        }}
      >
        <YearMonthLabel ym={month} />
        <div style={{ padding: 5 }}>
          <SelectOrgFilter
            localStorageKey={LocalStorageKey.WORK_LIST}
            defaultFrom={fromOrgOptions}
            onChangeFrom={setFromOrgOptions}
            defaultTo={toOrgOptions}
            onChangeTo={setToOrgOptions}
          />
          <Row style={{ marginBottom: 5 }}>
            <WorkListFilterGroup
              isLoading={isFilterLoading}
              filter={monthFilters[month]}
              selectedFilter={filter}
              onChangedToOrg={setIsEnabledToOrg}
              onChangedWorkTypeLarge={setIsEnabledWorkTypeLarge}
              onChangedWorkTypeMid={setIsEnabledWorkTypeMid}
              onChangedWorkTypeSmall={setIsEnabledWorkTypeSmall}
              onSelectedToOrg={setToOrgFilter}
              onSelectedWorkTypeLarge={setWorkTypeLargeFilter}
              onSelectedWorkTypeMid={setWorkTypeMidFilter}
              onSelectedWorkTypeSmall={setWorkTypeSmallFilter}
            />
          </Row>

          <Row style={{ position: "relative" }}>
            <Col>
              {/* Collapseトグルボタン */}
              <View
                style={{
                  flexDirection: "row",
                  // alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CheckBox
                  containerStyle={{ paddingHorizontal: 10, paddingVertical: 1 }}
                  checked={isOnlyReceived}
                  onPress={() => {
                    if (!isFirstLoad) {
                      setIsOnlyReceived(!isOnlyReceived);
                    }
                  }}
                  title="受領済みのみ"
                />
              </View>
              {/* <Divider /> */}
            </Col>

            <Col style={{ position: "absolute", right: 0, width: "auto" }}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <TouchableOpacity
                  onPress={() => setSort(CONSTANTS.SORT.DAY)}
                  style={[
                    styles.tab,
                    {
                      borderColor:
                        sort === CONSTANTS.SORT.DAY
                          ? CommonColor.Normal.Blue
                          : CommonColor.Black,
                    },
                  ]}
                  key={CONSTANTS.SORT.DAY}
                >
                  <Text
                    style={{
                      color:
                        sort === CONSTANTS.SORT.DAY
                          ? CommonColor.Normal.Blue
                          : CommonColor.Black,
                    }}
                  >
                    {"日付順"}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => setSort(CONSTANTS.SORT.SUBCLASS)}
                  style={[
                    styles.tab,
                    {
                      borderColor:
                        sort === CONSTANTS.SORT.SUBCLASS
                          ? CommonColor.Normal.Blue
                          : CommonColor.Black,
                    },
                  ]}
                  key={CONSTANTS.SORT.SUBCLASS}
                >
                  <Text
                    style={{
                      color:
                        sort === CONSTANTS.SORT.SUBCLASS
                          ? CommonColor.Normal.Blue
                          : CommonColor.Black,
                    }}
                  >
                    {"小分類順"}
                  </Text>
                </TouchableOpacity>
              </div>
            </Col>
          </Row>
        </div>
        <View
          style={{
            borderWidth: 0.1,
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            flexDirection: "row",
            marginBottom: 5,
          }}
        >
          <View
            style={{
              backgroundColor: CommonColor.Dark.Green,
              flex: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              paddingVertical: 3,
            }}
          >
            <Text style={{ paddingRight: 10 }}>{"受領合計:"}</Text>
            <Text>
              {formatLocalNumber(monthTotal[month]?.total ?? 0, {
                isThousands: true,
              })}
            </Text>
          </View>
          <View
            style={{
              backgroundColor: CommonColor.Dark.Blue,
              flex: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              paddingVertical: 3,
            }}
          >
            <Text style={{ paddingRight: 10 }}>{"確認合計:"}</Text>
            <Text>
              {formatLocalNumber(monthTotal[month]?.total_kakunin ?? 0, {
                isThousands: true,
              })}
            </Text>
          </View>
        </View>
        <WorkListTable
          isExpand={isTableExpand}
          onChangeExpand={handleonChangeExpand}
          items={monthInvoices[month] ?? []}
          isLoading={!isShowCoverIndicator && isLoading}
          tabelRef={tableRef}
        />
      </ScrollView>
      {isShowUpButton && (
        <FloatingButton
          icon="arrow-up"
          onPress={() => {
            if (scrollViewRef.current === undefined) return;
            if (scrollViewRef.current === null) return;
            scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true });
          }}
        />
      )}
      {isShowCoverIndicator && <CoverIndicator />}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // alignItems: "flex-start",
    // justifyContent: "flex-start",
    backgroundColor: "#fff",
  },
  td: {
    borderWidth: 1,
    borderColor: "#ccc",
    backgroundColor: "#ccc",
  },
  tab: {
    paddingHorizontal: 3,
    margin: 5,
    borderBottomWidth: 2,
  },
});

/**各画面定義 */
const CONSTANTS = {
  /**ホームタブ */
  SORT: {
    /**ホームTOP画面 */
    DAY: "day",
    /**旧フィルター画面 */
    SUBCLASS: "subclass",
  },
};
