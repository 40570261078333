import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { CheckBox, Divider } from "react-native-elements";
import {
  Text,
  StyleSheet,
  View,
  ScrollView,
  TouchableOpacity,
  NativeSyntheticEvent,
  NativeScrollEvent,
} from "react-native";
import {
  OrgOption,
  allCompanyOption,
} from "../../components/filter/CommonBumonFilter";
import { Modal, Table } from "react-bootstrap";
import { WorkDB, WorkDBModel, WorkMid } from "../../models/WorkDBModel";
import CommonLoadingIndicator from "../../components/common/LoadingIndicator";
import { FloatingButton } from "../../components/common/FloatingButton";
import CoverIndicator from "../../components/common/CoverIndicator";
import {
  loadSelectedOptionByCompany,
  orgOptionsToParam,
} from "../../util/OrgUtil";
import SelectOrgFilter from "../../components/filter/SelectOrgFilter";
import { LocalStorageKey } from "../../models/LocalStorage";
import { CommonColor } from "../../constants/Colors";
import { AntDesign } from "@expo/vector-icons";
import { Organisations } from "../../models/OrganisationsModel";
import { useNavigation } from "@react-navigation/native";
import { APP_VERSION_DATA } from "../../constants/Setting";
import { get } from "../../util/Api";
import WorkMemoButton from "../../components/common/WorkMemoButton";
import WorkMemoRequestButton from "../../components/common/WorkMemoRequestButton";

const headerList = ["内容", "単価", "数量", "履歴"];

export default function WorkDBScreen2(props: any) {
  const selectedMyOrganisations = loadSelectedOptionByCompany(
    LocalStorageKey.WORK_DB
  );

  //大分類
  const [largeList, setLargeList] = useState<{ name: string; total: number }[]>(
    []
  );
  const [largeName, setLargeName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fromOrgOptions, setFromOrgOptions] = useState<OrgOption[]>(
    selectedMyOrganisations.fromOrgOptions ?? [allCompanyOption]
  ); // 請求元フィルタ
  const [toOrgOptions, setToOrgOptions] = useState<OrgOption[]>(
    selectedMyOrganisations.toOrgOptions ?? [allCompanyOption]
  ); // 請求先フィルタ
  const [isOnlyMyInvoice, setIsOnlyMyInvoice] = useState(false); // 自分が請求した仕事のみフラグ
  //全ての小分類を展開するフラグ
  const [isOpenAllSmall, setIsOpenAllSmall] = useState(false);
  const navigation = useNavigation();

  const getLargeList = async () => {
    setLargeList([]);
    const fromOrgParams = orgOptionsToParam(fromOrgOptions);
    const toOrgParams = orgOptionsToParam(toOrgOptions);
    setIsLoading(true);
    const data = await WorkDBModel.large({
      from_filter_types: fromOrgParams.filter_types,
      from_org_ids: fromOrgParams.org_ids,
      to_filter_types: toOrgParams.filter_types,
      to_org_ids: toOrgParams.org_ids,
      isOnlyMyInvoice: isOnlyMyInvoice ? 1 : 0,
    });
    if (data.length === 0) {
      setLargeName("");
    } else {
      setLargeName(data[0].name ?? "");
    }
    setLargeList(data);
    setIsLoading(false);
  };

  // アプリのバージョンチェック
  async function app_version_check() {
    const url = "/app_version_check";
    let response = await get(url, { app_version: APP_VERSION_DATA() });
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    if (response.data) {
      alert(
        "バージョンが古い可能性がございます。ブラウザのキャッシュをクリアしてください。"
      );
    }
  }

  useEffect(() => {
    getLargeList();
    // リロード関数定義
    window.functions.reloadList = () => getLargeList();
    navigation.addListener("focus", async () => {
      window.functions.reloadList = () => getLargeList();
    });
  }, [fromOrgOptions, toOrgOptions, isOnlyMyInvoice]);

  // 画面切り替え時にバージョンが違っていたらアラート表示する。
  useEffect(() => {
    app_version_check();
  }, [props.route.name]);

  return (
    <>
      <ScrollView>
        <View style={styles.container}>
          <View style={{ padding: 5, zIndex: 2 }}>
            <SelectOrgFilter
              localStorageKey={LocalStorageKey.WORK_DB}
              defaultFrom={fromOrgOptions}
              onChangeFrom={setFromOrgOptions}
              defaultTo={toOrgOptions}
              onChangeTo={setToOrgOptions}
            />
          </View>
          {/* Collapseトグルボタン */}
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingHorizontal: 10,
              paddingVertical: 5,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <CheckBox
                containerStyle={{ paddingHorizontal: 10, paddingVertical: 1 }}
                checked={isOnlyMyInvoice}
                onPress={() => {
                  setIsOnlyMyInvoice(!isOnlyMyInvoice);
                }}
                title="自分が請求した仕事のみ"
              />
              <CheckBox
                containerStyle={{ paddingHorizontal: 10, paddingVertical: 1 }}
                checked={isOpenAllSmall}
                onPress={() => {
                  setIsOpenAllSmall(!isOpenAllSmall);
                }}
                title="全ての中分類を展開する"
              />
            </View>
          </View>
          <Divider />
          <View style={{ padding: 5 }}>
            <ScrollView horizontal={true}>
              {isLoading == false && largeList.length === 0 && (
                <p>{"データが存在しません"}</p>
              )}
              {largeList.map((large) => {
                return (
                  <TouchableOpacity
                    onPress={() => setLargeName(large.name)}
                    style={[
                      styles.tab,
                      {
                        borderColor:
                          large.name === largeName
                            ? CommonColor.Normal.Blue
                            : CommonColor.Black,
                      },
                    ]}
                    key={large.name}
                  >
                    <Text
                      style={{
                        color:
                          large.name === largeName
                            ? CommonColor.Normal.Blue
                            : CommonColor.Black,
                      }}
                    >
                      {large.name + " (" + large.total + ")"}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </ScrollView>
          </View>

          {largeList.map((large) => {
            return (
              <WorkTable
                large={large}
                largeName={largeName}
                fromOrgOptions={fromOrgOptions}
                toOrgOptions={toOrgOptions}
                key={large.name}
                isOnlyMyInvoice={isOnlyMyInvoice}
                isOpenAllSmall={isOpenAllSmall}
              />
            );
          })}
          {/* 取得インジケータ */}
        </View>
      </ScrollView>
      {isLoading && <CoverIndicator />}
    </>
  );
}

/**
 * 中分類仕事テーブル
 * @param param0
 * @returns
 */
const WorkTable = ({
  large,
  largeName,
  fromOrgOptions,
  toOrgOptions,
  isOnlyMyInvoice = false,
  isOpenAllSmall = false,
}: {
  large: { name: string; total: number };
  largeName: string;
  fromOrgOptions: OrgOption[];
  toOrgOptions: OrgOption[];
  isOnlyMyInvoice: boolean;
  isOpenAllSmall: boolean;
}) => {
  const [middleList, setMiddleList] = useState<WorkMid[]>([]);
  const [middleNameList, setMiddleNameList] = useState<string[]>([]);
  const [detailList, setDetailList] = useState<
    {
      billing_date: string;
      user_name: string;
      fromOrg?: Organisations;
      toOrg?: Organisations;
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowUpButton, setIsShowUpButton] = useState(false);
  const scrollViewRef = useRef<ScrollView>(null);

  const onShowDetail = (name: string) => {
    if (middleNameList.includes(name)) {
      setMiddleNameList(middleNameList.filter((mnl) => mnl !== name));
    } else {
      setMiddleNameList([...middleNameList, name]);
    }
  };

  const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const currentTarget = event.nativeEvent;
    // テーブル下まで500pxを切ったら、一番上へスクロールするボタンを表示
    currentTarget.contentOffset.y > 500
      ? setIsShowUpButton(true)
      : setIsShowUpButton(false);
  };

  const getDetail = async (workDB: WorkDB) => {
    const fromOrgParams = orgOptionsToParam(fromOrgOptions);
    const toOrgParams = orgOptionsToParam(toOrgOptions);
    setIsLoading(true);
    const detailList = await WorkDBModel.detail(
      {
        from_filter_types: fromOrgParams.filter_types,
        from_org_ids: fromOrgParams.org_ids,
        to_filter_types: toOrgParams.filter_types,
        to_org_ids: toOrgParams.org_ids,
        isOnlyMyInvoice: isOnlyMyInvoice ? 1 : 0,
      },
      workDB
    );
    setDetailList(detailList);
    setIsLoading(false);
  };

  const getList = async () => {
    const fromOrgParams = orgOptionsToParam(fromOrgOptions);
    const toOrgParams = orgOptionsToParam(toOrgOptions);
    setIsLoading(true);
    const middleList = await WorkDBModel.middle({
      from_filter_types: fromOrgParams.filter_types,
      from_org_ids: fromOrgParams.org_ids,
      to_filter_types: toOrgParams.filter_types,
      to_org_ids: toOrgParams.org_ids,
      large_name: large.name,
      isOnlyMyInvoice: isOnlyMyInvoice ? 1 : 0,
    });
    setMiddleList(middleList);
    setIsLoading(false);
  };

  useEffect(() => {
    if (large.name === largeName) {
      getList();
    }
  }, [fromOrgOptions, toOrgOptions]);

  useEffect(() => {
    if (large.name === largeName && middleList.length === 0) {
      getList();
    }
  }, [largeName]);

  useEffect(() => {
    if (isLoading) return;
    let middleNames: string[] = [];
    if (isOpenAllSmall) {
      middleNames = middleList.map((middle) => {
        return middle.name;
      });
    }

    setMiddleNameList(middleNames);
  }, [isOpenAllSmall, isLoading]);

  if (large.name !== largeName) {
    return <></>;
  }

  return (
    <>
      <ScrollView
        onScroll={onScroll}
        scrollEventThrottle={0}
        ref={scrollViewRef}
        style={styles.scroll}
      >
        <Table striped bordered hover>
          <thead>
            <tr>
              {headerList.map((header) => {
                return (
                  <th className="_sticky bg-secondary text-white" key={header}>
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody style={{ overflow: "scroll" }}>
            {middleList.map((middle) => {
              const showDetail = middleNameList.includes(middle.name);
              return (
                <React.Fragment key={middle.name}>
                  <tr>
                    <td colSpan={6}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <TouchableOpacity
                          style={{ flexDirection: "row" }}
                          onPress={() => onShowDetail(middle.name)}
                        >
                          <AntDesign
                            name={showDetail ? "caretdown" : "caretright"}
                            size={16}
                            color={CommonColor.Black}
                            style={{ margin: "auto" }}
                          />

                          <span className="p-2" style={{ fontWeight: "bold" }}>
                            {middle.name +
                              " (" +
                              middle.small_list?.length +
                              ")"}
                          </span>
                        </TouchableOpacity>
                        <div
                          style={{
                            height: "100%",
                            marginLeft: 5,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <WorkMemoButton
                            layoutType="left"
                            workTypeMidId={middle.id}
                          />
                          <WorkMemoRequestButton
                            layoutType="right"
                            workTypeId={middle.id}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  {showDetail && (
                    <SmallList
                      smallList={middle.small_list}
                      getDetail={getDetail}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
        {/* 取得インジケータ */}
        {isLoading && middleList.length === 0 && <CommonLoadingIndicator />}
      </ScrollView>
      {isShowUpButton && (
        <FloatingButton
          icon="arrow-up"
          onPress={() =>
            scrollViewRef.current?.scrollTo({ x: 0, y: 0, animated: true })
          }
        />
      )}
      {/* 取得インジケータ */}
      {isLoading && middleList.length > 0 && <CoverIndicator />}
      <Modal
        show={detailList.length > 0}
        onHide={() => setDetailList([])}
        backdrop={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{"履歴"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table>
            <thead>
              <tr>
                <th>{"請求日"}</th>
                <th>{"請求元"}</th>
                <th>{"請求先"}</th>
              </tr>
            </thead>
            <tbody>
              {detailList.map((detail) => {
                return (
                  <tr key={detail.billing_date + detail.user_name}>
                    <td className="p-2">{detail.billing_date}</td>
                    <td className="p-2">
                      {detail.fromOrg?.name ?? "削除済み部門"}
                    </td>
                    <td className="p-2">
                      {detail.toOrg?.name ?? "削除済み部門"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
};

const SmallList = ({
  smallList,
  getDetail,
}: {
  smallList: { name: string; id: number; detail_list: WorkDB[] }[];
  getDetail: (workDB: WorkDB) => void;
}) => {
  const [smallNameList, setSmallNameList] = useState<string[]>([]);

  const onShowDetail = (name: string) => {
    if (smallNameList.includes(name)) {
      setSmallNameList(smallNameList.filter((snl) => snl !== name));
    } else {
      setSmallNameList([...smallNameList, name]);
    }
  };

  return (
    <>
      {smallList.map((small) => {
        const showDetail = smallNameList.includes(small.name);
        return (
          <React.Fragment key={small.name}>
            <tr>
              <td colSpan={6}>
                <div
                  style={{
                    display: "flex",
                    marginLeft: 15,
                    alignItems: "center",
                  }}
                >
                  <TouchableOpacity
                    style={{ flexDirection: "row" }}
                    onPress={() => onShowDetail(small.name)}
                  >
                    <AntDesign
                      name={showDetail ? "caretdown" : "caretright"}
                      size={16}
                      color={CommonColor.Dark.Grey}
                      style={{ margin: "auto" }}
                    />
                    <span className="p-2">
                      {small.name + " (" + small.detail_list.length + ")"}
                    </span>
                  </TouchableOpacity>
                  <div
                    style={{
                      height: "100%",
                      marginLeft: 5,
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <WorkMemoButton
                      workTypeSmallId={small.id}
                      layoutType="left"
                    />
                    <WorkMemoRequestButton
                      workTypeId={small.id}
                      layoutType="right"
                    />
                  </div>
                </div>
              </td>
            </tr>
            {showDetail &&
              small.detail_list.map((workDB, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <span style={{ marginLeft: 30 }}>{workDB.work}</span>
                    </td>
                    <td>{workDB.unit_price}</td>
                    <td>{workDB.quantity}</td>
                    {/* <td>{workDB.total}</td> */}
                    <td>
                      <TouchableOpacity
                        onPress={() => getDetail(workDB)}
                        style={{
                          paddingHorizontal: 8,
                          paddingVertical: 2,
                          backgroundColor: CommonColor.Normal.Blue,
                          borderRadius: 20,
                        }}
                      >
                        <Text
                          style={{
                            color: CommonColor.White,
                            textAlign: "center",
                          }}
                        >
                          {"履歴"}
                        </Text>
                      </TouchableOpacity>
                    </td>
                  </tr>
                );
              })}
          </React.Fragment>
        );
      })}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 10,
    backgroundColor: CommonColor.White,
  },
  text: {
    fontSize: 16,
  },
  scroll: {
    width: "100%",
  },
  tab: {
    paddingHorizontal: 3,
    margin: 5,
    borderBottomWidth: 2,
  },
});
