import { OrgOption } from "../components/filter/CommonBumonFilter";

/**ローカルストレージキー */
export const LocalStorageKey = {
  /**ホーム画面用フィルタ */
  HOME: "HomeTotalFilter",
  /**請求画面用フィルタ */
  INVOICE: "InvoiceFilter",
  /**受領画面用フィルタ */
  RECEIPT: "ReceiptFilter",
  /**仕事一覧画面用フィルタ */
  WORK_LIST: "WorkListFilter",
  /**仕事比較画面用フィルタ */
  WORK_COMPARE: "WorkCompareFilter",
  /**仕事DB画面用フィルタ */
  WORK_DB: "WorkDBFilter",

  /**請求日 */
  BILLING_DATE: "invoice_billing_date",
};
/**先月・今月フィルタータイプ */
export type LocalStorageKey =
  (typeof LocalStorageKey)[keyof typeof LocalStorageKey];

interface Obj {
  label: string;
  value: string;
}

interface SatateObj {
  label: string;
  value: number;
}

export const FILTERS = {};

/**
 * 部門フィルタLocalStorage
 */
export interface OrganisationsFilterStorage {
  toOrgOptions?: OrgOption[];
  fromOrgOptions?: OrgOption[];
}

/**
 * 部門フィルタ(全社)LocalStorage
 */
export interface OrganisationsFilterStorageCompany {
  companyId: number;
  orgOptions: OrganisationsFilterStorage;
}

/**
 * 請求絞り込み条件LocalStorage
 */
/**
 * 請求絞り込み条件LocalStorage
 *
 * @property {Obj} billing_y_obj - 年情報
 * @property {Obj} billing_m_obj - 月情報
 * @property {number} from_org - 差出部門ID
 * @property {string} from_org_name - 差出部門名
 * @property {number} to_org - 宛先部門ID
 * @property {string} to_org_name - 宛先部門名
 * @property {number} work_type_id - 作業ID
 * @property {string} work_type_name - 作業名
 * @property {SatateObj} state_obj - ステータスオブジェクト
 */
export interface InvoiceFilterStorage {
  billing_y_obj: Obj;
  billing_m_obj: Obj;

  from_org: number;
  from_org_name: string;
  to_org: number;
  to_org_name: string;
  work_type_id: number;
  work_type_name: string;
  state_obj: SatateObj;
}

/**
 * 受注絞り込み条件LocalStorage
 */
export interface ReceiptFilterStorage {
  billing_y_obj: Obj;
  billing_m_obj: Obj;
  from_org: number;
  from_org_name: string;
  to_org: number;
  to_org_name: string;
  work_type_id: number;
  work_type_name: string;
  state_obj: SatateObj;
}

/**ローカルストレージのキー*/
export const LOCAL_STORAGE_KEY = {
  /**アクセストークン*/
  ACCESS_TOKEN: "accessToken",
};
