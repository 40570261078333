const tintColorLight = "#2f95dc";
const tintColorDark = "#fff";

export default {
  light: {
    text: "#000",
    background: "#fff",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: "#fff",
    background: "#000",
    tint: tintColorDark,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
  },
};

/**
 * 請求の状態を示す色
 */
export const StateColor: string[] = [
  "#a0d8ef",
  "#9ae69a",
  "#f19ca7",
  "#bdafdb",
  "#f4a460",
];

export const CommonColor = {
  Normal: {
    Grey: "#EEE",
    Blue: "#2f95dc",
    Green: "#3cb371",
    Red: "#D83232",
  },

  Light: {
    Grey: "#EEE",
    Blue: "#D7DDE0",
    Green: "#CAE8CF",
  },
  Dark: {
    Grey: "#676767",
    Blue: "#C0E3F9",
    Green: "#89CC97",
  },
  Blue: "#2f95dc",
  Grey: "#E4E4E4",
  White: "#FFFFFF",
  Black: "#000000",
  Orange: "#FF8C00",
  Red: "#D83232",
};
