import { post } from "../util/Api";

export interface HomeTotal {
  day_md: string;
  total: number;
  total_k: number;
  cumulative_total: number;
  cumulative_total_k: number;
}

export class HomeTotalModel {
  static set(data: any): HomeTotal {
    const d: HomeTotal = {
      day_md: data.day_md,
      total: data.total,
      total_k: data.total_k,
      cumulative_total: data.cumulative_total,
      cumulative_total_k: data.cumulative_total_k,
    };

    return d;
  }

  static async fetchList(params: {
    billing_ym: string;
    to_filter_types: number[];
    to_org_ids: number[];
    // to_self_org_ids: number[];
    from_filter_types: number[];
    from_org_ids: number[];
    // from_self_org_ids: number[];
  }): Promise<HomeTotal[]> {
    const uri = "/home/list";
    let response = await post(uri, params);

    if (response.status !== 200) {
      throw new Error();
    }

    return Array.isArray(response.data)
      ? response.data.map((data) => this.set(data))
      : [this.set(response)];
  }
}
