import * as React from "react";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Calendar from "react-calendar";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { CommonColor } from "../../constants/Colors";
import { FontAwesome } from "@expo/vector-icons";
import { LocalStorageService } from "../../services/LocalStorageService.";

interface SortChangeButtonProps {
  onPress: (e: boolean) => void;
  localStorageKey?: string;
  isAsc: boolean;
}

/**
 *
 */
const SortChangeButton = (props: SortChangeButtonProps) => {
  /**変更時のイベント */
  const handleOnPress = () => {
    if (props.localStorageKey !== undefined) {
      LocalStorageService.set(props.localStorageKey, !props.isAsc);
    }

    props.onPress(!props.isAsc);
  };

  return (
    <>
      <TouchableOpacity
        style={{
          marginRight: 10,
          paddingHorizontal: 8,
          paddingVertical: 2,
          backgroundColor: CommonColor.Normal.Grey,
          borderRadius: 20,
        }}
        onPress={handleOnPress}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {props.isAsc ? (
            <FontAwesome name="arrow-up" size={10} color="black" />
          ) : (
            <FontAwesome name="arrow-down" size={10} color="black" />
          )}
          <Text
            style={{
              marginLeft: 5,
            }}
          >
            {props.isAsc ? "昇順" : "降順"}
          </Text>
        </View>
      </TouchableOpacity>
    </>
  );
};

export default SortChangeButton;
