import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
  useNavigationState,
  useNavigation,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { useEffect, useState } from "react";
import { ColorSchemeName } from "react-native";

import NotFoundScreen from "../screens/common/NotFoundScreen";
import { RootStackParamList } from "../types";
import LoginNavigator from "./LoginNavigator";
import BottomTabNavigator from "./BottomTabNavigator";
import LinkingConfiguration from "./LinkingConfiguration";
import LoadingScreen from "../screens/common/LoadingScreen";
// import { useAuth0 } from "@auth0/auth0-react";
import { LOCAL_STORAGE_KEY } from "../models/LocalStorage";
import { Auth0Setting } from "../constants/Setting";
import { AuthType, loadLocalStorageInit } from "../App";
import { UserModel } from "../models/UserModel";
import { LocalStorageService } from "../services/LocalStorageService.";

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator() {
  const [isAuth, setIsAuth] = useState(false);
  const [isLaunching, setIsLaunching] = useState(true);
  const functions = { setIsAuth };
  // const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  window.functions.setIsAuth = setIsAuth;
  const loadToken = () => {
    window.functions.isAuth().then(async (token: any) => {
      // console.log("token呼び出し：", token);
      if (token != "" && token != null) {
        window.accessToken = token;
        window.authType = AuthType.laravel;
        await loadLocalStorageInit();
        await fetchMyUserInfo();
        setIsAuth(true);
        setIsLaunching(false);
      } else {
        window.accessToken = "";
        setIsAuth(false);
        setIsLaunching(false);
      }
    });
  };

  /**
   * 自分のユーザー情報を取得する
   */
  const fetchMyUserInfo = async () => {
    try {
      const res = await UserModel.fetchMyUserInfo();
      LocalStorageService.setUser(res);
      window.userInfo = res;
    } catch (error) {}
  };

  // useEffect(() => {
  //   const getUserMetadata = async () => {
  //     const domain = Auth0Setting().domain;

  //     try {
  //       const accessToken = await getAccessTokenSilently({
  //         authorizationParams: {
  //           audience: `https://${domain}/api/v2/`,
  //           scope: "read:current_user",
  //         },
  //       });
  //       console.log("accessToken:", accessToken);
  //       window.accessToken = accessToken;
  //       window.authType = AuthType.auth0;
  //       setIsAuth(true);
  //       setIsLaunching(false);

  //       const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
  //       const metadataResponse = await fetch(userDetailsByIdUrl, {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       });

  //       const { user_metadata } = await metadataResponse.json();
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };

  //   getUserMetadata();
  // }, [getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    loadToken();
  }, []);

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      {isLaunching && (
        <Stack.Screen
          name="Loading"
          component={LoadingScreen}
          options={{ title: "Now Loading" }}
        />
      )}
      {!isAuth && (
        <Stack.Screen name="Login">
          {() => <LoginNavigator functions={functions} />}
        </Stack.Screen>
      )}
      {isAuth && (
        <Stack.Screen name="Root">{() => <BottomTabNavigator />}</Stack.Screen>
      )}
      <Stack.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={{ title: "Oops!" }}
      />
    </Stack.Navigator>
  );
}
