import React from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";

/**画面を覆うインジケーター */
export default function CoverIndicator() {
  return (
    <View style={styles.container}>
      <View style={styles.overlay}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  overlay: {
    backgroundColor: "rgba(255, 255, 255,1)",
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 10,
  },
});
