import * as React from "react";
import { TouchableOpacity, Text, ViewStyle, StyleProp } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { StyledProps } from "styled-components";

type FloatingButtonProps = {
  style?: StyleProp<ViewStyle>;
  text?: string;
  icon?: string;
  onPress?: () => void;
};

/**
 * フローティングボタン
 * @param {
 *   style = {},
 *   text,
 *   icon,
 *   onPress,
 * }
 * @returns
 */
export function FloatingButton({
  style = {},
  text,
  icon,
  onPress,
}: FloatingButtonProps) {
  const defaultStyle = {
    position: "absolute",
    bottom: 5,
    right: 30,
    width: 50,
    height: 50,
    backgroundColor: "#2f95dc",
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
  };

  const mergedStyle = {
    ...defaultStyle,
    ...(style as object),
  } as StyleProp<ViewStyle>;

  return (
    <TouchableOpacity style={mergedStyle} onPress={onPress}>
      {icon && <FontAwesome name={icon} size={24} color="#fff" />}
      {text && <Text style={{ color: "#fff" }}>{text}</Text>}
    </TouchableOpacity>
  );
}
