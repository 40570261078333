import { MonthFilterType } from "../components/filter/SelectMonth";

/**
 * 先月・今月のクエリ
 */
interface BillingMonth {
  month: {
    label: string;
    value: string;
  };
  year: {
    label: string;
    value: string;
  };
}

/**
 * 月フィルタタイプをクエリ用に成形する。
 * @param mode
 * @returns
 */
export function toBillingMonth(mode: MonthFilterType): BillingMonth {
  const moment = require("moment");
  let df = mode == MonthFilterType.LAST ? -1 : 0;
  let y = moment().add("month", df).startOf("month").format("YYYY");
  let billing_y_obj = { label: y, value: y };
  let m = moment().add("month", df).startOf("month").format("MM");
  let billing_m_obj = { label: m, value: m };

  return { year: billing_y_obj, month: billing_m_obj };
}

/**
 * YMD形式を4/1表記に変更する
 * @param ymd
 * @returns
 */
export function dateToMD(ymd: string) {
  const date = new Date(ymd);

  const month = date.getMonth() + 1; // getMonth()は0から始まるため、1を足す
  const day = date.getDate();

  const formattedDate = `${month}/${day}`;
  return formattedDate;
}

/**
 * YMDで形式より曜日を取得する
 * @param ymd
 * @returns
 */
export function dateToDayName(ymd: string) {
  // 入力された日付をDateオブジェクトに変換
  const dateObject = new Date(ymd);

  // 曜日を取得 (0が日曜日, 1が月曜日, ... 6が土曜日)
  const daysOfWeek = ["(日)", "(月)", "(火)", "(水)", "(木)", "(金)", "(土)"];
  const dayIndex = dateObject.getDay();
  const dayName = daysOfWeek[dayIndex];

  //曜日の色
  let dayColor = "#000";
  switch (dayIndex) {
    case 0:
      dayColor = "#F00";

      break;
    case 6:
      dayColor = "#00F";

      break;

    default:
      break;
  }

  return { name: dayName, index: dayIndex, color: dayColor };
}

/**
 * 現在時刻をYM形式で取得する
 * @param ymd
 * @returns
 */
export function currentYM() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const YMFormat = `${year}${month}`;
  return YMFormat;
}

/**
 * YM形式で１月先にする
 * @param ym
 * @returns
 */
export function incrementYM(ym: string) {
  const year = parseInt(ym.substring(0, 4), 10);
  const month = parseInt(ym.substring(4, 6), 10);
  let newYear = year;
  let newMonth = month + 1;
  if (newMonth > 12) {
    newYear += 1;
    newMonth = 1;
  }
  const incrementYM = `${newYear}${newMonth.toString().padStart(2, "0")}`;

  return incrementYM;
}

/**
 * YM形式で１月前にする
 * @param ym
 * @returns
 */
export function decrementMonth(ym: string) {
  const year = parseInt(ym.substring(0, 4), 10);
  const month = parseInt(ym.substring(4, 6), 10);

  let newYear = year;
  let newMonth = month - 1;

  if (newMonth < 1) {
    newYear -= 1;
    newMonth = 12;
  }

  const decrementMonth = `${newYear}${newMonth.toString().padStart(2, "0")}`;
  return decrementMonth;
}

/**
 * 指定された年月を操作する関数
 * @param yearMonth 操作を行う年月の文字列（YYYYMM形式）
 * @param move 移動させる月数（正の数で未来に進み、負の数で過去に戻る）
 * @returns 操作後の年月の文字列（YYYYMM形式）
 */
export function manipulateYearMonth(yearMonth: string, move: number): string {
  const year = parseInt(yearMonth.slice(0, 4));
  const month = parseInt(yearMonth.slice(4, 6)) - 1;

  const date = new Date(year, month);
  date.setMonth(date.getMonth() + move);

  const manipulatedYear = date.getFullYear();
  const manipulatedMonth = (date.getMonth() + 1).toString().padStart(2, "0");

  return `${manipulatedYear}${manipulatedMonth}`;
}

/**
 * YM形式を"４月"形式に変更する
 * @param ym
 * @returns
 */
export function displayToMonth(ym: string) {
  const year = parseInt(ym.substring(0, 4), 10);
  const month = parseInt(ym.substring(4, 6), 10);

  const monthStr = month.toString();

  return monthStr + "月";
}

/**
 * YM形式を"2023年4月"形式に変更する
 * @param ym
 * @returns
 */
export function displayToYearMonth(ym: string) {
  const year = parseInt(ym.substring(0, 4), 10);
  const month = parseInt(ym.substring(4, 6), 10);

  const yearStr = year.toString();
  const monthStr = month.toString();

  return yearStr + "年" + monthStr + "月";
}

export function extractYM(ym: string): BillingMonth {
  const year = parseInt(ym.substring(0, 4), 10);
  const month = parseInt(ym.substring(4, 6), 10);

  const billingMonth: BillingMonth = {
    month: { label: month.toString(), value: month.toString() },
    year: { label: year.toString(), value: year.toString() },
  };

  return billingMonth;
}
