import React, { useState } from "react";
import { TouchableOpacity, Text, View, StyleSheet } from "react-native";

/**先月・今月フィルタータイプ */
export const MonthFilterType = {
  /**先月 */
  LAST: "last",
  /**今月 */
  THIS: "this",
};
/**先月・今月フィルタータイプ */
export type MonthFilterType =
  (typeof MonthFilterType)[keyof typeof MonthFilterType];

function SelectMonth() {
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  const [monthString, setMonthString] = useState<string>(`${currentMonth}月`);
  const [selectedMonth, setSelectedMonth] = useState<string>(
    MonthFilterType.THIS
  );

  const setMonthFilter = (month: string) => {
    let monthString = "";
    if (month === MonthFilterType.LAST) {
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      monthString = `${date.getMonth() + 1}月`;
      // 先月のデータを取得する処理など
    } else if (month === MonthFilterType.THIS) {
      const date = new Date();
      monthString = `${date.getMonth() + 1}月`;
      // 今月のデータを取得する処理など
    }
    setSelectedMonth(month);
    setMonthString(monthString);
  };

  return (
    <View style={styles.container}>
      <View style={styles.buttonGroup}>
        <TouchableOpacity
          onPress={() => {
            setMonthFilter(MonthFilterType.LAST);
            window.functions.setMonthFilter(MonthFilterType.LAST);
          }}
          style={[
            styles.button,
            selectedMonth === MonthFilterType.LAST && styles.selectedButton,
          ]}
        >
          <Text
            style={[
              styles.buttonText,
              selectedMonth === MonthFilterType.LAST &&
                styles.selectedButtonText,
            ]}
          >
            先月
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setMonthFilter(MonthFilterType.THIS);
            window.functions.setMonthFilter(MonthFilterType.THIS);
          }}
          style={[
            styles.button,
            selectedMonth === MonthFilterType.THIS && styles.selectedButton,
          ]}
        >
          <Text
            style={[
              styles.buttonText,
              selectedMonth === MonthFilterType.THIS &&
                styles.selectedButtonText,
            ]}
          >
            今月
          </Text>
        </TouchableOpacity>
        <Text style={styles.monthText}>{monthString}</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  buttonGroup: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    // marginBottom: 10,
  },
  button: {
    width: 70,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    marginHorizontal: 5,
  },
  selectedButton: {
    backgroundColor: "#2B92CD",
  },
  buttonText: {
    color: "#2B92CD",
    fontSize: 18,
  },
  selectedButtonText: {
    color: "#FFFFFF",
  },
  monthText: {
    paddingLeft: 10,
    paddingRight: 15,
    fontSize: 24,
    color: "#2B92CD",
    fontWeight: "bold",
  },
});

export default SelectMonth;
