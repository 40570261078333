import * as React from "react";

import { View, Text } from "react-native";

type CommonListEmptyProps = {
  /** 読み込み中フラグ*/
  isLoading: boolean;
};

/**
 *　何もレコードが無い場合に表示する
 * @param props
 */
const CommonListEmpty = (props: CommonListEmptyProps) => {
  return (
    <View>
      {!props.isLoading && (
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text style={{ textAlign: "center" }}>データがありません</Text>
        </View>
      )}
    </View>
  );
};

export default CommonListEmpty;
