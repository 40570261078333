import React, { useState } from "react";
import {
  Text,
  ViewStyle,
  TouchableOpacity,
  View,
  StyleProp,
} from "react-native";
import { CommonColor } from "../../constants/Colors";
// import { WorkMemoRequests } from "../../models/WorkMemoRequestsModel";
// import { WorkMemoRequestModal } from "../modal/WorkMemoRequestModal";
import CommonLoadingIndicator from "./LoadingIndicator";
import { Spinner } from "react-bootstrap";
import { WorkMemoRequestModal } from "../modal/WorkMemoRequestModal";
import { WorkMemoRequests } from "../../models/WorkMemoRequestsModel";

interface WorkMemoRequestButton {
  style?: ViewStyle;
  // onPress?: (WorkMemoRequest: WorkMemoRequests) => void;
  workTypeId?: number;
  workMemoId?: number;
  layoutType?: "right" | "left" | "center" | "normal";
}
/**
 * 仕事メモボタン
 * @param props
 * @returns
 */
const WorkMemoRequestButton = (props: WorkMemoRequestButton) => {
  /** */
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isExist, setIsExist] = useState<boolean | undefined>();

  const openModal = () => {
    setIsShowModal(true);
  };

  const closeModal = () => {
    setIsShowModal(false);
  };

  // const onSelected = (WorkMemoRequest: WorkMemoRequests) => {
  //   if (!props.onPress) return;
  //   props.onPress(WorkMemoRequest);
  //   closeModal();
  // };

  const handleOnLoad = (WorkMemoRequests: WorkMemoRequests | null) => {
    setIsExist(WorkMemoRequests !== null ? true : false);
  };

  const bgColor = () => {
    if (isExist) {
      return CommonColor.Red;
    } else if (isExist === undefined) {
      return CommonColor.Grey;
    } else {
      return CommonColor.Dark.Grey;
    }
  };

  const radius = () => {
    const layoutType = props?.layoutType;

    let radius;
    switch (layoutType) {
      case "left":
        radius = { borderTopLeftRadius: 10, borderBottomLeftRadius: 10 };
        break;

      case "right":
        radius = { borderTopRightRadius: 10, borderBottomRightRadius: 10 };
        break;

      case "center":
        radius = {};
        break;

      default:
        radius = { borderRadius: 10 };
        break;
    }

    return radius;
  };

  return (
    <View style={props.style}>
      <TouchableOpacity
        style={[
          props.style,
          {
            backgroundColor: bgColor(),
            paddingHorizontal: 10,
            paddingVertical: 2,
            ...radius(),
          },
        ]}
      >
        <Text style={{ color: CommonColor.White }} onPress={openModal}>
          要望
          {isExist === undefined ? (
            <Spinner
              animation="border"
              role="status"
              size="sm"
              style={{ marginLeft: 5 }}
            />
          ) : (
            <Text style={{ color: CommonColor.White, marginLeft: 5 }}>
              {isExist ? `(★)` : ""}
            </Text>
          )}
        </Text>
      </TouchableOpacity>
      <WorkMemoRequestModal
        workTypeId={props?.workTypeId}
        workMemoId={props?.workMemoId}
        isShow={isShowModal}
        // onPress={onSelected}
        onDismiss={closeModal}
        onLoaded={handleOnLoad}
      />
    </View>
  );
};

export default WorkMemoRequestButton;
