import React from "react";
import { TouchableOpacity, StyleSheet, View } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { CommonColor } from "../../constants/Colors";

type NextAndBackButtonProps = {
  onPrevious?: () => void;
  onNext?: () => void;
  previousDisabled?: boolean;
  nextDisabled?: boolean;
};

const NextAndBackButton = (props: NextAndBackButtonProps) => {
  const { onPrevious, onNext, previousDisabled, nextDisabled } = props;

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={onPrevious}
        style={[
          styles.button,
          styles.previousButton,
          previousDisabled && styles.disabledButton,
        ]}
        disabled={previousDisabled}
      >
        <FontAwesome name="angle-left" size={20} color="white" />
      </TouchableOpacity>

      <TouchableOpacity
        onPress={onNext}
        style={[
          styles.button,
          styles.nextButton,
          nextDisabled && styles.disabledButton,
        ]}
        disabled={nextDisabled}
      >
        <FontAwesome name="angle-right" size={20} color="white" />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  button: {
    backgroundColor: CommonColor.Normal.Blue,
    borderRadius: 10,
    padding: 8,
  },
  previousButton: {
    marginRight: 4,
  },
  nextButton: {
    marginLeft: 4,
  },
  disabledButton: {
    opacity: 0.1,
  },
});

export default NextAndBackButton;
