import formatNumber from "format-number";

/**
 * 数値を金額表示に変換する
 * @param num 数値
 * @param unit 単位補正(千円)
 * @returns
 */
export function convertToMoneyAndFormat(num: number, unit: boolean = true) {
  let result = num;
  if (unit) {
    result = Math.round(result / 1000);
  }

  let symbol: string = "";
  if (num < 0) {
    symbol = "△ ";
    result = Math.abs(result);
  }

  return symbol + result.toLocaleString();
}

/**
 * 少数点以下を四捨五入する
 * @param number
 * @returns
 */
export function numberFormat(number: number) {
  const formattedNumber = formatNumber({
    round: 0,
  })(number);

  return formattedNumber;
}

/**
 * 金額を千円単位でフォーマット
 * @param number
 * @param option?
 * @returns
 */
export function formatLocalNumber(
  /**金額 */
  number: number,
  option?: {
    /**マイナスマーク */
    minusMark?: boolean;
    /**千単位 */
    isThousands?: boolean;
    /**円の表示 */
    isShowUnit?: boolean;
    /**小数点以下の桁数 */
    decimalPlaces?: number;
  }
) {
  const isThousands = option?.isThousands ?? false;
  const isShowUnit = option?.isShowUnit ?? false;
  const decimalPlaces = option?.decimalPlaces ?? 0;
  const minusMarkFlg = option?.minusMark ?? true;
  const minusMark = minusMarkFlg ? "△ " : "- ";

  if (isThousands) {
    const unit = isShowUnit ? "" : "";
    const formattedTotal = Number(
      (Math.abs(number) / 1000).toFixed(decimalPlaces)
    ).toLocaleString();

    return `${number >= 0 ? "" : minusMark}${formattedTotal}${unit}`;
  }

  const unit = isShowUnit ? "円" : "";
  const formattedTotal = Number(
    Math.abs(number).toFixed(decimalPlaces)
  ).toLocaleString();

  return `${number >= 0 ? "" : minusMark}${formattedTotal}${unit}`;
}
