import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { convertToMoneyAndFormat } from "../../util/Math";
import { CommonColor } from "../../constants/Colors";
import { UsersGoal } from "../../models/UsersGoalsModel";
import { OrgFilterType, OrgOption } from "../filter/CommonBumonFilter";
import { AssignmentType } from "../../models/AssignmentModel";
import { Organisations } from "../../models/OrganisationsModel";

export interface Props {
  isLoading?: boolean;
  /**確定前請求合計 */
  total?: number;
  /**確定後請求合計 */
  total_k?: number;
  /**目標値 */
  usersGoals: UsersGoal[];
  fromOrgOptions: OrgOption[];
  allOrgs: Organisations[];
}

/**
 * 目標値表示テーブル
 * @param props
 * @returns
 */
const HomeTargetTable = (props: Props) => {
  const { usersGoals, fromOrgOptions, allOrgs } = props;
  const [total, setTotal] = useState<number>(props.total ?? 0);
  const [total_k, setTotal_k] = useState<number>(props.total_k ?? 0);

  const unit: string = "";

  /**選択された部門の目標値を取得する。 */
  const getSeletedOrgGoals = () => {
    const all = fromOrgOptions.some((e) => e.type.id == OrgFilterType.ALL.id);
    const self = fromOrgOptions.some((e) => e.type.id == OrgFilterType.SELF.id);
    const receive = fromOrgOptions.some(
      (e) => e.type.id == OrgFilterType.RECEIVE.id
    );

    const thousand = 1000;

    //【全社】か【自分の所属部門】を選択している場合。
    if (all || self) {
      // goalsの合計値を求める
      const sumGoal = usersGoals.reduce((sum, e) => sum + e.goal, 0);
      return sumGoal * thousand;
    }
    //【自分の受領権限】を選択している場合。
    let selectedMyOrg: Organisations[] = [];
    let selectedOrgIds: number[] = [];
    if (receive) {
      selectedMyOrg = allOrgs.filter(
        (e) => e.assignment?.role === AssignmentType.RECEIVING_AUTHORITY
      );
      selectedOrgIds = selectedMyOrg.map((e) => e.id);
    }

    //【選択部門】
    const otherSelectOrgIds = fromOrgOptions.map((e) => e.organisations?.id);
    const ids = otherSelectOrgIds.filter(
      (id): id is number => typeof id === "number"
    );
    //結合して重複を除去する
    selectedOrgIds = Array.from(new Set([...ids, ...selectedOrgIds]));

    //セグメントで選択している所属部門
    const selectedGoals = usersGoals.filter((e) =>
      selectedOrgIds.includes(e.from_org)
    );

    // goalsの合計値を求める
    const sumGoal = selectedGoals.reduce((sum, e) => sum + e.goal, 0);

    return sumGoal * thousand;
  };

  const sumGoal = getSeletedOrgGoals();

  //受領と確定の合計
  const sumTotal = (): number => {
    return Number(total) + Number(total_k);
  };

  //受領と目標の差額
  const difference = (): number => {
    return total - sumGoal;
  };

  //合計と目標の差額
  const totalDifference = (): number => {
    return sumTotal() - sumGoal;
  };
  const renderLoading = () => {
    return (
      <Spinner animation="border" variant="primary" size="sm" role="status" />
    );
  };

  const renderPriceForHeader = (
    price: number,
    columnType: number,
    isTdTag: boolean = true
  ) => {
    let fontColor: string = "#000";
    if (price < 0) {
      fontColor = "#F00";
    }
    let backgroundColor: string = "";
    if (columnType === 1) {
      backgroundColor = CommonColor.Dark.Green; // 受領カラー
    } else if (columnType === 2) {
      backgroundColor = CommonColor.Dark.Blue; // 確定カラー
    } else if (columnType === 3) {
      backgroundColor = CommonColor.Dark.Grey; // 合計カラー
    }

    let result = "";
    if (columnType == 1) {
      result = "受領合計";
    } else if (columnType == 2) {
      result = "確認合計";
    } else if (columnType == 3) {
      result = "総合計";
    }

    const label = props.isLoading
      ? renderLoading()
      : convertToMoneyAndFormat(price) + unit;
    if (isTdTag) {
      return (
        <td style={{ color: fontColor }}>
          <span>{result}</span>
          <span>{label}</span>
        </td>
      );
    }
    return (
      // 見出し
      <th
        style={{
          color: fontColor,
          backgroundColor,
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <span>{result}</span>
        <span>{label}</span>
      </th>
    );
  };

  const renderPriceForBody = (
    price: number,
    columnType: number,
    isTdTag: boolean = true
  ) => {
    let fontColor: string = "#000";
    if (price < 0) {
      fontColor = "#F00";
    }
    let backgroundColor: string = "";
    if (columnType === 1) {
      backgroundColor = CommonColor.Dark.Green; // 受領カラー
    } else if (columnType === 2) {
      backgroundColor = CommonColor.Dark.Blue; // 確定カラー
    } else if (columnType === 3) {
      backgroundColor = CommonColor.Dark.Grey; // 合計カラー
    }

    let result = "";
    if (price < 0) {
      result = "不足";
    } else if (price >= 0) {
      result = "達成";
    }

    const label = props.isLoading
      ? "　"
      : convertToMoneyAndFormat(price) + unit;

    if (isTdTag) {
      return (
        // 要素側
        <td
          style={{
            color: fontColor,
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {!props.isLoading && <span>{result}</span>}
          <span style={{}}>{label}</span>
        </td>
      );
    }
    return (
      <th style={{ color: fontColor, backgroundColor, textAlign: "right" }}>
        <span style={{}}>{result}</span>
        <span style={{}}>{label}</span>
      </th>
    );
  };

  useEffect(() => {
    setTotal(props.total ?? 0);
    setTotal_k(props.total_k ?? 0);
  }, [props.total, props.total_k, props.isLoading]);

  return (
    <Table responsive hover bordered>
      <thead>
        <tr className="bg-secondary text-white" style={{ display: "flex" }}>
          {renderPriceForHeader(total, 1, false)}
          {renderPriceForHeader(total_k, 2, false)}
          {renderPriceForHeader(sumTotal(), 3, false)}
        </tr>
      </thead>
      <tbody>
        <tr style={{ display: "flex" }}>
          {renderPriceForBody(difference(), 1)}
          {/* // 空白 */}
          <td
            style={{
              width: "100%",
            }}
          ></td>
          {renderPriceForBody(totalDifference(), 3)}
        </tr>
      </tbody>
    </Table>
  );
};

export default HomeTargetTable;
