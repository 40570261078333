import { post } from "../util/Api";
import { Organisations } from "./OrganisationsModel";

/**
 * ユーザー目標値
 */
export interface UsersGoal {
  id?: number;
  company_id?: number;
  // user_id: number;
  yyyymm: string;
  goal: number;
  created_at?: Date;
  updated_at?: Date;
  from_org: number;
}

export class UsersGoalsModel {
  static set(data: any): UsersGoal {
    const d: UsersGoal = {
      id: data.id,
      company_id: data.company_id,
      // user_id: data.user_id,
      yyyymm: data.yyyymm,
      goal: data.goal,
      created_at: data.created_at,
      updated_at: data.updated_at,
      from_org: data?.from_org ?? 0,
    };

    return d;
  }

  /**
   * 目標値を取得する
   * @param params
   * @returns
   */
  // static async find(params: { billing_ym: string }): Promise<UsersGoal | null> {
  //   const uri = "/usergoals/find";
  //   let response = await post(uri, params);
  //   if (response.status !== 200) {
  //     throw new Error();
  //   }

  //   if (response.data === null || response.data === undefined) {
  //     return null;
  //   }
  //   return this.set(response.data);
  // }

  /**
   * 目標値を取得する(各部門単位)
   * @param params
   * @returns
   */
  static async all(params: { yyyymm: string }): Promise<UsersGoal[] | null> {
    const uri = "/usergoals/all";
    let response = await post(uri, params);

    if (response.status !== 200) {
      throw new Error();
    }

    if (response.data === null || response.data === undefined) {
      return null;
    }

    return Array.isArray(response.data)
      ? response.data.map((data) => this.set(data))
      : [this.set(response)];
  }

  /**
   * 目標値を登録する
   * @param usersGoals
   * @returns
   */
  static async upsert(usersGoals: UsersGoal[]): Promise<boolean> {
    const uri = "/usergoals/upsert";
    let response = await post(uri, { usersGoals: usersGoals });
    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  /**
   * 旧システムで設定された目標値
   * ※本部目標値でもある
   * @param params
   * @returns
   */
  static async oldGoal(params: {
    from_filter_types: number[],
    from_org_ids: number[],
    billing_ym: string ,
  }): Promise<number | null> {
    const uri = "/home/oldGoal";
    let response = await post(uri, params);
    if (response.status !== 200) {
      throw new Error();
    }

    if (response.data === null || response.data === undefined) {
      return null;
    }
    return response.data.goal;
  }
}
