import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  CardStyleInterpolators,
  createStackNavigator,
} from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { View } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";

import AccountScreen from "../../screens/menu/AccountScreen";

import { BottomTabParamList, AccountParamList } from "../../types";
import { HeaderLeft } from "../../components/header/LeftHeader";

//アカウント画面（現在はログアウトするための画面）
const AccountStack = createStackNavigator<AccountParamList>();
export function AccountNavigator() {
  const navigation: any = useNavigation();
  window.functions.openDrawer = () => navigation.openDrawer();
  return (
    <AccountStack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
    >
      <AccountStack.Screen
        name="AccountScreen"
        component={AccountScreen}
        options={{
          headerTitle: "ログアウト",
          headerLeft: HeaderLeft,
          headerRight: () => <View style={{ flexDirection: "row" }}></View>,
        }}
      />
    </AccountStack.Navigator>
  );
}
