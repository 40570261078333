import { post } from "../util/Api";
import { WorkType } from "./WorkTypeModel";

export interface WorkMemos {
  id?: number;
  created_at?: Date | null;
  updated_at?: Date | null;
  company_id: number;
  work_type_id: number;
  name: string;
  unit_price: number;
  rank: number;
  work_type_obj?: WorkType;
}

export class WorkMemosModel {
  /**
   * 引数のデータからWorkMemosオブジェクトを生成して返します。
   *
   * @param data 生成元のデータ
   * @returns WorkMemosオブジェクト
   */
  static set(data: any): WorkMemos {
    // WorkMemosオブジェクトを生成
    const d: WorkMemos = {
      // 必須フィールドの設定
      id: data.id,
      company_id: data.company_id,
      work_type_id: data.work_type_id,
      name: data.name,
      unit_price: data.unit_price,
      rank: data.rank,
      work_type_obj: data?.work_type_obj,
      // 任意フィールドの設定
      created_at: data.created_at,
      updated_at: data.updated_at,
    };

    return d;
  }

  /**
   * 指定されたフィルターパラメータに基づいて、すべての作業メモを取得します。
   *
   * @param {Object} params - 作業メモのフィルターパラメータ。
   * @param {number} params.worktype_id - 作業タイプのID。
   * @return {Promise<WorkMemos[]>} すべての作業メモを含むオブジェクトを解決するPromise。
   */
  static async all(params: {
    workTypeSmallId?: number;
    worktypeMidId?: number;
  }): Promise<WorkMemos[]> {
    const uri = "/workmemos/all";
    let response = await post(uri, params);
    return Array.isArray(response.data)
      ? response.data.map((data) => this.set(data))
      : [];
  }
}
