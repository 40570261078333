import React from "react";
import { View, TouchableOpacity, Text } from "react-native";

export function OrgHeader(props: any) {
  return (
    <View style={{ flexDirection: "row" }}>
      <TouchableOpacity
        onPress={() => {
          window.functions.unset();
        }}
        style={{ paddingLeft: 10, paddingRight: 10, marginTop: 3 }}
      >
        <Text style={{ fontSize: 16, color: "#c00" }}>未選択にする</Text>
      </TouchableOpacity>
    </View>
  );
}
