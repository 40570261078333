import React, { useState, useEffect } from "react";
import { Text, ViewStyle, TouchableOpacity } from "react-native";
import { CommonColor } from "../../constants/Colors";

interface AddWorkTypeSmallButton {
  style?: ViewStyle;
  backScreen: string;
  gotoScreen: string;
  workTypeMidId?: number;
  workTypeMidName?: string;
  workTypeSmallId?: number;
}
/**
 * 小分類追加ボタン
 * @param props
 * @returns
 */
const AddWorkTypeSmallButton = (props: AddWorkTypeSmallButton) => {
  const onPress = () => {
    window.navi.navigate(props.gotoScreen, {
      backScreen: props.backScreen,
      workTypeMidId: props.workTypeMidId,
      workTypeMidName: props.workTypeMidName,
      workTypeSmallId: props.workTypeSmallId,
    });
  };

  return (
    <TouchableOpacity
      style={{
        backgroundColor: CommonColor.Normal.Blue,
        paddingHorizontal: 10,
        paddingVertical: 2,
        borderRadius: 10,
      }}
    >
      <Text style={{ color: CommonColor.White }} onPress={onPress}>
        小分類を登録
      </Text>
    </TouchableOpacity>
  );
};

export default AddWorkTypeSmallButton;
