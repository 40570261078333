import { post } from "../util/Api";

/**
 * ユーザー
 */
export interface User {
  /**管理者権限(名称が紛らわしい) */
  admin: number;
  current_company_id: number;
  email: string;
  // email_verified_at?: Date;
  id: number;
  /**最上位管理者権限(名称が紛らわしい) */
  master: number;
  name: string;
  rank: number;
  created_at: Date;
  updated_at: Date;
  deleted_at?: Date;
  current_company?: Company;
}
export class UserModel {
  static set(data: any): User {
    const d: User = {
      admin: data.admin,
      current_company_id: data.current_company_id,
      email: data.email,
      // email_verified_at
      id: data.id,
      master: data.master,
      name: data.name,
      rank: data.rank,
      created_at: data.created_at,
      updated_at: data.updated_at,
      current_company: data.current_company,
    };

    return d;
  }

  /**
   * 自分のユーザー情報を取得する
   * @returns
   */
  static async fetchMyUserInfo(): Promise<User> {
    const uri = "/user/showMyInfo";
    let response = await post(uri, {});
    return this.set(response.data);
  }
}
