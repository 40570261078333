import * as React from "react";
import { useEffect } from "react";
import { StyleSheet, View, ScrollView } from "react-native";
import { Button } from "react-native-elements";

import { useNavigation } from "@react-navigation/native";
// import { useAuth0 } from "@auth0/auth0-react";
import { AuthType } from "../../App";

export default function AccountScreen() {
  const navigation = useNavigation();
  // const { user, logout } = useAuth0();

  const handleLogOut = () => {
    // if (window.authType == AuthType.auth0) {
    //   logout({
    //     logoutParams: {
    //       redirect_uri: window.location.origin,
    //     },
    //   });
    //   window.functions.setIsAuth(false);
    // } else {
    window.functions.logout();
    // }
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {});
    return unsubscribe;
  }, []);

  return (
    <View style={styles.container}>
      <ScrollView
        style={{
          width: "100%",
          padding: 10,
        }}
      >
        <View
          style={{
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: 30,
          }}
        >
          <Button
            type={"solid"}
            style={{}}
            title="ログアウトする"
            onPress={handleLogOut}
          />
          <Button
            type={"outline"}
            style={{ marginTop: 30 }}
            title="ホームに戻る"
            onPress={() => {
              window.navi.navigate("HomeScreen");
            }}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
  },
});
