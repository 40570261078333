import { post } from "../util/Api";
import { Organisations } from "./OrganisationsModel";
import { WorkType } from "./WorkTypeModel";

/**
 *　仕事一覧フィルタ
 */
export interface WorkListFilterOption {
  isEnabledToOrg: boolean;
  isEnabledWorkTypeLarge: boolean;
  isEnabledWorkTypeMid: boolean;
  isEnabledWorkTypeSmall: boolean;
  toOrgFilterId?: number;
  workTypeLargeFilterId?: number;
  workTypeMidFilterId?: number;
  workTypeSmallFilterId?: number;
  toOrgFilter?: Organisations;
  workTypeLargeFilter?: WorkType;
  workTypeMidFilter?: WorkType;
  workTypeSmallFilter?: WorkType;
}

/**
 * 仕事一覧モデル
 */
export interface WorkListFilter {
  to_orgs: Organisations[];
  workTypeLarges: WorkerType[];
  workTypeMids: WorkerType[];
  workTypeSmalls: WorkerType[];
}

export class WorkListFilterModel {
  static init(): WorkListFilterOption {
    return {
      isEnabledToOrg: false,
      isEnabledWorkTypeLarge: false,
      isEnabledWorkTypeMid: false,
      isEnabledWorkTypeSmall: false,
      toOrgFilterId: undefined,
      workTypeLargeFilterId: undefined,
      workTypeMidFilterId: undefined,
      workTypeSmallFilterId: undefined,
      toOrgFilter: undefined,
      workTypeLargeFilter: undefined,
      workTypeMidFilter: undefined,
      workTypeSmallFilter: undefined,
    };
  }

  static set(data: any): WorkListFilter {
    const d: WorkListFilter = {
      to_orgs: data.toOrganations,
      workTypeLarges: data.workTypeLarges,
      workTypeMids: data.workTypeMids,
      workTypeSmalls: data.workTypeSmalls,
    };

    return d;
  }

  /**
   * フィルタを取得する
   * @param params
   * @returns mid history
   */
  static async fetchFilter(params: {
    page: number;
    billing_ym: string;
    to_filter_types: number[];
    to_org_ids: number[];
    from_filter_types: number[];
    from_org_ids: number[];
    states: number[];
    filter: WorkListFilterOption;
    isOnlySelectedYM?: number;
  }): Promise<WorkListFilter> {
    const uri = "/workList/filters";
    let response = await post(uri, params);
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return this.set(response.data);
  }
}
