import * as React from "react";

/**
 * 金額の表示スタイル
 * @param price
 * @returns
 */
export function priceStyle(price: number) {
  const style: React.CSSProperties = {
    textAlign: "right",
    color: price < 0 ? "red" : "inherit",
  };
  return style;
}
