import {
  CardStyleInterpolators,
  createStackNavigator,
} from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { useState } from "react";

import { CompareTabParamList, WorkListTabParamList } from "../../types";
import { HeaderLeft } from "../../components/header/LeftHeader";
import { SCREENS } from "../../constants/Screens";
import { ReceiptHeader } from "../../components/header/ReceiptHeader";
import { WorkListHeader } from "../../components/header/WorkListHeader";
import CompareScreen from "../../screens/compare/CompareScreen";
import { CompareHeader } from "../../components/header/CompareHeader";

//受領一覧
const CompareTabStack = createStackNavigator<CompareTabParamList>();
export function CompareTabNavigator() {
  const navigation: any = useNavigation();
  window.functions.openDrawer = () => navigation.openDrawer();

  const [confChecked, setConfChecked] = useState(false);
  window.functions.setConfChecked = setConfChecked;

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("tabPress", (e: any) => {
      window.reloadFlag = true;
    });
    return unsubscribe;
  }, [navigation]);

  return (
    <CompareTabStack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
    >
      <CompareTabStack.Screen
        name={SCREENS.COMPARE.COMPARE as keyof CompareTabParamList}
        component={CompareScreen}
        options={{
          headerTitle: "比較",
          headerLeft: HeaderLeft,
          headerRight: (props) => CompareHeader(props),
        }}
      />
    </CompareTabStack.Navigator>
  );
}
