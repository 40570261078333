import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { OrgFilterType, OrgOption } from "../filter/CommonBumonFilter";
import { View, Text, TouchableOpacity } from "react-native";
import { Organisations } from "../../models/OrganisationsModel";
import { displayToYearMonth } from "../../util/DateUtil";
import { AssignmentType } from "../../models/AssignmentModel";
import CommonInput, { InputType } from "../common/CommonInput";
import { UsersGoal } from "../../models/UsersGoalsModel";
import { Regex } from "../../constants/Regex";
import { CommonColor } from "../../constants/Colors";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";

export interface Props {
  onClose: () => void;
  onRegister: (v: UsersGoal[]) => void;
  preUsersGoals: UsersGoal[];
  show: boolean;
  fromOrgOptions: OrgOption[];
  allOrgs: Organisations[];
  selectYM: string;
}

const HomeTargetModal = (props: Props) => {
  const {
    onClose,
    onRegister,
    show,
    allOrgs,
    selectYM,
    fromOrgOptions,
    preUsersGoals,
  } = props;
  // const defaultUsersGoals: UsersGoal[] = myAllOrgs.map((e) => ({
  //   user_id: 0,
  //   yyyymm: selectYM, // yyyymmの初期値を適切な値に置き換える必要があります
  //   goal: 0,
  //   fromOrg: e.id, // goalの初期値を適切な値に置き換える必要があります
  // }));

  const [showOtherOrgs, setShowOtherOrgs] = useState<boolean>(false);
  const [usersGoals, setUsersGoals] = useState<UsersGoal[]>(preUsersGoals);

  if (allOrgs.length === 0) return <></>;

  /**自部門を取得する */
  const getMyOrg = (): {
    selected: Organisations[];
    disSelected: Organisations[];
  } => {
    //受領権限がある部門
    const myOrgs = allOrgs.filter(
      (e) =>
        e.assignment?.role === AssignmentType.RECEIVING_AUTHORITY ||
        e.assignment?.role === AssignmentType.GENERAL
    );

    const all = fromOrgOptions.some((e) => e.type.id == OrgFilterType.ALL.id);
    const self = fromOrgOptions.some((e) => e.type.id == OrgFilterType.SELF.id);
    const receive = fromOrgOptions.some(
      (e) => e.type.id == OrgFilterType.RECEIVE.id
    );

    //【全社】か【自分の所属部門】を選択している場合。
    if (all || self) {
      return { selected: sortOrgByUserGoals(myOrgs), disSelected: [] };
    }
    //【自分の受領権限】を選択している場合。
    let selectedMyOrg: Organisations[] = [];
    let selectedOrgIds: number[] = [];
    if (receive) {
      selectedMyOrg = allOrgs.filter(
        (e) => e.assignment?.role === AssignmentType.RECEIVING_AUTHORITY
      );
      selectedOrgIds = selectedMyOrg.map((e) => e.id);
    }

    //【選択部門】
    const otherSelectOrgIds = fromOrgOptions.map((e) => e.organisations?.id);
    const ids = otherSelectOrgIds.filter(
      (id): id is number => typeof id === "number"
    );
    //結合して重複を除去する
    selectedOrgIds = Array.from(new Set([...ids, ...selectedOrgIds]));

    //セグメントで選択している所属部門
    selectedMyOrg = myOrgs.filter((e) => selectedOrgIds.includes(e.id));
    const selectedMyOrgIds = selectedMyOrg.map((e) => e.id);

    let disSelectedMyOrg = myOrgs.filter(
      (e) => !selectedMyOrgIds.includes(e.id)
    );

    return {
      selected: sortOrgByUserGoals(selectedMyOrg),
      disSelected: sortOrgByUserGoals(disSelectedMyOrg),
    };
  };

  const sortOrgByUserGoals = (orgs: Organisations[]) => {
    orgs = orgs.map((e) => {
      const updatedE = { ...e }; // オブジェクトのコピーを作成
      updatedE.usersGoals = usersGoals.find(
        (usersGoal) => usersGoal.from_org == e.id
      );
      return updatedE; // 修正されたオブジェクトを返す
    });

    orgs.sort((a, b) => {
      const aFromOrg = a.usersGoals ? a.usersGoals.goal : 0; // usersGoalsが存在しない場合は0を使用
      const bFromOrg = b.usersGoals ? b.usersGoals.goal : 0; // usersGoalsが存在しない場合は0を使用
      return bFromOrg - aFromOrg;
    });

    return orgs;
  };

  const myOrgs = getMyOrg();

  const onChangeShowOtherOrgs = () => {
    setShowOtherOrgs(!showOtherOrgs);
  };

  /**
   * 目標値の入力
   * @param userGoal
   * @param v
   */
  const onChangeTarget = (userGoal: UsersGoal, v: string) => {
    // usersGoals内でuserGoalのfromOrgと一致する要素があるかチェック
    const isExist = usersGoals.some((e) => e.from_org === userGoal.from_org);

    if (Regex.NUMBER_WITH_NEGATIVE.test(v) == false && v != "") {
      // console.log(Regex.NUMBER_WITH_NEGATIVE.test(v));
      return;
    }

    // 新しい usersGoals ステートを作成
    const updatedUsersGoals = usersGoals.map((e) => {
      // fromOrgが一致する要素を探す
      if (e.from_org === userGoal.from_org) {
        // goalを更新
        e.goal = Number(v);
      }
      return e;
    });
    // fromOrgが一致する要素がない場合、新しい要素を追加
    if (!isExist) {
      userGoal.goal = Number(v);
      updatedUsersGoals.push(userGoal);
    }

    // 更新後の usersGoals ステートをセット
    setUsersGoals(updatedUsersGoals);
  };

  return (
    <Modal show={show} onHide={onClose} dialogClassName="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title>{displayToYearMonth(selectYM) + " 目標設定"}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "50vh", overflowY: "auto" }}>
        <Text>{"選択中の所属部門"}</Text>
        {myOrgs.selected.map((e) => renderItem(e))}
        <TouchableOpacity
          onPress={onChangeShowOtherOrgs}
          style={{ flexDirection: "row" }}
        >
          <AntDesign
            name={showOtherOrgs ? "caretdown" : "caretright"}
            size={16}
            // color={iconColor}
          />
          <Text>{"その他の所属部門"}</Text>
        </TouchableOpacity>
        {showOtherOrgs && myOrgs.disSelected.map((e) => renderItem(e))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onRegister(usersGoals)}>保存</Button>
        <Button
          variant="secondary"
          // style={{ backgroundColor: CommonColor.Light.Grey }}
          onClick={onClose}
        >
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );

  function renderItem(org: Organisations) {
    const preUserGoal = usersGoals.find((e) => e.from_org == org.id);
    const usersGoal: UsersGoal = preUserGoal
      ? preUserGoal
      : {
          user_id: 0,
          yyyymm: selectYM, // yyyymmの初期値を適切な値に置き換える必要があります
          goal: 0,
          from_org: org.id, // goalの初期値を適切な値に置き換える必要があります
        };

    const goal = usersGoal.goal !== 0 ? usersGoal.goal.toString() : "";
    const bgColor = goal == "" ? "#CCC" : "#FFF";

    return (
      <View
        key={org.id.toString()}
        style={{
          flex: 1,
          justifyContent: "center",
          paddingLeft: 10,
          paddingRight: 10,
          borderBottomColor: "#ccc",
          // borderBottomWidth: 1,
        }}
      >
        <Text>{org.name}</Text>
        <InputGroup size="sm" className="mb-3">
          <CommonInput
            value={goal}
            type={InputType.NUMBER}
            placeholder={"設定されていません"}
            style={{ textAlign: "right", backgroundColor: bgColor }}
            onChange={(v) => {
              onChangeTarget(usersGoal, v);
            }}
          />
          <InputGroup.Text>千円</InputGroup.Text>
          <Button
            variant="outline-primary"
            id="button-addon2"
            onClick={() => {
              onChangeTarget(usersGoal, "");
            }}
          >
            削除
          </Button>
        </InputGroup>
      </View>
    );
  }
};

export default HomeTargetModal;
