import React, { Fragment, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FlatList, View, TouchableOpacity } from "react-native";
import { Divider, Text } from "react-native-elements";
import { WorkMemos, WorkMemosModel } from "../../models/WorkMemosModel";
import { convertToMoneyAndFormat } from "../../util/Math";

export interface SelectItem {
  title: string;
  id: number;
}

type WorkMemoModalProps = {
  isShow: boolean;
  title?: string;
  body?: string;
  element?: JSX.Element;
  actionTitle?: string;
  workTypeMidId?: number;
  workTypeSmallId?: number;
  onPress: (e: WorkMemos) => void;
  onDismiss?: () => void;
  onLoaded?: (e: WorkMemos[]) => void;
};

export function WorkMemoModal(props: WorkMemoModalProps) {
  const [workMemos, setWorkMemos] = useState<WorkMemos[]>([]);
  const [items, setItems] = useState<SelectItem[]>([]);

  const fetch = async (params: {
    workTypeSmallId?: number;
    workTypeMidId?: number;
  }) => {
    const res = await WorkMemosModel.all(params);
    const items = res.map((memo) => {
      return {
        id: memo?.id ?? 0,
        title: memo.name,
      };
    });
    setWorkMemos(res ?? []);
    setItems(items ?? []);
    if (props.onLoaded) {
      props.onLoaded(res);
    }
  };

  const renderItems = (index: number) => {
    const workMemo: WorkMemos = workMemos[index];

    return (
      <Fragment key={workMemo.id}>
        <TouchableOpacity
          onPress={() => {
            props.onPress(workMemo);
          }}
        >
          <Row
            className="pt-2 mb-1"
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            <View style={{ flexDirection: "row" }}>
              {/* <p className="mb-0" style={{ height: 16 }}>
                {`【${workMemo.work_type_obj?.parent?.name}】`}
              </p> */}
              {/* 小分類仕事 */}
              <p className="mb-0" style={{ fontSize: 12, color: "#393CFF" }}>
                {`【${workMemo.work_type_obj?.name}】`}
              </p>
            </View>
          </Row>
          <Row className="mb-2" style={{ alignItems: "center" }}>
            {/* 仕事 */}
            <Col style={{ flex: 1 }}>
              <p className="mb-0" style={{ fontSize: 14, fontWeight: "bold" }}>
                {workMemo.name}
              </p>
            </Col>
            {/* 単価 */}
            <Col style={{ flex: 1, textAlign: "right" }}>
              <p className="mb-0" style={{ fontSize: 14, fontWeight: "bold" }}>
                {convertToMoneyAndFormat(workMemo.unit_price, false)}
              </p>
            </Col>
          </Row>
          <Divider />
        </TouchableOpacity>
      </Fragment>
    );
  };

  useEffect(() => {
    if (props.workTypeMidId == undefined || props.workTypeMidId == 0) return;
    fetch({ workTypeMidId: props.workTypeMidId });
  }, [props.workTypeMidId]);

  useEffect(() => {
    if (props.workTypeSmallId == undefined || props.workTypeSmallId == 0)
      return;
    fetch({ workTypeSmallId: props.workTypeSmallId });
  }, [props.workTypeSmallId]);

  function keyExtractor(item: SelectItem, index: number): string {
    return item.id.toString();
  }

  return (
    <>
      <Modal
        show={props.isShow}
        onHide={props?.onDismiss}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{"仕事(メモ)"}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "50vh", overflowY: "auto" }}>
          <Container>
            <FlatList
              keyExtractor={keyExtractor}
              data={items}
              renderItem={({ item, index }) => renderItems(index)}
              ListEmptyComponent={() => <Text>仕事メモはありません。</Text>}
            />
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onDismiss}>閉じる</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
