import React from "react";
import { Form } from "react-bootstrap";

/**
 * 入力タイプ
 */
export const InputType = {
  TEXT: "text",
  PASSWORD: "password",
  EMAIL: "email",
  NUMBER: "number",
  DATE: "date",
  TIME: "time",
  FILE: "file",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  SELECT: "select",
  TEXTAREA: "textarea",
};

export type InputType = (typeof InputType)[keyof typeof InputType];

type CommonInputProps = {
  value: string;
  placeholder?: string;
  onChange?: (v: string) => void;
  type?: InputType;
  style?: React.CSSProperties;
};

/**
 * 共通の入力フォーム
 */
const CommonInput: React.FC<CommonInputProps> = (props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  return (
    <Form.Control
      style={props.style}
      type={props.type ?? InputType.TEXT}
      value={props.value}
      placeholder={props.placeholder}
      onChange={handleChange}
      aria-label="Text input"
    />
  );
};

export default CommonInput;
