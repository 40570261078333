import * as React from "react";
import { Text } from "react-native-elements";
import { View } from "react-native";

import { CommonColor } from "../../constants/Colors";

type HeaderBarProps = {
  title: string;
};
export function HeaderTitleBar(params: HeaderBarProps) {
  return (
    <View
      style={{
        width: "100%",
        height: 25,
        backgroundColor: CommonColor.Normal.Green,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text style={{ textAlign: "center", fontSize: 14, color: "#fff" }}>
        {params.title}
      </Text>
    </View>
  );
}
