import { ApiMethodType, get, post } from "../util/Api";
import { WorkType } from "./WorkTypeModel";

/**
 * 定番
 */
export interface Teiban {
  id: number;
  company_id: number;
  type: number;
  to_org: number;
  from_org: number;
  user_id: number;
  work_type_id: number;
  work: string;
  unit_price: number;
  quantity_type: number;
  quantity: number;
  total: number;
  source_id: number;
  share: number;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
  company_name: string;
  to_org_name: string;
  last_billing_dt: Date;
  company: Company;
  to_org_obj: Org;
  from_org_obj?: Org;
  work_type_obj?: WorkType;
  display_order: number;
  targetday: string;
  draft: number;
  work_type_small_id?: number;
  work_type_small_obj?: WorkType;
}

/**
 * 定番モデル
 */
export class TeibanModel {
  static set(data: any): Teiban {
    const d: Teiban = {
      id: data.id,
      company_id: data.company_id,
      type: data.type,
      to_org: data.to_org,
      from_org: data.from_org,
      user_id: data.user_id,
      work_type_id: data.work_type_id,
      work: data.work,
      unit_price: data.unit_price,
      quantity_type: data.quantity_type,
      quantity: data.quantity,
      total: data.total,
      source_id: data.source_id,
      share: data.share,
      created_at: data.created_at,
      updated_at: data.updated_at,
      deleted_at: data.deleted_at,
      company_name: data.company_name,
      to_org_name: data.to_org_name,
      last_billing_dt: data.last_billing_dt,
      company: data.company,
      to_org_obj: data.to_org_obj,
      from_org_obj: data?.from_org_obj,
      work_type_obj: data?.work_type_obj,
      display_order: data?.display_order,
      targetday: data?.targetday,
      draft: data?.draft,
      work_type_small_id: data?.work_type_small_id,
      work_type_small_obj: data?.work_type_small_obj,
    };

    return d;
  }

  /**
   * 定番一覧取得
   * @param params
   * @returns list
   */
  static async index(params: {
    page: number;
    type: number;
    filter?: any;
  }): Promise<{
    current_page: number;
    last_page: number;
    total: number;
    data: Teiban[];
  }> {
    const uri = "/teiban";
    let response = await get(uri, params);

    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    const responseData = response.data;
    const current_page = responseData.current_page;
    const last_page = responseData.last_page;
    const total = responseData.total;

    const teibans = Array.isArray(responseData.data)
      ? responseData.data.map((data: any) => this.set(data))
      : [this.set(responseData.data)];

    return {
      current_page: current_page,
      last_page: last_page,
      data: teibans,
      total: total,
    };
  }

  /**
   * 定番取得
   * @param params
   * @returns list
   */
  static async destroy(id: number): Promise<{}> {
    const uri = "/teiban/" + id;
    let response = await post(uri, {}, ApiMethodType.DELETE);

    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return {};
  }

  /**
   * 定番参照
   * @param params
   * @returns Teiban
   */
  static async show(id: number): Promise<Teiban> {
    const uri = "/teiban/" + id;
    let response = await get(uri, {});

    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return this.set(response.data);
  }

  /**
   * 登録処理
   * @param params
   * @returns list
   */
  static async store(params: {
    id?: number;
    type: number;
    billing_date: Date;
    to_org: number;
    from_org: number;
    work_type_id: number;
    work: string;
    unit_price: string;
    quantity: string;
    total: string;
    source_id: number;
    draft?: number;
    work_type_small_id: number;
    work_type_small_name: string;
  }): Promise<{
    data: {};
  }> {
    let methodType =
      params.id === undefined ? ApiMethodType.POST : ApiMethodType.PUT;
    let uri =
      methodType === ApiMethodType.POST ? "/teiban" : "/teiban/" + params.id;

    let response = await post(uri, params, methodType);

    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return { data: { result: true } };
  }

  /**
   * 並び替え
   * @param params
   * @returns
   */
  static async updateOrder(params: { items: { id: number; order: number }[] }) {
    const uri = "/teiban/updateOrder";
    let response = await post(uri, params);

    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return { data: { result: true } };
  }

  /**
   * 設定日付を更新
   * @param params
   * @returns
   */
  static async updateTargetDay(params: {
    id: number;
    targetDay: string;
  }): Promise<Teiban> {
    const uri = "/teiban/updateTargetDay";
    let response = await post(uri, {
      id: params.id,
      targetDay: params.targetDay,
    });

    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return this.set(response.data);
  }

  /**
   * 設定数量を更新
   * @param params
   * @returns
   */
  static async updateQuantity(params: {
    id: number;
    quantity: string;
  }): Promise<Teiban> {
    const uri = "/teiban/updateQuantity";
    let response = await post(uri, {
      id: params.id,
      quantity: params.quantity,
    });

    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return this.set(response.data);
  }

  /**
   * 定番新規の初期値
   * @param params
   * @returns
   */
  static async default(): Promise<{
    from_org: Org;
    to_org: Org;
    unit_price: number;
    work: string;
    work_type: WorkType;
    work_small_type?: WorkType;
  }> {
    const uri = "/teiban/default";
    let response = await post(uri, {});

    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    const data = response.data;
    return {
      from_org: data.from_org,
      to_org: data.to_org,
      unit_price: data.unit_price,
      work: data.work,
      work_type: data.work_type,
      work_small_type: data?.work_small_type,
    };
  }

  /**
   * 定番前後のID
   * @param params
   * @returns
   */
  static async previousAndNextId(id: number): Promise<{
    previousId?: number;
    nextId?: number;
  }> {
    const uri = "/teiban/getPreviousAndNext";
    let response = await post(uri, { id: id });
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    const data = response.data;
    return {
      previousId: data.previousId,
      nextId: data.nextId,
    };
  }

  /**
   * 複数のIDを指定し一括削除する
   * @param params
   * @returns
   */
  static async deleteByIds(ids: number[]): Promise<{}> {
    const uri = "/teiban/deleteByIds";
    let response = await post(uri, { ids: ids });
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    const data = response.data;
    return {};
  }

  /**
   * 全ての定番を請求にコピーする
   * @param params
   * @returns
   */
  static async copyByTeiban(params: {
    teibanIds?: number[];
    ym: string;
  }): Promise<{}> {
    const uri = "/invoice/copyByTeiban";
    let response = await post(uri, params);
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    const data = response.data;
    return {};
  }

  /**
   * 先月の請求を請求にコピーする
   * @param params
   * @returns
   */
  static async copyLastMonth(parms: {
    ym: string;
    lastYm: string;
  }): Promise<{}> {
    const uri = "/invoice/copyLastMonth";
    let response = await post(uri, parms);
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    const data = response.data;
    return {};
  }
}
